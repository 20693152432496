import { i18n } from '@lingui/core';

import * as moment from 'moment';

import lazyImportWithRetry from 'utils/lazyImportWithRetry';

export const actionTypes = {
  SWITCH_LANGUAGE_REQUEST: 'SWITCH_LANGUAGE_REQUEST',
  SWITCH_LANGUAGE_SUCCESS: 'SWITCH_LANGUAGE_SUCCESS',
  SWITCH_LANGUAGE_FAILURE: 'SWITCH_LANGUAGE_FAILURE',
};

export const fnsLocales = {};

export const loadLanguage = async (languageValue) => {
  // Load the locale wording
  const catalog = await lazyImportWithRetry(() =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "loc-[index]", webpackExclude: /_lib/ */
      `../../locales/${languageValue}/messages.js`
    )
  );
  // Load the locale date formatting
  fnsLocales[languageValue] = await lazyImportWithRetry(
    () =>
      import(
        /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
        `date-fns/locale/${
          (languageValue === 'en' && 'en-GB') ||
          (languageValue?.includes('fr') && 'fr') ||
          languageValue
        }/index.js`
      ),
    `date-fns-${languageValue}`
  );
  i18n.load(languageValue, catalog.messages);
  // Set first date formatting locale to make sure it is set before redux is reloaded
  window.__locale__ = languageValue;
  moment.locale(languageValue);
  i18n.activate(languageValue);
  window.HelpCrunch?.('setPhraseList', languageValue);
};

export const switchLanguage = (language) => async (dispatch, getState) => {
  loadLanguage(language);
  return dispatch({
    language,
    type: actionTypes.SWITCH_LANGUAGE_SUCCESS,
  });
};
