import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { defaultItemsPerPage, defaultPage } from 'store/monitor/channelsSlice';

import BeatingLoader from 'components/ui/BeatingLoader';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import Segment from 'components/ui/Segment';
import emptyDataUrl from 'components/ui/svg/undraw_empty_xct9.svg';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import ActionBar from './ActionBar';
import ListPagination from './Pagination';
import HeaderFactory from './header';

const sortItems = (items, { key, ascending }, accessor, sortType) =>
  items.sort((element1, element2) => {
    const element1Value = (accessor && accessor(element1)) || element1[key];
    const element2Value = (accessor && accessor(element2)) || element2[key];

    // If value is boolean do not consider `ascending` to have true value always first
    if (element1Value === true) return -1;
    if (element1Value === false) return 0;
    let sortValue = 0;
    if (element1Value !== null && element1Value !== undefined) {
      if (sortType === 'basic') {
        sortValue = element2Value - element1Value;
      } else if (element1Value?.localeCompare) {
        sortValue = element1Value.localeCompare(element2Value);
      } else if (typeof sortType === 'function') {
        sortValue = sortType(element1Value, element2Value);
      }
    } else if (element2Value !== null && element2Value !== undefined) {
      sortValue = 1;
    }

    return sortValue * (ascending ? 1 : -1);
  });

const MaybeClickableSegment = styled(Segment)`
  &&& {
    display: flex;
    flex-direction: column;
    ${({ compacted }) =>
      compacted
        ? `padding: ${svars.spaceNormal}; padding-right: 0; margin: ${svars.spaceSmaller} 3px;`
        : ''}
    ${({ clickable }) =>
      clickable
        ? css`
            ${svars.hoverClickableCss}
            ${svars.activeClickableCss}
          `
        : 'background: none'}
  }
`;

function ManagementList({
  items,
  displayFavoritesOnly,
  onToggleDisplayFavouritesOnly,
  defaultSorted,
  renderItemRow,
  withTextFilter,
  textFilterPlaceholder,
  actions,
  loading,
  emptySearchHeader,
  getTextFromItem,
  emptyListHeader,
  EmptyListContent,
  onRowClick,
  rowFields,
  nActions,
  isRowActive,
  rowIdAccessor,
  listStyle,
  testid,
  itemsToFetch,
  hasLockedProperty,
  payloadAccessor,
  updateItems,
  compact,
  style,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [columnSorted, setColumnSorted] = useState(defaultSorted);
  const [filteredItems, setFilteredItems] = useState(items);
  const [facetSearchValue, setFacetSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [totalPages, setTotalPages] = useState(0);
  const [showArchived, setShowArchived] = useState(false);

  const fetchItems = useCallback(
    (page, itemsPerPage, sortField, sortOrder, searchQuery) => {
      const effectivePage = page === 0 ? 1 : page;
      dispatch(
        itemsToFetch({
          campaignId,
          page: effectivePage,
          itemsPerPage,
          sortField,
          sortOrder,
          searchQuery,
          showArchived,
        })
      )
        .then((response) => {
          setFilteredItems(response.payload[payloadAccessor]);
          setTotalPages(
            Math.ceil(response.payload.count / defaultItemsPerPage)
          );
        })
        .catch(() => {});
    },
    [dispatch, campaignId, currentPage, showArchived]
  );
  const onResetAllFilters = useCallback(() => {
    setFacetSearchValue('');
  }, [items, columnSorted]);

  const onItemSort = useCallback(
    (fieldName) => () => {
      const newFacetSorted = {
        key: fieldName,
        ascending:
          columnSorted.key !== fieldName ||
          (columnSorted.key === fieldName && !columnSorted.ascending),
      };
      setColumnSorted(newFacetSorted);
      if (!itemsToFetch) {
        setFilteredItems((prevItems) =>
          sortItems(
            [...prevItems],
            newFacetSorted,
            rowFields.find(({ id }) => id === fieldName)?.accessor
          )
        );
      }
    },
    [items, columnSorted, filteredItems]
  );

  const onUpdateSearchResults = useCallback(
    (e, { searchValue }) => {
      if (itemsToFetch) {
        setCurrentPage(1);
        fetchItems(
          currentPage,
          defaultItemsPerPage,
          columnSorted.key,
          columnSorted.ascending ? 1 : -1,
          searchValue
        );
      } else {
        const sortField = rowFields.find(({ id }) => id === columnSorted?.key);
        if (items && items.length) {
          const baseItems = displayFavoritesOnly
            ? items.filter(({ favorite }) => favorite)
            : [...items];

          const updatedFilteredItems = sortItems(
            searchValue
              ? accentInsensitiveSearch(baseItems, searchValue, getTextFromItem)
              : baseItems,
            columnSorted,
            sortField?.accessor,
            sortField?.sortType
          );
          setFilteredItems(updatedFilteredItems);
        } else {
          setFilteredItems([]);
        }
      }
    },
    [items, displayFavoritesOnly, columnSorted, showArchived]
  );

  const handlePageChange = useCallback(
    (e, { activePage }) => {
      const effectivePage = activePage === 0 ? 1 : activePage;
      setCurrentPage(effectivePage);
      if (itemsToFetch) {
        fetchItems(
          activePage,
          defaultItemsPerPage,
          columnSorted.key,
          columnSorted.ascending ? 1 : -1,
          facetSearchValue
        );
      }
    },
    [
      fetchItems,
      itemsToFetch,
      columnSorted.key,
      columnSorted.ascending,
      facetSearchValue,
    ]
  );

  const debouncedFacetSearchValue = useDebounce(
    facetSearchValue,
    itemsToFetch ? 500 : null
  );

  const onResetSearch = useCallback(() => {
    setFacetSearchValue('');
  }, []);

  const onSearchChange = useCallback((e, { value }) => {
    setFacetSearchValue(value);
  }, []);

  const HeaderComponent = ManagementList.HeaderFactory([
    ...rowFields,
    { key: 'actions', width: 55 * nActions, auto: 'true' },
    { key: 'menu', width: 10 },
  ]);

  useEffect(() => {
    onUpdateSearchResults(null, { searchValue: debouncedFacetSearchValue });
  }, [debouncedFacetSearchValue, columnSorted, onUpdateSearchResults]);

  useEffect(() => {
    if (updateItems) {
      setFilteredItems(updateItems);
    }
  }, [items]);

  return (
    <>
      <ActionBar
        textFilterPlaceholder={textFilterPlaceholder}
        onSearchChange={onSearchChange}
        onResetSearch={onResetSearch}
        facetSearchValue={facetSearchValue}
        onToggleDisplayFavouritesOnly={onToggleDisplayFavouritesOnly}
        displayFavoritesOnly={displayFavoritesOnly}
        actions={actions}
        testid={testid}
        withTextFilter={withTextFilter}
        hasLockedProperty={hasLockedProperty}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
      />
      {(loading && <BeatingLoader />) ||
        (!filteredItems?.length && (
          <EmptyDataPage
            headerText={emptySearchHeader || emptyListHeader}
            illustrationUrl={emptyDataUrl}
            actionComponent={
              <EmptyListContent resetFilters={onResetAllFilters} />
            }
          />
        )) || (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto hidden',
              alignItems: 'baseline',
              ...style,
            }}
          >
            <HeaderComponent onSort={onItemSort} sorted={columnSorted} />
            <ItemList
              compact={compact}
              items={filteredItems}
              rowIdAccessor={rowIdAccessor}
              onRowClick={onRowClick}
              renderItemRow={renderItemRow}
              rowFields={rowFields}
              isRowActive={isRowActive}
              listStyle={listStyle}
            />
            {itemsToFetch && totalPages && filteredItems?.length ? (
              <>
                <br />
                <ListPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  loading={loading}
                />
              </>
            ) : null}
          </div>
        )}
    </>
  );
}

ManagementList.propTypes = {
  /** Array of items to be displayed in the list. */
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  /** Flag to show only favorite items in the list view. */
  displayFavoritesOnly: PropTypes.bool,
  /** Callback function to toggle the display of favorite items only. */
  onToggleDisplayFavouritesOnly: PropTypes.func,
  /** Default sorting configuration for the list items. */
  defaultSorted: PropTypes.shape({
    /** Field by which to sort items initially. */
    key: PropTypes.string,
    /** Specifies ascending sort order if true. */
    ascending: PropTypes.bool,
  }).isRequired,
  /** Function to render each row item in the list, taking item data as input. */
  renderItemRow: PropTypes.func.isRequired,
  /** List of fields to display in each row, defining the structure of displayed data. */
  rowFields: PropTypes.arrayOf(commonPropTypes.uiFields),
  /** Placeholder text for the text filter input field. */
  textFilterPlaceholder: PropTypes.string,
  /** Component rendering any actions associated with list items, such as create or delete. */
  actions: PropTypes.node,
  /** Indicates if the list is loading data, often tied to fetch or async operations. */
  loading: PropTypes.bool,
  /** Header displayed when no items are found after performing a search. */
  emptySearchHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Function that extracts and returns the text content of each item for filtering purposes. */
  getTextFromItem: PropTypes.func,
  /** Header shown when the list is empty. */
  emptyListHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /** Custom content displayed when the list has no items. */
  EmptyListContent: PropTypes.oneOfType([PropTypes.func]),
  /** Callback function that is triggered when a row is clicked, usually for selecting or focusing on an item. */
  onRowClick: PropTypes.func,
  /** Number of actions available for each row, derived from an array length of actions. */
  nActions: PropTypes.number,
  /** Determines if a text filter should be shown for list searching. */
  withTextFilter: PropTypes.bool,
  /** Function that returns a boolean indicating if a row should be marked as active. */
  isRowActive: PropTypes.func,
  /** Unique identifier used for testing purposes, allowing easy element selection. */
  testid: PropTypes.string,
  /** Style type for list presentation, often used to apply specific class or layout options. */
  listStyle: PropTypes.shape({}),
  /** Function to retrieve the list items, required for paginated lists. */
  itemsToFetch: PropTypes.func,
  /** Boolean flag indicating if items have a locked property affecting display or actions. */
  hasLockedProperty: PropTypes.bool,
  /** Function to refresh or update the list of items; useful for real-time data updates. */
  updateItems: PropTypes.func,
  /** Specifies the property key to access items in the response data when fetched. */
  payloadAccessor: PropTypes.string,
  /** Flag to render the list in a compact mode. */
  compact: PropTypes.bool,
  /** Accessor for the unique identifier of each item. */
  rowIdAccessor: PropTypes.string,
  /** Custom styles for the list component. */
  style: commonPropTypes.style,
};

ManagementList.defaultProps = {
  displayFavoritesOnly: false,
  emptySearchHeader: null,
  getTextFromItem: ({ name }) => name,
  emptyListHeader: null,
  EmptyListContent: () => null,
  items: undefined,
  onToggleDisplayFavouritesOnly: null,
  loading: false,
  onRowClick: null,
  nActions: 0,
  testid: undefined,
  withTextFilter: true,
  isRowActive: null,
  textFilterPlaceholder: null,
  listStyle: {},
  itemsToFetch: null,
  hasLockedProperty: false,
  updateItems: null,
  payloadAccessor: null,
  rowFields: [],
  compact: false,
  rowIdAccessor: 'id',
  actions: null,
  style: {},
};
ManagementList.HeaderFactory = HeaderFactory;

export default ManagementList;

function ItemList({
  items,
  rowIdAccessor,
  onRowClick,
  renderItemRow,
  rowFields,
  listStyle,
  isRowActive,
  compact,
}) {
  return (
    <div
      style={{
        height: '100%',
        minWidth: '100%',
        overflow: 'clip auto',
        ...listStyle,
      }}
    >
      {items.map((item, index) => (
        <MaybeClickableSegment
          compacted={compact ? '1' : ''}
          key={`facet-${item[rowIdAccessor]}`}
          clickable={onRowClick ? '1' : ''}
          active={isRowActive?.(item) ? '1' : ''}
          onClick={onRowClick ? () => onRowClick(item) : null}
          data-testid={`bo-list-card-${item?.id}`}
        >
          {renderItemRow({ fields: rowFields, item, index })}
        </MaybeClickableSegment>
      ))}
    </div>
  );
}

ItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  renderItemRow: PropTypes.func.isRequired,
  rowFields: PropTypes.arrayOf(commonPropTypes.uiFields).isRequired,
  onRowClick: PropTypes.func,
  listStyle: PropTypes.shape({}),
  isRowActive: PropTypes.func,
  // Whether the list should be compact or not
  compact: PropTypes.bool,
  rowIdAccessor: PropTypes.string.isRequired,
};
ItemList.defaultProps = {
  items: undefined,
  onRowClick: null,
  listStyle: {},
  isRowActive: null,
  compact: false,
};
