import { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import { TextInput } from './TextInput';

const sliderThumbSize = '24px';
const sliderThumbClickableSize = '24px';
const sliderHeight = '10px';

const minWidth = '200px';
const maxWidth = '300px';

export default styled.input`
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const DualRangeInput = styled.div`
  display: flex;
  min-width: ${minWidth};
  max-width: ${maxWidth};
  * {
    outline: none;
  }
  .range-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .sliders-control {
    position: relative;
    min-height: ${sliderHeight};
    flex-grow: 1;
    margin: 0 ${svars.spaceNormal};
    height: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: ${minWidth};
    max-width: ${maxWidth};
  }

  .form-control {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: ${svars.colorGrey};
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: ${sliderThumbClickableSize};
    height: ${sliderThumbClickableSize};
    background-color: ${svars.colorWhite};
    border-radius: 50%;
    cursor: pointer;
    margin-top: -${(sliderThumbClickableSize - sliderThumbSize) / 2}px;
    border: 2px solid ${svars.colorPrimary};
    transform: translateX(-2px);
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: ${sliderThumbClickableSize};
    height: ${sliderThumbClickableSize};
    background-color: ${svars.colorWhite};
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid ${svars.colorPrimary};
    transform: translateX(-2px);
  }

  input[type='range']::-webkit-slider-thumb:active {
    box-shadow: inset 4px 4px 6px ${svars.colorGreyMediumLight},
      inset -4px -4px 6px ${svars.colorLighterGrey};
    border: 2px solid ${svars.colorPrimary};
  }

  input[type='range']::-moz-range-thumb:active {
    box-shadow: inset 4px 4px 6px ${svars.colorGreyMediumLight},
      inset -4px -4px 6px ${svars.colorLighterGrey};
    border: 2px solid ${svars.colorPrimary};
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    height: ${sliderHeight};
    width: 100%;
    position: absolute;
    z-index: 1;
    background: transparent;
    pointer-events: none;
  }

  #fromSlider::-webkit-slider-thumb {
    background-color: ${svars.accentColorLightest};
  }

  #fromSlider::-moz-range-thumb {
    background-color: ${svars.accentColorLightest};
  }

  #toSlider::-webkit-slider-thumb {
    /* background-color: ${svars.accentColorMedium}; */
    background-color: ${svars.accentColorLightest};
    transform: translateX(4px);
    z-index: 2;
  }

  #toSlider::-moz-range-thumb {
    background-color: ${svars.accentColorMedium};
    transform: translateX(4px);
    z-index: 2;
  }

  #fromSlider {
    height: 0;
    z-index: 2;
    top: 18px;
    transform: translateX(-4px);
  }
`;

const Track = styled.div`
  position: absolute;
  height: ${sliderHeight};
  width: 100%;
  background-color: ${svars.colorLightGrey};
  border-radius: ${svars.borderRadius};
  pointer-events: none;
`;

const Highlight = styled.div`
  position: absolute;
  height: ${sliderHeight};
  background: ${svars.accentColorClearMedium};
  pointer-events: none;
  z-index: 1;
`;

const parseLocaleNumber = (value) => {
  if (value === '') return '';
  const normalizedValue = String(value).replace(/[,.]/g, '');
  const parsed = parseFloat(normalizedValue);
  return Number.isNaN(parsed) ? '' : parsed;
};

export function DualRange({
  fromValue,
  toValue,
  onFromChange,
  onToChange,
  fromMin: fromMinInitial,
  fromMax: fromMaxInitial,
  toMin,
  toMax,
  style,
}) {
  const fromMin = fromMinInitial || 0;
  const fromMax =
    fromMaxInitial && fromMaxInitial >= fromMin ? fromMaxInitial : fromMin;
  // Use state to store current values while debouncing changes to the parent
  const [currentFromValue, setCurrentFromValue] = useState(
    fromValue || fromMin
  );
  const [currentToValue, setCurrentToValue] = useState(toValue || toMax);

  const calculateHighlightStyle = () => {
    const fromPercent = Math.max(
      0,
      ((currentFromValue - fromMin) / (toMax - fromMin)) * 100
    );
    const toPercent = Math.max(
      fromPercent,
      ((currentToValue - fromMin) / (toMax - fromMin)) * 100
    );
    return {
      left: `${fromPercent + 0.5}%`,
      width: `${toPercent - fromPercent}%`,
    };
  };

  // Use different state values to store text input values to allow setting the current value
  // only at blur time
  const [fromInputValue, setFromInputValue] = useState(currentFromValue);
  const [toInputValue, setToInputValue] = useState(currentToValue);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setCurrentFromValue(fromValue || fromMin);
  }, [fromValue, fromMin]);

  useEffect(() => {
    setCurrentToValue(toValue || toMax);
  }, [toValue, toMax]);

  const debouncedFromChange = useCallback(debounce(onFromChange, 250), [
    onFromChange,
  ]);
  const debouncedToChange = useCallback(debounce(onToChange, 250), [
    onToChange,
  ]);

  const handleFromChange = (e) => {
    const value =
      e.target.value === ''
        ? ''
        : Math.min(
            Math.max(Math.round(parseLocaleNumber(e.target.value)), fromMin),
            currentToValue,
            fromMax
          );
    setFromInputValue(value);
    if (value !== '') {
      setCurrentFromValue(value);
      debouncedFromChange(value);
    }
  };

  const handleToChange = (e) => {
    const value =
      e.target.value === ''
        ? ''
        : Math.min(
            Math.max(
              Math.round(parseLocaleNumber(e.target.value)),
              toMin,
              currentFromValue
            ),
            toMax
          );
    setToInputValue(value);
    if (value !== '') {
      setCurrentToValue(value);
      debouncedToChange(value);
    }
  };

  const handleTrackClick = (e) => {
    if (isDragging) return;

    const track = e.target;
    const rect = track.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const trackWidth = rect.width;
    const clickValue = Math.round(
      (clickPosition / trackWidth) * (toMax - fromMin) + fromMin
    );

    const fromDistance = Math.abs(clickValue - currentFromValue);
    const toDistance = Math.abs(clickValue - currentToValue);

    if (fromDistance < toDistance && clickValue < currentToValue) {
      setCurrentFromValue(clickValue);
      debouncedFromChange(clickValue);
      setFromInputValue(clickValue);
    } else if (clickValue > currentFromValue) {
      setCurrentToValue(clickValue);
      debouncedToChange(clickValue);
      setToInputValue(clickValue);
    }
  };

  const handleTrackKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleTrackClick(e);
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setTimeout(() => {
      setIsDragging(false);
    }, 200);
  };

  const handleFromInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const numericValue =
      value === ''
        ? ''
        : Math.min(
            Math.max(parseLocaleNumber(value), fromMin),
            currentToValue,
            fromMax
          );
    setFromInputValue(numericValue);
    if (value !== '') {
      setCurrentFromValue(numericValue);
      debouncedFromChange(numericValue);
    }
  };

  const handleToInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    const numericValue =
      value === ''
        ? ''
        : Math.min(
            Math.max(parseLocaleNumber(value), toMin, currentFromValue),
            toMax
          );
    setToInputValue(numericValue);
    if (value !== '') {
      setCurrentToValue(numericValue);
      debouncedToChange(numericValue);
    }
  };

  return (
    <DualRangeInput style={style}>
      <div className="range-container">
        <TextInput
          type="text"
          style={{ width: '40px', marginRight: svars.spaceNormal }}
          value={fromInputValue}
          min={fromMin}
          max={fromMax}
          onChange={handleFromInputChange}
          onBlur={() => {
            if (fromInputValue === '') {
              const previousValue = currentFromValue || fromMin;
              setCurrentFromValue(previousValue);
              debouncedFromChange(previousValue);
              setFromInputValue(`${previousValue}`);
            }
          }}
        />
        <div
          className="sliders-control"
          onClick={handleTrackClick}
          onKeyDown={handleTrackKeyDown}
          tabIndex={0}
          role="button"
          aria-label="Adjust range"
        >
          <Track />
          <Highlight style={calculateHighlightStyle()} />
          <input
            style={{ outline: 'none' }}
            type="range"
            id="fromSlider"
            min={fromMin}
            max={fromMax}
            value={currentFromValue === '' ? fromMin : currentFromValue}
            onChange={handleFromChange}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
          />
          <input
            style={{ outline: 'none' }}
            type="range"
            id="toSlider"
            min={toMin}
            max={toMax}
            value={currentToValue === '' ? toMax : currentToValue}
            onChange={handleToChange}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
          />
        </div>
        <TextInput
          style={{ width: '40px', marginLeft: svars.spaceNormal }}
          type="text"
          value={toInputValue}
          min={toMin}
          max={toMax}
          onChange={handleToInputChange}
          onBlur={() => {
            if (toInputValue === '') {
              const previousValue = currentToValue || toMax;
              setCurrentToValue(previousValue);
              debouncedToChange(previousValue);
              setToInputValue(`${previousValue}`);
            }
          }}
        />
      </div>
    </DualRangeInput>
  );
}

DualRange.propTypes = {
  fromValue: PropTypes.number,
  toValue: PropTypes.number,
  onFromChange: PropTypes.func.isRequired,
  onToChange: PropTypes.func.isRequired,
  fromMin: PropTypes.number.isRequired,
  fromMax: PropTypes.number.isRequired,
  toMin: PropTypes.number.isRequired,
  toMax: PropTypes.number.isRequired,
  style: commonPropTypes.style,
};

DualRange.defaultProps = {
  fromValue: null,
  toValue: null,
  style: null,
};
