import { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Grid, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ResponsiveContainer } from 'recharts';

import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import BaseTimeSeriesVisualization from 'components/ui/visualization/BaseTimeSeriesVisualization';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const FullWidthHeader = styled(Header)`
  &&& {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
`;

function VolumeChart({ data }) {
  return (
    <BaseTimeSeriesVisualization
      data={data}
      tooltipFieldKeys={[
        'date',
        'cumulated',
        'n_polarities',
        'average_sentiment',
      ]}
      childrenAttributes={[
        {
          key: 'cumulated',
          y_id: 0,
        },
        { key: 'n_negative', y_id: 1 },
        { key: 'n_neutral', y_id: 1 },
        { key: 'n_positive', y_id: 1 },
      ]}
      yAxisProps={{
        0: {},
        1: { orientation: 'right' },
      }}
    />
  );
}

VolumeChart.propTypes = {
  data: commonPropTypes.timeSeries,
};

VolumeChart.defaultProps = {
  data: undefined,
};

function SentimentChart({ data, chartId }) {
  const sentiments = data.map((it) => it.average_sentiment);
  const gradientName = `colorSentiment-${chartId}`;
  return (
    <BaseTimeSeriesVisualization
      data={data}
      sentimentGradientName={gradientName}
      sentiments={sentiments}
      tooltipFieldKeys={['date', 'n_chunks', 'average_sentiment']}
      childrenAttributes={[
        {
          key: 'n_chunks',
          y_id: 0,
        },
        {
          key: 'average_sentiment',
          y_id: 1,
        },
      ]}
      yAxisProps={{
        0: { orientation: 'right' },
        1: { orientation: 'left' },
      }}
    />
  );
}

SentimentChart.propTypes = {
  data: commonPropTypes.timeSeries,
  chartId: PropTypes.string.isRequired,
};

SentimentChart.defaultProps = {
  data: undefined,
};

function CustomChart({
  chartId,
  data,
  Chart,
  setModalState,
  headerText,
  loading,
}) {
  const onExpand = () =>
    setModalState({
      expandChart: Chart,
      modalHeaderText: headerText,
    });

  return (
    <Grid.Column width={6} stretched>
      <Segment>
        <FullWidthHeader data-testid="analysis-kpi-header">
          {headerText}
          <span
            style={{
              height: 'fit-content',
              display: 'inline-flex',
              justifyContent: 'center',
            }}
          >
            <ExportAsImage
              disabled={loading}
              exportId={chartId}
              exportName={headerText}
              exportAsImageTestId={`overview-export-as-image-icon-${chartId}`}
            />
            <AnalyticsAwareHoverableIconButtonWithTooltip
              gaCategory="Dashboard - overview"
              gaAction="Expand chart"
              compact
              size="large"
              mouseEnterDelay={300}
              mouseLeaveDelay={50}
              help={t({ id: 'zoom' })}
              position="top center"
              name="expand"
              onClick={onExpand}
              style={{ marginLeft: svars.spaceNormal, padding: 0 }}
            />
          </span>
        </FullWidthHeader>
        {loading ? (
          <LoadingDataVisualization height={svars.chartHeight} />
        ) : (
          <ResponsiveContainer
            id={chartId}
            height={svars.chartHeight}
            width="100%"
          >
            {(data === undefined && <div />) ||
              (data && Chart && <Chart data={data} chartId={chartId} />) || (
                <EmptyDataVisualization />
              )}
          </ResponsiveContainer>
        )}
      </Segment>
    </Grid.Column>
  );
}

CustomChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: commonPropTypes.timeSeries,
  Chart: PropTypes.func.isRequired,
  setModalState: PropTypes.func.isRequired,
  headerText: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

CustomChart.defaultProps = {
  data: undefined,
  loading: false,
};

function SentimentVolumeTimeSeriesVisualizations({ data, loading }) {
  const { i18n } = useLingui();
  const [modalChartState, setModalState] = useState({
    expandChart: null,
    modalHeaderText: '',
  });

  return (
    <>
      <CustomChart
        chartId="tsVolumeChart"
        setModalState={setModalState}
        data={data}
        Chart={VolumeChart}
        headerText={
          capitalize(i18n._(t({ id: 'temporal-evolution-of-volume' })))
        }
        loading={loading}
      />
      <CustomChart
        chartId="tsSentimentChart"
        setModalState={setModalState}
        data={data}
        Chart={SentimentChart}
        headerText={
          capitalize(i18n._(t({ id: 'temporal-evolution-of-sentiment' })))
        }
        loading={loading}
      />

      <Modal
        closeIcon
        onClose={() =>
          setModalState({ modalHeaderText: '', expandChart: null })
        }
        open={modalChartState.expandChart != null}
        style={{
          top: svars.spaceMassive,
          bottom: svars.spaceMassive,
          width: '90%',
        }}
      >
        <Modal.Header content={modalChartState.modalHeaderText} />
        <Modal.Content>
          <ResponsiveContainer height={1.7 * svars.chartHeight} width="100%">
            {modalChartState.expandChart ? (
              modalChartState.expandChart({ data, chartId: 'expanded-svtss' })
            ) : (
              <div />
            )}
          </ResponsiveContainer>
        </Modal.Content>
      </Modal>
    </>
  );
}

SentimentVolumeTimeSeriesVisualizations.propTypes = {
  data: commonPropTypes.timeSeries,
  loading: PropTypes.bool,
};

SentimentVolumeTimeSeriesVisualizations.defaultProps = {
  data: undefined,
  loading: false,
};

export default SentimentVolumeTimeSeriesVisualizations;
