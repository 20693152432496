import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { LightHeader } from 'components/ui/Header';

import * as svars from 'assets/style/variables';

const SHAPE_SIZE = '38px';
const NPS_BUTTON_SIZE_PX = 30;
const BUTTON_MARGIN_PX = 3;

const getRangeWidth = (nButtons) =>
  nButtons * (NPS_BUTTON_SIZE_PX + 2 * BUTTON_MARGIN_PX) -
  (nButtons ? 2 : 0) * BUTTON_MARGIN_PX;

const relativeRangeWidth = (nButtons) => `${(nButtons / 11) * 100}%`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: ${svars.spaceNormal};
`;

const DummyButton = styled.button`
  width: ${NPS_BUTTON_SIZE_PX}px;
  height: ${NPS_BUTTON_SIZE_PX}px;
  margin: ${BUTTON_MARGIN_PX}px;
  border: ${svars.lightBorderStyle};
  box-shadow: ${svars.baseBoxShadow};
  border-radius: ${svars.borderRadius};
  cursor: default;
  color: ${({ background }) =>
    svars.getReadableColor(background || svars.colorWhite)};
  background: ${({ background }) => background || svars.colorWhite};
  user-select: none;
  outline: none;
`;

const ShapeButton = styled.button`
  width: ${SHAPE_SIZE};
  height: ${SHAPE_SIZE};
  font-size: ${svars.fontSizeLarge};
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: ${svars.borderRadius};
  cursor: default;
  background: none;
  color: #ccc;
  transition: color 0.15s;
`;

const ShapeButtonContainer = styled(Container)`
  &:hover ${ShapeButton} {
    color: ${(props) =>
      props.shape === 'heart'
        ? svars.colorDanger
        : svars.colorYellowWarning}; /* Hover color */
  }

  ${ShapeButton}:hover ~ ${ShapeButton} {
    color: #ccc; /* Reset color for subsequent buttons */
  }
`;

const Bracket = styled.div`
  position: absolute;
  left: ${({ start }) =>
    `calc(${relativeRangeWidth(start)} + ${
      (start ? 2 : 0) * BUTTON_MARGIN_PX
    }px)`};
  background: ${({ start, elements }) =>
    `linear-gradient(to right, ${svars.NPS_COLORS_ARRAY.slice(
      start,
      start + elements
    ).join(', ')})`};
  width: ${({ elements, start }) =>
    `calc(${relativeRangeWidth(elements)} - ${
      (start ? 2 : 1) * BUTTON_MARGIN_PX
    }px)`};
  top: 0;
  height: 5px;
  border-radius: 50px;
`;

const BracketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${NPS_BUTTON_SIZE_PX}px;
  margin: 0 auto;
  max-width: ${getRangeWidth(11)}px;
  width: 100%;
`;

const BracketLabel = styled.div`
  position: absolute;
  top: ${NPS_BUTTON_SIZE_PX / 3}px;
  transform: translateX(-50%);
  left: ${({ start, elements }) =>
    `calc(
    ${relativeRangeWidth(start + elements / 2)})`};
`;

export function StarIcon({ color, size, starIconTestId }) {
  return (
    <svg
      data-testid={starIconTestId}
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
        fill="currentColor"
      />
    </svg>
  );
}

StarIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  starIconTestId: PropTypes.string,
};
StarIcon.defaultProps = {
  color: 'none',
  size: 32,
  starIconTestId: 'bo-star-icon',
};

export function HeartIcon({ color, size, heartIconTestId }) {
  return (
    <svg
      data-testid={heartIconTestId}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.35L10.55 20.03C5.4 15.36 2 12.28 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.28 18.6 15.36 13.45 20.04L12 21.35Z"
        fill="currentColor"
      />
    </svg>
  );
}

HeartIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  heartIconTestId: PropTypes.string,
};
HeartIcon.defaultProps = {
  color: 'none',
  size: 30,
  heartIconTestId: 'bo-heart-icon',
};

export function NPSQuestionDummy() {
  return (
    <div>
      <Container>
        {[...Array(11).keys()].map((number) => (
          <DummyButton key={number} type="button">
            {number}
          </DummyButton>
        ))}
      </Container>
      <div style={{ marginTop: '0.5rem', textAlign: 'center' }}>
        <BracketContainer>
          <Bracket start={0} elements={7} />
          <Bracket start={7} elements={2} />
          <Bracket start={9} elements={2} />
          <BracketLabel start={0} elements={7}>
            -100
          </BracketLabel>
          <BracketLabel start={7} elements={2}>
            0
          </BracketLabel>
          <BracketLabel start={9} elements={2}>
            100
          </BracketLabel>
        </BracketContainer>
        <LightHeader style={{ marginTop: 0 }}>
          <Trans id="nps-score-color-legend" />
        </LightHeader>
      </div>
    </div>
  );
}

export function RatingQuestionDummy({ nElements, shape }) {
  const shapes = {
    star: <StarIcon />,
    heart: <HeartIcon />,
  };

  return (
    <ShapeButtonContainer shape={shape}>
      {[...Array(nElements).keys()].map((number) => (
        <ShapeButton key={number} type="button" disabled shape={shape}>
          {shapes[shape]}
        </ShapeButton>
      ))}
    </ShapeButtonContainer>
  );
}

const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${svars.fontSizeXLarge};
  width: 100%;
  margin-top: ${svars.spaceNormal};
  padding: ${svars.spaceNormal};
`;

export function DateQuestionDummy() {
  return (
    <div>
      <DateContainer>
        <Icon name="calendar" style={{ marginRight: svars.spaceNormal }} />
        <span>__ / __ / ____</span>
      </DateContainer>
    </div>
  );
}

RatingQuestionDummy.propTypes = {
  nElements: PropTypes.number.isRequired,
  shape: PropTypes.oneOf(['star', 'heart']).isRequired,
};

export default NPSQuestionDummy;
