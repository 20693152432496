import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'actions/utils';

import createAPISlice, { StateStatus } from 'utils/apiSlice';

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications:load',
  async ({ offset = 0, limit = 20 }) => {
    const response = await api.get(
      `/notifications?offset=${offset}&limit=${limit}`
    );
    return response.data;
  }
);

export const updateNotificationStatus = createAsyncThunk(
  'notifications/updateNotificationStatus',
  async ({ notificationIds, seen, callEndpoint }) => {
    try {
      if (callEndpoint) {
        await api.post('/notifications/mark_status', {
          notification_ids: notificationIds,
          seen,
        });
      }
      return { notificationIds, seen };
    } catch (error) {
      throw new Error(`Failed to update notification status: ${error.message}`);
    }
  }
);

const notificationsSlice = createAPISlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    status: StateStatus.IDLE,
    error: null,
    total_count: 0,
    offset: 0,
    limit: 20,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = StateStatus.PENDING;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = StateStatus.FULFILLED;
        const { offset, limit, total_count, notifications } = action.payload;

        state.total_count = total_count;
        state.offset = offset;
        state.limit = limit;

        if (offset === 0) {
          state.notifications = notifications;
        } else {
          state.notifications = [...state.notifications, ...notifications];
        }
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = StateStatus.REJECTED;
        state.error = action.error.message;
      })
      .addCase(updateNotificationStatus.fulfilled, (state, action) => {
        const { notificationIds, seen } = action.payload;
        notificationIds.forEach((id) => {
          const notification = state.notifications.find((n) => n.id === id);
          if (notification) {
            notification.seen = seen;
          }
        });
      });
  },
});

export default notificationsSlice.reducer;
