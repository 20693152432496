import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, useLingui } from '@lingui/react';
import { Divider, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { campaignAclSelectorFactory } from 'selectors/campaign';

import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { AnalyticsAwareButton } from 'components/ui/button';
import { SecondaryAccentTabButton } from 'components/ui/button/TabButton';
import { PageLayout } from 'components/ui/layout/Page';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import getCampaignSubRoutes from './campaignSubRoutes';

const HomeItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${svars.spaceMedium};
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  &&&& button {
    min-width: 12rem;
  }
`;

function CampaignHomePage() {
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const onNavigate = useCallback((route) => () => navigate(route), []);
  const subRoutes = useMemo(
    () => getCampaignSubRoutes(acl?.a),
    [acl?.a, i18n.locale]
  );
  return (
    <PageLayout compact padded>
      <Segment>
        {subRoutes
          .map(({ header, description, actions }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <HomeItemContainer key={`${i}`}>
              <Header
                style={{
                  width: '100%',
                  maxWidth: '800px',
                  marginRight: svars.spaceMedium,
                }}
              >
                {i18n._(header)}
                <Header.Subheader style={{ marginTop: svars.spaceNormalLarge }}>
                  {i18n._(description)}
                </Header.Subheader>
              </Header>

              <div>
                {actions.map(({ to, ctaText, icon }) => (
                  <AnalyticsAwareButton
                    key={to}
                    onClick={onNavigate(to)}
                    data-testid={`bo-campaign-homepage-cta-buttons-${to}`}
                    style={{
                      marginRight: svars.spaceMedium,
                      marginTop: svars.spaceNormal,
                    }}
                    inputComponent={SecondaryAccentTabButton}
                    gaCategory="Campaign - home"
                    gaAction="Navigate to"
                    gaLabel={to}
                  >
                    <Icon name={icon} />
                    <Trans id={ctaText} render={capitalizedTranslation} />
                  </AnalyticsAwareButton>
                ))}
              </div>
            </HomeItemContainer>
          ))
          .reduce(
            (acc, item, iReduce) =>
              acc.length > 0
                ? // eslint-disable-next-line react/no-array-index-key
                  [...acc, <Divider key={`pcse-${iReduce}`} />, item]
                : [item],
            []
          )}
      </Segment>
    </PageLayout>
  );
}

CampaignHomePage.propTypes = {};
CampaignHomePage.defaultProps = { children: null };

export default CampaignHomePage;
