import { useLocation } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import useHelpcrunch from 'components/ui/navigation/useHelpcrunch';

import { useKeyPress } from 'utils/hooks';
import withClickAnalytics from 'utils/withAnalytics';

import * as svars from 'assets/style/variables';

import { HoverableIconButtonContainer } from '../icon/HoverableIcon';

function BookOpenIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      fill="currentColor"
      width="18"
      height="16"
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: `${svars.spaceSmall} 0`,
      }}
    >
      <path d="M542.2 32.1c-54.8 3.1-163.7 14.4-231 55.6-4.6 2.8-7.3 7.9-7.3 13.2v363.9c0 11.6 12.6 18.9 23.3 13.5 69.2-34.8 169.2-44.3 218.7-46.9 16.9-.9 30-14.4 30-30.7V62.8c0-17.7-15.4-31.7-33.8-30.7zM264.7 87.6C197.5 46.5 88.6 35.2 33.8 32.1 15.4 31 0 45 0 62.8V400.6c0 16.2 13.1 29.8 30 30.7 49.5 2.6 149.6 12.1 218.8 47 10.6 5.4 23.2-1.9 23.2-13.5V100.6c0-5.3-2.6-10.1-7.3-13z" />
    </svg>
  );
}

const StyledSvg = styled(BookOpenIconSvg)`
  color: ${svars.colorIconGrey};

  &:hover {
    color: ${svars.accentColor};
  }
`;

function HoverableBookIconButtonWithTooltip({
  help,
  position,
  showTooltipOn,
  mouseEnterDelay,
  mouseLeaveDelay,
  ...props
}) {
  const trigger = (
    <HoverableIconButtonContainer {...props}>
      <StyledSvg />
    </HoverableIconButtonContainer>
  );
  return (
    <HelpTooltip
      compact
      size="tiny"
      help={help}
      trigger={trigger}
      on={showTooltipOn}
      position={position}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      {...props}
    />
  );
}

HoverableBookIconButtonWithTooltip.propTypes = {
  position: PropTypes.string,
  tooltipInverted: PropTypes.bool,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  accent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showTooltipOn: PropTypes.oneOf(['hover', 'click']),
};
HoverableBookIconButtonWithTooltip.defaultProps = {
  position: undefined,
  tooltipInverted: true,
  mouseEnterDelay: 800,
  mouseLeaveDelay: 200,
  help: null,
  accent: true,
  showTooltipOn: 'hover',
};

export const AnalyticsAwareHoverableBookIconButtonWithTooltip =
  withClickAnalytics(
    (props) => <HoverableBookIconButtonWithTooltip {...props} />,
    'onClick'
  );

function WidgetCloseInteractions() {
  useKeyPress('Escape', () => window.HelpCrunch?.('closeChat'));
  return null;
}
function HelpcrunchBookIconButton({
  articleId,
  anchor,
  helpcrunchIconButtonTestId,
  ...props
}) {
  const [handleClick, active] = useHelpcrunch(articleId, anchor);
  const location = useLocation();

  return window.HelpCrunch ? (
    <>
      {active ? <WidgetCloseInteractions /> : null}
      <AnalyticsAwareHoverableBookIconButtonWithTooltip
        articleId={articleId}
        onClick={handleClick}
        gaCategory={articleId ? `Article ${articleId}` : 'Home'}
        gaAction={active ? 'Close FAQ' : 'Open FAQ'}
        gaLabel={`path=${location.pathname}`}
        active={active}
        help={<Trans id="knowledge-base" />}
        data-testid={helpcrunchIconButtonTestId}
        {...props}
      />
    </>
  ) : null;
}

HelpcrunchBookIconButton.propTypes = {
  articleId: PropTypes.number,
  anchor: PropTypes.string,
  helpcrunchIconButtonTestId: PropTypes.string,
};

HelpcrunchBookIconButton.defaultProps = {
  articleId: null,
  anchor: null,
  helpcrunchIconButtonTestId: undefined,
};

export default HelpcrunchBookIconButton;
