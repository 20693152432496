import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleKnowledgeBase } from 'actions/ui';
import { languageSelector } from 'reducers/locale';
import {
  currentArticleIdSelector,
  knowledgeBaseActiveSelector,
} from 'reducers/ui';

const onLoad = (anchorId) => {
  const iframe = document.getElementsByTagName('iframe')['helpcrunch-iframe'];
  const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
  let interval;
  function findArticleContainer() {
    const articleContainer = innerDoc.getElementsByClassName(
      'scrollbar-container hcw-main-section__scroll kb-article-page ps ps--active-y'
    )[0];
    const anchorTag = innerDoc.getElementById(anchorId);
    if (anchorTag) {
      clearInterval(interval);
      const anchorOffset = anchorTag.offsetTop;
      articleContainer.scrollTo(0, anchorOffset);
    }
  }
  interval = setInterval(findArticleContainer, 250);
  return () => clearInterval(interval);
};

const handleArticleClick =
  (dispatch, active, currentArticleId, newArticleId, anchor, language) =>
  () => {
    if (active && !newArticleId) {
      window.HelpCrunch?.('closeChat');
      dispatch(toggleKnowledgeBase(null, false));
    } else {
      dispatch(toggleKnowledgeBase(newArticleId));
      if (newArticleId && currentArticleId !== newArticleId) {
        window.HelpCrunch?.('openKBArticle', {
          articleId: newArticleId,
          lang: language?.value,
        });
        if (anchor) {
          setTimeout(onLoad(anchor), 250);
        }
      } else {
        window.HelpCrunch?.('openKBArticle', {
          articleId: currentArticleId || -1,
          lang: language?.value,
        });
      }
    }
  };

const useHelpcrunch = (articleId = null, anchor = null) => {
  const currentArticleId = useSelector(currentArticleIdSelector);
  const dispatch = useDispatch();
  const active = useSelector(knowledgeBaseActiveSelector);
  const language = useSelector(languageSelector);

  const handleClick = useCallback(
    handleArticleClick(
      dispatch,
      active,
      currentArticleId,
      articleId,
      anchor,
      language
    ),
    [active, currentArticleId, articleId, anchor, language]
  );
  return [handleClick, active || !!currentArticleId];
};

export function HelpcrunchWidgetManagement() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.HelpCrunch?.('onChatOpen', () => {
      dispatch(toggleKnowledgeBase(null));
    });
    window.HelpCrunch?.('onChatClose', () => {
      dispatch(toggleKnowledgeBase(null, false));
    });
  }, [dispatch]);
  return null;
}

export default useHelpcrunch;
