import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { List, ListItem } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  useTagColorFormatter,
  useTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import {
  entityLabelFormatterSelector,
  viewFacetSourceItemsSelector,
} from 'selectors/entities';
import {
  isComparativeViewFacet,
  selectTransformedFilteringTags,
} from 'selectors/facet';
import {
  baseProductHierarchyGroupSelector,
  facetSourceGroupSelector,
  viewFacetSelector,
} from 'selectors/view';

import HelpTooltip, { ListHelpTooltip } from 'components/ui/HelpTooltip';
import { SecondaryTabButton } from 'components/ui/button/TabButton';

import commonPropTypes from 'utils/commonPropTypes';
import { dateTimeFormatter, formatPeriod } from 'utils/formatter';
import { capitalize } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import FlagItem from '../cells/FlagItem';
import ListChips from '../visualization/ListChips';

export const ImgLogo = styled.img`
  &&&&&&& {
    width: ${svars.logoWidth};
  }
`;

function TagList({ filteringTags, tagLabelFormatter, tagLabelColorFormatter }) {
  if (
    !filteringTags ||
    Object.values(filteringTags).every((tags) => !tags || tags.length === 0)
  ) {
    return null;
  }

  return (
    <List>
      {Object.values(filteringTags).map((tagsPerTagset) =>
        tagsPerTagset && tagsPerTagset.length > 0 ? (
          <ListItem key={tagsPerTagset[0].id}>
            <ListChips
              size="tiny"
              items={tagsPerTagset}
              field="id"
              formatter={tagLabelFormatter}
              colorFormatter={tagLabelColorFormatter}
              style={{
                margin: svars.spaceSmaller,
              }}
            />
          </ListItem>
        ) : null
      )}
    </List>
  );
}

TagList.propTypes = {
  filteringTags: PropTypes.objectOf(PropTypes.arrayOf(commonPropTypes.tag))
    .isRequired,
  tagLabelFormatter: PropTypes.func.isRequired,
  tagLabelColorFormatter: PropTypes.func.isRequired,
};

export default TagList;

function ComparativeProductGroupHelper({
  viewFacet,
  tooltipCommonProps,
  entityLabelFormatter,
}) {
  if (!(viewFacet && viewFacet.comparative_product_hierarchy_groups.length)) {
    return null;
  }
  const commonProps = {
    ...tooltipCommonProps,
  };
  return viewFacet.comparative_product_hierarchy_groups.length > 1 ? (
    <ListHelpTooltip
      hoverable={false}
      {...commonProps}
      title={<Trans id="all-groups" render={capitalizedTranslation} />}
      items={viewFacet.comparative_product_hierarchy_groups}
      getItemText={({ id }) =>
        entityLabelFormatter('productHierarchyGroup', id)
      }
    />
  ) : (
    <ListHelpTooltip
      hoverable={false}
      {...commonProps}
      headerText={viewFacet.comparative_product_hierarchy_groups[0].name}
      title={
        <Trans id="product(s)-service(s)" render={capitalizedTranslation} />
      }
      items={viewFacet.comparative_product_hierarchy_groups[0].items}
      getItemText={({ id }) => entityLabelFormatter('productHierarchy', id)}
    />
  );
}

ComparativeProductGroupHelper.propTypes = {
  viewFacet: commonPropTypes.viewFacet,
  entityLabelFormatter: PropTypes.func.isRequired,
  // eslint-disable-next-line
  tooltipCommonProps: PropTypes.object,
};

ComparativeProductGroupHelper.defaultProps = {
  tooltipCommonProps: {},
  viewFacet: null,
};

const getConfigurationSummaryItems = (
  i18n,
  viewFacet,
  facetSourceGroup,
  facetSourceItems,
  baseProductHierarchyGroup,
  entityLabelFormatter
) => {
  const tooltipCommonProps = {
    mouseEnterDelay: 100,
    mouseLeaveDelay: 100,
    on: 'hover',
  };
  let facetName = '-';
  let updateDate = '-';
  let baseHierarchyGroupName = '-';
  let sourceGroupName = '-';
  if (viewFacet) {
    facetName = viewFacet.name;
    updateDate = dateTimeFormatter(viewFacet.aggregates_update_date);
    baseHierarchyGroupName = entityLabelFormatter(
      'productHierarchyGroup',
      baseProductHierarchyGroup?.id
    );
    sourceGroupName = facetSourceGroup?.name;
  }
  const transformedFilteringTags = useSelector((state) =>
    selectTransformedFilteringTags(state, viewFacet)
  );
  const tagLabelFormatter = useTagLabelFormatter();
  const tagLabelColorFormatter = useTagColorFormatter();

  const items = [
    {
      name: t({ id: 'analysis' }),
      value: facetName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={facetName}
          items={[
            `${t({ id: 'creation' })} : ${updateDate}`,
            `${t({ id: 'update' })} : ${updateDate}`,
          ]}
          getItemText={(name) => name}
        />
      ),
    },
    {
      name: t({ id: 'base-group' }),
      value: baseHierarchyGroupName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={baseHierarchyGroupName}
          title={
            <Trans id="product(s)-service(s)" render={capitalizedTranslation} />
          }
          items={baseProductHierarchyGroup?.items || []}
          getItemText={({ id }) => entityLabelFormatter('productHierarchy', id)}
        />
      ),
    },
    {
      name: t({ id: 'comparative-group' }),
      hidden: !(viewFacet && isComparativeViewFacet(viewFacet)),
      value:
        (viewFacet &&
          viewFacet.comparative_product_hierarchy_groups &&
          viewFacet.comparative_product_hierarchy_groups
            .map(({ id }) => entityLabelFormatter('productHierarchyGroup', id))
            .join(', ')) ||
        '-',
      helper: (
        <ComparativeProductGroupHelper
          tooltipCommonProps={tooltipCommonProps}
          viewFacet={viewFacet}
          entityLabelFormatter={entityLabelFormatter}
        />
      ),
    },
    {
      name: t({ id: 'source(s)' }),
      value: sourceGroupName,
      helper: (
        <ListHelpTooltip
          hoverable={false}
          {...tooltipCommonProps}
          headerText={sourceGroupName}
          title={`${i18n._(t({ id: 'source(s)' }))} :`}
          items={facetSourceItems || []}
          getItemText={({ label }) => label}
        />
      ),
    },
    {
      name: t({ id: 'tag-list' }),
      value: (
        <TagList
          filteringTags={transformedFilteringTags}
          tagLabelFormatter={tagLabelFormatter}
          tagLabelColorFormatter={tagLabelColorFormatter}
        />
      ),
      hidden: isEmpty(transformedFilteringTags),
    },
    {
      name: t({ id: 'restitution-language' }),
      value: viewFacet?.restitution_language ? (
        <FlagItem language={viewFacet?.restitution_language} />
      ) : null,
      hidden: !viewFacet?.restitution_language,
    },
    {
      name: t({ id: 'period' }),
      value: viewFacet ? formatPeriod(viewFacet) : '...',
    },
  ];
  return items.filter((item) => !item.hidden);
};

const TooltipSummaryContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-grow: 100;
  justify-content: space-around;
  padding-right: ${svars.spaceMediumLarge};
`;

const ConfigurationItemContainer = styled.span`
  font-size: ${svars.fontSizeSmall};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${svars.spaceSmall} ${svars.spaceNormal} ${svars.spaceNormal}
    ${svars.spaceNormal};
  line-height: 1.3;
  white-space: nowrap;
`;
const ConfigurationItemName = styled.div`
  font-weight: ${svars.fontWeightBase};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ConfigurationValue = styled.div`
  width: 100%;
  display: inline-flex;
  font-weight: ${svars.fontWeightBold};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ConfigurationValueText = styled.div`
  margin: auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigurationItemFactory = (nSummaryItems) => {
  function ConfigurationItem({ name, value, helper }) {
    return (
      <ConfigurationItemContainer key={`ci-${name}`} nItems={nSummaryItems}>
        <ConfigurationItemName>{capitalize(name)} :</ConfigurationItemName>
        <ConfigurationValue>
          <ConfigurationValueText>{value}</ConfigurationValueText>
          {helper && helper}
        </ConfigurationValue>
      </ConfigurationItemContainer>
    );
  }

  ConfigurationItem.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    helper: PropTypes.string.isRequired,
  };
  return ConfigurationItem;
};

export function FacetHelperTooltip() {
  const { i18n } = useLingui();
  const [opened, setOpened] = useState(false);

  const viewFacet = useSelector(viewFacetSelector);
  const entityLabelFormatter = useSelector(entityLabelFormatterSelector);
  const baseProductHierarchyGroup = useSelector(
    baseProductHierarchyGroupSelector
  );
  const facetSourceGroup = useSelector(facetSourceGroupSelector);
  const facetSourceItems = useSelector(viewFacetSourceItemsSelector);
  const summaryItems = getConfigurationSummaryItems(
    i18n,
    viewFacet,
    facetSourceGroup,
    facetSourceItems,
    baseProductHierarchyGroup,
    entityLabelFormatter
  );
  const summaryItemsFactory = ConfigurationItemFactory(summaryItems.length);
  return (
    <HelpTooltip
      mouseEnterDelay={200}
      mouseLeaveDelay={1000}
      help={
        <TooltipSummaryContainer>
          {summaryItems.map(summaryItemsFactory)}
        </TooltipSummaryContainer>
      }
      hoverable
      inverted={false}
      on="click"
      position="bottom right"
      iconSize={svars.fontSizeLarge}
      open={opened}
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
      trigger={
        <span>
          <SecondaryTabButton
            active={opened}
            labelPosition="right"
            content={<Trans id="analysis-details" />}
            icon="help"
          />
        </span>
      }
    />
  );
}

FacetHelperTooltip.propTypes = {};
FacetHelperTooltip.defaultProps = {};
