import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const MessageContainer = styled.div`
  padding: ${svars.spaceNormal};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  min-height: ${svars.fontSizeBase};
  transition: ${svars.transitionBase};
`;

function createMessageComponent(color, backgroundColor) {
  function Message({ message, show, style }) {
    return show ? (
      <MessageContainer
        color={color}
        backgroundColor={backgroundColor}
        style={style}
      >
        <Icon name="exclamation circle" />
        <Trans id={message} />
      </MessageContainer>
    ) : null;
  }

  Message.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string.isRequired,
    style: PropTypes.shape(),
  };

  Message.defaultProps = {
    style: {},
    show: false,
  };

  return Message;
}

const ErrorMessage = createMessageComponent(
  svars.colorDanger,
  svars.colorDangerLightest
);
const WarningMessage = createMessageComponent(
  svars.colorWarning,
  svars.colorYellowWarningLight
);

export { ErrorMessage, WarningMessage };
