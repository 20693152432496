import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Divider, Form, FormField, Grid } from 'semantic-ui-react';

import { maybeFetchProductHierarchyGroups as onMaybeFetchProductHierarchyGroups } from 'actions/entity_groups';
import { createViewFacet, updateViewFacet } from 'actions/facet';
import { maybeFetchViews as onMaybeFetchViews } from 'actions/view';
import { tagSetsSelector } from 'reducers/entityLabelFormatter';
import { languageSelector } from 'reducers/locale';
import {
  fetchTagSetsLoadingSelector,
  productHierarchyGroupsItemsSelector,
  productHierarchyGroupsLoadingSelector,
  restitutionLanguagesItemsSelector,
  sourceGroupsItemsSelector,
} from 'selectors/entities';
import {
  selectTransformedFilteringTags,
  tagSetsItemsSelector,
  viewFacetByIdSelector,
} from 'selectors/facet';
import { analysisLanguageSelector } from 'selectors/user';
import { currentViewSelector, fetchViewsLoadingSelector } from 'selectors/view';

import ProductHierarchyGroupCreateModal from 'components/customer/home/ProductHierarchyGroupCreateModal';
import PERIOD_TYPES from 'components/customer/periodTypes';
import * as helpMessages from 'components/customer/v2/helpMessageV2';
import BeatingLoader from 'components/ui/BeatingLoader';
import Header, { LargeHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import { WarningMessage } from 'components/ui/Message';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import HelpcrunchButtonLink from 'components/ui/button/HelpcrunchButtonLink';
import LargeRadioButtonGroup from 'components/ui/button/LargeRadioButtonGroup';
import FlagItem from 'components/ui/cells/FlagItem';
import { SelectBox, StyledDropdown } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import CheckboxList from 'components/ui/inputs/checkbox-list';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';
import SvgContentUrl from 'components/ui/svg/undraw_content_vbqo.svg';

import * as svars from 'assets/style/variables';

import FacetCreateField from './FacetCreateField';
import PeriodFieldHelper from './PeriodField';

const ALTERNATIVE_LANGUAGE_ITEMS = [
  {
    help: msg({ id: 'restitution-language.default-language-help' }),
    type: false,
    header: msg({ id: 'default-language' }),
  },
  {
    help: msg({ id: 'restitution-language.alternative-language-help' }),
    type: true,
    header: msg({ id: 'alternative-language' }),
  },
];

function HeaderLabel({ headerText, subHeaderText }) {
  return (
    <label
      htmlFor="lcb-ca"
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      <Header style={{ margin: 0 }}>
        <Trans id={headerText} />
        <Header.Subheader>
          <Trans id={subHeaderText} />
        </Header.Subheader>
      </Header>
    </label>
  );
}

HeaderLabel.propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
};

const findSourceGroup = (data) => (group) => group.value === data.value;

function ViewFacetCreatePage() {
  useLingui();
  const { viewFacetId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // @TODO use id only remove deprecated group related fields
  const [baseProductHierarchyGroupId, setBaseProductHierarchyGroupId] =
    useState('');

  const currentView = useSelector(currentViewSelector);
  const [name, setName] = useState('');

  const [selectedTagSetItems, setSelectedTagSetItems] = useState({});

  const [sourceGroupId, setSourceGroupId] = useState('');
  const [sourceGroupName, setSourceGroupName] = useState('');
  const [sourceGroupTagSets, setSourceGroupTagSets] = useState([]);
  const [periodType, setPeriodType] = useState(null);
  const [period, setPeriod] = useState(null);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [useRestitutionLanguage, setUseRestitutionLanguage] = useState(false);
  const [restitutionLanguage, setRestitutionLanguage] = useState(undefined);
  const [
    comparativeProductHierarchyGroupIds,
    setComparativeProductHierarchyGroupIds,
  ] = useState([]);
  // Product hierarchy group creation modal management - we use one modal for both cases
  const [displayProductGroupCreateModal, setDisplayProductGroupCreateModal] =
    useState(false);
  const [createComparativeHierarchyGroup, setCreateComparativeHierarchyGroup] =
    useState(false);

  const isViewFacetUpdate = pathname.includes('update');
  const viewFacetToUpdateOrCopy = useSelector(
    viewFacetByIdSelector(viewFacetId)
  );
  const transformedFilteringTags = useSelector((state) =>
    selectTransformedFilteringTags(state, viewFacetToUpdateOrCopy)
  );
  const productHierarchyGroupsItems = useSelector(
    productHierarchyGroupsItemsSelector()
  );
  const comparativeHierarchyGroupsItems = useSelector(
    productHierarchyGroupsItemsSelector(baseProductHierarchyGroupId)
  );
  const sourceGroupsItems = useSelector(sourceGroupsItemsSelector);
  const restitutionLanguages = useSelector(restitutionLanguagesItemsSelector);
  const analysisLanguage = useSelector(analysisLanguageSelector);
  const appLanguage = useSelector(languageSelector);

  const tagSetItems = useSelector(tagSetsSelector);
  const tagSet = useSelector(tagSetsItemsSelector);

  const tagSetsLoading = useSelector(fetchTagSetsLoadingSelector);
  const viewLoading = useSelector(fetchViewsLoadingSelector);
  const productHierarchyGroupsLoading = useSelector(
    productHierarchyGroupsLoadingSelector
  );

  const handleTagSetChange = (e, { value }, index) => {
    const newSelectedTagSetItems = { ...selectedTagSetItems };

    if (value) {
      // Adding or replacing a tag set
      const oldTagSetId = Object.keys(selectedTagSetItems)[index];
      if (oldTagSetId && oldTagSetId !== value) {
        // Remove previous tag set's selected tags
        delete newSelectedTagSetItems[oldTagSetId];
      }
      newSelectedTagSetItems[value] = newSelectedTagSetItems[value] || [];
    } else {
      // Removing a tag set
      const oldTagSetId = Object.keys(selectedTagSetItems)[index];
      if (oldTagSetId) {
        delete newSelectedTagSetItems[oldTagSetId];
      }
    }

    setSelectedTagSetItems(newSelectedTagSetItems);
  };

  const handleTagChange = (tagSetId, selectedValues) => {
    setSelectedTagSetItems((prev) => ({
      ...prev,
      [tagSetId]: selectedValues,
    }));
  };

  const handleTagUnselect = (value, tagSetId) => {
    setSelectedTagSetItems((prev) => {
      const selectedValues = prev[tagSetId] || [];
      const newSelectedValues = selectedValues.filter((val) => val !== value);
      return {
        ...prev,
        [tagSetId]: newSelectedValues,
      };
    });
  };

  const getAvailableTagSetOptions = (
    excludeTagSetIds = [],
    currentTagSetId = null
  ) => {
    const selectedTagSetIds = Object.keys(selectedTagSetItems);
    const sourceGroup = sourceGroupsItems.find(
      (group) => group.key === sourceGroupId
    );

    if (!sourceGroup || !sourceGroupTagSets) {
      return [];
    }

    const allowedTagSetIds = sourceGroupTagSets?.map((tagSet_) => tagSet_.id);
    return tagSet.filter(
      (tag) =>
        allowedTagSetIds.includes(tag.key) && // Belongs to the source group
        (!selectedTagSetIds.includes(tag.key) || tag.key === currentTagSetId) && // Not selected by others or is the current selection
        !excludeTagSetIds.includes(tag.key) // Not excluded by other dropdowns
    );
  };

  const preferredRestitutionLanguage =
    restitutionLanguages?.find(
      ({ value: restitutionValue }) => restitutionValue === appLanguage?.value
    )?.value || undefined;

  const onSelectUseRestitutionLanguage = (value) => {
    setUseRestitutionLanguage(value);
    if (value && preferredRestitutionLanguage) {
      setRestitutionLanguage(preferredRestitutionLanguage);
    } else if (!value) {
      setRestitutionLanguage(undefined);
    }
  };

  const checkFacetParameters = useCallback(() => {
    if (!viewFacetToUpdateOrCopy) {
      // In case of creation, check only the default restitution language
      if (preferredRestitutionLanguage) {
        setUseRestitutionLanguage(true);
        setRestitutionLanguage(preferredRestitutionLanguage);
      }
      return;
    }

    // Set name (for copy or update)
    if (isViewFacetUpdate) {
      setName(viewFacetToUpdateOrCopy.name);
    } else {
      setName(`${t({ id: 'copy-of' })} ${viewFacetToUpdateOrCopy.name}`);
    }

    // Set source group and base product hierarchy group
    setSourceGroupId(viewFacetToUpdateOrCopy.source_group.id);
    setSourceGroupName(viewFacetToUpdateOrCopy.source_group.name);
    setSourceGroupTagSets(
      sourceGroupsItems.find(
        (group) => group.key === viewFacetToUpdateOrCopy.source_group.id
      )?.tag_sets
    );
    setBaseProductHierarchyGroupId(
      viewFacetToUpdateOrCopy.base_product_hierarchy_group.id
    );

    // Handle period and custom dates
    setPeriodType(viewFacetToUpdateOrCopy.period_type);
    if (viewFacetToUpdateOrCopy.period_type === 'custom') {
      if (viewFacetToUpdateOrCopy.min_date) {
        setMinDate(viewFacetToUpdateOrCopy.min_date?.split('T')?.[0]);
      }
      if (viewFacetToUpdateOrCopy.max_date) {
        setMaxDate(viewFacetToUpdateOrCopy.max_date?.split('T')?.[0]);
      }
    } else {
      setPeriod(viewFacetToUpdateOrCopy.period);
    }

    // Set comparative product hierarchy group IDs
    if (
      viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups &&
      viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups.length
    ) {
      setComparativeProductHierarchyGroupIds(
        viewFacetToUpdateOrCopy.comparative_product_hierarchy_groups.map(
          ({ id }) => id
        )
      );
    }

    // Set restitution language
    if (viewFacetToUpdateOrCopy.restitution_language) {
      setUseRestitutionLanguage(true);
      setRestitutionLanguage(viewFacetToUpdateOrCopy.restitution_language);
    }

    // handling filtering_tags
    if (transformedFilteringTags) {
      const transformedTags = Object.fromEntries(
        Object.entries(transformedFilteringTags).map(([tagSetId, tags]) => [
          tagSetId,
          tags.map((tag) => tag.id),
        ])
      );

      setSelectedTagSetItems(transformedTags);
    }
  }, [viewFacetToUpdateOrCopy, isViewFacetUpdate, appLanguage]);

  useEffect(() => {
    dispatch(onMaybeFetchViews());
    dispatch(onMaybeFetchProductHierarchyGroups());
    checkFacetParameters();
  }, []);

  useEffect(() => {
    checkFacetParameters();
  }, [viewFacetToUpdateOrCopy]);

  const onModalClose = useCallback(
    (newGroupId) => {
      if (displayProductGroupCreateModal) {
        setDisplayProductGroupCreateModal(false);
        if (newGroupId) setBaseProductHierarchyGroupId(newGroupId);
      } else if (createComparativeHierarchyGroup) {
        setCreateComparativeHierarchyGroup(false);
        if (newGroupId) {
          setComparativeProductHierarchyGroupIds([
            ...comparativeProductHierarchyGroupIds,
            newGroupId,
          ]);
        }
      }
    },
    [
      displayProductGroupCreateModal,
      createComparativeHierarchyGroup,
      comparativeProductHierarchyGroupIds,
    ]
  );

  const onPeriodChange = (newPeriod) => () => setPeriod(newPeriod);

  const onSelectSourceGroup = (e, data) => {
    if (data.value) {
      const sourceGroupOption = data.options.find(findSourceGroup(data));
      setSourceGroupId(data.value);
      setSourceGroupName(sourceGroupOption.text);
      setSourceGroupTagSets(sourceGroupOption.tag_sets);

      const allowedTagSetIds = Array.isArray(sourceGroupOption.tag_sets)
        ? sourceGroupOption.tag_sets.map((tagSet_) => tagSet_.id)
        : [];
      const newSelectedTagSetItems = Object.fromEntries(
        Object.entries(selectedTagSetItems).filter(([tagSetId]) =>
          allowedTagSetIds.includes(tagSetId)
        )
      );

      setSelectedTagSetItems(newSelectedTagSetItems);
    } else {
      setSourceGroupId('');
      setSourceGroupName('');
      setSourceGroupTagSets([]);
      setSelectedTagSetItems([]);
    }
  };

  const onSelectRestitutionLanguage = (e, { value }) =>
    setRestitutionLanguage(value);

  const onSelectPeriodType = useCallback(
    (value) => {
      setPeriodType(value);
      setPeriod(value ? PERIOD_TYPES?.[value].periods[0]?.value : null);

      if (minDate) {
        setMinDate(null);
      }
      if (maxDate) {
        setMaxDate(null);
      }
    },
    [minDate, maxDate]
  );

  const onSelectBaseProductHierarchyGroup = useCallback(
    (e, { value }) => {
      setBaseProductHierarchyGroupId(value);

      if (comparativeProductHierarchyGroupIds.includes(value)) {
        setComparativeProductHierarchyGroupIds(
          comparativeProductHierarchyGroupIds.filter((id) => id !== value)
        );
      }
    },
    [comparativeProductHierarchyGroupIds]
  );
  const goToViewFacetList = () => navigate('/facets');

  const validateUpdate = () => {
    dispatch(
      updateViewFacet({
        viewFacetId,
        name,
        sourceGroupId,
        baseProductHierarchyGroupId,
        comparativeProductHierarchyGroupIds,
        periodType,
        period,
        minDate,
        maxDate,
        restitutionLanguage,
        selectedTagSetItems,
      })
    );
    navigate('/facets');
  };
  const validateCreate = () => {
    dispatch(
      createViewFacet({
        name,
        sourceGroupId,
        baseProductHierarchyGroupId,
        comparativeProductHierarchyGroupIds,
        periodType,
        period,
        minDate,
        maxDate,
        restitutionLanguage,
        selectedTagSetItems,
      })
    );
    navigate('/facets');
  };
  const getTagSetCheckboxListProps = useMemo(
    () => (tagSetId) => ({
      items: tagSetItems[tagSetId].items.map((item) => ({
        key: item.id,
        text: item.display_name,
        value: item.id,
      })),
      onSelectItem: ({ value }) => {
        const currentSelectedValues = selectedTagSetItems[tagSetId] || [];
        const newSelectedValues = currentSelectedValues.includes(value)
          ? currentSelectedValues.filter((val) => val !== value)
          : [...currentSelectedValues, value];
        handleTagChange(tagSetId, newSelectedValues);
      },
      onSelectItems: (selectedItems) => {
        handleTagChange(
          tagSetId,
          selectedItems.map(({ value }) => value)
        );
      },
      onUnselectItem: ({ value }) => {
        handleTagUnselect(value, tagSetId);
      },
      onResetItems: () => {
        handleTagChange(tagSetId, []);
      },
      searchable: tagSetItems[tagSetId].items.length > 6,
      selectedItems: selectedTagSetItems[tagSetId] || [],
    }),
    [selectedTagSetItems]
  );
  let title;
  let description;
  const facetName = viewFacetToUpdateOrCopy?.name;
  if (viewFacetId && isViewFacetUpdate) {
    title = t`Modifier l'analyse "${facetName}"`;
    description = helpMessages.analysisUpdateDescription;
  } else {
    title = t({ id: 'create-new-analysis' });
    description = helpMessages.analysisCreateDescription;
  }
  const gaLabel = `(${sourceGroupName}) ${baseProductHierarchyGroupId} `;
  const disabled =
    baseProductHierarchyGroupId === '' ||
    sourceGroupId === '' ||
    name === '' ||
    (useRestitutionLanguage && !restitutionLanguage) ||
    (maxDate && minDate && maxDate < minDate) ||
    Object.keys(selectedTagSetItems).some(
      (tagSetId) => !(selectedTagSetItems[tagSetId]?.length > 0)
    );
  if (
    !currentView ||
    !currentView.configuration ||
    viewLoading ||
    tagSetsLoading ||
    productHierarchyGroupsLoading
  ) {
    return <BeatingLoader />;
  }
  if (!currentView || !currentView.configuration) return null;

  return (
    <PageLayout simple>
      <div style={{ padding: '1rem', overflowY: 'auto' }}>
        <Grid style={{ padding: svars.spaceMedium }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={9}>
              <LargeHeader>{title}</LargeHeader>
              <div style={{ maxWidth: svars.textMaxWidth }}>
                {description.map((item) => (
                  <p key={`text-it-${item.id || item}`}>
                    <Trans id={item} />
                  </p>
                ))}
              </div>
              <HelpcrunchButtonLink
                style={{ marginTop: svars.spaceMedium }}
                articleId={9}
                helpcrunchButtonLinkTestId="bo-helpcrunch-article-9"
              />
            </Grid.Column>
            <Grid.Column
              width={7}
              floated="right"
              textAlign="right"
              verticalAlign="bottom"
            >
              <img
                style={{
                  maxHeight: '15rem',
                  paddingRight: svars.spaceLarge,
                }}
                src={SvgContentUrl}
                alt="star"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid relaxed>
          <FacetCreateField
            input={
              <LimitedTextInput
                style={{
                  width: '100%',
                  fontWeight: svars.fontWeightBold,
                  fontSize: svars.fontSizeXLarge,
                }}
                placeholder={t({ id: 'facet-create.new-analysis-title' })}
                data-testid="bo-new-analysis-input"
                value={name}
                onChange={(e, data) => setName(data.value)}
                maxCharacters={80}
              />
            }
            inputWidth={15}
          />
          <FacetCreateField
            name={t({ id: 'base-product-services-group' })}
            input={
              <SelectBox
                placeholder={t({ id: 'product-services-group' })}
                selectBoxTestId="bo-facility-group-select-box"
                options={productHierarchyGroupsItems}
                onChange={onSelectBaseProductHierarchyGroup}
                value={baseProductHierarchyGroupId}
              />
            }
            legend={
              <Link
                base
                onClick={() => setDisplayProductGroupCreateModal(true)}
                data-testid="bo-top-create-new-facility-group-link"
              >
                <Trans id="create-new-product-group" />
              </Link>
            }
            helpMessage={
              helpMessages.analysisBaseProductHierarchyGroupDescription
            }
          />
          <FacetCreateField
            name={t({ id: 'source(s)' })}
            input={
              <SelectBox
                loading={tagSetsLoading || viewLoading}
                disabled={tagSetsLoading || viewLoading}
                selectBoxTestId="bo-select-source-group-box"
                placeholder={t({ id: 'source(s)' })}
                options={sourceGroupsItems}
                onChange={onSelectSourceGroup}
                value={sourceGroupId}
              />
            }
            helpMessage={helpMessages.analysisSourceGroupDescription}
          />
          <FacetCreateField
            name={t({ id: 'tags' })}
            input={[...Object.keys(selectedTagSetItems), null].map(
              (tagSetId, index) => {
                const remainingOptions = getAvailableTagSetOptions(
                  Object.keys(selectedTagSetItems).filter(
                    (id, i) => i !== index
                  ),
                  tagSetId
                );
                return (
                  <Form key={tagSetId || `new-${index}`}>
                    <FormField>
                      <StyledDropdown
                        loading={tagSetsLoading || viewLoading}
                        selection
                        search
                        selectBoxTestId="bo-select-tagset-multi-box"
                        placeholder={
                          index === 0
                            ? t({
                                id: 'facet-create-review-tag-add-placeholder',
                              })
                            : t({
                                id: 'facet-create-review-tag-add-another-placeholder',
                              })
                        }
                        options={remainingOptions}
                        onChange={(e, data) =>
                          handleTagSetChange(e, data, index)
                        }
                        value={tagSetId || ''}
                        disabled={
                          !sourceGroupId || remainingOptions.length === 0
                        }
                        clearable
                      />
                    </FormField>
                    {tagSetId ? (
                      <CheckboxList
                        loading={tagSetsLoading || viewLoading}
                        style={{
                          maxHeight: svars.listMaxHeight,
                          paddingBottom: svars.spaceNormal,
                        }}
                        {...getTagSetCheckboxListProps(tagSetId)}
                        disabled={!sourceGroupId}
                        placeholder={t({ id: 'search-tag' })}
                        showNSelected
                      />
                    ) : null}
                  </Form>
                );
              }
            )}
            helpMessage={[
              ...helpMessages.tagSetHelpMessage,
              ...(Object.values(selectedTagSetItems).some(
                (selectedTags) => selectedTags.length === 0
              )
                ? [
                    <WarningMessage
                      show
                      message={t({ id: 'facet-create-review-tag-warning' })}
                    />,
                  ]
                : []),
            ]}
          />
          {restitutionLanguages?.length ? (
            <FacetCreateField
              name={
                <HeaderLabel
                  headerText={t({ id: 'restitution-language' })}
                  subHeaderText={t({
                    id: 'restitution-language.form-subtitle',
                  })}
                />
              }
              input={
                <LargeRadioButtonGroup
                  items={ALTERNATIVE_LANGUAGE_ITEMS}
                  value={useRestitutionLanguage}
                  onClick={onSelectUseRestitutionLanguage}
                  largeRadioButtonGroupTestId="bo-restitution-language-checkbox"
                />
              }
              helpMessage={
                <>
                  <div style={{ margin: `${svars.spaceMedium} 0` }}>
                    <Trans id="this-analysis-use-restitution-language" />{' '}
                  </div>
                  {useRestitutionLanguage ? (
                    <SelectBox
                      style={{
                        maxWidth: svars.smallInputMaxWidth,
                      }}
                      placeholder={t({ id: 'select-a-restitution-language' })}
                      options={restitutionLanguages}
                      onChange={onSelectRestitutionLanguage}
                      value={restitutionLanguage}
                    />
                  ) : (
                    <SelectBox
                      style={{
                        pointerEvents: 'none',
                        opacity: 1,
                        maxWidth: svars.smallInputMaxWidth,
                      }}
                      value="1"
                      clearable={false}
                      icon={null}
                      options={[
                        {
                          value: '1',
                          key: '1',
                          text: (
                            <FlagItem
                              style={{
                                display: 'inline',
                              }}
                              language={analysisLanguage}
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                </>
              }
            />
          ) : null}
          <FacetCreateField
            name={t({ id: 'period' })}
            input={
              <LargeRadioButtonGroup
                items={Object.values(PERIOD_TYPES)}
                value={periodType}
                onClick={onSelectPeriodType}
              />
            }
            inputWidth={7}
            helpMessage={
              <PeriodFieldHelper
                period={period}
                periodType={periodType}
                periodLengths={
                  periodType ? PERIOD_TYPES[periodType]?.periods : []
                }
                minDate={minDate}
                maxDate={maxDate}
                onPeriodChange={onPeriodChange}
                onChangeStartPeriodDatePicker={setMinDate}
                onChangeEndPeriodDatePicker={setMaxDate}
              />
            }
          />
          <FacetCreateField
            name={
              <HeaderLabel
                headerText={t({ id: 'comparative-analysis' })}
                subHeaderText={t({ id: 'comparative-analysis.form-subtitle' })}
              />
            }
            input={
              <CheckboxList
                bordered
                style={{ height: svars.listMaxHeight }}
                items={comparativeHierarchyGroupsItems}
                onSelectItem={({ value }) =>
                  setComparativeProductHierarchyGroupIds([
                    ...(comparativeProductHierarchyGroupIds || []),
                    value,
                  ])
                }
                onUnselectItem={({ value }) =>
                  setComparativeProductHierarchyGroupIds(
                    (comparativeProductHierarchyGroupIds || []).filter(
                      (itemValue) => itemValue !== value
                    )
                  )
                }
                onResetItems={() => setComparativeProductHierarchyGroupIds([])}
                placeholder={t({ id: 'comparative-product-services-groups' })}
                selectedItems={comparativeProductHierarchyGroupIds}
                searchable={comparativeHierarchyGroupsItems.length > 6}
                nMaxSelectedItems={svars.nMaxComparativeGroups}
                showNSelected
                noDataMessage={t({ id: 'no-existing-group' })}
              />
            }
            legend={
              <Link
                base
                onClick={() => setCreateComparativeHierarchyGroup(true)}
                basicLinkTestId="bo-bottom-create-new-facility-group-link"
              >
                <Trans id="create-new-product-group" />
              </Link>
            }
            helpMessage={
              helpMessages.analysisComparativeProductHierarchyGroupDescription
            }
          />
        </Grid>
      </div>
      <Divider style={{ margin: 0 }} />
      <ButtonLineLayout padded>
        <AnalyticsAwareButton
          gaCategory="V2"
          gaAction="Update View Facet"
          gaLabel="cancel"
          inputComponent={ButtonTransparentDanger}
          onClick={goToViewFacetList}
        >
          <Trans id="cancel" />
        </AnalyticsAwareButton>
        {viewFacetId && isViewFacetUpdate ? (
          <AnalyticsAwareButton
            gaCategory="V2"
            gaAction="Update View Facet"
            gaLabel={gaLabel}
            inputComponent={ButtonAccent}
            onClick={validateUpdate}
            disabled={disabled}
            style={{ marginLeft: svars.spaceMediumLarge }}
            data-testid="bo-edit-analysis-button"
          >
            <Trans id="modify" />
          </AnalyticsAwareButton>
        ) : (
          <AnalyticsAwareButton
            gaCategory="V2"
            gaAction="Create View Facet"
            gaLabel={gaLabel}
            inputComponent={ButtonAccent}
            onClick={validateCreate}
            disabled={disabled}
            style={{ marginLeft: svars.spaceMediumLarge }}
            data-testid="bo-create-analysis-button"
          >
            <Trans id="create" />
          </AnalyticsAwareButton>
        )}
      </ButtonLineLayout>
      <ProductHierarchyGroupCreateModal
        open={displayProductGroupCreateModal || createComparativeHierarchyGroup}
        key={`product-hierarchy-group-create-modal-${displayProductGroupCreateModal}-${createComparativeHierarchyGroup}`}
        onClose={onModalClose}
      />
    </PageLayout>
  );
}

export default ViewFacetCreatePage;
