import { t } from '@lingui/macro';
import omit from 'lodash/omit';

import { createLoadingSelector } from 'reducers/ui';

import { pushSuccessToaster } from 'components/ui/Toaster';

import { api } from './utils';

export const actionTypes = {
  FETCH_CAMPAIGNS_LIST_REQUEST: 'FETCH_CAMPAIGNS_LIST_REQUEST',
  FETCH_CAMPAIGNS_LIST_FAILURE: 'FETCH_CAMPAIGNS_LIST_FAILURE',
  FETCH_CAMPAIGNS_LIST_SUCCESS: 'FETCH_CAMPAIGNS_LIST_SUCCESS',
  CREATE_CAMPAIGN_REQUEST: 'CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_FAILURE: 'CREATE_CAMPAIGN_FAILURE',
  CREATE_CAMPAIGN_SUCCESS: 'CREATE_CAMPAIGN_SUCCESS',
  FETCH_CAMPAIGN_CONFIGURATION_REQUEST: 'FETCH_CAMPAIGN_CONFIGURATION_REQUEST',
  FETCH_CAMPAIGN_CONFIGURATION_FAILURE: 'FETCH_CAMPAIGN_CONFIGURATION_FAILURE',
  FETCH_CAMPAIGN_CONFIGURATION_SUCCESS: 'FETCH_CAMPAIGN_CONFIGURATION_SUCCESS',
  DELETE_CAMPAIGN_REQUEST: 'DELETE_CAMPAIGN_REQUEST',
  DELETE_CAMPAIGN_FAILURE: 'DELETE_CAMPAIGN_FAILURE',
  DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
  UPDATE_PREVIEW_CONFIG_REQUEST: 'UPDATE_PREVIEW_CONFIG_REQUEST',
  UPDATE_PREVIEW_CONFIG_FAILURE: 'UPDATE_PREVIEW_CONFIG_FAILURE',
  UPDATE_PREVIEW_CONFIG_SUCCESS: 'UPDATE_PREVIEW_CONFIG_SUCCESS',
  SAVE_CAMPAIGN_CONFIGURATION_REQUEST: 'SAVE_CAMPAIGN_CONFIGURATION_REQUEST',
  SAVE_CAMPAIGN_CONFIGURATION_SUCCESS: 'SAVE_CAMPAIGN_CONFIGURATION_SUCCESS',
  SAVE_CAMPAIGN_CONFIGURATION_FAILURE: 'SAVE_CAMPAIGN_CONFIGURATION_FAILURE',
  RENAME_CAMPAIGN_REQUEST: 'RENAME_CAMPAIGN_REQUEST',
  RENAME_CAMPAIGN_SUCCESS: 'RENAME_CAMPAIGN_SUCCESS',
  RENAME_CAMPAIGN_FAILURE: 'RENAME_CAMPAIGN_FAILURE',
  ADD_IDENTIFICATION_FORM_ELEMENT_REQUEST:
    'ADD_IDENTIFICATION_FORM_ELEMENT_REQUEST',
  ADD_IDENTIFICATION_FORM_ELEMENT_FAILURE:
    'ADD_IDENTIFICATION_FORM_ELEMENT_FAILURE',
  ADD_IDENTIFICATION_FORM_ELEMENT_SUCCESS:
    'ADD_IDENTIFICATION_FORM_ELEMENT_SUCCESS',
  ADD_FAQ_ITEM_REQUEST: 'ADD_FAQ_ITEM_REQUEST',
  ADD_FAQ_ITEM_FAILURE: 'ADD_FAQ_ITEM_FAILURE',
  ADD_FAQ_ITEM_SUCCESS: 'ADD_FAQ_ITEM_SUCCESS',
  RESET_WHOLE_IDENTIFICATION_FORM_TO_DEFAULT:
    'RESET_WHOLE_IDENTIFICATION_FORM_TO_DEFAULT',
  RESET_ALL_FAQ_ITEMS_TO_DEFAULT: 'RESET_ALL_FAQ_ITEMS_TO_DEFAULT',
  REMOVE_IDENTIFICATION_FORM_ELEMENT: 'REMOVE_IDENTIFICATION_FORM_ELEMENT',
  RESTORE_IDENTIFICATION_FORM_ELEMENT: 'RESTORE_IDENTIFICATION_FORM_ELEMENT',
  UPDATE_IDENTIFICATION_FORM_ELEMENT: 'UPDATE_IDENTIFICATION_FORM_ELEMENT',
  SET_WORDING_VALUE: 'SET_WORDING_VALUE',
  SET_WORDING_TO_DEFAULT: 'SET_WORDING_TO_DEFAULT',
  REMOVE_FAQ_ITEM: 'REMOVE_FAQ_ITEM',
  RESTORE_FAQ_ITEM: 'RESTORE_FAQ_ITEM',
  UPDATE_FAQ_ITEM: 'UPDATE_FAQ_ITEM',
  ACTIVATE_PREVIEW: 'ACTIVATE_PREVIEW',
  DEACTIVATE_PREVIEW: 'DEACTIVATE_PREVIEW',
  SET_CONCEPT_RANDOMIZATION: 'SET_CONCEPT_RANDOMIZATION',
  SET_CONCEPT_HIGHLIGHT: 'SET_CONCEPT_HIGHLIGHT',
  SET_EXPANDED_IDENTIFICATION_FORM: 'SET_EXPANDED_IDENTIFICATION_FORM',
  RESET_PREVIEW_CONFIG_REQUEST: 'RESET_PREVIEW_CONFIG_REQUEST',
  RESET_PREVIEW_CONFIG_FAILURE: 'RESET_PREVIEW_CONFIG_FAILURE',
  RESET_PREVIEW_CONFIG_SUCCESS: 'RESET_PREVIEW_CONFIG_SUCCESS',
  ACTIVATE_RESTORE_DIFF: 'ACTIVATE_RESTORE_DIFF',
  DEACTIVATE_RESTORE_DIFF: 'DEACTIVATE_RESTORE_DIFF',
  RESTORE_DIFF: 'RESTORE_DIFF',
  UPDATE_FORM_ELEMENT_POSITIONS: 'UPDATE_FORM_ELEMENT_POSITIONS',
  UPDATE_FAQ_ITEM_POSITION: 'UPDATE_FAQ_ITEM_POSITION',
  ADD_USER_FORM_ELEMENT_REQUEST: 'ADD_USER_FORM_ELEMENT_REQUEST',
  ADD_USER_FORM_ELEMENT_FAILURE: 'ADD_USER_FORM_ELEMENT_FAILURE',
  ADD_USER_FORM_ELEMENT_SUCCESS: 'ADD_USER_FORM_ELEMENT_SUCCESS',
  UPDATE_USER_FORM_ELEMENT_REQUEST: 'UPDATE_USER_FORM_ELEMENT_REQUEST',
  UPDATE_USER_FORM_ELEMENT_FAILURE: 'UPDATE_USER_FORM_ELEMENT_FAILURE',
  UPDATE_USER_FORM_ELEMENT_SUCCESS: 'UPDATE_USER_FORM_ELEMENT_SUCCESS',
  DELETE_USER_FORM_ELEMENT_REQUEST: 'DELETE_USER_FORM_ELEMENT_REQUEST',
  DELETE_USER_FORM_ELEMENT_FAILURE: 'DELETE_USER_FORM_ELEMENT_FAILURE',
  DELETE_USER_FORM_ELEMENT_SUCCESS: 'DELETE_USER_FORM_ELEMENT_SUCCESS',
  UPLOAD_LOGO_REQUEST: 'UPLOAD_LOGO_REQUEST',
  UPLOAD_LOGO_SUCCESS: 'UPLOAD_LOGO_SUCCESS',
  UPLOAD_LOGO_FAILURE: 'UPLOAD_LOGO_FAILURE',
};

const makeVersionTokenUrlParams = (versionToken) =>
  (versionToken && `?version_token=${versionToken}`) || '';

/**
 * Merge the identification form list from the current configuration and from the diff to create the displayed list
 *
 * @param {*} state Redux state
 * @param {*} campaignId Id of the campaign being customized
 * @return {*} The identification form updated with the diff and if each element is disabled or not
 */
export const getCompleteIdentificationForm = (state, campaignId) => {
  const currentIdentificationForm =
    state.campaign.customization[campaignId].currentCampaignConfiguration
      .identification_form;

  const diffIdentificationForm =
    state.campaign.customization[campaignId].diff.identification_form;
  const identificationFormElementToDisable =
    state.campaign.customization[campaignId].identification_form_deletion;
  const diffIdentificationFormIdsList = diffIdentificationForm.map(
    (element) => element.id
  );
  const currentIdentificationFormIdsList = currentIdentificationForm.map(
    (element) => element.id
  );
  return [
    ...currentIdentificationForm.map((currentIdentificationFormElement) => {
      const elementIndexInDIff = diffIdentificationFormIdsList.indexOf(
        currentIdentificationFormElement.id
      );
      if (elementIndexInDIff === -1) {
        return {
          ...currentIdentificationFormElement,
          disabled: identificationFormElementToDisable.includes(
            currentIdentificationFormElement.id
          ),
        };
      }
      return {
        ...currentIdentificationFormElement,
        ...diffIdentificationForm[elementIndexInDIff],
        disabled: identificationFormElementToDisable.includes(
          currentIdentificationFormElement.id
        ),
      };
    }),
    ...diffIdentificationForm
      .filter(
        (element) => !currentIdentificationFormIdsList.includes(element.id)
      )
      .map((element) => ({
        ...element,
        disabled: identificationFormElementToDisable.includes(element.id),
      })),
  ].sort((a, b) => a.position - b.position);
};

/**
 *Merge the faq items from the database with the diff to create the displayed list
 *
 * @param {*} state Redux state
 * @param {*} campaignId Id of the campaign being customized
 * @return {*} The faq items updated with the diff and if each item is disabled or not
 */
export const getCompleteFaqItems = (campaignCustomization) => {
  const currentFaqItems =
    campaignCustomization.currentCampaignConfiguration.faq_items;

  const diffFaqItems = campaignCustomization.diff.faq_items || [];
  const faqItemsToDisable = campaignCustomization?.faq_items_deletion || [];
  const diffFaqItemsIdsList = diffFaqItems.map((item) => item.id);
  return [
    ...currentFaqItems.filter((item) => !diffFaqItemsIdsList.includes(item.id)),
    ...diffFaqItems,
  ]
    .map((currentFaqItem) => ({
      ...currentFaqItem,
      disabled: faqItemsToDisable.includes(currentFaqItem.id),
      archived: faqItemsToDisable.includes(currentFaqItem.id),
    }))
    .sort((a, b) => a.position - b.position);
};

const getCampaigns = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_CAMPAIGNS_LIST_REQUEST,
  });
  let response;
  try {
    response = await api.get('/campaigns');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_CAMPAIGNS_LIST_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_CAMPAIGNS_LIST_SUCCESS,
    campaigns: response.data,
  });
};

export const maybeGetCampaigns = () => async (dispatch, getState) => {
  const state = getState();
  if (!state.campaign.campaigns) {
    return dispatch(getCampaigns());
  }
  return null;
};

export const createCampaign =
  (campaignType, name, hierarchyId, language, campaignIdToCopy) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_CAMPAIGN_REQUEST,
    });
    let response;
    try {
      response = await api.post('/campaign', {
        type: campaignType,
        name,
        hierarchy: { id: hierarchyId },
        language,
        copy: campaignIdToCopy,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.CREATE_CAMPAIGN_FAILURE,
      });
    }
    dispatch({
      type: actionTypes.CREATE_CAMPAIGN_SUCCESS,
      campaignConfiguration: response.data,
      campaignId: response.data.id,
    });
    return response.data.id;
  };

/**
 * Check campaign generated slug name is not used.
 *
 * @param {*} name the name to check
 * @param {*} callback a callback to update state.
 */
export const checkIsAvailableCampaignName = async (name, callback) => {
  let response;
  try {
    response = await api.get(`/campaign-check?name=${name}`);
  } catch (error) {
    return callback(false);
  }
  return callback(response.data.unique);
};

export const renameCampaign = (campaignId, newName) => async (dispatch) => {
  dispatch({
    type: actionTypes.RENAME_CAMPAIGN_REQUEST,
  });
  try {
    await api.put(`/campaign/${campaignId}/rename`, {
      name: newName,
    });
  } catch (error) {
    return dispatch({
      type: actionTypes.RENAME_CAMPAIGN_FAILURE,
    });
  }
  pushSuccessToaster(t({ id: 'campaign-rename-success-title' }));
  return dispatch({
    type: actionTypes.RENAME_CAMPAIGN_SUCCESS,
    campaignId,
    name: newName,
  });
};

export const getCampaignConfiguration = (campaignId) => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
    campaignId,
  });
  let response;
  try {
    response = await api.get(`/campaign/${campaignId}`);
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_CAMPAIGN_CONFIGURATION_FAILURE,
    });
  }
  if (response.data.preview_configuration) {
    dispatch({
      type: actionTypes.ACTIVATE_RESTORE_DIFF,
      diff: response.data.preview_configuration.diff,
      identification_form_deletion:
        response.data.preview_configuration.identification_form_deletion,
      faq_items_deletion:
        response.data.preview_configuration.faq_items_deletion,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_CAMPAIGN_CONFIGURATION_SUCCESS,
    campaignConfiguration: response.data.configuration,
    previewConfiguration: response.data.preview_configuration,
    campaignId,
  });
};

export const deleteCampaign = (campaignId) => async (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_CAMPAIGN_REQUEST,
  });
  let response;
  try {
    response = await api.delete(`/campaign/${campaignId}`);
  } catch (error) {
    return dispatch({
      type: actionTypes.DELETE_CAMPAIGN_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  pushSuccessToaster(
    t({ id: 'campaign-deleted-success-title' }),
    t({ id: 'campaign-deleted-success-message' })
  );
  return dispatch({
    type: actionTypes.DELETE_CAMPAIGN_SUCCESS,
    campaignId: response.data.id,
  });
};

export const deactivateRestoreDiff = () => (dispatch) =>
  dispatch({ type: actionTypes.DEACTIVATE_RESTORE_DIFF });

export const restoreCachedDiff = (campaignId) => (dispatch) =>
  dispatch({
    type: actionTypes.RESTORE_DIFF,
    campaignId,
  });

export const campaignDraftLoadingActionTypes = [
  actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST,
  actionTypes.RESET_PREVIEW_CONFIG_REQUEST,
  actionTypes.SAVE_CAMPAIGN_CONFIGURATION_REQUEST,
  actionTypes.UPDATE_PREVIEW_CONFIG_REQUEST,
  actionTypes.ADD_FAQ_ITEM_REQUEST,
];

export const saveCampaignConfigurationAsDraft =
  (campaignId) => async (dispatch, getState) => {
    const state = getState();
    const { hasUnsavedChanges, versionConflict } = state.campaign;
    const isLoading = createLoadingSelector(campaignDraftLoadingActionTypes)(
      state
    );

    if (!hasUnsavedChanges || versionConflict || isLoading) {
      return;
    }
    const {
      versionToken,
      diff,
      identification_form_deletion,
      faq_items_deletion,
    } = state.campaign.customization?.[campaignId] || {};
    dispatch({
      type: actionTypes.UPDATE_PREVIEW_CONFIG_REQUEST,
    });
    let response;
    try {
      response = await api.put(
        `/${campaignId}/preview${makeVersionTokenUrlParams(versionToken)}`,
        {
          diff: {
            ...diff,
            identification_form: diff.identification_form?.map((element) =>
              omit(element, ['archived'])
            ),
          },
          identification_form_deletion,
          faq_items_deletion,
        }
      );
      dispatch({
        type: actionTypes.UPDATE_PREVIEW_CONFIG_SUCCESS,
        campaignId: response.data.id,
        versionToken: response.data.version_token,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.UPDATE_PREVIEW_CONFIG_FAILURE,
        versionConflict: error.response?.status === 409,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
  };

export const deletePreviewConfig =
  (campaignId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_PREVIEW_CONFIG_REQUEST, campaignId });
    const { versionToken } = getState().campaign.customization[campaignId];
    let response;
    try {
      response = await api.delete(
        `/${campaignId}/preview${makeVersionTokenUrlParams(versionToken)}`
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.RESET_PREVIEW_CONFIG_FAILURE,
        versionConflict: error.response?.status === 409,
      });
    }
    return dispatch({
      type: actionTypes.RESET_PREVIEW_CONFIG_SUCCESS,
      campaignId,
      versionToken: response.data.version_token,
    });
  };

export const openPreview = () => (dispatch) =>
  dispatch({
    type: actionTypes.ACTIVATE_PREVIEW,
  });

export const closePreview = () => (dispatch) =>
  dispatch({
    type: actionTypes.DEACTIVATE_PREVIEW,
  });

export const setWordingValue = (campaignId, page, inputKey, value) => ({
  type: actionTypes.SET_WORDING_VALUE,
  campaignId,
  page,
  inputKey,
  value,
});

export const setConceptRandomization = (campaignId, randomize) => ({
  type: actionTypes.SET_CONCEPT_RANDOMIZATION,
  randomize,
  campaignId,
});

export const setConceptHighlight = (campaignId, conceptIds) => ({
  type: actionTypes.SET_CONCEPT_HIGHLIGHT,
  highlightConcepts: conceptIds,
  campaignId,
});

export const setExpandedForm = (campaignId, expanded_form) => ({
  type: actionTypes.SET_EXPANDED_IDENTIFICATION_FORM,
  expanded_form,
  campaignId,
});

export const setWordingToDefault = (campaignId, page, inputKey) => ({
  type: actionTypes.SET_WORDING_TO_DEFAULT,
  campaignId,
  page,
  inputKey,
});

export const saveCampaignConfiguration =
  (campaignId) => async (dispatch, getState) => {
    const state = getState();
    let { diff } = state.campaign.customization[campaignId];
    const { versionToken } = state.campaign.customization[campaignId];
    if (diff.questions) {
      // Rewrite diff and remove index field from questions and from answers
      diff = {
        ...diff,
        questions: diff.questions.map((question) => ({
          ...omit(question, ['index']),
          ...(question.options
            ? {
                options: question.options.map((answer) =>
                  omit(answer, ['index'])
                ),
              }
            : {}),
        })),
      };
    }
    const { identification_form_deletion, faq_items_deletion } =
      state.campaign.customization[campaignId];
    dispatch({
      type: actionTypes.SAVE_CAMPAIGN_CONFIGURATION_REQUEST,
    });
    let response;
    try {
      response = await api.put(
        `/campaign/${campaignId}${makeVersionTokenUrlParams(versionToken)}`,
        {
          diff,
          identification_form_deletion,
          faq_items_deletion,
        }
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.SAVE_CAMPAIGN_CONFIGURATION_FAILURE,
        versionConflict: error.response?.status === 409,
      });
    }
    dispatch(getCampaignConfiguration(campaignId));
    pushSuccessToaster(
      t({ id: 'campaign-published-success-title' }),
      t({ id: 'campaign-published-success-message' })
    );
    return dispatch({
      type: actionTypes.SAVE_CAMPAIGN_CONFIGURATION_SUCCESS,
      campaignId: response.data,
    });
  };

export const addIdentificationFormElement =
  (campaignId, element) => async (dispatch, getState) => {
    const { identification_form_current_size, versionToken } =
      getState().campaign.customization[campaignId];
    element.position = identification_form_current_size;
    element.placeholder = element.placeholder || '';
    dispatch({
      type: actionTypes.ADD_IDENTIFICATION_FORM_ELEMENT_REQUEST,
    });
    let response;
    try {
      response = await api.post(
        `/campaign/${campaignId}/identification-form-element${makeVersionTokenUrlParams(
          versionToken
        )}`,
        element
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.ADD_IDENTIFICATION_FORM_ELEMENT_FAILURE,
      });
    }
    return dispatch({
      type: actionTypes.ADD_IDENTIFICATION_FORM_ELEMENT_SUCCESS,
      identification_form_element: response.data,
      campaignId,
    });
  };

export const addUserFormElement =
  (campaignId, campaignField, element) => async (dispatch, getState) => {
    const campaignConfiguration =
      getState().campaign.customization[campaignId]
        .currentCampaignConfiguration;
    element.position = campaignConfiguration[campaignField].length;
    element.placeholder = element.placeholder || '';
    dispatch({
      type: actionTypes.ADD_USER_FORM_ELEMENT_REQUEST,
    });
    let response;
    try {
      response = await api.post(
        `/campaign/${campaignId}/user-form-element?form=${campaignField}`,
        element
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.ADD_USER_FORM_ELEMENT_FAILURE,
      });
    }
    return dispatch({
      type: actionTypes.ADD_USER_FORM_ELEMENT_SUCCESS,
      campaignField,
      campaignId,
      formElement: response.data,
    });
  };

export const updateFormElement =
  (campaignId, campaignField, element) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPDATE_USER_FORM_ELEMENT_REQUEST,
    });
    let response = null;
    try {
      response = await api.put(
        `/campaign/${campaignId}/user-form-element/${element.id}?form=${campaignField}`,
        omit(element, ['archived'])
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.UPDATE_USER_FORM_ELEMENT_FAILURE,
      });
    }
    return dispatch({
      type: actionTypes.UPDATE_USER_FORM_ELEMENT_SUCCESS,
      campaignField,
      campaignId,
      element: response.data,
    });
  };

export const removeFormElement =
  (campaignId, campaignField, element) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DELETE_USER_FORM_ELEMENT_REQUEST,
    });
    try {
      await api.delete(
        `/campaign/${campaignId}/user-form-element/${element.id}`
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.DELETE_USER_FORM_ELEMENT_FAILURE,
      });
    }
    return dispatch({
      type: actionTypes.DELETE_USER_FORM_ELEMENT_SUCCESS,
      campaignField,
      campaignId,
      formElementId: element.id,
    });
  };

export const resetWholeIdentificationFormToDefault =
  (campaignId) => (dispatch) =>
    dispatch({
      type: actionTypes.RESET_WHOLE_IDENTIFICATION_FORM_TO_DEFAULT,
      campaignId,
    });

export const resetAllFaqItemsToDefault = (campaignId) => (dispatch) =>
  dispatch({
    type: actionTypes.RESET_ALL_FAQ_ITEMS_TO_DEFAULT,
    campaignId,
  });

export const removeIdentificationFormElement =
  (campaignId, elementId) => (dispatch) =>
    dispatch({
      type: actionTypes.REMOVE_IDENTIFICATION_FORM_ELEMENT,
      campaignId,
      elementId,
    });

export const restoreIdentificationFormElement =
  (campaignId, elementId) => (dispatch, getState) => {
    const state = getState();
    const { identification_form_deletion } =
      state.campaign.customization[campaignId];
    return dispatch({
      type: actionTypes.RESTORE_IDENTIFICATION_FORM_ELEMENT,
      index: identification_form_deletion.indexOf(elementId),
      campaignId,
    });
  };

export const updateIdentificationFormElement =
  (campaignId, element) => (dispatch, getState) =>
    dispatch({
      type: actionTypes.UPDATE_IDENTIFICATION_FORM_ELEMENT,
      campaignId,
      element,
    });

export const addFaqItem =
  (campaignId, header, content) => async (dispatch, getState) => {
    const { faq_items_current_size, versionToken } =
      getState().campaign.customization[campaignId];

    const item = {
      position: faq_items_current_size,
      header,
      content,
    };
    dispatch({
      type: actionTypes.ADD_FAQ_ITEM_REQUEST,
    });
    let response;
    try {
      response = await api.post(
        `/campaign/${campaignId}/faq-item${makeVersionTokenUrlParams(
          versionToken
        )}`,
        item
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.ADD_FAQ_ITEM_FAILURE,
      });
    }
    return dispatch({
      type: actionTypes.ADD_FAQ_ITEM_SUCCESS,
      faq_item: response.data,
      campaignId,
    });
  };

export const removeFaqItem = (campaignId, elementId) => (dispatch) =>
  dispatch({
    type: actionTypes.REMOVE_FAQ_ITEM,
    campaignId,
    elementId,
  });

export const restoreFaqItem =
  (campaignId, position) => (dispatch, getState) => {
    const { customization } = getState().campaign;
    const { faq_items_deletion } = customization[campaignId];
    return dispatch({
      type: actionTypes.RESTORE_FAQ_ITEM,
      index: faq_items_deletion.indexOf(position),
      campaignId,
    });
  };

export const updateFaqItem =
  (campaignId, elementId, position, value) => (dispatch, getState) => {
    const diffFaqItems =
      getState().campaign.customization[campaignId].diff.faq_items;
    const diffFaqSelectedItem = diffFaqItems.filter(
      (item) => item.id === elementId
    );
    return dispatch({
      type: actionTypes.UPDATE_FAQ_ITEM,
      index: diffFaqItems.indexOf(diffFaqSelectedItem[0]),
      alreadyInDiff: diffFaqSelectedItem.length !== 0,
      campaignId,
      position,
      elementId,
      value,
    });
  };

export const reorderFaqItemsFromDragAndDrop =
  (campaignId, reorderedFaqItems) => (dispatch, getState) => {
    const state = getState();
    const completeFaqItems = getCompleteFaqItems(
      state.campaign.customization[campaignId]
    );
    const completeFaqItemsIds = completeFaqItems.map((item) => item.id);
    reorderedFaqItems.forEach((faqItem, i) => {
      const hasMoved = completeFaqItemsIds[i] !== faqItem.id;
      if (hasMoved) {
        dispatch({
          type: actionTypes.UPDATE_FAQ_ITEM_POSITION,
          campaignId,
          faqItem,
          newPosition: i,
        });
      }
    });
  };

const reorderFormElements =
  (formElementKey, useDiff = false) =>
  (campaignId, newItems) =>
  (dispatch, getState) => {
    const state = getState();
    const completeIdentificationFormIds = getCompleteIdentificationForm(
      state,
      campaignId
    ).map((item) => item.id);
    const newPositions = [];
    newItems.forEach((element, i) => {
      const hasMoved = completeIdentificationFormIds[i] !== element.id;
      if (hasMoved) {
        newPositions.push({ id: element.id, position: i });
      }
    });
    dispatch({
      type: actionTypes.UPDATE_FORM_ELEMENT_POSITIONS,
      campaignId,
      newPositions,
      formElementKey,
      useDiff,
    });
  };

export const reorderIdentificationForm = reorderFormElements(
  'identification_form',
  true
);
export const reorderAnnotationForm = reorderFormElements('annotation_form');
export const reorderUrlForm = reorderFormElements('url_form');

export const reloadDraft = (campaignId) => async (dispatch) => {
  dispatch(getCampaignConfiguration(campaignId));
};

export const updateLogoUrl = (campaignId, logoUrl) => ({
  type: actionTypes.UPLOAD_LOGO_SUCCESS,
  campaignId,
  logoUrl,
});

export const updateCampaignLogo =
  (campaignId, file) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.UPLOAD_LOGO_REQUEST,
    });
    const { versionToken } = getState().campaign.customization[campaignId];
    let response;
    const formData = new FormData();
    formData.append('file', file);
    try {
      response = await api.post(
        `/campaign/${campaignId}/upload_asset${makeVersionTokenUrlParams(
          versionToken
        )}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.UPLOAD_LOGO_FAILURE,
        versionConflict: error.response?.status === 409,
      });
    }
    return dispatch(updateLogoUrl(campaignId, response.data.file_url));
  };
