import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { Trans, msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';

import {
  useChannelLabelFormatter,
  useConceptLabelFormatter,
  useUserOrTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { formElementLabelFormatterSelectorFactory } from 'selectors/campaign';
import {
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
} from 'selectors/survey';
import {
  campaignPresetFiltersAreEmptySelector,
  campaignPresetsSelectorFactory,
  createCampaignFilterPreset,
  deleteCampaignFilterPreset,
  editPresetSelector,
  isPresetNameAvailableSelectorFactory,
  saveCampaignPresetLoadingSelector,
  selectedPresetSelector,
  setCampaignFilterPreset,
  setEditPreset,
  updateCampaignFilterPreset,
} from 'store/monitor/campaignPresetSlice';
import { campaignFiltersSelector } from 'store/monitor/monitorSearchSlice';

import { AccordionToggleTitle } from 'components/ui/Accordion';
import Link from 'components/ui/Link';
import ManagementListRowFactory from 'components/ui/ManagementList/ManagementListRowFactory';
import ManagementList from 'components/ui/ManagementList/index';
import { ErrorMessage } from 'components/ui/Message';
import { NoPaddingSegment } from 'components/ui/Segment';
import { SecondaryTabButton, TabButton } from 'components/ui/button/TabButton';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button/index';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import DeleteModal from 'components/ui/modal/DeleteModal';
import SidePane from 'components/ui/panels/SidePane';
import FiltersSummary from 'components/ui/search/FiltersSummary';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import { enrichFieldProps } from '../useCampaignFields';

const MONITORING_PRESETS_ALERT_FREQUENCY_OPTIONS = [
  { key: '10m', i18nText: msg({ id: 'once-every-10-minutes' }), value: '10m' },
  { key: '1H', i18nText: msg({ id: 'once-every-1-hour' }), value: '1H' },
  { key: '1D', i18nText: msg({ id: 'once-a-day' }), value: '1D' },
  { key: '1W', i18nText: msg({ id: 'once-a-week' }), value: '1W' },
];

const getRowActions = (onEditPreset, onDeletePreset) => [
  { icon: 'edit', onClick: onEditPreset },
  { icon: 'trash', onClick: onDeletePreset, danger: true },
];

function MonitoringPresetList({
  onDisplayPresetEditor,
  onConfirmDelete,
  onTogglePresets,
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { campaignId } = useParams();
  const presets = useSelector(campaignPresetsSelectorFactory(campaignId));
  const selectedPreset = useSelector(selectedPresetSelector);
  const onSelectPreset = useCallback(
    (preset) => {
      dispatch(
        setCampaignFilterPreset({ campaignId, presetId: preset.id })
      ).then(() => onTogglePresets());
    },
    [campaignId, onTogglePresets]
  );
  const onEditPreset = useCallback(
    (preset) => {
      if (selectedPreset?.id !== preset.id) {
        dispatch(setCampaignFilterPreset({ campaignId, presetId: preset.id }));
      }
      onDisplayPresetEditor(preset);
    },
    [selectedPreset?.id, campaignId]
  );
  const rowActions = useMemo(
    () => getRowActions(onEditPreset, onConfirmDelete),
    [onEditPreset, onConfirmDelete]
  );

  const renderItemRow = useMemo(
    () => ManagementListRowFactory(null, rowActions),
    [rowActions]
  );

  useEffect(() => {
    const applyPreset = async () => {
      const presetId = searchParams.get('preset');
      if (!presets?.length || !presetId) return;
      await dispatch(
        setCampaignFilterPreset({
          campaignId,
          presetId,
        })
      );

      if (searchParams.get('edit') === '1') {
        const preset = presets?.find((p) => p.id === presetId);
        searchParams.delete('edit');
        if (preset) {
          onDisplayPresetEditor(preset);
        }
      }

      setSearchParams(searchParams);
    };

    applyPreset();
  }, [searchParams, setCampaignFilterPreset, presets]);

  return (
    <ManagementList
      listStyle={{ padding: `0 ${svars.spaceNormal}` }}
      nActions={2}
      onRowClick={onSelectPreset}
      items={presets || []}
      rowFields={[
        enrichFieldProps({
          key: 'name',
          accessor: (item) => item.name,
          sortable: false,
          width: 170,
        }),
        enrichFieldProps(
          {
            key: 'date',
            centered: true,
            accessor: (item) => item.create_date,
            sortable: false,
            width: 80,
          },
          false,
          { isDateTime: true }
        ),
      ]}
      defaultSorted={{
        key: 'date',
        ascending: false,
      }}
      renderItemRow={renderItemRow}
      isRowActive={(item) => item.id === selectedPreset?.id}
      emptyListHeader={<Trans id="no-campaign-preset" />}
      withTextFilter={false}
      actions={
        <AnalyticsAwareButton
          gaCategory="Campaign - monitoring preset sidepane"
          gaAction="Create campaign preset"
          inputComponent={TabButton}
          onClick={() => onDisplayPresetEditor(true)}
          content={t({ id: 'new-preset' })}
          icon="plus"
          labelPosition="right"
          data-testid="bo-campaign-preset-list-create-campaign-preset-button"
          style={{ margin: svars.spaceMedium }}
        />
      }
    />
  );
}

MonitoringPresetList.propTypes = {
  onDisplayPresetEditor: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onTogglePresets: PropTypes.func.isRequired,
};

function MonitoringPresetEditor({
  preset,
  onDisplayPresetList,
  onToggleFilters,
  onTogglePresets,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [presetName, setPresetName] = useState(preset?.name || '');
  const [hasNotification, setHasNotification] = useState(
    !!preset?.notification?.frequency || false
  );
  const [notificationFrequency, setNotificationFrequency] = useState(
    preset?.notification?.frequency ||
      MONITORING_PRESETS_ALERT_FREQUENCY_OPTIONS[0].value
  );
  const savePresetLoading = useSelector(saveCampaignPresetLoadingSelector);
  const filtersValues = useSelector(campaignFiltersSelector);
  const userOrTagLabelFormatter = useUserOrTagLabelFormatter();
  const conceptLabelFormatter = useConceptLabelFormatter();
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId)
  );
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const questionAnswerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const formElementLabelFormatter = useSelector(
    formElementLabelFormatterSelectorFactory(campaignId)
  );
  const isPresetNameAvailable = useSelector(
    isPresetNameAvailableSelectorFactory(campaignId)
  );
  const campaignPresetFiltersAreEmpty = useSelector(
    campaignPresetFiltersAreEmptySelector
  );
  const onSaveFilterPreset = useCallback(() => {
    const payload = {
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency,
    };
    // Update
    const action = preset?.id
      ? updateCampaignFilterPreset({ ...payload, id: preset?.id })
      : createCampaignFilterPreset(payload);
    // eslint-disable-next-line consistent-return
    dispatch(action).then((response) => {
      if (response?.payload) {
        return onDisplayPresetList();
      }
    });
  }, [
    campaignId,
    presetName,
    filtersValues,
    hasNotification,
    notificationFrequency,
    preset,
  ]);
  const isValidPresetConfiguration =
    !!presetName && (preset?.id || isPresetNameAvailable(presetName));
  return (
    <>
      <SidePane.Body>
        <LimitedTextInput
          autoFocus
          placeholder={t({ id: 'monitoring-preset-name' })}
          style={{
            margin: `0 ${svars.spaceMedium}`,
            marginTop: svars.spaceMedium,
          }}
          maxCharacters={80}
          value={presetName}
          onChange={(_, { value }) => setPresetName(value)}
        />
        <ErrorMessage
          style={{ margin: `0 ${svars.spaceMedium}` }}
          show={!!(presetName && !isValidPresetConfiguration)}
          message={t({ id: 'campaign-preset-name-not-available' })}
        />

        <Accordion as={NoPaddingSegment} style={{ margin: svars.spaceMedium }}>
          <AccordionToggleTitle
            articleId={28}
            active={hasNotification}
            index={0}
            onToggle={() => setHasNotification(!hasNotification)}
            title={<Trans id="add-notification" />}
          />
          <Accordion.Content active={hasNotification}>
            <div
              style={{ margin: `${svars.spaceNormal} ${svars.spaceMedium}` }}
            >
              <Trans id="notification-will-be-triggered" />
              <InTextDropdown
                options={MONITORING_PRESETS_ALERT_FREQUENCY_OPTIONS}
                value={notificationFrequency}
                onChange={(_, { value }) => setNotificationFrequency(value)}
              />
            </div>
          </Accordion.Content>
        </Accordion>

        <FiltersSummary
          fluid
          style={{ margin: `${svars.spaceMedium} auto` }}
          title={t({ id: 'Filters' })}
          titleAction={
            <SecondaryTabButton
              style={{ marginLeft: svars.spaceMedium }}
              labelPosition="right"
              icon="edit"
              content={t({ id: 'modify' })}
              onClick={() => {
                onToggleFilters();
                onTogglePresets();
              }}
            />
          }
          isEmpty={campaignPresetFiltersAreEmpty}
          emptyDataActionElement={
            <span>
              <p>
                <Trans>
                  <Link base="true" onClick={onToggleFilters}>
                    Go back to search
                  </Link>{' '}
                  to add filters.
                </Trans>
              </p>
            </span>
          }
        >
          <FiltersSummary.OneFilter
            label={t({ id: 'channels' })}
            value={filtersValues.channels}
            formatter={channelLabelFormatter}
          />
          {Object.entries(filtersValues.tags).map(
            ([formFieldName, formFieldValues]) =>
              formFieldName === 'satisfaction_tag' ? (
                <FiltersSummary.OneFilter
                  key="fvti-satisfaction"
                  label={t({ id: 'satisfaction-level' })}
                  value={formFieldValues}
                  formatter={userOrTagLabelFormatter}
                />
              ) : (
                Object.entries(formFieldValues).map(([formFieldId, tagIds]) => (
                  <FiltersSummary.OneFilter
                    key={`fvti-${formFieldId}`}
                    label={
                      formFieldName === 'satisfaction_tag'
                        ? t({ id: 'satisfaction-level' })
                        : formElementLabelFormatter(formFieldId)
                    }
                    value={tagIds}
                    formatter={userOrTagLabelFormatter}
                  />
                ))
              )
          )}
          <FiltersSummary.OneFilter
            label={t({ id: 'ontology-concepts' })}
            value={filtersValues.concepts}
            formatter={conceptLabelFormatter}
          />

          {Object.entries(filtersValues.answers || {}).map(
            ([questionId, optionIds]) => (
              <FiltersSummary.OneFilter
                key={`fva-${questionId}`}
                label={questionLabelFormatter(questionId, true)}
                value={optionIds}
                formatter={questionAnswerLabelFormatter}
              />
            )
          )}
        </FiltersSummary>
      </SidePane.Body>
      <SidePane.Actions visible>
        <AnalyticsAwareButton
          gaCategory="Campaign - monitoring preset sidepane"
          gaAction="Cancel monitoring preset save"
          disabled={savePresetLoading}
          inputComponent={ButtonTransparentDanger}
          content={t({ id: 'campaign-preset-edit.back-to-list' })}
          onClick={onDisplayPresetList}
        />
        <AnalyticsAwareButton
          disabled={!isValidPresetConfiguration}
          loading={savePresetLoading}
          gaCategory="Campaign - monitoring preset sidepane"
          gaAction="Save monitoring preset"
          gaLabel={presetName}
          inputComponent={ButtonAccent}
          content={t({ id: 'save' })}
          style={{ marginLeft: svars.spaceMediumLarge }}
          onClick={onSaveFilterPreset}
        />
      </SidePane.Actions>
    </>
  );
}

MonitoringPresetEditor.propTypes = {
  preset: PropTypes.oneOfType([commonPropTypes.campaignPreset, PropTypes.bool]),
  onDisplayPresetList: PropTypes.func.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
  onTogglePresets: PropTypes.func.isRequired,
};
MonitoringPresetEditor.defaultProps = { preset: null };

function MonitoringPresetSidePane({
  displayPresets,
  onTogglePresets,
  onToggleFilters,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [toDeletePreset, setToDeletePreset] = useState(null);

  const editPreset = useSelector(editPresetSelector);
  const onSetEditPreset = useCallback(
    (preset) => {
      dispatch(setEditPreset(preset));
    },
    [dispatch]
  );

  const onDeletePreset = useCallback(() => {
    dispatch(
      deleteCampaignFilterPreset({ campaignId, presetId: toDeletePreset.id })
    );
  }, [campaignId, toDeletePreset]);

  const onConfirmDelete = useCallback(
    (preset) => {
      setToDeletePreset(preset);
      setDeleteModalOpen(true);
    },
    [deleteModalOpen]
  );

  const onDisplayPresetEditor = useCallback(
    (preset) => {
      onSetEditPreset(preset);
      if (!displayPresets) onTogglePresets();
    },
    [displayPresets, onSetEditPreset]
  );
  const onGoBackToList = useCallback(() => onSetEditPreset(null), []);

  useEffect(() => {
    if (!displayPresets) {
      onSetEditPreset(null);
    }
  }, [displayPresets]);
  const toDeleteCampaignName = toDeletePreset?.name;
  return (
    <>
      <SidePane
        dimmed
        dimmerColor={svars.colorLightestGrey}
        onToggle={onTogglePresets}
        animation="push"
        direction="right"
        visible={displayPresets}
        width="very wide"
      >
        <SidePane.Header
          title={t({ id: 'new-preset' })}
          onToggle={onTogglePresets}
          gaCategory="Campaign - monitoring preset sidepane"
        />
        {editPreset ? (
          <MonitoringPresetEditor
            preset={editPreset}
            onDisplayPresetList={onGoBackToList}
            onToggleFilters={onToggleFilters}
            onTogglePresets={onTogglePresets}
          />
        ) : (
          <SidePane.Body>
            <MonitoringPresetList
              onDisplayPresetEditor={onDisplayPresetEditor}
              onConfirmDelete={onConfirmDelete}
              onTogglePresets={onTogglePresets}
            />
          </SidePane.Body>
        )}
      </SidePane>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => {
          setToDeletePreset(null);
          setDeleteModalOpen(false);
        }}
        onDelete={onDeletePreset}
        headerText={t({ id: 'campaign-preset-deletion.confirm-modal-title' })}
        contentText={
          <Trans>
            campaign-deletion.confirm-modal-message-
            <b>{toDeleteCampaignName}</b>
          </Trans>
        }
        contentSubText={
          <Trans>
            campaign-preset-deletion.confirm-modal-sub-message-
            <DeleteModal.WarningText>delete-forever</DeleteModal.WarningText>
          </Trans>
        }
        confirmWithInputValue={toDeleteCampaignName}
      />
    </>
  );
}

MonitoringPresetSidePane.propTypes = {
  displayPresets: PropTypes.bool.isRequired,
  onTogglePresets: PropTypes.func.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
};

MonitoringPresetSidePane.defaultProps = {};

export default MonitoringPresetSidePane;
