import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist/index';

import { Trans, t } from '@lingui/macro';
import { Image, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { toggleKnowledgeBase } from 'actions/ui';
import {
  fetchNotifications,
  updateNotificationStatus,
} from 'store/monitor/notificationsSlice';

import NotificationCard from 'components/customer/notifications/Card';
import BeatingLoader from 'components/ui/BeatingLoader';
import Header, { LargeHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import { TabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip } from 'components/ui/icon/HoverableIconWithBadge';
import DeleteModal from 'components/ui/modal/DeleteModal';
import SidePane from 'components/ui/panels/SidePane';
import errorNotificationsUrl from 'components/ui/svg/undraw_bug_fixing_oc-7-a.svg';
import noNotificationsUrl from 'components/ui/svg/undraw_dreamer_re_9tua.svg';

import config from 'config';
import { StateStatus } from 'utils/apiSlice';
import { useInfiniteScroll } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import getStatusAndEndpoint from './utils';

const CenteredImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  min-height: ${svars.imageHeight};
  padding: ${svars.imagePadding};
`;

const NotificationList = styled.div`
  &&&&& {
    margin-right: 15px;
  }
`;

function NoNotifications() {
  return (
    <CenteredImageContainer>
      <Image src={noNotificationsUrl} size="medium" />
      <LargeHeader>{t({ id: 'no-notifications' })}</LargeHeader>
      <div
        style={{
          fontSize: svars.fontSizeMedium,
          color: svars.colorGrey,
        }}
      >
        <Trans id="all-caught-up" />
      </div>
    </CenteredImageContainer>
  );
}

function ErrorFetchingNotifications() {
  const { supportEmail } = config;
  return (
    <CenteredImageContainer>
      <Image src={errorNotificationsUrl} size="medium" />
      <LargeHeader>{t({ id: `error-fetching-notifications` })}</LargeHeader>
      <div
        style={{
          fontSize: svars.fontSizeMedium,
          color: svars.colorGrey,
        }}
      >
        <Trans>
          general-error-boundary.reach-support-for-emergency{' '}
          <Link base primary href={`mailto:${config.supportEmail}`}>
            {supportEmail}
          </Link>
        </Trans>
      </div>
    </CenteredImageContainer>
  );
}

function renderNotificationContent(
  notificationStatus,
  notifications,
  toggleSidePane
) {
  if (
    notificationStatus === StateStatus.PENDING &&
    notifications.length === 0
  ) {
    return <BeatingLoader />;
  }

  if (notificationStatus === StateStatus.REJECTED) {
    return <ErrorFetchingNotifications />;
  }

  if (notifications.length === 0) {
    return <NoNotifications />;
  }

  return (
    <NotificationList>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          onToggle={toggleSidePane}
          {...notification}
        />
      ))}
    </NotificationList>
  );
}

export default function NotificationCenter() {
  const [isSidePaneOpen, setIsSidePaneOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const notificationStatus = useSelector(
    (state) => state.notifications?.status
  );
  const notifications = useSelector(
    (state) => state.notifications?.notifications
  );
  const totalCount = useSelector((state) => state.notifications?.total_count);

  const notificationIds = notifications.map((notification) => notification.id);
  const showBadge = notifications.some((notification) => !notification.seen);

  const closeModal = useCallback(() => setModalOpen(false), []);
  const toggleSidePane = () => {
    setIsSidePaneOpen(!isSidePaneOpen);
  };

  const handleNotificationAction = ({
    notificationId = null,
    links = {},
    markAsSeen = false,
    toggleSeenStatus = false,
    currentSeenStatus = false,
  }) => {
    const { status, callEndpoint } = getStatusAndEndpoint({
      markAsSeen,
      toggleSeenStatus,
      currentSeenStatus,
    });
    const ids = notificationId ? [notificationId] : notificationIds;

    dispatch(
      updateNotificationStatus({
        notificationIds: ids,
        seen: status,
        callEndpoint,
      })
    );

    if (navigate && (links.providedLink || links.generatedLink)) {
      navigate(links.providedLink || links.generatedLink);
    }
  };

  useEffect(() => {
    if (isSidePaneOpen) {
      setOffset(0);
      dispatch(fetchNotifications({ offset: 0, limit }));
    }
  }, [dispatch, isSidePaneOpen, limit]);

  const loading = notificationStatus === StateStatus.PENDING;
  const noMoreToLoad = notifications.length >= totalCount;

  const loadMore = useCallback(() => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    dispatch(fetchNotifications({ offset: newOffset, limit }));
  }, [dispatch, offset, limit]);

  const showInfiniteScrollLoader = loading && notifications.length > 0;

  const loaderComponent = (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <Loader active inline size="small" />
    </div>
  );

  const loader = useInfiniteScroll({
    elementRef: scrollRef,
    loading: showInfiniteScrollLoader,
    noMoreToLoad,
    loadMoreCallback: loadMore,
    offset: 50,
    loaderComponent,
  });

  return (
    <>
      <AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip
        onClick={() => {
          window.HelpCrunch?.('closeChat');
          dispatch(toggleKnowledgeBase(null, false));
          toggleSidePane();
        }}
        name="bell"
        help={<Trans id="notifications" />}
        showBadge={showBadge}
      />
      <SidePane
        direction="right"
        visible={isSidePaneOpen}
        onToggle={toggleSidePane}
        width="very wide"
        animation="overlay"
        dimmed
      >
        <SidePane.Header
          gaCategory="Notification center"
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Header nomargin="true">Notifications</Header>
              <TabButton
                icon="envelope open outline"
                content={t({ id: `mark-all-as-read` })}
                onClick={() => {
                  setModalOpen(true);
                }}
                disabled={
                  notificationStatus === StateStatus.REJECTED ||
                  notifications.length === 0
                }
              />
              <DeleteModal
                open={modalOpen}
                onDelete={() => {
                  handleNotificationAction({
                    markAsSeen: true,
                    currentSeenStatus: false,
                  });
                }}
                onClose={closeModal}
                headerText={t({ id: `mark-all-as-read-confirmation-text` })}
                confirmButtonText={t({
                  id: `mark-all-as-read-confirmation-button`,
                })}
                cancelButtonText={t({ id: `mark-all-as-read-cancel-button` })}
                contentText={t({
                  id: `mark-all-as-read-confirmation-context`,
                })}
                isDelete={false}
              />
            </div>
          }
          onToggle={toggleSidePane}
        />
        <SidePane.Body ref={scrollRef}>
          {renderNotificationContent(
            notificationStatus,
            notifications,
            toggleSidePane
          )}
          {loader}
        </SidePane.Body>
      </SidePane>
    </>
  );
}
