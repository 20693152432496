import { Navigate, Outlet, Route } from 'react-router-dom';

import { t } from '@lingui/macro';

import AxiosInterceptorNavigate from 'actions/middlewares';

import ErrorBoundary from 'components/ErrorBoundary';
import AdminHomePage from 'components/admin/AdminHomePage';
import ComponentDesignPage from 'components/admin/ComponentDesignPage';
import IntentionalErrorPage from 'components/admin/IntentionalErrorPage';
import LivePage from 'components/admin/LivePage';
import AdminOperationsPage from 'components/admin/operations/AdminOperationsPage';
import AdminSupportPage from 'components/admin/support/AdminSupportPage';
import ValidationPage from 'components/admin/validation/ValidationPage';
import CustomerSupportPage from 'components/customer/CustomerSupportPage';
import SettingsPage from 'components/customer/SettingsPage';
import SupportModal from 'components/customer/SupportModal';
import ViewFacetAnalysisPage from 'components/customer/analysis/ViewFacetAnalysisPage';
import CampaignHomePage from 'components/customer/campaign/CampaignHomePage';
import CampaignListPage from 'components/customer/campaign/CampaignListPage';
import CampaignLoader from 'components/customer/campaign/CampaignLoader';
import { CampaignPagesCrumb } from 'components/customer/campaign/CampaignPagesCrumb';
import CreateCampaignPage from 'components/customer/campaign/CreateCampaignPage';
import AdministationAnnotationFormTabPage from 'components/customer/campaign/administrate/AnnotationFormTabPage';
import CampaignAdministrationPage from 'components/customer/campaign/administrate/CampaignAdministrationPage';
import MembersManagementTabPage from 'components/customer/campaign/administrate/MembersManagementTabPage';
import AdministationUrlFormTabPage from 'components/customer/campaign/administrate/UrlFormTabPage';
import CampaignChannelListPage from 'components/customer/campaign/channel/CampaignChannelListPage';
import CreateChannelPage from 'components/customer/campaign/channel/CreateChannelPage';
import CustomizeCampaignLayout from 'components/customer/campaign/customize/CustomizeCampaignLayout';
import CustomizeHelpPrivacyTab from 'components/customer/campaign/customize/CustomizeHelpPrivacyTab';
import CustomizeIdentificationFormTab from 'components/customer/campaign/customize/CustomizeIdentificationFormTab';
import CustomizeQuestionsTab from 'components/customer/campaign/customize/CustomizeQuestionsTab';
import CustomizeThemeTab from 'components/customer/campaign/customize/CustomizeThemeTab';
import CustomizeGeneralTab from 'components/customer/campaign/customize/GeneralCampaignCustomizeTab';
import {
  CustomizeFeedbackFormTab,
  CustomizeHomeTab,
  CustomizePublishedTab,
  CustomizeSearchTab,
} from 'components/customer/campaign/customize/tabs';
import MonitoringDashboardLayout from 'components/customer/campaign/monitor/MonitoringDashboardLayout';
import MonitoringDashboardTab from 'components/customer/campaign/monitor/MonitoringDashboardTab';
import MonitoringSearchTab from 'components/customer/campaign/monitor/MonitoringSearchTab';
import ProductHierarchyGroupListPage from 'components/customer/home/ProductHierarchyGroupListPage';
import ViewFacetListPage from 'components/customer/home/ViewFacetListPage';
import ViewFacetCreatePage from 'components/customer/home/view-facet-create/ViewFacetCreatePage';
import LoginLayout from 'components/login/LoginLayout';
import LoginPage from 'components/login/LoginPage';
import RenewPasswordPage from 'components/login/RenewPasswordPage';
import UpdatePasswordPage from 'components/login/UpdatePasswordPage';
import Toaster from 'components/ui/Toaster';
import LoggedLayout, { AdminLoggedLayout } from 'components/ui/layout/Layout';
import {
  CampaignListLoader,
  FacetListLoader,
} from 'components/ui/layout/loaders';
import {
  CampaignCrumb,
  FacetCrumb,
  pageCrumbFactory,
} from 'components/ui/navigation/PageBreadcrumbs';

import { PageTracker } from 'utils/hooks';
import {
  AdminCampaignRoute,
  ReadCampaignRoute,
  RedirectToHome,
  WriteCampaignRoute,
} from 'utils/routing';

const baseRoutes = () => (
  <Route
    errorElement={<ErrorBoundary />}
    element={
      <>
        <AxiosInterceptorNavigate />
        <Toaster />
        <SupportModal />
        <PageTracker />
        <Outlet />
      </>
    }
  >
    <Route element={<LoginLayout />}>
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/renew-password" element={<RenewPasswordPage />} />
      <Route exact path="/update-password" element={<UpdatePasswordPage />} />
    </Route>
    <Route
      path="/settings"
      handle={{ crumb: pageCrumbFactory(t({ id: 'user-settings' })) }}
    >
      <Route
        path="profile"
        element={
          <LoggedLayout withV3Navbar fullHeight>
            <SettingsPage />
          </LoggedLayout>
        }
      />
    </Route>
    <Route
      path="/admin"
      handle={{ crumb: pageCrumbFactory(t({ id: 'admin' })) }}
    >
      <Route
        index
        element={
          <AdminLoggedLayout
            withV3Navbar
            // withSidebar
            fullHeight
            // withLargeSidebar
            // withBreadcrumbs
          >
            <AdminHomePage />
          </AdminLoggedLayout>
        }
      />
      <Route
        element={
          <AdminLoggedLayout withV3Navbar>
            <Outlet />
          </AdminLoggedLayout>
        }
      >
        <Route
          exact
          path="intentional-error"
          element={<IntentionalErrorPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t({ id: 'validation' })) }}
          path="validation"
          element={<ValidationPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t({ id: 'support' })) }}
          path="support"
          element={<AdminSupportPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t({ id: 'operations' })) }}
          path="operations"
          element={<AdminOperationsPage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t({ id: 'live' })) }}
          path="live"
          element={<LivePage />}
        />
        <Route
          exact
          handle={{ crumb: pageCrumbFactory(t({ id: 'design' })) }}
          path="design"
          element={<ComponentDesignPage />}
        />
      </Route>
    </Route>
    <Route
      exact
      path="/product-groups"
      element={
        <LoggedLayout withV3Navbar fullHeight>
          <FacetListLoader />
          <ProductHierarchyGroupListPage />
        </LoggedLayout>
      }
      handle={{ crumb: pageCrumbFactory(t({ id: 'product-groups' })) }}
    />
    <Route
      path="/facets"
      handle={{ crumb: pageCrumbFactory(t({ id: 'analyses' })) }}
      element={
        <LoggedLayout withV3Navbar fullHeight>
          <FacetListLoader />
          <Outlet />
        </LoggedLayout>
      }
    >
      <Route index element={<ViewFacetListPage />} />
      <Route
        exact
        path="create"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t({ id: 'create-analysis' })) }}
      />
      <Route
        exact
        path="update/:viewFacetId"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t({ id: 'update-analysis' })) }}
      />
      <Route
        exact
        path="copy/:viewFacetId"
        element={<ViewFacetCreatePage />}
        handle={{ crumb: pageCrumbFactory(t({ id: 'copy-analysis' })) }}
      />
      <Route
        path="analyze/:viewFacetId/:dashboardPane"
        element={<ViewFacetAnalysisPage />}
        handle={{ crumb: FacetCrumb }}
      />
    </Route>
    <Route
      exact
      path="/campaign"
      handle={{ crumb: pageCrumbFactory(t({ id: 'campaigns' })) }}
      element={
        <LoggedLayout fullHeight withV3Navbar>
          <CampaignListLoader />
          <Outlet />
        </LoggedLayout>
      }
    >
      <Route index element={<CampaignListPage />} />
      <Route
        path="create"
        element={<CreateCampaignPage />}
        handle={{ crumb: pageCrumbFactory(t({ id: 'create-new-campaign' })) }}
      />
      <Route
        exact
        path=":campaignId"
        element={
          <CampaignLoader>
            <Outlet />
          </CampaignLoader>
        }
        handle={{ crumb: CampaignCrumb }}
      >
        <Route index element={<CampaignHomePage />} />
        <Route handle={{ crumb: CampaignPagesCrumb }} element={<Outlet />}>
          <Route
            path="customize"
            element={
              <AdminCampaignRoute>
                <CustomizeCampaignLayout />
              </AdminCampaignRoute>
            }
          >
            <Route path="general" element={<CustomizeGeneralTab />} />
            <Route
              path="feedback-form"
              element={<CustomizeFeedbackFormTab />}
            />
            <Route path="home" element={<CustomizeHomeTab />} />
            <Route
              path="identification-form"
              element={<CustomizeIdentificationFormTab />}
            />
            <Route path="privacy" element={<CustomizeHelpPrivacyTab />} />
            <Route path="published" element={<CustomizePublishedTab />} />
            <Route path="questions" element={<CustomizeQuestionsTab />} />
            <Route path="search" element={<CustomizeSearchTab />} />
            <Route path="appearance" element={<CustomizeThemeTab />} />
          </Route>
          <Route path="channels">
            <Route
              index
              element={
                <ReadCampaignRoute>
                  <CampaignChannelListPage />
                </ReadCampaignRoute>
              }
            />
            <Route
              path="create"
              element={
                <WriteCampaignRoute>
                  <CreateChannelPage />
                </WriteCampaignRoute>
              }
            />
          </Route>
          <Route
            path="administrate"
            element={
              <AdminCampaignRoute>
                <CampaignAdministrationPage />
              </AdminCampaignRoute>
            }
          >
            <Route path="url-form" element={<AdministationUrlFormTabPage />} />
            <Route
              path="annotation-form"
              element={<AdministationAnnotationFormTabPage />}
            />
            <Route path="members" element={<MembersManagementTabPage />} />
            <Route
              index
              path="*"
              element={<Navigate to="url-form" replace />}
            />
          </Route>
          <Route path="monitor" element={<MonitoringDashboardLayout />}>
            <Route
              path="dashboard"
              element={<MonitoringDashboardTab key={1} />}
            />
            <Route path="search" element={<MonitoringSearchTab />} />
            <Route
              index
              path="*"
              element={<Navigate to="dashboard" replace />}
            />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="/contact-support" element={<CustomerSupportPage />} />
    <Route path="*" element={<RedirectToHome />} />
  </Route>
);

export default baseRoutes;
