import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { usersIsLoadingSelector } from 'selectors/entities';
import { authorizedUsersItemsSelector } from 'store/campaign/campaignPermissionsSlice';

import TogglableSection from 'components/customer/campaign/customize/TogglableSection';
import { ErrorMessage } from 'components/ui/Message';
import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { ButtonAccent, ButtonTransparentDanger } from 'components/ui/button';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

import { RouterLink } from '../Link';
import LightText from '../Text';
import LargeRadioButtonGroup from '../button/LargeRadioButtonGroup';
import CheckboxList from '../inputs/checkbox-list/index';

export const formElementTypeMapping = {
  InputFormElement: 'Input element',
  MultipleChoiceFormElement: 'Multichoice selector',
  UserFormElement: 'User selector',
};

const COMMON_TYPE_OPTIONS = [
  {
    type: 'InputFormElement',
    header: msg({ id: 'input-field' }),
    help: msg({ id: 'input-field-help' }),
  },
  {
    type: 'MultipleChoiceFormElement',
    header: msg({ id: 'multi-choice-field' }),
    help: msg({ id: 'multi-choice-field-help' }),
  },
];

const USER_FORM_ELEMENT_TYPE_OPTION = {
  type: 'UserFormElement',
  header: msg({ id: 'user-field' }),
  help: msg({ id: 'user-field-help' }),
};

const getFormTypeOptions = (canCreateUserFormElement) => {
  const options = [...COMMON_TYPE_OPTIONS];
  if (canCreateUserFormElement) {
    options.push(USER_FORM_ELEMENT_TYPE_OPTION);
  }
  return options;
};

const FieldNameBox = styled.span`
  flex-grow: 1;
  max-width: ${svars.inputMaxWidth};
  margin: 0 ${svars.spaceLarge};
`;

const PlaceholderBox = styled.span`
  flex-grow: 1;
  margin: 0 ${svars.spaceMediumLarge};
  min-width: ${svars.inputMinWidth};
`;

const MultiChoiceField = styled.span`
  max-width: ${svars.inputMaxWidth};
`;

function FormItemManagementModal({
  formNames,
  create,
  formElement,
  open,
  onClose,
  tagSetsOptions,
  onAddElement,
  onUpdateElement,
  addItemLabel,
  editItemLabel,
  mandatoryIsAllowed,
  mutableIsAllowed,
  userFormElementIsAllowed,
}) {
  const { campaignId } = useParams();
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [formType, setFormType] = useState('');
  const [tag_set, setTagSet] = useState('');
  const [dropdown, setDropdown] = useState(false);
  const [mandatory, setMandatory] = useState(false);
  const [mutable, setMutable] = useState(false);
  const [annotableUsers, setAnnotableUsers] = useState([]);
  const isUserAnnotable = (user) => annotableUsers.includes(user.value);

  const users = useSelector(authorizedUsersItemsSelector(campaignId));
  const usersIsLoading = useSelector(usersIsLoadingSelector);

  useEffect(() => {
    if (formElement) {
      setLabel(formElement.label);
      setPlaceholder(formElement.placeholder);
      setFormType(formElement.form_type);
      if (formElement.users) {
        setAnnotableUsers(formElement.users.map((user) => user.id));
      }

      if (formElement.form_type === 'MultipleChoiceFormElement') {
        setTagSet(formElement.tag_set);
        setDropdown(formElement.dropdown);
      }
      if (mandatoryIsAllowed) setMandatory(formElement.mandatory);
      if (mutableIsAllowed) setMutable(formElement.mutable);
    }
  }, [formElement]);

  const onSelectItem = useCallback(
    ({ value }) => setAnnotableUsers([...annotableUsers, value]),
    [annotableUsers]
  );
  const onSelectItems = useCallback(
    (selectedItems) =>
      setAnnotableUsers([
        ...annotableUsers,
        ...selectedItems
          .filter((item) => !isUserAnnotable(item))
          .map(({ value }) => value),
      ]),
    [annotableUsers]
  );
  const onUnselectItem = useCallback(
    ({ value: target }) =>
      setAnnotableUsers(annotableUsers.filter((value) => target !== value)),
    [annotableUsers]
  );

  const onFilterReset = useCallback(() => setAnnotableUsers([]), []);

  const onSubmit = useCallback(async () => {
    const element = {
      form_type: formType,
      ...formElement,
      label,
      placeholder,
      mandatory,
      mutable,
    };
    if (formType === 'MultipleChoiceFormElement') {
      element.tag_set = tag_set;
      element.dropdown = dropdown;
    } else if (formType === 'UserFormElement') {
      element.users = annotableUsers.map((user) => ({ id: user }));
    }
    if (create) {
      await onAddElement(element);
    } else {
      onUpdateElement(element);
    }
    onClose();
  }, [
    create,
    dropdown,
    formElement,
    formType,
    label,
    mandatory,
    annotableUsers,
    mutable,
    onAddElement,
    onClose,
    onUpdateElement,
    placeholder,
    tag_set,
  ]);
  const nameIsAlreadyUsed =
    formNames.includes(label) && (!formElement || formElement.label !== label);
  const isFormIncomplete =
    !formType ||
    !label ||
    (formType === 'MultipleChoiceFormElement' && !tag_set) ||
    (formType === 'UserFormElement' && annotableUsers.length === 0) ||
    nameIsAlreadyUsed;
  const placeholderRequired = formType === 'InputFormElement' || dropdown;
  const typeOptions = useMemo(
    () => getFormTypeOptions(userFormElementIsAllowed),
    [userFormElementIsAllowed]
  );
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header content={create ? addItemLabel : editItemLabel} />
      <Modal.Content style={{ maxHeight: '75vh', overflow: 'clip auto' }}>
        <Segment
          style={{ display: 'inline-flex', width: '100%', flexWrap: 'wrap' }}
        >
          <FieldNameBox>
            <Header
              content={<Trans id="form-item-name" />}
              style={{ marginBottom: svars.spaceNormal }}
            />
            <LimitedTextInput
              style={{ width: '100%' }}
              value={label}
              onChange={(event) => {
                setLabel(event.target.value);
              }}
              maxCharacters={40}
            />
            <ErrorMessage
              show={nameIsAlreadyUsed}
              message={t({ id: 'form-item-name-already-used' })}
            />
          </FieldNameBox>
          <PlaceholderBox>
            <Header
              content={t({ id: 'placeholder' })}
              style={{ marginBottom: svars.spaceNormal }}
            />
            <TextInput
              style={{ width: '100%' }}
              value={placeholder}
              disabled={!placeholderRequired}
              onChange={(event) => {
                setPlaceholder(event.target.value);
              }}
            />
          </PlaceholderBox>
        </Segment>

        <Segment style={{ display: 'flex', flexDirection: 'column' }}>
          <Header style={{ marginBottom: svars.spaceNormal }}>
            <Trans id="form-item-type" />
          </Header>
          {create ? (
            <LargeRadioButtonGroup
              items={typeOptions}
              value={formType}
              onClick={setFormType}
            />
          ) : (
            <span style={{ marginLeft: '3px' }}>
              {formElementTypeMapping[formType]}
            </span>
          )}
        </Segment>
        {formType === 'MultipleChoiceFormElement' && (
          <Segment>
            <MultiChoiceField>
              <Header
                content={t({ id: 'tag-set' })}
                style={{ marginBottom: svars.spaceNormal }}
              />
              <SelectBox
                options={tagSetsOptions}
                value={tag_set.id}
                onChange={(e, data) => {
                  const choice = data.options.filter(
                    (option) => option.value === data.value
                  )[0];
                  setTagSet({ id: choice?.key, name: choice?.text });
                }}
              />
              <Header
                content={t({ id: 'dropdown' })}
                style={{ marginBottom: svars.spaceNormal }}
              />
              <Checkbox
                checked={dropdown}
                onChange={(e, data) => {
                  setDropdown(data.checked);
                }}
              />
            </MultiChoiceField>
          </Segment>
        )}
        {formType === 'UserFormElement' && (
          <Segment>
            <span style={{ margin: `0 ${svars.spaceLarge}` }}>
              <p style={{ marginBottom: svars.spaceNormal }}>
                <Trans id="select-users-for-annotation" />
                <br />
                <LightText>
                  <Trans>
                    If users are missing, you can add them in the{' '}
                    <RouterLink to="../members">members section</RouterLink>.
                  </Trans>
                </LightText>
              </p>
              <CheckboxList
                items={users}
                selectedItems={annotableUsers}
                loading={usersIsLoading}
                onSelectItem={onSelectItem}
                onSelectItems={onSelectItems}
                onUnselectItem={onUnselectItem}
                onResetItems={onFilterReset}
                isSelectedItem={isUserAnnotable}
                placeholder={t({ id: 'search-users-for-annotation' })}
                noDataMessage={t({ id: 'no-users-authorized' })}
                emptyListMessage={t({ id: 'no-users-found' })}
                searchable={(users?.length || 0) > 15}
              />
            </span>
          </Segment>
        )}
        {mandatoryIsAllowed ? (
          <Segment>
            <Checkbox
              style={{ width: '100%' }}
              toggle
              label={t({ id: 'mandatory' })}
              checked={mandatory}
              onChange={(e, data) => {
                setMandatory(data.checked);
              }}
            />
          </Segment>
        ) : null}
        {mutableIsAllowed ? (
          <Segment style={{ margin: 0, padding: 0 }}>
            <TogglableSection
              gaCategory="Campaign - administration"
              gaAction="Toggle mutable"
              checked={mutable}
              onChange={(e, data) => {
                setMutable(data.checked);
              }}
              title={t({ id: 'field-is-mutable' })}
              subtitle={t({ id: 'form-field-is-mutable-help' })}
              style={{ width: '100%', borderRadius: svars.borderRadius }}
            />
          </Segment>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger
          type="submit"
          onClick={onClose}
          content={t({ id: 'cancel' })}
        />
        <ButtonAccent
          type="submit"
          disabled={isFormIncomplete}
          onClick={onSubmit}
        >
          {create ? t({ id: 'create' }) : t({ id: 'save-changes' })}
        </ButtonAccent>
      </Modal.Actions>
    </Modal>
  );
}

FormItemManagementModal.propTypes = {
  formNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tagSetsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  create: PropTypes.bool,
  formElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.number,
    form_type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tag_set: PropTypes.objectOf(PropTypes.string),
    users: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    dropdown: PropTypes.bool,
    mandatory: PropTypes.bool,
    mutable: PropTypes.bool,
  }),
  onUpdateElement: PropTypes.func,
  onAddElement: PropTypes.func,
  addItemLabel: PropTypes.string.isRequired,
  editItemLabel: PropTypes.string.isRequired,
  // Whether the form element can be flagged as mandatory
  mandatoryIsAllowed: PropTypes.bool.isRequired,
  // Whether the form element can be flagged as mutable
  mutableIsAllowed: PropTypes.bool.isRequired,
  // Whether the form element can be flagged as user form element
  userFormElementIsAllowed: PropTypes.bool,
};

FormItemManagementModal.defaultProps = {
  create: false,
  onUpdateElement: null,
  onAddElement: null,
  formElement: null,
  userFormElementIsAllowed: false,
};

export default FormItemManagementModal;
