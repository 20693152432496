import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ButtonTransparentAccent } from 'components/ui/button';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import ReactTable from 'components/ui/table/ReactTableUi';

import * as svars from 'assets/style/variables';

import { ButtonLineLayout } from '../layout/Page';

const StyledModalContent = styled(Modal.Content)`
  &&& {
    padding: ${svars.spaceMedium} ${svars.spaceMediumLarge};
    max-height: 75vh;
    margin: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
`;

const StyledIcon = styled(Icon)`
  &&&&& {
    width: 2rem;
  }
`;

const StyledButtonLineLayout = styled(ButtonLineLayout)`
  margin-bottom: ${svars.spaceNormal};
`;

function DataModal({
  title,
  open,
  data,
  makeTableData,
  meta,
  onClose,
  exportAsData,
}) {
  const {
    data: tableData,
    columns,
    defaultSorted,
  } = useMemo(() => makeTableData({ data, meta }), [data]);
  if (!data) {
    return null;
  }
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header>
        {typeof title === 'function' ? title() : title}
      </Modal.Header>
      <StyledModalContent>
        <StyledButtonLineLayout>
          <PrimaryTabButton
            onClick={exportAsData}
            icon={<StyledIcon size="large" name="file excel outline" />}
            labelPosition="right"
            content={<Trans id="export" />}
          />
        </StyledButtonLineLayout>
        <ReactTable
          data={tableData}
          columns={columns}
          defaultSorted={defaultSorted}
          noDataText={t({ id: 'no-data' })}
        />
      </StyledModalContent>
      <Modal.Actions>
        <ButtonTransparentAccent
          onClick={onClose}
          content={t({ id: 'close' })}
        />
      </Modal.Actions>
    </Modal>
  );
}

DataModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  makeTableData: PropTypes.func.isRequired,
  meta: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  exportAsData: PropTypes.func.isRequired,
};
DataModal.defaultProps = {
  open: false,
  title: null,
};

export default DataModal;
