/**
 * FILEPATH: /Users/kevinadda/work/bw/backoffice-app/src/reducers/campaign.js
 *
 * @module campaignReducer
 * @description Redux reducer for managing campaign state
 * @exports default
 */
import { unset } from 'lodash';

import { actionTypes } from 'actions/campaign';
import { actionTypes as entitiesActionTypes } from 'actions/entities';
import {
  END_SCREEN_ILLUSTRATION_OPTIONS,
  actionTypes as surveyActionTypes,
} from 'actions/survey';
import { actionTypes as userActionTypes } from 'actions/user';
import {
  isFeedbackCampaignConfiguration,
  isFeedbackCampaignSelector,
  questionSelectorFactory,
} from 'selectors/survey';

import produce from 'immer';

// Fields that are common to all question types
const COMMON_QUESTION_FIELDS = [
  'id',
  'index',
  'title',
  'description',
  'section_name',
  'required',
  'conditions',
];

const DEFAULT_SURVEY_CAMPAIGN_DIFF = {
  questions: [],
  faq_items: [],
  end_screens: [],
  home_screen: {},
  display_home_screen: false,
  // logo_url: '',
};

const getDiffElement = (draft, campaignId, elementKey, getSubElement) => {
  if (!draft.customization[campaignId].diff[elementKey]) {
    draft.customization[campaignId].diff[elementKey] =
      DEFAULT_SURVEY_CAMPAIGN_DIFF[elementKey];
  }
  const element = draft.customization[campaignId].diff[elementKey];
  if (getSubElement) {
    return element.find(getSubElement);
  }
  return element;
};

const indexElement = (element, index) => ({
  ...element,
  // Start indexation at 1
  index: index + 1,
});

const reindexElements = (elements) => elements?.map(indexElement);

const getCampaignInitialState = () => ({
  campaigns: null,
  tagSets: [],
  customization: {},
  previewActivated: false,
  restorationActivated: false,
  hasUnsavedChanges: 0,
  versionConflict: false,
  cachedRestoration: {
    diff: {},
    identification_form_deletion: [],
    faq_items_deletion: [],
  },
  previousFile: {},
});

const compileSurveyCampaignDiff = (previewConfiguration) => ({
  questions: reindexElements(previewConfiguration?.diff?.questions) || [],
  home_screen: previewConfiguration?.diff?.home_screen || {},
  end_screens: previewConfiguration?.diff?.end_screens || [],
  display_home_screen: previewConfiguration?.diff?.display_home_screen,
  faq_items: previewConfiguration?.diff?.faq_items || [],
  identification_form: previewConfiguration?.diff?.identification_form || [],
  theme: previewConfiguration?.diff?.theme,
  confidentiality: previewConfiguration?.diff?.confidentiality,
  logo_url:
    previewConfiguration?.diff?.logo_url ||
    DEFAULT_SURVEY_CAMPAIGN_DIFF.logo_url,
});

const compileFeedbackCampaignDiff = (
  baseConfiguration,
  initialConfiguration
) => ({
  configuration_ui: {
    wording: baseConfiguration?.diff?.configuration_ui?.wording || {},
  },
  highlight_concepts: baseConfiguration?.diff?.highlight_concepts || null,
  randomize: baseConfiguration?.diff?.randomize,
  identification_form: baseConfiguration?.diff?.identification_form || [],
  faq_items: baseConfiguration?.diff?.faq_items || [],
  theme: baseConfiguration?.diff?.theme || initialConfiguration?.theme,
  // We keep the confidentiality of the initial configuration if it was not modified
  // NB : this is the only field of feedback campaign that is handled this way :
  // - It allows to use the same elements for survey and feedback campaigns even though diffs and handled differently
  // - The issue is it will add `confidentiality` to the all diffs, even when it is not modified
  confidentiality:
    baseConfiguration?.diff?.confidentiality ||
    initialConfiguration.confidentiality,
  logo_url:
    baseConfiguration?.diff?.logo_url || DEFAULT_SURVEY_CAMPAIGN_DIFF.logo_url,
});

const addQuestionIndices = (configuration) => ({
  ...configuration,
  questions: reindexElements(configuration.questions),
});

const compileCampaignCustomization = (
  previewConfiguration,
  currentConfiguration,
  isFeedbackCampaign
) => ({
  hasUnpublishedChanges: previewConfiguration?.unpublished_changes,
  versionToken: previewConfiguration?.version_token,
  currentCampaignConfiguration: isFeedbackCampaign
    ? currentConfiguration
    : addQuestionIndices(currentConfiguration),
  diff: isFeedbackCampaign
    ? compileFeedbackCampaignDiff(previewConfiguration, currentConfiguration)
    : compileSurveyCampaignDiff(previewConfiguration, currentConfiguration),
  identification_form_current_size:
    previewConfiguration?.identification_form?.length || 0,
  identification_form_deletion: [],
  faq_items_current_size:
    previewConfiguration?.faq_items?.length ||
    currentConfiguration.faq_items?.length ||
    0,
  faq_items_deletion: [],
});

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_LIST_SUCCESS: {
      draft.campaigns = action.campaigns;
      break;
    }
    case entitiesActionTypes.FETCH_TAG_SETS_SUCCESS: {
      draft.tagSets = action.tagSets;
      break;
    }
    case actionTypes.CREATE_CAMPAIGN_SUCCESS: {
      const { name, publication_date, create_date, id, status, type } =
        action.campaignConfiguration;
      draft.campaigns = [
        ...(draft.campaigns || []),
        // Add created campaign to the list to avoid querying the entire list again
        { name, publication_date, create_date, id, status, type },
      ];
      draft.customization[action.campaignId] = compileCampaignCustomization(
        action.campaignConfiguration,
        action.campaignConfiguration,
        isFeedbackCampaignSelector(id)({ campaign: draft })
      );
      draft.hasUnsavedChanges = 0;
      break;
    }
    case actionTypes.RENAME_CAMPAIGN_SUCCESS:
      draft.campaigns[
        draft.campaigns?.findIndex(({ id }) => id === action.campaignId)
      ].name = action.name;
      break;

    case actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST: {
      // Remove previous diff if it exists

      delete draft.customization[action.campaignId];
      draft.previewActivated = false;
      draft.hasUnsavedChanges = 0;
      draft.restorationActivated = false;
      break;
    }
    case actionTypes.FETCH_CAMPAIGN_CONFIGURATION_SUCCESS: {
      const baseConfiguration = action.campaignConfiguration;
      const isFeedbackCampaign =
        isFeedbackCampaignConfiguration(baseConfiguration);
      draft.customization[action.campaignId] = compileCampaignCustomization(
        action.previewConfiguration,
        baseConfiguration,
        isFeedbackCampaign
      );

      // Update campaign list info for this campaign
      const campaignListIndex = draft.campaigns?.findIndex(
        ({ id }) => id === action.campaignId
      );
      if (campaignListIndex) {
        draft.campaigns[campaignListIndex] = {
          ...draft.campaigns[campaignListIndex],
          status: baseConfiguration.status,
          publication_date: baseConfiguration.publication_date,
          first_publication_date: baseConfiguration.first_publication_date,
          // logo_url: baseConfiguration.logo_url,
        };
      }

      draft.hasUnsavedChanges = 0;
      draft.versionConflict = false;
      break;
    }
    case actionTypes.SAVE_CAMPAIGN_CONFIGURATION_SUCCESS: {
      draft.previewActivated = false;
      draft.hasUnsavedChanges = 0;
      break;
    }
    case actionTypes.SAVE_CAMPAIGN_CONFIGURATION_FAILURE:
      draft.hasUnsavedChanges += 1;
      draft.versionConflict = action.versionConflict;
      break;
    case actionTypes.DELETE_CAMPAIGN_SUCCESS: {
      const indexToRemove = draft.campaigns
        .map((campaign) => campaign.id)
        .indexOf(action.campaignId);
      draft.campaigns.splice(indexToRemove, 1);
      draft.hasUnsavedChanges = 0;
      break;
    }
    case actionTypes.UPDATE_PREVIEW_CONFIG_SUCCESS: {
      draft.hasUnsavedChanges = 0;
      draft.customization[action.campaignId].hasUnpublishedChanges = true;
      draft.customization[action.campaignId].versionToken = action.versionToken;
      break;
    }
    case actionTypes.UPDATE_PREVIEW_CONFIG_FAILURE:
      draft.hasUnsavedChanges += 1;
      draft.versionConflict = action.versionConflict;
      break;

    case actionTypes.RESET_PREVIEW_CONFIG_REQUEST:
      draft.customization[action.campaignId].diff = {};
      draft.hasUnsavedChanges = 0;
      break;
    case actionTypes.RESET_PREVIEW_CONFIG_FAILURE:
      draft.hasUnsavedChanges += 1;
      draft.versionConflict = action.versionConflict;
      break;
    case actionTypes.RESET_PREVIEW_CONFIG_SUCCESS: {
      const isFeedbackCampaign = isFeedbackCampaignSelector(action.campaignId)({
        campaign: draft,
      });
      draft.customization[action.campaignId] = compileCampaignCustomization(
        {
          version_token: action.versionToken,
          diff: {
            ...draft.customization[action.campaignId]
              ?.currentCampaignConfiguration,
          },
        },
        draft.customization[action.campaignId]?.currentCampaignConfiguration,
        isFeedbackCampaign
      );
      break;
    }
    case actionTypes.ACTIVATE_RESTORE_DIFF: {
      // Activate restore only for feedback campaigns
      if (isFeedbackCampaignSelector(action.campaignId)({ campaign: draft })) {
        draft.restorationActivated = true;
        draft.cachedRestoration = {
          diff: action.diff,
          identification_form_deletion: action.identification_form_deletion,
          faq_items_deletion: action.faq_items_deletion,
        };
      }
      break;
    }
    case actionTypes.DEACTIVATE_RESTORE_DIFF:
      draft.restorationActivated = false;
      draft.cachedRestoration = {
        diff: {},
        identification_form_deletion: [],
        faq_items_deletion: [],
      };
      break;
    case actionTypes.RESTORE_DIFF: {
      const isFeedbackCampaign = isFeedbackCampaignSelector(action.campaignId)({
        campaign: draft,
      });
      draft.customization[action.campaignId] = compileCampaignCustomization(
        draft.cachedRestoration.diff,
        draft.customization[action.campaignId].currentCampaignConfiguration,
        isFeedbackCampaign
      );
      draft.cachedRestoration = {
        diff: {},
        identification_form_deletion: [],
        faq_items_deletion: [],
      };
      draft.restorationActivated = false;
      draft.hasUnsavedChanges = 0;
      break;
    }
    case actionTypes.ACTIVATE_PREVIEW: {
      draft.previewActivated = true;
      break;
    }
    case actionTypes.ADD_IDENTIFICATION_FORM_ELEMENT_SUCCESS: {
      draft.customization[action.campaignId].diff.identification_form.push(
        action.identification_form_element
      );
      draft.customization[
        action.campaignId
      ].identification_form_current_size += 1;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.ADD_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ].push(action.formElement);
      break;
    case actionTypes.UPDATE_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ][
        draft.customization[action.campaignId].currentCampaignConfiguration[
          action.campaignField
        ].findIndex(({ id }) => id === action.element.id)
      ] = action.element;
      break;
    case actionTypes.DELETE_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ] = [
        ...draft.customization[action.campaignId].currentCampaignConfiguration[
          action.campaignField
        ].filter(({ id }) => id !== action.formElementId),
      ];
      break;
    case actionTypes.RESET_ALL_FAQ_ITEMS_TO_DEFAULT: {
      draft.customization[action.campaignId].faq_items_deletion = [];
      draft.customization[action.campaignId].diff.faq_items = [];
      draft.customization[action.campaignId].faq_items_current_size =
        draft.customization[action.campaignId].currentCampaignConfiguration
          .faq_items?.length || 0;
      draft.hasUnsavedChanges = 0;
      break;
    }
    case actionTypes.RESET_WHOLE_IDENTIFICATION_FORM_TO_DEFAULT: {
      draft.customization[action.campaignId].identification_form_deletion = [];
      draft.customization[action.campaignId].diff.identification_form = [];
      draft.customization[action.campaignId].identification_form_current_size =
        draft.customization[action.campaignId].currentCampaignConfiguration
          .identification_form?.length || 0;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.REMOVE_IDENTIFICATION_FORM_ELEMENT: {
      draft.customization[action.campaignId].identification_form_deletion.push(
        action.elementId
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.RESTORE_IDENTIFICATION_FORM_ELEMENT: {
      draft.customization[
        action.campaignId
      ].identification_form_deletion.splice(action.index, 1);
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.UPDATE_IDENTIFICATION_FORM_ELEMENT: {
      const identificationForm =
        draft.customization[action.campaignId].diff.identification_form;
      let elementDiffIndex = identificationForm.findIndex(
        ({ id }) => id === action.element.id
      );
      let element = { ...action.element };
      if (elementDiffIndex === -1) {
        elementDiffIndex = identificationForm.length;
      } else {
        element = {
          ...identificationForm[elementDiffIndex],
          ...action.element,
        };
      }
      identificationForm[elementDiffIndex] = element;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.DEACTIVATE_PREVIEW: {
      draft.previewActivated = false;
      break;
    }
    case actionTypes.SET_CONCEPT_RANDOMIZATION:
      draft.customization[action.campaignId].diff.randomize = action.randomize;
      draft.hasUnsavedChanges += 1;
      break;
    case actionTypes.SET_CONCEPT_HIGHLIGHT:
      draft.customization[action.campaignId].diff.highlight_concepts =
        action.highlightConcepts.map((conceptId) => ({ id: conceptId }));
      draft.hasUnsavedChanges += 1;
      break;
    case actionTypes.SET_EXPANDED_IDENTIFICATION_FORM:
      draft.customization[
        action.campaignId
      ].diff.configuration_ui.expanded_form = action.expanded_form;
      draft.hasUnsavedChanges += 1;
      break;
    case actionTypes.SET_WORDING_VALUE: {
      if (
        !draft.customization[action.campaignId].diff.configuration_ui.wording[
          action.page
        ]
      ) {
        draft.customization[action.campaignId].diff.configuration_ui.wording[
          action.page
        ] = {};
      }
      draft.customization[action.campaignId].diff.configuration_ui.wording[
        action.page
      ][action.inputKey] = action.value;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.SET_WORDING_TO_DEFAULT: {
      unset(
        draft.customization[action.campaignId].diff.configuration_ui.wording,
        [action.page, action.inputKey]
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.ADD_FAQ_ITEM_SUCCESS: {
      draft.customization[action.campaignId].faq_items_current_size += 1;
      draft.customization[action.campaignId].diff.faq_items.push(
        action.faq_item
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.REMOVE_FAQ_ITEM: {
      draft.customization[action.campaignId].faq_items_current_size -= 1;
      draft.customization[action.campaignId].faq_items_deletion.push(
        action.elementId
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.RESTORE_FAQ_ITEM: {
      draft.customization[action.campaignId].faq_items_deletion.splice(
        action.index,
        1
      );

      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.UPDATE_FAQ_ITEM: {
      if (action.alreadyInDiff) {
        draft.customization[action.campaignId].diff.faq_items[action.index] = {
          ...draft.customization[action.campaignId].diff.faq_items[
            action.index
          ],
          ...action.value,
        };
      } else {
        draft.customization[action.campaignId].diff.faq_items.push({
          position: action.position,
          id: action.elementId,
          ...action.value,
        });
      }
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.UPDATE_FAQ_ITEM_POSITION: {
      const indexInDIff = draft.customization[action.campaignId].diff.faq_items
        .map((item) => item.id)
        .indexOf(action.faqItem.id);
      if (indexInDIff === -1) {
        draft.customization[action.campaignId].diff.faq_items.push({
          // id: action.itemId,
          ...action.faqItem,
          position: action.newPosition,
        });
      } else {
        draft.customization[action.campaignId].diff.faq_items[
          indexInDIff
        ].position = action.newPosition;
      }
      draft.hasUnsavedChanges += 1;
      break;
    }
    case actionTypes.UPDATE_FORM_ELEMENT_POSITIONS: {
      const baseFormElement =
        draft.customization[action.campaignId].currentCampaignConfiguration[
          action.formElementKey
        ];
      const formElement = action.useDiff
        ? draft.customization[action.campaignId].diff[action.formElementKey]
        : baseFormElement;
      action.newPositions.forEach(({ id, position }) => {
        const index = formElement.findIndex((element) => element.id === id);
        if (index === -1) {
          const item = baseFormElement.find(({ id: baseId }) => baseId === id);
          formElement.push({ ...item, position });
        } else {
          formElement[index].position = position;
        }
      });
      draft.hasUnsavedChanges += 1;
      break;
    }

    // Survey customization reducers
    case surveyActionTypes.REORDER_QUESTIONS_DRAG_AND_DROP:
      {
        draft.customization[action.campaignId].diff.questions = reindexElements(
          action.questions
        );
        // Remove conditions when referenced question is subsequent to the question holding the condition
        const traveredQuestions = [];
        draft.customization[action.campaignId].diff.questions.forEach(
          (question) => {
            if (question.conditions) {
              question.conditions = question.conditions.filter(
                // Check if the question is not already traversed
                ({ question: { id } }) => traveredQuestions.includes(id)
              );
            }
            traveredQuestions.push(question.id);
          }
        );
        draft.hasUnsavedChanges += 1;
      }
      break;
    case surveyActionTypes.ADD_QUESTION_FAILURE:
    case surveyActionTypes.DUPLICATE_QUESTION_FAILURE:
      draft.versionConflict = action.versionConflict;
      break;
    case surveyActionTypes.ADD_QUESTION_SUCCESS:
    case surveyActionTypes.DUPLICATE_QUESTION_SUCCESS: {
      const finalPosition =
        action.position ||
        draft.customization[action.campaignId].diff.questions.length;

      draft.customization[action.campaignId].diff.questions.splice(
        finalPosition,
        0,
        action.question
      );
      draft.customization[action.campaignId].diff.questions = reindexElements(
        draft.customization[action.campaignId].diff.questions
      );

      draft.hasUnsavedChanges += 1;
      draft.customization[action.campaignId].newQuestion =
        draft.customization[action.campaignId].diff.questions[finalPosition];
      // Reset new version hash
      draft.customization[action.campaignId].versionToken = action.versionToken;
      break;
    }
    case surveyActionTypes.DUPLICATE_QUESTION:
      draft.customization[action.campaignId].diff.questions.push(
        indexElement(
          {
            ...questionSelectorFactory(
              action.campaignId,
              action.questionId,
              true
            )({
              campaign: draft,
            }),
            id: null,
          },
          draft.customization[action.campaignId].diff.questions.length
        )
      );
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.DELETE_QUESTION:
      draft.customization[action.campaignId].diff.questions.splice(
        draft.customization[action.campaignId].diff.questions.findIndex(
          (question) => question.id === action.questionId
        ),
        1
      );
      // Reindex questions
      draft.customization[action.campaignId].diff.questions = reindexElements(
        draft.customization[action.campaignId].diff.questions
      );
      draft.customization[action.campaignId].diff.questions.forEach(
        (question) => {
          // Check if deleted question is referenced in conditions
          // If so, remove the condition
          if (question.conditions) {
            question.conditions = question.conditions.filter(
              ({ question: { id } }) => id !== action.questionId
            );
          }
        }
      );
      // Also remove possible question reference in end screens conditions
      draft.customization[action.campaignId].diff.end_screens.forEach(
        (endScreen) => {
          if (endScreen.conditions) {
            endScreen.conditions = endScreen.conditions.filter(
              ({ question: { id } }) => id !== action.questionId
            );
          }
        }
      );
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.RESET_QUESTION_TYPE: {
      const newQuestion = {
        type: action.questionType,
      };
      const currentQuestion = questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      });
      COMMON_QUESTION_FIELDS.forEach((field) => {
        newQuestion[field] = currentQuestion[field];
      });
      draft.customization[action.campaignId].diff.questions[
        draft.customization[action.campaignId].diff.questions.findIndex(
          (question) => question.id === action.questionId
        )
      ] = newQuestion;
      if (action.clearConditions) {
        // Remove the conditions of all questions that reference the current question
        draft.customization[action.campaignId].diff.questions.forEach(
          (question) => {
            if (question.conditions) {
              question.conditions = question.conditions.filter(
                ({ question: { id } }) => id !== action.questionId
              );
            }
          }
        );
      }

      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.UPDATE_QUESTION_TITLE:
      questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      }).title = action.title;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_QUESTION_DESCRIPTION:
      questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      }).description = action.description;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_QUESTION_SETTING:
      getDiffElement(
        draft,
        action.campaignId,
        'questions',
        ({ id }) => id === action.questionId
      )[action.key] = action.value;

      draft.hasUnsavedChanges += 1;
      break;
    // case surveyActionTypes.ADD_QUESTION_LOGIC_CONDITION:

    // questionSelectorFactory(
    //   action.campaignId,
    //   action.questionId,
    //   true
    // )({
    //   campaign: draft,
    // }).conditions.push({
    //   question: { id: action.conditionQuestionId },
    //   ...action.conditionValues,
    // });
    // draft.hasUnsavedChanges += 1;
    // break;
    case surveyActionTypes.UPDATE_QUESTION_LOGIC_CONDITION:
      questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      }).conditions[action.conditionIndex] = {
        question: { id: action.conditionQuestionId },
        ...action.conditionValues,
      };
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.DELETE_QUESTION_LOGIC_CONDITION:
      questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      }).conditions.splice(action.conditionIndex, 1);
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_END_SCREEN_LOGIC_CONDITION:
      getDiffElement(draft, action.campaignId, 'end_screens')[
        action.endScreenIndex
      ].conditions[action.conditionIndex] = {
        question: { id: action.conditionQuestionId },
        ...action.conditionValues,
      };
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.DELETE_END_SCREEN_LOGIC_CONDITION: {
      const endScreen =
        draft.customization[action.campaignId].diff.end_screens[
          action.endScreenIndex
        ];
      endScreen.conditions = endScreen.conditions.filter(
        (_, index) => index !== action.conditionIndex
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.REORDER_OPTIONS_DRAG_AND_DROP:
      questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      }).options = action.options;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_OPTION_CONFIGURATION: {
      const { options } = questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      });
      options[options.findIndex((option) => option.id === action.optionId)][
        action.field
      ] = action.value;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.ADD_OPTION_FAILURE: {
      draft.versionConflict = action.versionConflict;
      break;
    }
    case surveyActionTypes.ADD_OPTION_SUCCESS: {
      const question = questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      });
      if (!question.options) {
        question.options = [];
      }
      question.options.push(action.option);
      draft.hasUnsavedChanges += 1;
      // Reset new version hash
      draft.customization[action.campaignId].versionToken = action.versionToken;
      break;
    }
    case surveyActionTypes.DELETE_OPTION: {
      const { options } = questionSelectorFactory(
        action.campaignId,
        action.questionId,
        true
      )({
        campaign: draft,
      });
      options.splice(
        options.findIndex((option) => option.id === action.optionId),
        1
      );
      // Remove the option from the conditions values of all questions
      // If condition values are empty, remove the condition
      draft.customization[action.campaignId].diff.questions.forEach(
        (question) => {
          if (question.conditions) {
            question.conditions
              // Apply only on multi choice question conditions
              .filter((condition) => condition.values?.length)
              .forEach((condition) => {
                condition.values = condition.values.filter(
                  ({ id }) => id !== action.optionId
                );
                if (condition.values.length === 0) {
                  question.conditions.splice(
                    question.conditions.indexOf(condition),
                    1
                  );
                }
              });
          }
        }
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.TOGGLE_DISPLAY_HOME_SCREEN_ELEMENT:
      draft.customization[action.campaignId].diff.display_home_screen =
        action.value;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_HOME_SCREEN_ELEMENT:
      if (!draft.customization[action.campaignId].diff.home_screen) {
        draft.customization[action.campaignId].diff.home_screen = {};
      }
      draft.customization[action.campaignId].diff.home_screen[action.key] =
        action.value;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_END_SCREEN_ELEMENT: {
      const endScreens =
        draft.customization[action.campaignId].diff.end_screens;
      const endScreen = endScreens[action.endScreenIndex];
      endScreen[action.key] = action.value;
      if (action.key === 'show_button' && !action.value) {
        endScreen.button_label = null;
        endScreen.button_url = null;
      }
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.UPDATE_END_SCREEN_ILLUSTRATION: {
      const endScreens =
        draft.customization[action.campaignId].diff.end_screens;
      const endScreen = endScreens[action.endScreenIndex];
      endScreen.illustration = END_SCREEN_ILLUSTRATION_OPTIONS[0].value;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.UPDATE_THEME_ELEMENT:
      if (!draft.customization[action.campaignId].diff.theme) {
        draft.customization[action.campaignId].diff.theme = {};
      }
      draft.customization[action.campaignId].diff.theme[action.key] =
        action.value;
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.UPDATE_CONFIDENTIALITY_ELEMENT:
      if (!draft.customization[action.campaignId].diff.confidentiality) {
        draft.customization[action.campaignId].diff.confidentiality = {};
      }
      draft.customization[action.campaignId].diff.confidentiality[action.key] =
        action.value;
      draft.hasUnsavedChanges += 1;
      break;
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getCampaignInitialState();
    case actionTypes.UPLOAD_LOGO_SUCCESS: {
      draft.customization[action.campaignId].diff.logo_url = action.logoUrl;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.ADD_END_SCREEN_SUCCESS: {
      draft.customization[action.campaignId].diff.end_screens.push(
        action.endScreen
      );
      draft.customization[action.campaignId].newEndScreenIndex =
        (draft.customization[action.campaignId].diff.end_screens?.length || 0) -
        1;
      draft.customization[action.campaignId].versionToken = action.versionToken;
      draft.hasUnsavedChanges += 1;
      break;
    }
    case surveyActionTypes.SET_DEFAULT_END_SCREEN:
      // Set endScreenIndex end screen default property to true, unset all other end screens default property
      draft.customization[action.campaignId].diff.end_screens =
        draft.customization[action.campaignId].diff.end_screens.map(
          (endScreen, index) => ({
            ...endScreen,
            default: index === action.endScreenIndex,
            conditions:
              index === action.endScreenIndex ? [] : endScreen.conditions,
          })
        );
      draft.hasUnsavedChanges += 1;
      break;
    case surveyActionTypes.DELETE_END_SCREEN: {
      draft.customization[action.campaignId].diff.end_screens.splice(
        action.endScreenIndex,
        1
      );
      draft.hasUnsavedChanges += 1;
      break;
    }
    default:
      break;
  }
  return draft;
}, getCampaignInitialState());
