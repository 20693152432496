import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Label, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { EMPTY_CATEGORY_LABEL } from 'reducers/entityLabelFormatter';
import { formatDate } from 'reducers/locale';

import CopyToClipboard from 'components/ui/CopyToClipboard';
import { LimitedTextCell } from 'components/ui/Text';
import SentimentCell from 'components/ui/cells/SentimentCell';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';

import commonPropTypes from 'utils/commonPropTypes';
import { numberFormatter } from 'utils/formatter';
import { roundFloatedValue } from 'utils/helpers';

import * as svars from 'assets/style/variables';

export function ChannelNameCell({ value }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {value.name}
      {value.archived ? (
        <Label
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: svars.colorLightGrey,
            maxWidth: 'none',
            marginLeft: svars.spaceNormal,
          }}
        >
          <Icon name="archive" style={{ marginRight: svars.spaceNormal }} />{' '}
          {t({ id: 'archived' })}
        </Label>
      ) : null}
    </div>
  );
}

ChannelNameCell.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    archived: PropTypes.bool,
  }),
};
ChannelNameCell.defaultProps = { value: { name: null, archived: null } };

export function PlaceholderCell() {
  return (
    <Placeholder
      style={{
        background: svars.colorGreyMedium,
        animationPlayState: 'paused',
        margin: svars.spaceNormal,
      }}
    >
      <Placeholder.Line />
    </Placeholder>
  );
}

export function ValueIndicatorCell({ value }) {
  return (
    <CellContainer
      style={{
        color:
          (value > 0 && svars.colorSuccess) ||
          (value < 0 && svars.colorDanger) ||
          svars.fontSizeBase,
      }}
    >
      {roundFloatedValue(value, true, '-')}
    </CellContainer>
  );
}

ValueIndicatorCell.propTypes = {
  value: PropTypes.number,
};
ValueIndicatorCell.defaultProps = { value: null };

export const cellPropTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
};

export function ReactTableTextCell({ padded, value, style }) {
  return (
    <LimitedTextCell
      style={{
        margin: '0',
        paddingRight: padded ? svars.spaceNormalLarge : 0,
        width: '100%',
        display: 'block',
        ...style,
      }}
    >
      {value}
    </LimitedTextCell>
  );
}

ReactTableTextCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
ReactTableTextCell.defaultProps = { style: {}, value: null };

export function MultilineTextCell({ padded, value, style }) {
  return (
    <div
      style={{
        margin: '0',
        paddingRight: padded ? svars.spaceNormalLarge : 0,
        width: '100%',
        display: 'block',
        maxHeight: '200px',
        overflow: 'auto',
        ...style,
      }}
    >
      {value}
    </div>
  );
}

MultilineTextCell.propTypes = {
  ...cellPropTypes,
  style: commonPropTypes.style,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};
MultilineTextCell.defaultProps = { style: {}, value: null };

export function EllipsedTextCell({ value }) {
  return (
    <LimitedTextCell
      style={{
        whiteSpace: 'nowrap',
        width: '100%',
        display: 'inline-block',
        paddingRight: svars.spaceNormalLarge,
      }}
    >
      {value}
    </LimitedTextCell>
  );
}
EllipsedTextCell.propTypes = cellPropTypes;
export function CopyableTextCell({ value, formatter }) {
  const finalValue = formatter?.(value) || value;
  return (
    value && (
      <LimitedTextCell style={{ height: '100%', display: 'block' }}>
        <CopyToClipboard
          text={finalValue}
          iconName="linkify"
          style={{ whiteSpace: 'nowrap' }}
        >
          {finalValue}
        </CopyToClipboard>
      </LimitedTextCell>
    )
  );
}

CopyableTextCell.propTypes = { ...cellPropTypes, formatter: PropTypes.func };
CopyableTextCell.defaultProps = { formatter: null };

export function CopyableOpenableTextCell({ value, formatter }) {
  const finalValue = formatter?.(value) || value;
  return (
    value && (
      <LimitedTextCell
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginRight: svars.spaceNormal,
        }}
      >
        <LimitedTextCell style={{ flexGrow: 1 }}>{finalValue}</LimitedTextCell>
        <span
          style={{
            display: 'flex',
            fontSize: svars.fontSizeLarger,
            flexShrink: 0,
          }}
        >
          <CopyToClipboard
            text={finalValue}
            iconName="copy"
            style={{ whiteSpace: 'nowrap' }}
          />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            name="external"
            help="open-in-new-tab"
            onClick={() => window.open(finalValue, '_blank')}
            labelPosition="left"
          />
        </span>
      </LimitedTextCell>
    )
  );
}

CopyableOpenableTextCell.propTypes = {
  ...cellPropTypes,
  formatter: PropTypes.func,
};
CopyableOpenableTextCell.defaultProps = { formatter: null };

export function EmptyPlaceholderValueCell({ value }) {
  return <CellContainer>{value != null ? value : '-'}</CellContainer>;
}

EmptyPlaceholderValueCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EmptyPlaceholderValueCell.defaultProps = { value: null };

export function DateTimeCell({ value, inline }) {
  return (
    <LimitedTextCell
      style={{
        height: '100%',
        display: 'flex',
      }}
    >
      <span
        style={{ display: 'flex', flexDirection: inline ? 'row' : 'column' }}
      >
        {value ? (
          <>
            {formatDate(new Date(value), 'P')}

            <span
              style={{
                color: svars.fontColorLighter,
                marginLeft: inline ? svars.spaceNormal : 0,
                opacity: 0.75,
              }}
            >
              {formatDate(new Date(value), 'p')}
            </span>
          </>
        ) : (
          '-'
        )}
      </span>
    </LimitedTextCell>
  );
}

DateTimeCell.propTypes = { ...cellPropTypes, inline: PropTypes.bool };
DateTimeCell.defaultProps = { inline: false };

export function DayCell({ value }) {
  return (
    <LimitedTextCell>
      <span style={{ display: 'inline-block', textAlign: 'start' }}>
        {(typeof value === 'object' && value) ||
          (value && formatDate(new Date(value), 'P')) ||
          '-'}
      </span>
    </LimitedTextCell>
  );
}

DayCell.propTypes = { value: PropTypes.string };
DayCell.defaultProps = { value: null };

const TagLabel = styled.span`
  border-bottom: 3px solid transparent;
  border-radius: 3px;
  padding: ${svars.spaceXSmall} 4px;
  border-color: ${({ color }) => color};
  color: ${svars.fontColorBase};
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function LabelCell({ value, formatter, color, basic, getColor }) {
  return (
    <LimitedTextCell
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {(value && (
        <TagLabel
          basic={basic}
          color={color || (getColor && getColor(value)) || null}
        >
          {formatter?.(value) || value || '-'}
        </TagLabel>
      )) ||
        '-'}
    </LimitedTextCell>
  );
}

LabelCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  formatter: PropTypes.func,
  color: PropTypes.string,
  getColor: PropTypes.func,
  basic: PropTypes.bool,
};
LabelCell.defaultProps = {
  formatter: null,
  color: null,
  getColor: null,
  basic: null,
  value: null,
};
LabelCell.getLabelCell = (getColor) => (props) =>
  <LabelCell getColor={getColor} {...props} />;

export function UserLabelCell({ value, formatter }) {
  return (
    <LimitedTextCell
      style={{
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value ? (
        <>
          <Icon name="user" style={{ color: svars.colorGreyMedium }} />
          {formatter?.(value) || value}
        </>
      ) : (
        '-'
      )}
    </LimitedTextCell>
  );
}

UserLabelCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  formatter: PropTypes.func,
};
UserLabelCell.defaultProps = { formatter: null, value: null };

export function ReactTableSentimentCell({ value }) {
  return <SentimentCell sentiment={value} />;
}
ReactTableSentimentCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export function SerializedCopyableTextCell({ value }) {
  return <CopyableTextCell value={JSON.stringify(value, null, 2)} />;
}

SerializedCopyableTextCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};
SerializedCopyableTextCell.defaultProps = { value: null };

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: ${svars.borderRadius};
  white-space: nowrap;
`;

const VolumeCellIndicatorBar = styled.div`
  border-radius: ${svars.ctaBorderRadius};
  min-width: 70px;
  max-width: 100px;
  width: 100%;
  text-align: center;
  color: ${svars.fontColorBase};
  font-weight: ${svars.fontWeightLight};

  & div {
    border-radius: ${svars.ctaBorderRadius};

    background-image: ${svars.volumeGradient};
    ${({ valueRatio }) =>
      valueRatio === 0
        ? `width: 100%; background: ${svars.colorGreyMediumLight};`
        : `width: ${10 + 0.9 * valueRatio * 100}%;`}

    color: white;
    height: 0.45rem;

    transition: all 0.2s ease-out;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: inset 100px 0px 50px rgba(255, 255, 255, 0.25);
  }
`;

export function ReactTableVolumeCell({ maxNChunks, value }) {
  return (
    <CellContainer>
      <VolumeCellIndicatorBar valueRatio={value / maxNChunks}>
        {numberFormatter(value)}
        <div />
      </VolumeCellIndicatorBar>
    </CellContainer>
  );
}

export const reactTableVolumeCellFactory = (maxNChunks) => {
  function VolumeCell({ value }) {
    return <ReactTableVolumeCell maxNChunks={maxNChunks} value={value} />;
  }
  VolumeCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };
  return VolumeCell;
};

ReactTableVolumeCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxNChunks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ReactTableVolumeCell.defaultProps = { value: null, maxNChunks: 0 };

function ReactTableRatingCellFactory(iconName, color) {
  function RatingCell({ value }) {
    return !parseInt(value, 10) ? (
      <EmptyPlaceholderValueCell />
    ) : (
      <CellContainer
        style={{ fontSize: svars.fontSizeLarge, display: 'block' }}
      >
        <Icon name={iconName} style={{ color }} />
        {value}
      </CellContainer>
    );
  }
  RatingCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  RatingCell.defaultProps = { value: null };
  return RatingCell;
}

export const StarRatingCell = ReactTableRatingCellFactory(
  'star',
  svars.colorYellowWarning
);
export const HeartRatingCell = ReactTableRatingCellFactory(
  'heart',
  svars.colorDanger
);

const NpsIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${svars.fontSizeMedium};
  font-weight: ${svars.fontWeightMedium};
  background: ${({ color }) => color};
  color: ${({ color }) => svars.getReadableColor(color)};
  padding: ${svars.spaceXSmall} ${svars.spaceNormal};
  border-radius: ${svars.ctaBorderRadius};
`;

export function NPSCell({ value }) {
  return value === EMPTY_CATEGORY_LABEL ? (
    <EmptyPlaceholderValueCell />
  ) : (
    <CellContainer>
      <NpsIndicator color={svars.NPS_COLORS[value]}>{value}</NpsIndicator>
    </CellContainer>
  );
}

NPSCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
NPSCell.defaultProps = { value: null };

export function NumberCell({ value }) {
  return (
    <CellContainer
      style={{
        fontSize: svars.fontSizeLarge,
        fontWeight: svars.fontWeightMedium,
      }}
    >
      {numberFormatter(value, '-')}
    </CellContainer>
  );
}

NumberCell.propTypes = { value: PropTypes.number };
NumberCell.defaultProps = { value: null };

export function IconCell({ value, row: { original } }) {
  return <Icon style={{ color: original?.iconColor }} name={value} />;
}

IconCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({ iconColor: PropTypes.string }),
  }),
};
IconCell.defaultProps = {
  value: null,
  row: {},
};

export function FormattedCell({ value, column: { formatter } }) {
  return <LimitedTextCell>{formatter?.(value) || value}</LimitedTextCell>;
}

FormattedCell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  column: PropTypes.shape({ formatter: PropTypes.func }),
};

FormattedCell.defaultProps = { value: null, column: {} };
