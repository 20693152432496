import PropTypes from 'prop-types';
import { LabelGroup } from 'semantic-ui-react';
import styled from 'styled-components';

import { useTagSetLabelFormatter } from 'reducers/entityLabelFormatter';

import * as svars from 'assets/style/variables';

const TagLabel = styled.span`
  border-bottom: 3px solid transparent;
  border-radius: 3px;
  margin: 4px 4px 0px 0px;
  padding: 1px 2px;
  border-color: ${({ color }) => color};
  color: ${svars.fontColorBase};
  white-space: nowrap;
  display: inline-block;
`;

const TagListContainer = styled(LabelGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
`;

export default function ListChips({
  items,
  field,
  formatter,
  colorFormatter,
  categoryFormatter,
  ...props
}) {
  const tagSetLabelFormatter = useTagSetLabelFormatter();
  const getFormattedLabel = (item) => {
    if (categoryFormatter && formatter) {
      return `${tagSetLabelFormatter(
        categoryFormatter(item[field])
      )} - ${formatter(item[field])}`;
    }
    if (categoryFormatter) {
      return tagSetLabelFormatter(categoryFormatter(item[field]));
    }
    if (formatter) {
      return formatter(item[field]);
    }
    return item[field];
  };

  return (
    <TagListContainer {...props}>
      {items.map((item) => (
        <TagLabel
          key={item[field]}
          color={colorFormatter ? colorFormatter(item[field]) : null}
        >
          {getFormattedLabel(item)}
        </TagLabel>
      ))}
    </TagListContainer>
  );
}

ListChips.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  field: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  colorFormatter: PropTypes.func,
  categoryFormatter: PropTypes.func,
};
ListChips.defaultProps = {
  formatter: null,
  colorFormatter: null,
  categoryFormatter: null,
};
