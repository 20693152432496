import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import { LimitedTextCell } from './Text';

const StyledLabelWithIcon = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

function LabelWithIcon({ icon, iconColor, label, style }) {
  return (
    <StyledLabelWithIcon style={style}>
      {icon ? (
        <Icon
          name={icon}
          style={{
            color: iconColor || 'inherit',
            marginRight: svars.spaceNormal,
            fontSize: svars.fontSizeLarger,
          }}
        />
      ) : null}
      <LimitedTextCell>{label}</LimitedTextCell>
    </StyledLabelWithIcon>
  );
}

LabelWithIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconColor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  style: commonPropTypes.style,
};

LabelWithIcon.defaultProps = {
  iconColor: null,
  icon: null,
  style: {},
};

export default LabelWithIcon;
