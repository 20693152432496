import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { activateQuestionScoring, updateQuestionSetting } from 'actions/survey';

import InlineFormField from 'components/ui/form/InlineFormField';
import { Toggle } from 'components/ui/inputs/Checkbox';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const ScoringForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${svars.spaceMedium};
`;

function ScoredQuestionSettings({
  question,
  campaignId,
  questionId,
  children,
}) {
  const dispatch = useDispatch();
  const onQuestionWeightChange = useCallback(
    (e, { value }) =>
      dispatch(updateQuestionSetting(campaignId, questionId, 'weight', value)),
    [dispatch, questionId, campaignId]
  );
  const onActivateQuestionScoring = useCallback(() => {
    if (question) {
      dispatch(
        activateQuestionScoring(campaignId, questionId, !question.with_scoring)
      );
    }
  }, [dispatch, questionId, campaignId, question]);

  return (
    <>
      <Toggle
        toggleTestId="bo-campaign-use-in-scoring-toggle"
        label={t({ id: 'use-in-scoring' })}
        checked={!!question?.with_scoring}
        onChange={onActivateQuestionScoring}
      />
      {question?.with_scoring ? (
        <ScoringForm>
          <InlineFormField
            labelId="question-weight"
            input={
              <TextInput
                data-testid="bo-question-weight-input"
                type="number"
                min="-100"
                max="100"
                step={1}
                style={{ marginTop: svars.spaceNormal, maxWidth: '200px' }}
                value={question?.weight}
                onChange={onQuestionWeightChange}
              />
            }
            help={t({ id: 'question-weight-help' })}
          />
          {children}
        </ScoringForm>
      ) : null}
    </>
  );
}

ScoredQuestionSettings.propTypes = {
  question: commonPropTypes.question.isRequired,
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ScoredQuestionSettings.defaultProps = {
  children: null,
};

export default ScoredQuestionSettings;
