
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { LightHeader } from 'components/ui/Header';
import * as svars from 'assets/style/variables';
import capitalizedTranslation from 'utils/i18n';
import { TabButton } from 'components/ui/button/TabButton';

const HeaderContainer = styled.div`
  padding: ${svars.spaceMedium} ${svars.spaceNormalLarge} 0 ${svars.spaceMedium};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  ${({ clickable }) =>
    clickable
      ? css`
          ${svars.hoverClickableCss} ${svars.activeClickableCss}
        `
      : 'cursor: default;'}
`;

function NavigationSectionHeader({
  title,
  action,
  actionDisabled,
  onClick,
  active,
  navigationSectionTestId,
}) {
  return (
    <HeaderContainer
      active={active ? 'true' : null}
      onClick={onClick}
      clickable={onClick ? 'true' : null}
      data-testid={navigationSectionTestId}
    >
      <LightHeader inline="true">
        <Trans id={title} render={capitalizedTranslation} />
      </LightHeader>
      {action || (
        <TabButton fitted="true" disabled={actionDisabled}>
          <Icon name="add" style={{ margin: 0 }} disabled />
        </TabButton>
      )}
    </HeaderContainer>
  );
}

NavigationSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.node,
  actionDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  navigationSectionTestId: PropTypes.string,
};

NavigationSectionHeader.defaultProps = {
  action: null,
  actionDisabled: false,
  onClick: null,
  active: false,
  navigationSectionTestId: undefined,
};

export default NavigationSectionHeader;