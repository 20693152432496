import escapeRegExp from 'lodash/escapeRegExp';

export default function deepGet(obj, path) {
  const parts = path.split('.');
  if (parts.length === 1) {
    return obj[parts[0]];
  }
  return deepGet(obj[parts[0]], parts.slice(1).join('.'));
}

const accentMap = {
  á: 'a',
  à: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  í: 'i',
  ï: 'i',
  î: 'i',
  ó: 'o',
  ô: 'o',
  ö: 'o',
  ú: 'u',
  û: 'u',
};
export function accentFold(s) {
  if (!s) {
    return '';
  }
  let ret = '';
  for (let i = 0; i < s.length; i += 1) {
    ret += accentMap[s.charAt?.(i)] || s.charAt?.(i) || 0;
  }
  return ret;
}

export const removePunctuation = (text) =>
  text.replace(/[.,/#?!$%^&*;:{}=\-_`~()]/g, '');

export const accentInsensitiveSearch = (
  options,
  query,
  valueAccessor = (row) => row.text
) => {
  const re = new RegExp(escapeRegExp(accentFold(query)), 'i');
  return options.filter((opt) => re.test(accentFold(valueAccessor(opt))));
};

/**
 *
 *
 * @param {String} text the text to search indices in
 * @param {Array<Array<String>>} queryStrings the strings to search in `text`.
 * @param {Boolean} distinctIndices whether to return only distinct sets of indices or not.
 */
export const accentInsensitiveSearchIndices = (
  text,
  queryStrings,
  distinctIndices = false
) => {
  const regexps = [];
  queryStrings.forEach((terms, i) => {
    const query = terms
      .map((term) => escapeRegExp(removePunctuation(accentFold(term))))
      .filter((term) => !!term)
      .join('|');
    if (query) {
      regexps.push([
        new RegExp(`\\b(([a-z0-9]{0,1})?${query}([a-z0-9]{0,3})?)\\b`, 'gi'),
        i,
      ]);
    }
  });
  const foldedText = accentFold(text);
  let indices = [];
  regexps.forEach(([re, i]) => {
    let match = re.exec(foldedText);
    while (match !== null) {
      indices.push([match.index, match.index + match[0].length, i]);
      match = re.exec(foldedText);
    }
  });
  if (distinctIndices) {
    indices = indices.reduce((acc, item) => {
      if (
        !acc.find((accItem) => accItem[0] === item[0] && accItem[1] === item[1])
      ) {
        acc.push(item);
      }
      return acc;
    }, []);
  }
  return indices.sort((item1, item2) => item1[0] - item2[0]);
};

export const roundFloatedValue = (
  value,
  withSign = false,
  defaultIfNull = '0.00'
) => {
  const rounded = value ? Number(value.toFixed(2)) : 0;
  if (rounded === 0) return defaultIfNull;
  return withSign && value > 0 ? `+${rounded}` : `${rounded}`;
};

export const zip = (arr, ...arrs) =>
  arr.map((val, i) => arrs.reduce((a, newArr) => [...a, newArr[i]], [val]));

/**
 * Compute a new object by applying mapFunction to values of initial object.
 *
 * @param {*} initialObject the object to compute from.
 * @param {*} mapFunction the method to apply to initial object values.
 */
export const objectMap = (initialObject, mapFunction) =>
  Object.keys(initialObject).reduce((result, key) => {
    result[key] = mapFunction(initialObject[key]);
    return result;
  }, {});

/**
 * Python-like range method.
 *
 * @param {*} n the size of the range.
 */
export const range = (n, m = null) => {
  if (m !== null) {
    // n is the minimum value, m is max value plus one (because indexing starts at 0)
    return range(m - n).map((value) => value + n);
  }
  return Array(n)
    .fill()
    .map((_, i) => i);
};

export const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Split a text label into substrings of maximum size, avoiding word splits.
 *
 * @param {*} text the text label to split.
 * @param {*} maxLength the maximum number of characters per split.
 * @returns an array of text chunks.
 */
export const splitTextLabel = (text, maxLength, trimChunks = false) => {
  if (!maxLength) return [];
  const texts = [];
  let remainingText = text;
  while (remainingText?.length > maxLength) {
    let lastCharIndex = remainingText.substring(0, maxLength).lastIndexOf(' ');
    // If a word cannot be splitted, stop the label there, add ellipsis
    // if (lastCharIndex === -1) {
    // }
    lastCharIndex = lastCharIndex <= 0 ? maxLength : lastCharIndex;
    const textChunk = remainingText.substring(0, lastCharIndex);
    texts.push(trimChunks ? textChunk.trim() : textChunk);
    // Commented for now - it seems it does not help when cutting a word
    let i = remainingText.indexOf(' ', lastCharIndex) + 1;
    if (i < lastCharIndex || i > lastCharIndex + maxLength) i = lastCharIndex;
    // remainingText = remainingText.substring(lastCharIndex + 1);
    remainingText = remainingText.substring(i);
  }
  if (texts.length && remainingText.length < 5) {
    texts[texts.length - 1] = `${texts[texts.length - 1].slice(0, -2)}...`;
  } else {
    texts.push(remainingText);
  }
  return texts;
};

export const getDescendantProp = (obj, path) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj);

export const generateRandomInt = (min, max) =>
  Math.round(Math.random() * (max - min) + min);

export const areEqualShallow = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) =>
      Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
  );

const EmailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email) =>
  EmailRe.test(String(email).toLowerCase());

const HTML_REPLACE_PATTERN = /<[^>]+>/g;
export const removeHtmlTags = (text) =>
  text?.replace(HTML_REPLACE_PATTERN, ' ')?.trim();
