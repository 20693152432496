import { useCallback, useState } from 'react';

import { Trans, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  ButtonAccent,
  ButtonDanger,
  ButtonTransparentDanger,
} from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import CopyToClipboard from '../CopyToClipboard';

const CopyableContainer = styled.b`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
`;

function DeleteModal({
  open,
  onDelete,
  onClose,
  headerText,
  contentText,
  contentSubText,
  confirmButtonText,
  cancelButtonText,
  confirmWithInputValue,
  isDelete,
}) {
  const [confirmTextValue, setConfirmTextValue] = useState('');
  const onConfirmTextChange = useCallback((e, { value }) => {
    setConfirmTextValue(value);
  }, []);

  const onConfirm = useCallback(() => {
    onDelete();
    setConfirmTextValue('');
    onClose();
  }, [onDelete, onClose]);
  const shouldConfirmText =
    confirmWithInputValue &&
    confirmTextValue?.trim() !== confirmWithInputValue?.trim();
  return (
    <Modal closeIcon open={open} onClose={onClose} size="tiny">
      <Modal.Header content={headerText} />
      <Modal.Content syle={{ padding: svars.spaceNormalLarge }}>
        <p>{contentText}</p>
        {contentSubText ? <p>{contentSubText}</p> : null}
        {confirmWithInputValue ? (
          <>
            <p style={{ display: 'flex', flexDirection: 'column' }}>
              <Trans>to-confirm-enter-text</Trans>:{' '}
              <CopyableContainer>
                <CopyToClipboard
                  text={confirmWithInputValue}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: svars.spaceNormalLarge,
                    paddingLeft: svars.spaceNormal,
                    border: svars.lightBorderStyle,
                    borderRadius: svars.roundedBorderRadius,
                  }}
                >
                  {confirmWithInputValue}
                </CopyToClipboard>
              </CopyableContainer>
            </p>
            <TextInput
              autoFocus
              fluid
              large="true"
              onChange={onConfirmTextChange}
              placeholder={confirmWithInputValue}
              value={confirmTextValue}
            />
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger onClick={() => onClose()}>
          <Trans id={cancelButtonText} />
        </ButtonTransparentDanger>
        {isDelete ? (
          <ButtonDanger onClick={onConfirm} disabled={shouldConfirmText}>
            <Trans id={confirmButtonText} />
          </ButtonDanger>
        ) : (
          <ButtonAccent onClick={onConfirm} disabled={shouldConfirmText}>
            <Trans id={confirmButtonText} />
          </ButtonAccent>
        )}
      </Modal.Actions>
    </Modal>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  contentSubText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmButtonText: commonPropTypes.i18nText,
  cancelButtonText: commonPropTypes.i18nText,
  confirmWithInputValue: PropTypes.string,
  isDelete: PropTypes.bool,
};
DeleteModal.defaultProps = {
  open: false,
  confirmButtonText: msg({ id: 'delete' }),
  cancelButtonText: msg({ id: 'cancel' }),
  contentSubText: null,
  confirmWithInputValue: null,
  isDelete: true,
};

DeleteModal.WarningText = styled.span`
  color: darkred;
  font-weight: ${svars.fontWeightMedium};
`;

export default DeleteModal;
