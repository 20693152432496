import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  DATE_QUESTION_ITEM,
  MULTICHOICE_QUESTION_ITEM,
  conditionQuestionItemsSelectorFactory,
  isScoredQuestionType,
  questionAnswerItemsSelector,
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
  questionSelectorFactory,
} from 'selectors/survey';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { LightHeader } from 'components/ui/Header';
import StyledSegment from 'components/ui/Segment';
import { TabButton } from 'components/ui/button/TabButton';
import {
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button/index';
import { AnalyticsAwareHoverableIconButton } from 'components/ui/icon/HoverableIcon';
import { DropdownTrigger, SelectBox } from 'components/ui/inputs/Dropdown';
import { DefaultLabel } from 'components/ui/inputs/Label';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import {
  ConditionValueSummary,
  DateCondition,
  MultiChoiceCondition,
  ScoredCondition,
} from './conditions';

const ScrollableContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  overflow: clip auto;
  flex-grow: 1;
  padding: ${({ visible }) => (visible ? `${svars.spaceNormal} 0 0 0` : '0')};
`;

const ConditionContainer = styled(StyledSegment)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${svars.spaceNormal} 0;
  width: 100%;
`;

const StyledModal = styled(Modal)`
  &&& {
    & .header {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${({ closeIcon }) =>
        // Make room for the close icon, or keep default padding
        closeIcon ? `padding-right: 1.8rem !important;` : ''}
    }
    & > .content {
      padding: ${svars.spaceMedium} ${svars.spaceMediumLarge};
      min-height: 50vh;
      margin: auto;
      display: flex;
      flex-direction: column;
    }
  }
`;

const ConditionItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${svars.borderColorLight};
  border-radius: ${svars.ctaBorderRadius};
  margin: ${svars.spaceSmaller} 0;
  padding: ${svars.spaceSmall};
  padding-left: ${svars.spaceNormal};
  box-shadow: ${svars.baseBoxShadow};
`;

const getConditionValuesState = (question, condition) => {
  if (question?.type === MULTICHOICE_QUESTION_ITEM.value) {
    return {
      type: 'MultiChoiceDisplayCondition',
      values: condition?.values?.map(({ id }) => id) || [],
    };
  }
  if (question?.type === DATE_QUESTION_ITEM.value) {
    return {
      type: 'DateDisplayCondition',
      min_date: condition?.min_date,
      max_date: condition?.max_date,
    };
  }
  const type =
    (question?.type === 'NPSQuestion' && 'NPSDisplayCondition') ||
    (question?.type === 'RatingQuestion' && 'RatingDisplayCondition');
  return {
    type,
    min_value: condition?.min_value,
    max_value: condition?.max_value,
  };
};

function DisplayLogicEditorModal({
  open,
  condition,
  selectedConditionIndex,
  campaignId,
  questionId,
  addCondition,
  onClose,
  conditionQuestionItems,
}) {
  const dispatch = useDispatch();
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    condition?.question?.id
  );
  const selectedQuestion = useSelector(
    questionSelectorFactory(campaignId, selectedQuestionId, true)
  );
  const [conditionValues, setConditionValues] = useState(
    getConditionValuesState(selectedQuestion, condition)
  );
  const onSetConditionValues = useCallback(
    (type) => (values) =>
      type === 'MultiChoiceDisplayCondition'
        ? setConditionValues({ type, values })
        : setConditionValues({ type, ...values }),
    []
  );
  useEffect(() => {
    setConditionValues(getConditionValuesState(selectedQuestion, condition));
  }, [selectedQuestion, condition]);

  const questionOptionItems = useSelector(
    questionAnswerItemsSelector(campaignId, true)(selectedQuestionId)
  );
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, true)
  );
  const selectedQuestionItem = conditionQuestionItems.find(
    (item) => item.value === selectedQuestionId
  );
  const setMultiChoiceConditionValues = onSetConditionValues(
    'MultiChoiceDisplayCondition'
  );
  const setDatesConditionValues = onSetConditionValues('DateDisplayCondition');
  const setScoredConditionValues = onSetConditionValues(
    'ScoredDisplayCondition'
  );

  const onUpdateLogicConditionQuestion = (e, { value }) => {
    setSelectedQuestionId(value);
    if (value !== selectedQuestionId) {
      if (selectedQuestion?.type === MULTICHOICE_QUESTION_ITEM.value) {
        setMultiChoiceConditionValues([]);
      } else if (selectedQuestion?.type === DATE_QUESTION_ITEM.value) {
        setDatesConditionValues({
          min_date: null,
          max_date: null,
        });
      } else {
        setScoredConditionValues({
          min_value: null,
          max_value: null,
        });
      }
    }
  };
  const onAddLogicCondition = useCallback(() => {
    const values =
      selectedQuestion?.type === MULTICHOICE_QUESTION_ITEM.value
        ? {
            ...conditionValues,
            values:
              conditionValues.values?.map((value) => ({ id: value })) || [],
          }
        : conditionValues;
    addCondition(selectedQuestionId, selectedConditionIndex, values);

    onClose();
  }, [
    dispatch,
    campaignId,
    questionId,
    selectedQuestionId,
    conditionValues,
    selectedConditionIndex,
    onClose,
  ]);
  return (
    <StyledModal closeIcon open={open} onClose={onClose}>
      <Modal.Header>
        {questionId ? (
          <>
            <Trans id="add-a-condition-to-question" /> : &quot;
            {questionLabelFormatter(questionId)}&quot;
          </>
        ) : (
          <Trans id="add-a-condition-to-ending..." />
        )}
      </Modal.Header>
      <Modal.Content>
        <ConditionContainer>
          <p>
            <Trans id="select-question" />
          </p>
          <SelectBox
            trigger={
              <DropdownTrigger
                value={selectedQuestionItem}
                placeholder={t({ id: 'select-question' })}
              />
            }
            style={{
              margin: `${svars.spaceNormal} ${svars.spaceMedium}`,
              minWidth: '300px',
              flexGrow: 1,
            }}
            value={selectedQuestionId}
            onChange={onUpdateLogicConditionQuestion}
            options={conditionQuestionItems}
            fluid
            selectBoxTestId="bo-combobox-for-select-conditional-question"
          />
        </ConditionContainer>

        {selectedQuestion ? (
          <ConditionContainer>
            {selectedQuestion?.type === MULTICHOICE_QUESTION_ITEM.value && (
              <>
                <p>
                  <Trans id="select-answers" />
                </p>
                <MultiChoiceCondition
                  questionOptionItems={questionOptionItems}
                  values={conditionValues?.values || []}
                  setConditionValues={setMultiChoiceConditionValues}
                />
              </>
            )}
            {selectedQuestion?.type === DATE_QUESTION_ITEM.value && (
              <>
                <p>
                  <Trans id="select-range-of-answers" />
                </p>
                <DateCondition
                  values={conditionValues}
                  question={selectedQuestion}
                  setConditionValues={setDatesConditionValues}
                />
              </>
            )}
            {isScoredQuestionType(selectedQuestion?.type) && (
              <>
                <p>
                  <Trans id="select-range-of-answers" />
                </p>
                <ScoredCondition
                  question={selectedQuestion}
                  values={conditionValues}
                  setConditionValues={setScoredConditionValues}
                />
              </>
            )}
          </ConditionContainer>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger
          onClick={onClose}
          content={t({ id: 'cancel' })}
        />
        <ButtonAccent
          onClick={onAddLogicCondition}
          content={t({ id: 'save-condition' })}
          data-testid="bo-save-condition-question-button"
          disabled={
            !selectedQuestionId ||
            (selectedQuestion?.type === MULTICHOICE_QUESTION_ITEM.value &&
              !conditionValues?.values?.length) ||
            (selectedQuestion?.type === DATE_QUESTION_ITEM.value &&
              !(conditionValues.min_date || conditionValues.max_date)) ||
            (isScoredQuestionType(selectedQuestion?.type) &&
              !(conditionValues.min_value || conditionValues.max_value))
          }
        />
      </Modal.Actions>
    </StyledModal>
  );
}

DisplayLogicEditorModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  addCondition: PropTypes.func.isRequired,
  condition: commonPropTypes.condition.isRequired,
  question: commonPropTypes.question.isRequired,
  open: PropTypes.bool.isRequired,
  selectedConditionIndex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
  conditionQuestionItems: commonPropTypes.items.isRequired,
};

DisplayLogicEditorModal.defaultProps = {
  selectedConditionIndex: false,
};

const HeaderContainer = styled.span`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
`;

const SegmentContainer = styled(StyledSegment)`
  &&& {
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 0;
    max-height: 35%;
  }
`;

function DisplayLogicEditorWithModal({
  campaignId,
  currentQuestionId,
  conditions,
  addCondition,
  deleteCondition,
  isDefaultScreen,
  subHeaderLabel,
  showEmptyCase,
}) {
  const dispatch = useDispatch();
  const [displayConditionModal, setDisplayConditionModal] = useState(false);
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, true)
  );
  const answerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId, true)
  );
  const conditionQuestionItems = useSelector(
    conditionQuestionItemsSelectorFactory(
      campaignId,
      currentQuestionId,
      true,
      true,
      conditions?.map(
        ({ question: { id } = {} }) =>
          id !== conditions[displayConditionModal]?.question?.id && id
      )
    )
  );
  const openConditionLanguageModal = useCallback(
    (conditionIndex) => () => {
      setDisplayConditionModal(conditionIndex);
    },
    []
  );
  const onDeleteCondition = useCallback(
    (conditionIndex) => () => deleteCondition(conditionIndex),
    [dispatch, campaignId, currentQuestionId]
  );
  return (
    <SegmentContainer>
      <HeaderContainer>
        <LightHeader nomargin="1">
          <Trans id="display-logic" />
          <LightHeader.Subheader>
            <Trans id={subHeaderLabel} />
          </LightHeader.Subheader>
        </LightHeader>
        {isDefaultScreen ? (
          <DefaultLabel />
        ) : (
          <TabButton
            icon="add"
            content={t({ id: 'add-condition' })}
            onClick={openConditionLanguageModal(conditions?.length)}
            active={false}
            disabled={isDefaultScreen || !conditionQuestionItems?.length}
            data-testid="bo-campaign-add-condition-icon"
          />
        )}
      </HeaderContainer>
      <ScrollableContainer visible={conditions?.length ? '1' : null}>
        {conditions?.map(
          ({ question: conditionQuestion, values, ...others }, i) => (
            <ConditionItem key={conditionQuestion.id}>
              <span
                style={{ display: 'flex', minWidth: 0, alignItems: 'center' }}
              >
                {questionLabelFormatter(conditionQuestion?.id, true)}
                <ConditionValueSummary
                  values={values}
                  question={conditionQuestion}
                  labelFormatter={answerLabelFormatter}
                  {...others}
                />
              </span>
              {conditionQuestion?.values?.map(answerLabelFormatter).join(', ')}
              <span style={{ display: 'inline-flex' }}>
                <AnalyticsAwareHoverableIconButton
                  name="edit"
                  onClick={openConditionLanguageModal(i)}
                  tooltipContent={t({ id: 'edit' })}
                  tooltipPosition="top center"
                />
                <AnalyticsAwareHoverableIconButton
                  name="delete"
                  danger
                  onClick={onDeleteCondition(i)}
                  tooltipContent={t({ id: 'delete' })}
                  tooltipPosition="top center"
                />
              </span>
            </ConditionItem>
          )
        )}
      </ScrollableContainer>
      {!isDefaultScreen && conditions?.length === 0 && showEmptyCase ? (
        <EmptyDataPage
          style={{ padding: '3rem' }}
          maxHeight={80}
          layout="row"
          i18nHeaderText={t({ id: 'no-condition-defined' })}
          actionComponent={<Trans id="add-condition-to-display-end-screen" />}
        />
      ) : null}
      {displayConditionModal !== false ? (
        <DisplayLogicEditorModal
          key={displayConditionModal}
          campaignId={campaignId}
          questionId={currentQuestionId}
          condition={conditions?.[displayConditionModal]}
          open={displayConditionModal !== false}
          selectedConditionIndex={displayConditionModal}
          onClose={openConditionLanguageModal(false)}
          conditionQuestionItems={conditionQuestionItems}
          addCondition={addCondition}
        />
      ) : null}
    </SegmentContainer>
  );
}

DisplayLogicEditorWithModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  currentQuestionId: PropTypes.string,
  conditions: PropTypes.arrayOf(commonPropTypes.condition),
  addCondition: PropTypes.func.isRequired,
  deleteCondition: PropTypes.func.isRequired,
  isDefaultScreen: PropTypes.bool,
  subHeaderLabel: commonPropTypes.i18nText.isRequired,
  // If true, show the empty case message
  showEmptyCase: PropTypes.bool,
};

DisplayLogicEditorWithModal.defaultProps = {
  conditions: [],
  currentQuestionId: null,
  isDefaultScreen: false,
  showEmptyCase: false,
};

export default DisplayLogicEditorWithModal;
