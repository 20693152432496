import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import {
  fetchFilterPresets,
  initializeFacetSearch,
  updateLocationQuery,
} from 'actions/search';
import { searchPageInitializingSelector } from 'selectors/search';

import BeatingLoader from 'components/ui/BeatingLoader';

import commonPropTypes from 'utils/commonPropTypes';

import ChunkSearch from './ChunkSearch';

function V2DashboardSearch({ viewFacet, locationQuery, hidden }) {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const searchPageInitializing = useSelector(searchPageInitializingSelector);

  useEffect(() => {
    if (hidden === false && mounted) {
      // When navigating again to search, we reset the filters based on location query
      dispatch(updateLocationQuery(locationQuery, false));
    } else if (!mounted) {
      // First load
      dispatch(initializeFacetSearch(viewFacet.id));
      dispatch(fetchFilterPresets(viewFacet.id)).then(() => {
        dispatch(
          updateLocationQuery(
            locationQuery.preset
              ? {
                  preset: locationQuery.preset,
                }
              : omit(locationQuery, ['preset']),
            true
          )
        );
      });
      setMounted(true);
    }
  }, [locationQuery, hidden]);

  if (hidden) return null;
  return searchPageInitializing ? <BeatingLoader /> : <ChunkSearch />;
}

V2DashboardSearch.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
  locationQuery: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    // Product hierarchies - we use a short name to limit url size
    ph: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    phg: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    ontology: PropTypes.string,
    concept: PropTypes.string,
    positive: PropTypes.string,
    negative: PropTypes.string,
    // Id of a filter preset belonging to the current view facet
    preset: PropTypes.string,
    tags: PropTypes.shape({
      id: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  hidden: PropTypes.bool,
};

V2DashboardSearch.defaultProps = { hidden: false };

export default V2DashboardSearch;
