import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  background: ${svars.colorWhite};
  padding: ${({ padded }) =>
    padded ? `${svars.spaceNormalLarge} ${svars.spaceMedium}` : 0};
  // Right padding would display between scroll bar and side of screen for scrollable fields
  padding-right: 0;
`;

const FieldHeader = styled.div`
  margin: auto 0;
  margin-bottom: ${svars.spaceSmaller};
  color: ${svars.fontColorLighter};
  padding-right: ${svars.spaceNormalLarge};
  ${({ wrapped }) =>
    wrapped
      ? ''
      : "white-space: nowrap; overflow: hidden; text-overflow: ellipsis; &::after {content: ' : '}"}
`;

const FieldValueContainer = styled.div`
  flex-grow: 1;
  align-items: flex-start;
  overflow: unset;
  width: 100%;
`;

export function PaneFieldValuePlaceholder() {
  return (
    <Placeholder
      style={{
        width: '100%',
        marginRight: svars.spaceMedium,
      }}
    >
      <Placeholder.Line style={{ height: '5px' }} />
      <Placeholder.Line style={{ height: '5px' }} />
    </Placeholder>
  );
}

function FieldValue({
  header,
  loading,
  id,
  reviewItem,
  value,
  Cell,
  formatter,
  getCellProps,
  LoadingCell,
  ...otherProps
}) {
  const loadingState =
    loading &&
    ((LoadingCell && <LoadingCell />) || (LoadingCell === null && <div />) || (
      <PaneFieldValuePlaceholder />
    ));
  return (
    <>
      {((!loading || loadingState) && header) || ''}
      <FieldValueContainer>
        {loadingState ||
          (Cell && (
            <Cell
              key={`${id}-${reviewItem?.id}`}
              padded
              formatter={formatter}
              feedbackId={reviewItem.id}
              formId={id}
              value={value}
              {...otherProps}
              {...(getCellProps ? getCellProps(reviewItem) : {})}
            />
          )) ||
          value}
      </FieldValueContainer>
    </>
  );
}

FieldValue.propTypes = {
  header: PropTypes.node,
  loading: PropTypes.bool,
  id: PropTypes.string,
  reviewItem: PropTypes.shape({ id: PropTypes.string }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node,
  ]),
  Cell: PropTypes.func,
  getCellProps: PropTypes.func,
  LoadingCell: PropTypes.func,
  formatter: PropTypes.func,
};
FieldValue.defaultProps = {
  header: null,
  loading: false,
  id: null,
  reviewItem: {},
  value: null,
  Cell: null,
  getCellProps: null,
  LoadingCell: undefined,
  formatter: null,
};

const SectionHeader = styled.div`
  &&& {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium} 0;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    font-weight: ${svars.fontWeightMedium};
    font-size: ${svars.fontSizeLarge};
    background: ${svars.colorLightestGrey};
    border-bottom: ${svars.lightBorderStyle};
  }
`;

function PaneFields({
  header: sectionHeader,
  headerIcon,
  headerAction,
  reviewItem,
  fields,
  loading,
  alwaysShow,
  wrapped,
}) {
  if (!fields?.length) return null;
  // Fields are hidden if they are empty and alwaysShow is false
  const fieldValuesAndIsHidden = fields.map(({ accessor }) => {
    const value =
      (!loading &&
        ((typeof accessor === 'function' && accessor(reviewItem)) ||
          reviewItem?.[accessor] ||
          '-')) ||
      null;
    return [
      value,
      !loading && // Don't show empty fields if not loading
        !alwaysShow && // Don't show empty fields if alwaysShow is false
        (!value ||
          (typeof value === 'object' && value.length === 0) ||
          value === '-'),
    ];
  });
  if (fieldValuesAndIsHidden.every(([, empty]) => empty)) return null;
  return (
    <>
      {sectionHeader ? (
        <SectionHeader>
          <span>
            {headerIcon ? (
              <Icon
                name={headerIcon}
                style={{
                  fontSize: 'inherit',
                  margin: `0 ${svars.spaceMedium}`,
                }}
              />
            ) : null}
            <Trans id={sectionHeader} render={capitalizedTranslation} />
          </span>
          {headerAction}
        </SectionHeader>
      ) : null}
      {fields.map(
        ({ id, key, header, formatter, scrollable, ...valueProps }, i) => {
          const [value, isHidden] = fieldValuesAndIsHidden[i];
          if (isHidden) {
            return null;
          }
          return (
            <FieldContainer
              scrollable={scrollable ? '1' : null}
              padded={!scrollable ? '1' : null}
              key={`${header}-${id || key}`}
              id={`pane-field-${header}`}
            >
              <FieldValue
                id={id}
                header={
                  header ? (
                    <FieldHeader wrapped={wrapped}>
                      <Trans id={header} render={capitalizedTranslation} />
                    </FieldHeader>
                  ) : null
                }
                formatter={formatter}
                loading={loading}
                reviewItem={reviewItem}
                value={value}
                // value={formatter ? formatter(value) : value}
                {...valueProps}
              />
            </FieldContainer>
          );
        }
      )}
    </>
  );
}

PaneFields.propTypes = {
  header: PropTypes.oneOfType([commonPropTypes.i18nText, PropTypes.node]),
  headerIcon: PropTypes.string,
  headerAction: PropTypes.node,
  reviewItem: PropTypes.shape({ id: PropTypes.string }),
  fields: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  alwaysShow: PropTypes.bool,
  wrapped: PropTypes.bool,
};
PaneFields.defaultProps = {
  header: null,
  headerIcon: null,
  headerAction: null,
  reviewItem: {},
  fields: [],
  loading: false,
  alwaysShow: false,
  wrapped: false,
};

export default PaneFields;
