import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import { ResettableTextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

const CHECKBOX_STYLES = {
  fontSize: svars.fontSizeLarge,
  padding: `0 ${svars.spaceMediumLarge}`,
  flexGrow: 1,
  minWidth: '100px',
};

const FilterActions = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: ${svars.spaceMedium};
  flex-wrap: wrap;
`;

const StyledActionBar = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

function ActionBar({
  textFilterPlaceholder,
  onSearchChange,
  onResetSearch,
  facetSearchValue,
  onToggleDisplayFavouritesOnly,
  displayFavoritesOnly,
  actions,
  testid,
  items,
  withTextFilter,
  hasLockedProperty,
  showArchived,
  setShowArchived,
}) {
  const { i18n } = useLingui();
  if (
    !withTextFilter &&
    !onToggleDisplayFavouritesOnly &&
    !hasLockedProperty &&
    !actions
  ) {
    return null;
  }
  return (
    <StyledActionBar>
      <FilterActions
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{
            flexGrow: 0.5,
            minWidth: '50px',
            margin: `${svars.spaceNormal} 0`,
          }}
        >
          {withTextFilter ? (
            <ResettableTextInput
              placeholder={textFilterPlaceholder}
              onChange={onSearchChange}
              onReset={onResetSearch}
              value={facetSearchValue}
              disabled={items?.length === 0}
              baseIconName="search"
              large={1}
              fluid
              style={{ minWidth: svars.smallInputMinWidth }}
              data-testid={testid}
            />
          ) : null}
        </span>
        <div
          style={{
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {onToggleDisplayFavouritesOnly ? (
            <AnalyticsAwareCheckbox
              gaCategory="View Facet List"
              gaAction="Display favorites"
              style={CHECKBOX_STYLES}
              onClick={onToggleDisplayFavouritesOnly}
              label={i18n._(t({ id: 'display-favorites-only' }))}
              checked={displayFavoritesOnly}
            />
          ) : null}
          {hasLockedProperty ? (
            <AnalyticsAwareCheckbox
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              label={i18n._(t({ id: 'unarchived-only' }))}
              data-testid="unlocked-only-toggle"
              style={{
                fontSize: svars.fontSizeLarge,
                padding: `0 ${svars.spaceMediumLarge}`,
              }}
            />
          ) : null}
        </div>
      </FilterActions>
      {actions}
    </StyledActionBar>
  );
}

ActionBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  displayFavoritesOnly: PropTypes.bool,
  onToggleDisplayFavouritesOnly: PropTypes.func,
  textFilterPlaceholder: PropTypes.string,
  actions: PropTypes.node,
  onSearchChange: PropTypes.func,
  onResetSearch: PropTypes.func,
  facetSearchValue: PropTypes.string,
  testid: PropTypes.string,
  withTextFilter: PropTypes.bool,
  hasLockedProperty: PropTypes.bool,
  showArchived: PropTypes.bool,
  setShowArchived: PropTypes.func,
};
ActionBar.defaultProps = {
  displayFavoritesOnly: false,
  items: undefined,
  onToggleDisplayFavouritesOnly: null,
  testid: undefined,
  onSearchChange: null,
  onResetSearch: null,
  facetSearchValue: '',
  withTextFilter: true,
  hasLockedProperty: false,
  showArchived: false,
  setShowArchived: null,
  textFilterPlaceholder: null,
  actions: null,
};

export default ActionBar;
