import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  appConfigSelector,
  isAuthenticatedSelector,
  isBwSelector,
} from 'selectors/user';

import { getHomePath } from 'utils/routing';

import * as svars from 'assets/style/variables';

import V3Navbar from '../navigation/V3Navbar';
import { HelpcrunchWidgetManagement } from '../navigation/useHelpcrunch';

const loaderDots = Array(1)
  .fill()
  .map((_, i) => i);

const LayoutContainerDiv = styled.div`
  &&&&&&&&&& {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${svars.fontColorBase};
    background-color: ${svars.backgroundColorBase};
    overflow: hidden;
  }
`;

const DivLayout = styled.div`
  display: flex;
  flex-direction: ${({ sidebar }) => (sidebar ? 'column' : 'column')};
  ${({ fullHeight }) => (fullHeight ? 'height: 100%;' : '')}
`;

const InnerDivLayout = styled.div`
  display: flex;
  flex-direction: column;
  ${({ navbar, fullHeight }) =>
    fullHeight
      ? `min-height: ${
          navbar ? `calc(100vh - ${svars.navbarHeight})` : '100vh'
        };`
      : 'height: 100%;'}
  ${({ sidebar }) =>
    sidebar
      ? `
    width: calc(100% - ${svars.sidebarWidth});
    margin-left: ${svars.sidebarWidth};
  `
      : 'width: 100%'}
`;

const Loader = styled.div`
  height: ${svars.loaderHeight};
  width: 100vw;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  left: 0px;
  top: calc(${svars.navbarHeight} - 2px);

  div {
    transition: visibility 0s, opacity 0.5s linear;
    height: ${svars.loaderHeight};
    position: absolute;
    left: 0px;
    background-color: ${svars.accentColor};
    z-index: 5;
    opacity: 0.6;
    animation: move 6s infinite cubic-bezier(0.45, 0.01, 0.41, 0.8);
  }

  ${loaderDots.reduce(
    (st, i) => `
    ${st}
    div:nth-child(${i + 1}) {
      animation-delay: ${20 * (i + 1)}ms;
    }`,
    ''
  )} @keyframes move {
    0% {
      width: 0%;
      left: 0;
    }
    50% {
      width: 100%;
      left: 0;
    }

    100% {
      width: 100%;
      left: 100%;
    }
  }
`;

const GlobalLoader = connect((state) => ({
  isLoading: state.ui.isLoading,
}))(({ isLoading }) => <Loader isLoading={isLoading} />);

export function BasePageLayout({ withV3Navbar, fullHeight, children }) {
  return (
    <DivLayout fullHeight={fullHeight}>
      {withV3Navbar ? <V3Navbar /> : null}
      <InnerDivLayout
        navbar={(withV3Navbar && '1') || null}
        fullHeight={fullHeight ? '1' : null}
      >
        <GlobalLoader />

        <LayoutContainerDiv>{children}</LayoutContainerDiv>
      </InnerDivLayout>
    </DivLayout>
  );
}

BasePageLayout.propTypes = {
  withV3Navbar: PropTypes.bool,
  fullHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

BasePageLayout.defaultProps = {
  withV3Navbar: false,
  fullHeight: false,
};

function LoggedLayout({ children, withV3Navbar, fullHeight }) {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isBw = useSelector(isBwSelector);
  const { analysis, campaign } = useSelector(appConfigSelector);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else if (
      !isBw &&
      ((!analysis &&
        (window.location.pathname.includes('/facets') ||
          window.location.pathname.includes('/product-group'))) ||
        (!campaign && window.location.pathname.includes('/campaign')))
    ) {
      navigate(getHomePath(isAuthenticated, isBw, analysis, campaign));
    }
  }, [isAuthenticated]);

  return (
    <>
      <HelpcrunchWidgetManagement />
      <BasePageLayout withV3Navbar={withV3Navbar} fullHeight={fullHeight}>
        {children}
      </BasePageLayout>
    </>
  );
}
LoggedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  withV3Navbar: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
LoggedLayout.defaultProps = {
  withV3Navbar: false,
  fullHeight: false,
};

export function AdminLoggedLayout(props) {
  const isBw = useSelector(isBwSelector);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isBw) {
      navigate('/');
    }
  }, [isBw]);
  return <LoggedLayout {...props} />;
}

export default LoggedLayout;
