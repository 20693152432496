import { t } from '@lingui/macro';

import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { api } from 'actions/utils';
import { EMPTY_CATEGORY_LABEL } from 'reducers/entityLabelFormatter';
import {
  DATE_QUESTION_ITEM,
  MULTICHOICE_QUESTION_ITEM,
  NPS_QUESTION_ITEM,
  RATING_QUESTION_ITEM,
  questionSelectorFactory,
} from 'selectors/survey';

import { pushDangerToaster } from 'components/ui/Toaster';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

import {
  campaignFiltersSelector,
  toggleFiltersPaneAndMaybeFetchFeedback,
  updateFiltersFromPreset,
} from './monitorSearchSlice';

// Thunks

export const maybeLoadCampaignFilterPresets = createAsyncThunk(
  'campaignPreset/maybeLoadCampaignFilterPresets:load',
  async ({ campaignId }, { getState }) => {
    // Do not fetch if already loaded
    const { presets } = getState().campaignPreset;
    if (presets[campaignId]) {
      return {};
    }

    const response = await api.get(`/campaign/${campaignId}/preset`);
    return { campaignId, presets: response.data };
  }
);

export class NameAlreadyTakenError extends Error {
  constructor() {
    super('Name already taken');
    this.name = 'NameAlreadyTakenError';
  }
}

export const compileCampaignFilterPresetPayload = (
  state,
  campaignId,
  presetName,
  filtersValues,
  hasNotification,
  notificationFrequency
) => {
  const { period, customRangeDates } = state.campaignSearch;
  const payload = {
    name: presetName,
    date_period: period,
    ...filtersValues,
  };
  if (filtersValues.answers) {
    payload.answers = [];
    Object.entries(filtersValues.answers).forEach(([questionId, value]) => {
      const answerPayload = { question: { id: questionId } };
      const question = questionSelectorFactory(campaignId, questionId)(state);

      switch (question.type) {
        case MULTICHOICE_QUESTION_ITEM.value:
          // If `EMPTY_CATEGORY_LABEL` in values, add empty filter
          // and remove it from values
          if (value?.includes?.(EMPTY_CATEGORY_LABEL)) {
            payload.answers.push({
              question: { id: questionId },
              type: 'EmptySurveyQuestionFilter',
            });
          }
          answerPayload.type = 'MultiChoiceSurveyQuestionAnswer';
          answerPayload.value = value
            .filter((id) => id !== EMPTY_CATEGORY_LABEL)
            .map((id) => ({ id }));

          break;
        case DATE_QUESTION_ITEM.value:
          answerPayload.type = 'DateQuestionFilter';
          if (value.empty) {
            answerPayload.empty = true;
          }
          answerPayload.min_date = value.minDate;
          answerPayload.max_date = value.maxDate;
          break;
        case NPS_QUESTION_ITEM.value:
        case RATING_QUESTION_ITEM.value:
          if (value.empty) {
            answerPayload.empty = true;
          }
          answerPayload.type = 'ScoredQuestionFilter';
          answerPayload.min_value = value.minValue;
          answerPayload.max_value = value.maxValue;
          break;
        default:
          throw new Error(`Unsupported question type: ${question.type}`);
      }
      payload.answers.push({
        question: { id: questionId },
        ...answerPayload,
      });
    });
  }
  if (period === 'custom') {
    payload.min_date = customRangeDates.minDate;
    payload.max_date = customRangeDates.maxDate;
  }
  if (hasNotification) {
    payload.notification = { frequency: notificationFrequency };
  }
  return payload;
};

export const createCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/createCampaignFilterPreset:load',
  async (
    {
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency,
    },
    { getState }
  ) => {
    // Get date filters from state
    const payload = compileCampaignFilterPresetPayload(
      getState(),
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency
    );
    try {
      const response = await api.post(
        `/campaign/${campaignId}/preset`,
        payload
      );
      return { campaignId, preset: response.data };
    } catch (error) {
      // If 409, the name is already taken
      if (error.response?.status === 409) {
        throw new NameAlreadyTakenError();
      }
      throw error;
    }
  }
);

export const updateCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/updateCampaignFilterPreset:load',
  async (
    {
      id,
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency,
    },
    { getState }
  ) => {
    // Get date filters from state
    const payload = compileCampaignFilterPresetPayload(
      getState(),
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency
    );
    const response = await api.put(
      `/campaign/${campaignId}/preset/${id}`,
      payload
    );
    return { campaignId, preset: response.data };
  }
);

export const deleteCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/deleteCampaignFilterPreset:load',
  async ({ campaignId, presetId }, { getState }) => {
    await api.delete(`/campaign/${campaignId}/preset/${presetId}`);
    return { campaignId, presetId };
  }
);

export const setCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/setCampaignFilterPreset:load',
  async ({ campaignId, presetId }, { getState, dispatch }) => {
    const preset = getState().campaignPreset.presets[campaignId]?.find(
      ({ id }) => id === presetId
    );
    const filters = { tags: {} };
    if (preset.channels?.length) {
      filters.channels = preset.channels.map(({ id }) => id);
    }
    if (preset.db_concepts?.length) {
      filters.concepts = preset.db_concepts.map(({ id }) => id);
    }
    if (preset.answers?.length) {
      filters.answers = {};

      preset.answers.forEach(
        ({
          question: { id: questionId },
          value,
          min_date,
          max_date,
          min_value,
          max_value,
          empty,
        }) => {
          const question = questionSelectorFactory(
            campaignId,
            questionId
          )(getState());
          filters.answers[questionId] =
            (question.type === DATE_QUESTION_ITEM.value && {
              minDate: min_date,
              maxDate: max_date,
            }) ||
            ((question.type === NPS_QUESTION_ITEM.value ||
              question.type === RATING_QUESTION_ITEM.value) && {
              minValue: min_value,
              maxValue: max_value,
              empty,
            }) ||
            value?.map(({ id }) => id);
        }
      );
    }
    ['annotation_values', 'response_values', 'url_values'].forEach(
      (tagFieldName) => {
        if (preset[tagFieldName]?.length) {
          filters.tags[tagFieldName] = {};
          preset[tagFieldName].forEach(
            ({ type, tags, users, form_element: { id: formElementId } }) => {
              if (!filters.tags[tagFieldName][formElementId]) {
                filters.tags[tagFieldName][formElementId] = [];
              }
              if (type === 'EmptyFilter') {
                filters.tags[tagFieldName][formElementId].push(
                  EMPTY_CATEGORY_LABEL
                );
                return;
              }
              filters.tags[tagFieldName][formElementId] = [
                ...filters.tags[tagFieldName][formElementId],
                ...((tags || users)?.map(
                  ({ id }) => id || EMPTY_CATEGORY_LABEL
                ) || []),
              ];
            }
          );
        }
      }
    );
    if (preset.satisfaction_tags?.length) {
      filters.tags.satisfaction_tag = preset.satisfaction_tags.map(
        ({ id }) => id
      );
    }
    if (preset.empty_satisfaction_tag) {
      filters.tags.satisfaction_tag = [
        ...(filters.tags.satisfaction_tag || []),
        EMPTY_CATEGORY_LABEL,
      ];
    }
    if (filters) {
      await dispatch(
        updateFiltersFromPreset({
          period: preset.date_period,
          customRangeDates: {
            minDate: preset.min_date,
            maxDate: preset.max_date,
          },
          filters,
        })
      );
      await dispatch(
        toggleFiltersPaneAndMaybeFetchFeedback({
          campaignId,
          forceUpdate: true,
        })
      );
    }
    return { campaignId, presetId };
  }
);

const campaignSearchSlice = createAPISlice(
  {
    name: 'campaignPreset',
    initialState: {
      // Map campaign id to filter values
      presets: {},
      selectedPreset: null,
      editPreset: null,
    },
    reducers: {
      setEditPreset: (state, { payload }) => {
        state.editPreset = payload;
      },
      resetPresetSelection: (state) => {
        state.selectedPreset = null;
        state.editPreset = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        maybeLoadCampaignFilterPresets.fulfilled,
        (state, { payload: { campaignId, presets } }) => {
          if (presets) state.presets[campaignId] = presets;
        }
      );
      builder.addCase(
        createCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, preset } }) => {
          state.presets[campaignId] = [...state.presets[campaignId], preset];
          state.selectedPreset = preset;
        }
      );
      builder.addCase(createCampaignFilterPreset.rejected, (state, action) => {
        if (action.error.name === 'NameAlreadyTakenError') {
          // Show a toaster saying name is already taken
          pushDangerToaster(t({ id: 'campaign-preset-name-not-available' }));
        }
      });
      builder.addCase(
        updateCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, preset } }) => {
          const toUpdateIndex = state.presets[campaignId].findIndex(
            ({ id }) => id === preset.id
          );
          state.presets[campaignId] = [
            ...state.presets[campaignId].slice(0, toUpdateIndex),
            preset,
            ...state.presets[campaignId].slice(toUpdateIndex + 1),
          ];
          state.selectedPreset = preset;
        }
      );
      builder.addCase(
        deleteCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, presetId } }) => {
          const toRemoveIndex = state.presets[campaignId].findIndex(
            ({ id }) => id === presetId
          );
          state.presets[campaignId] = [
            ...state.presets[campaignId].slice(0, toRemoveIndex),
            ...state.presets[campaignId].slice(toRemoveIndex + 1),
          ];

          if (state.selectedPreset?.id === presetId) {
            state.selectedPreset = null;
          }
          if (state.editPreset?.id === presetId) {
            state.editPreset = null;
          }
        }
      );
      builder.addCase(
        setCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, presetId } }) => {
          state.selectedPreset = state.presets[campaignId].find(
            ({ id }) => id === presetId
          );
        }
      );
    },
  },
  {
    keys: [
      'maybeLoadCampaignFilterPresets',
      'createCampaignFilterPreset',
      'deleteCampaignFilterPreset',
      'updateCampaignFilterPreset',
    ],
  }
);

export const { setEditPreset, resetPresetSelection } =
  campaignSearchSlice.actions;

export default campaignSearchSlice.reducer;

// Selectors
export const campaignPresetsSelectorFactory = (campaignId) => (state) =>
  state.campaignPreset.presets[campaignId];

export const campaignPresetsLoadingSelector = (state) =>
  state.campaignPreset.maybeLoadCampaignFilterPresets === StateStatus.PENDING;

export const saveCampaignPresetLoadingSelector = (state) =>
  state.campaignPreset.createCampaignFilterPreset === StateStatus.PENDING;

export const selectedPresetSelector = (state) =>
  state.campaignPreset.selectedPreset;

export const editPresetSelector = (state) => state.campaignPreset.editPreset;

export const isPresetNameAvailableSelectorFactory = (campaignId) =>
  createSelector(
    campaignPresetsSelectorFactory(campaignId),
    (presets) => (presetName) =>
      !presets.some(({ name }) => name === presetName)
  );

export const campaignPresetFiltersAreEmptySelector = createSelector(
  campaignFiltersSelector,
  (filters) =>
    !filters.channels?.length &&
    !filters.ontologyConcepts?.length &&
    !filters.concepts?.length &&
    !filters.tags?.satisfaction_tag?.length &&
    !Object.keys(filters.tags?.url_values || {}).length &&
    !Object.keys(filters.tags?.annotation_values || {}).length &&
    !Object.keys(filters.tags?.respondent_values || {}).length &&
    !Object.keys(filters.answers || {}).length
);
