import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactTable, { Row, THead } from 'components/ui/table/ReactTableUi';

import * as svars from 'assets/style/variables';

const MonitorReactTable = styled(ReactTable)`
  max-width: unset;
  box-shadow: unset;
  border: none;
  border-radius: 0;
  overflow-y: auto;
  align-content: center;

  ${THead} {
    background-color: transparent;
    border-bottom: 1px solid ${svars.borderColorLight};
    box-shadow: none;
    margin-top: ${svars.spaceNormalLarge};
  }
  ${Row} {
    border-radius: 0;
`;

export default function QuestionScoresTable({ chartId, data, meta, height }) {
  return (
    <MonitorReactTable
      chartId={chartId}
      data={data}
      columns={meta.columns}
      defaultSorted={meta.defaultSorted}
      sortable
      style={{ height }}
    />
  );
}

QuestionScoresTable.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    defaultSorted: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  height: PropTypes.number,
};

QuestionScoresTable.defaultProps = {
  height: null,
};
