import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Divider, Grid } from 'semantic-ui-react';

import {
  formSelectorFactory,
  urlFormTagSetsDropdownSelectorFactory,
} from 'selectors/campaign';
import { conceptsItemsSelector } from 'selectors/entities';
import { isFeedbackCampaignSelector } from 'selectors/survey';
import {
  createChannel,
  createChannelFormValuesSelector,
  createChannelLoadingSelector,
  fetchChannels,
  isChannelHiddenQuestionValidSelectorFactory,
  resetCreateFormValues,
  setChannelConceptValue,
  setChannelFormValue,
} from 'store/monitor/channelsSlice';

import FacetCreateField from 'components/customer/home/view-facet-create/FacetCreateField';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import { LargeHeader } from 'components/ui/Header';
import Link, { RouterLink } from 'components/ui/Link';
import StyledSegment from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import HelpcrunchButtonLink from 'components/ui/button/HelpcrunchButtonLink';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import { LimitedTextInput, TextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout, PageLayout } from 'components/ui/layout/Page';
import SvgContentUrl from 'components/ui/svg/undraw_content_vbqo.svg';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import ChannelQuestionFilter from './ChannelQuestionFilter';

function CreateChannelPage() {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetCreateFormValues());
  }, [location]);
  const goToList = useCallback(() => {
    navigate('../');
  }, [navigate]);
  const { campaignId } = useParams();
  const [channelCreateError, setChannelCreateError] = useState(false);
  const urlForm = useSelector(formSelectorFactory(campaignId, 'url_form'));
  const formValues = useSelector(createChannelFormValuesSelector);
  const isChannelHiddenQuestionValid = useSelector(
    isChannelHiddenQuestionValidSelectorFactory(campaignId)
  );
  const tagSets = useSelector(
    urlFormTagSetsDropdownSelectorFactory(campaignId)
  );
  const concepts = useSelector(conceptsItemsSelector(null));
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const createLoading = useSelector(createChannelLoadingSelector);

  const [channelName, setChannelName] = useState('');
  const onChannelNameChange = useCallback(
    (e, { value }) => setChannelName(value),
    []
  );

  const onTagFormValueChange = useCallback(
    (formId, tagSetId) =>
      (e, { value }) =>
        dispatch(
          setChannelFormValue({
            key: formId,
            value: { tagId: value, tagSetId },
          })
        ),
    []
  );
  const onConceptValueChange = useCallback(
    (e, { value }) => dispatch(setChannelConceptValue(value)),
    []
  );
  const onBasicFormValueChange = useCallback(
    (formId) =>
      (e, { value }) =>
        dispatch(setChannelFormValue({ key: formId, value })),
    []
  );
  const onConfirm = useCallback(() => {
    dispatch(createChannel({ campaignId, channelName })).then((channel) => {
      if (channel.error) {
        setChannelCreateError(true);
      } else {
        dispatch(fetchChannels({ campaignId }));
        goToList();
      }
    });
  }, [campaignId, channelName]);
  const isComplete =
    isChannelHiddenQuestionValid &&
    !!channelName &&
    urlForm.every(
      ({ id, mandatory }) =>
        !mandatory ||
        (typeof formValues[id] === 'object' && formValues[id]?.tagId) ||
        (typeof formValues[id] === 'string' && formValues[id])
    );
  return (
    <PageLayout simple>
      <div style={{ padding: svars.spaceMedium, overflowY: 'auto' }}>
        <Grid style={{ padding: svars.spaceMedium }}>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width={9}>
              <LargeHeader>
                <Trans id="create-new-channel" />
              </LargeHeader>
              <div style={{ maxWidth: svars.textMaxWidth }}>
                <Trans id="campaign-channels.description" />
              </div>
              <HelpcrunchButtonLink
                style={{ marginTop: svars.spaceMedium }}
                articleId={12}
                helpcrunchButtonLinkTestId="bo-helpcrunch-article-12"
              />
            </Grid.Column>
            <Grid.Column
              width={7}
              floated="right"
              textAlign="right"
              verticalAlign="bottom"
            >
              <img
                style={{
                  maxHeight: '10rem',
                  paddingRight: svars.spaceLarge,
                }}
                src={SvgContentUrl}
                alt="star"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid relaxed style={{ padding: `0 ${svars.spaceMedium}` }}>
          <FacetCreateField
            style={{ display: 'flex' }}
            inputWidth={15}
            input={
              <LimitedTextInput
                style={{
                  width: '100%',
                  fontWeight: svars.fontWeightBold,
                  fontSize: svars.fontSizeXLarge,
                }}
                placeholder={i18n._(t({ id: 'name' }))}
                value={channelName}
                onChange={onChannelNameChange}
                maxCharacters={40}
                error={channelCreateError}
                data-testid="bo-campaign-channel-creation-name-input"
                autoFocus
              />
            }
            errorMessage={
              channelCreateError
                ? t({ id: 'channel-name-is-taken-select-an-other-one' })
                : null
            }
          />
          {urlForm.length ? (
            <>
              {urlForm.map(
                ({ id, label, tag_set, placeholder, form_type, mandatory }) => (
                  <FacetCreateField
                    key={`${id}`}
                    mandatory={mandatory}
                    name={label}
                    input={
                      form_type === 'InputFormElement' ? (
                        <TextInput
                          value={formValues[id] || ''}
                          onChange={onBasicFormValueChange(id)}
                          placeholder={placeholder}
                        />
                      ) : (
                        <StyledDropdown
                          name={t({ id: 'base-product-services-group' })}
                          search={
                            tagSets[tag_set.id] &&
                            tagSets[tag_set.id].length > 10
                          }
                          selection
                          clearable
                          options={tagSets[tag_set.id]}
                          disabled={!module}
                          value={formValues[id]?.tagId || ''}
                          onChange={onTagFormValueChange(id, tag_set?.id)}
                          placeholder={placeholder}
                        />
                      )
                    }
                    inputWidth={15}
                  />
                )
              )}
            </>
          ) : (
            <StyledSegment style={{ width: '100%' }}>
              <EmptyDataPage
                maxHeight="100px"
                layout="row"
                i18nHeaderText={t({ id: 'no-url-form-field' })}
                style={{ margin: 'auto' }}
                actionComponent={
                  <span>
                    <Trans id="no-url-form-field-description" />
                    <br />
                    <Trans>
                      Naviguez vers
                      <RouterLink to="../administrate/url-form">
                        <Link base="true">
                          l&apos;administration de la campagne
                        </Link>
                      </RouterLink>{' '}
                      pour créer des champs de formulaire.
                    </Trans>
                  </span>
                }
              />
            </StyledSegment>
          )}
          {isFeedbackCampaign ? (
            <FacetCreateField
              name={<Trans render={capitalizedTranslation} id="concept" />}
              input={
                <StyledDropdown
                  upward
                  search
                  selection
                  clearable
                  options={concepts}
                  disabled={false}
                  value={formValues?.concept || ''}
                  onChange={onConceptValueChange}
                />
              }
            />
          ) : (
            <ChannelQuestionFilter campaignId={campaignId} />
          )}
        </Grid>
      </div>
      <Divider style={{ margin: 0 }} />
      <ButtonLineLayout padded>
        <AnalyticsAwareButton
          gaCategory="Campaign - create channel"
          gaAction="Cancel channel creation"
          onClick={goToList}
          content={t({ id: 'cancel' })}
          data-testid="bo-campaign-channel-creation-cancel-button"
          inputComponent={ButtonTransparentDanger}
        />
        <AnalyticsAwareButton
          gaCategory="Campaign - create channel"
          gaAction="Create channel"
          disabled={!isComplete}
          loading={createLoading}
          onClick={onConfirm}
          content={<Trans id="create-channel" />}
          data-testid="bo-campaign-channel-creation-create-button"
          inputComponent={ButtonAccent}
          style={{ marginLeft: svars.spaceMediumLarge }}
        />
      </ButtonLineLayout>
    </PageLayout>
  );
}

export default CreateChannelPage;
