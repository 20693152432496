import React, { PureComponent } from 'react';

import { t } from '@lingui/macro';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import d3Cloud from 'd3-cloud';
import { ResponsiveContainer } from 'recharts';

import HelpTooltip from 'components/ui/HelpTooltip';
import SentimentScale from 'components/ui/SentimentScale';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import HelpcrunchHelpTooltip from 'components/ui/navigation/HelpcrunchHelpTooltip';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import { LoadingTagCloud } from 'components/ui/visualization/LoadingDataVisualization';

import { getInterpolatedColor, linearInterpolation } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';

import * as styleVariables from 'assets/style/variables';

// To validate : border removed when word is selected
const HoverableWord = styled.div`
  z-index: ${(props) => (props.selected ? '2' : '0')};
  box-shadow: ${(props) =>
    props.selected
      ? 'inset 0px 0px 5px 100px rgba(255,255,255,0.80), 10px 10px 5px 1000px rgba(255,255,255,0.80)'
      : 'none'};
  text-decoration: ${(props) => (props.selected ? 'underline' : 'none')};

  &:hover,
  &:active {
    transform: scale(1.5);
    text-decoration: underline;
    text-decoration-thickness: 3px;
  }
`;

const WORDCLOUD_EXPORT_ID = 'explore-wordcloud';

/**
 * Word cloud for React.js using d3.js
 * Inspired from : https://github.com/IjzerenHein/react-tag-cloud
 *
 * @class TagCloud
 * @extends {PureComponent}
 */
class ClickableTagCloud extends PureComponent {
  static text = (word) => {
    let { text } = word.child.props;

    if (!text) {
      const { children } = word.child.props;

      if (Array.isArray(children)) {
        [text] = children;
      } else {
        text = children;
      }
    }
    return text;
  };

  constructor(props) {
    super(props);
    this.width = props.width || 0;
    this.height = props.height || 0;
    this.state = {
      wrappedChildren: [],
      hoveredWord: undefined,
    };
    // Creating methods to parametrize d3-cloud
    this.text = this.constructor.text.bind(this);
    this.fontFamily = this.getStyleValue.bind(this, 'fontFamily');
    this.fontWeight = this.getStyleValue.bind(this, 'fontWeight');
    this.fontStyle = this.getStyleValue.bind(this, 'fontStyle');
    this.padding = this.getStyleValue.bind(this, 'padding');
    this.rotate = this.rotate.bind(this);

    this.updateLayout = this.updateLayout.bind(this);
    this.resizeTimer = debounce(() => {
      this.updateLayout(this.props);
    }, 200);
    if (props.data) this.updateLayout(props);
  }

  componentDidUpdate(prevProps) {
    const { data, width, height } = this.props;
    if (data) {
      if (data !== prevProps.data) {
        this.updateLayout(this.props);
      }
      if (width !== prevProps.width || height !== prevProps.height) {
        this.updateLayout(this.props);
        this.resizeTimer();
      }
    }
  }

  onWordMouseLeave = () => {
    const { hoveredWord } = this.state;
    return hoveredWord && this.setState({ hoveredWord: null });
  };

  onHoverableWordClick = (word) => () => {
    const { onWordClick } = this.props;
    const { wrappedChildren } = this.state;
    const nextSelectedWord = word;
    onWordClick(nextSelectedWord);
    const newWrappedChildren = [];
    wrappedChildren.forEach((wordElement) => {
      let element = wordElement;
      if (wordElement.props.selected) {
        element = React.cloneElement(wordElement, { selected: false });
      } else if (wordElement.key === word.text) {
        element = React.cloneElement(wordElement, { selected: true });
      }
      newWrappedChildren.push(element);
    });
    this.setState({ wrappedChildren: newWrappedChildren });
  };

  getStyleValue(propName, word) {
    const { style } = this.props;
    const childValue = word.child.props.style
      ? word.child.props.style[propName]
      : undefined;
    let value =
      childValue ||
      style[propName] ||
      ClickableTagCloud.defaultProps.style[propName];
    if (typeof value === 'function') {
      value = value(word.child.props);
    }
    if (propName === 'fontSize') value += 2;
    return value;
  }

  static getWordFontSize = (value) =>
    Math.max(
      // Minimum font size (not supposed to grow below but it would if value < 0)
      11,
      Math.min(
        // Piecewise affine function - more spread in font size for lower values
        14 + 16 * value,
        // Maximum font size (not supposed to grow above but it would if value > 1)
        49
      )
    );

  updateLayout(props) {
    const { width, height } = this;
    if (!width || !height) {
      return;
    }

    this.calculateLayout(props).then((children) => {
      this.setState({
        wrappedChildren: children,
      });
    });
  }

  calculateLayout(props) {
    const { spiral, random, data, selectedWord, style, onWordClick } = props;
    const { width, height } = this;
    const children = data.map((word) => {
      const wordStyles = {
        fontSize: ClickableTagCloud.getWordFontSize(word.value),
        borderRadius: styleVariables.ctaBorderRadius,
        color: getInterpolatedColor(
          word.sentiment,
          linearInterpolation,
          styleVariables.wordCloudAbsoluteMinColor,
          styleVariables.wordCloudAbsoluteMaxColor,
          styleVariables.wordCloudAbsoluteMidColor
        ),
        fontWeight: 400 + 500 * word.value,
        cursor: onWordClick ? 'pointer' : 'default',
        userSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
      };
      return onWordClick ? (
        <HoverableWord
          style={wordStyles}
          selected={selectedWord && word.text === selectedWord.text}
          key={`wd-${word.text}`}
          onMouseEnter={() => {
            this.setState({ hoveredWord: word });
          }}
          onMouseLeave={this.onWordMouseLeave}
          onClick={this.onHoverableWordClick(word)}
        >
          {word.text}
        </HoverableWord>
      ) : (
        <div key={`wd-${word.text}`} style={wordStyles}>
          {word.text}
        </div>
      );
    });
    return new Promise((resolve) => {
      d3Cloud()
        .size([width, height])
        .words(React.Children.map(children, (child) => ({ child })))
        .font(this.fontFamily)
        .fontStyle(this.fontStyle)
        .fontWeight(this.fontWeight)
        .fontSize((item) => item.child.props.style.fontSize + 1)
        .text(this.text)
        .padding(this.padding)
        .rotate(this.rotate)
        .spiral(spiral)
        .random(random)
        .on('end', (items) => {
          const newChildren = items.map((item, index) => {
            let { x } = item;
            x += item.x0;
            x += width / 2;
            let { y } = item;
            y += item.y0;
            y += height / 2;
            const childStyle = {
              position: 'absolute',
              ...item.child.props.style,
              fontFamily: item.font,
              fontWeight: item.weight,
              fontStyle: item.style,
              fontSize: item.child.props.style.fontSize,
              transform: `translate(${x}px,${y}px) rotate(${item.rotate}deg)`,
              width: item.width,
              // padding: item.height / 9,
              margin: '1px',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              transformOrigin: 'center bottom',
            };
            if (
              !childStyle.color &&
              style.color &&
              typeof style.color === 'function'
            ) {
              childStyle.color = style.color(item.child, index);
            }
            return React.cloneElement(
              item.child,
              {
                ...item.child.props,
                key: item.text,
                style: childStyle,
              },
              item.child.props.children
            );
          });
          resolve(newChildren);
        })
        .start();
    });
  }

  rotate(word) {
    const { rotate } = this.props;
    const value =
      word.child.props.rotate ||
      rotate ||
      ClickableTagCloud.defaultProps.rotate;
    if (typeof value === 'function') {
      return value(word.child.props);
    }
    return value;
  }

  render() {
    const {
      helpMessage,
      containerStyle,
      selectedWord,
      loading,
      sentimentRange,
      chartTitle,
      showScale,
      exportable,
    } = this.props;
    const { wrappedChildren, hoveredWord } = this.state;
    if (loading) return <LoadingTagCloud />;
    if (!(wrappedChildren && wrappedChildren.length)) {
      return <EmptyDataVisualization />;
    }
    const cursorSentiment =
      (hoveredWord && hoveredWord.sentiment) ||
      (selectedWord && selectedWord.sentiment) ||
      null;
    return (
      <div style={containerStyle}>
        {(exportable || showScale || helpMessage) && (
          <span
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <div style={{ zIndex: 3 }}>
              {helpMessage && (
                <span
                  style={{
                    position: 'fixed',
                    margin: styleVariables.spaceMedium,
                    zIndex: 3,
                  }}
                >
                  <HelpTooltip size="small" help={helpMessage} />
                </span>
              )}
              {showScale && (
                <SentimentScale
                  id="wc"
                  cursorSentiment={cursorSentiment}
                  minSentiment={sentimentRange[0]}
                  maxSentiment={sentimentRange[1]}
                />
              )}
              <HelpcrunchHelpTooltip
                position="right center"
                helpTitle={t({ id: 'wordcloud' })}
                helpText={t({ id: 'wordcloud-help-crunch-text' })}
                articleId={22}
                helpcrunchHelpTooltipTestId="bo-helpcrunch-article-22"
              />
            </div>
            {exportable && (
              <ExportAsImage
                style={{ zIndex: 3 }}
                exportId={WORDCLOUD_EXPORT_ID}
                exportName={chartTitle}
                disabled={loading}
              />
            )}
          </span>
        )}
        <span>{wrappedChildren}</span>
      </div>
    );
  }
}

ClickableTagCloud.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(commonPropTypes.word.isRequired),
  selectedWord: commonPropTypes.word,
  style: commonPropTypes.style,
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: commonPropTypes.style,
  rotate: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  // eslint-disable-next-line react/no-unused-prop-types
  spiral: PropTypes.oneOfType([
    PropTypes.oneOf(['archimedean', 'rectangular']),
    PropTypes.func,
  ]),
  // eslint-disable-next-line react/no-unused-prop-types
  random: PropTypes.func,
  helpMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onWordClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  loading: PropTypes.bool,
  sentimentRange: PropTypes.arrayOf(PropTypes.number),
  showScale: PropTypes.bool,
  exportable: PropTypes.bool,
  // Chart title - used only to name export file
  chartTitle: PropTypes.string,
};

ClickableTagCloud.defaultProps = {
  data: null,
  selectedWord: undefined,
  style: {
    fontFamily: 'serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    padding: 1,
  },
  containerStyle: {},
  rotate: () => 0,
  spiral: 'rectangular',
  random: Math.random,
  helpMessage: null,
  onWordClick: undefined,
  loading: false,
  sentimentRange: [-1.0, 1.0],
  chartTitle: undefined,
  showScale: false,
  exportable: false,
};

function ResponsiveClickableTagCloud({ width, height, ...otherProps }) {
  return (
    <ResponsiveContainer width={width} height={height} id={WORDCLOUD_EXPORT_ID}>
      <ClickableTagCloud width={width} height={height} {...otherProps} />
    </ResponsiveContainer>
  );
}

ResponsiveClickableTagCloud.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
ResponsiveClickableTagCloud.defaultProps = {
  height: '100%',
  width: '100%',
};

export default ResponsiveClickableTagCloud;
