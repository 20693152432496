import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  addQuestionDisplayCondition,
  addQuestionOption,
  deleteAnswer,
  deleteQuestionDisplayCondition,
  reorderAnswers,
  surveyAddOptionLoadingSelector,
  updateAnswerScore,
  updateAnswerText,
  updateQuestionDescription,
  updateQuestionTitle,
} from 'actions/survey';
import {
  DATE_QUESTION_ITEM,
  NPS_QUESTION_ITEM,
  RATING_QUESTION_ITEM,
  isMultichoiceQuestionType,
  questionSelectorFactory,
} from 'selectors/survey';

import StyledSegment from 'components/ui/Segment';
import { TabButton } from 'components/ui/button/TabButton';
import {
  AnalyticsAwareHoverableIconButtonWithTooltip,
  HoverableIconButton,
} from 'components/ui/icon/HoverableIcon';
import DragAndDropList from 'components/ui/inputs/DragAndDropList';
import RichTextEditor from 'components/ui/inputs/RichTextEditor';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import NPSQuestionDummy, {
  DateQuestionDummy,
  RatingQuestionDummy,
} from './QuestionDummies';
import DisplayLogicEditorWithModal from './logic/DisplayLogicEditorModal';

const ScoreInput = styled.input`
  min-width: 80px;
  border-radius: ${svars.ctaBorderRadius};
  border-color: ${({ disabled }) =>
    disabled ? svars.borderColorLight : svars.accentColorClearMedium};
  padding: ${svars.spaceSmaller};
  padding-right: ${svars.spaceXLarge};
  margin-left: ${svars.spaceNormalLarge};
`;

const renderAnswerOptionFactory = (
  onTextChange,
  onScoreChange,
  onDelete,
  withScoring
) =>
  function renderAnswerOption(option, index, disabled) {
    const displayIndex = index + 1;
    const scoreDisabled = option?.score === null;
    const onScoreInputBlur = (e) => {
      // If score is empty, set it to null
      if (e.target.value === '') {
        onScoreChange(option?.id)({ target: { value: null } });
      }
    };
    const onClick = () => {
      onScoreChange(option?.id)({
        target: { value: scoreDisabled ? 1 : null },
      });
      if (scoreDisabled) {
        // autofocus on the score input
        const input = document.getElementById(`score-${option?.id}`);
        if (input) {
          input.focus();
        }
      }
    };
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <RichTextEditor
          placeholder={t`response-${displayIndex}`}
          singleLine
          onChange={onTextChange(option?.id)}
          content={option?.display_name}
          editable={!disabled}
        />
        {withScoring ? (
          <>
            <ScoreInput
              type="number"
              min="-100"
              max="100"
              step={1}
              disabled={scoreDisabled || disabled}
              onChange={onScoreChange(option?.id)}
              value={option?.score ?? ''}
              onBlur={onScoreInputBlur}
            />
            <AnalyticsAwareHoverableIconButtonWithTooltip
              danger={!scoreDisabled}
              disabled={disabled}
              mouseEnterDelay={200}
              focus={!scoreDisabled}
              name={
                scoreDisabled ? 'plus square outline' : 'minus square outline'
              }
              id={`score-${option?.id}`}
              onClick={onClick}
              help={
                scoreDisabled
                  ? t({ id: 'multichoice-question-option.activate-score' })
                  : t({ id: 'multichoice-question-option.deactivate-score' })
              }
              style={{
                marginLeft: `calc(-${svars.spaceLarge} - 0.1rem)`,
              }}
              tabIndex="-1"
            />
          </>
        ) : null}
        <HoverableIconButton
          tabIndex="-1"
          name="delete"
          danger
          onClick={onDelete?.(option?.id)}
          disabled={!onDelete || disabled}
        />
      </div>
    );
  };

function MultichoiceQuestionEdition({
  campaignId,
  questionId,
  question,
  disabled,
}) {
  const dispatch = useDispatch();
  const loading = useSelector(surveyAddOptionLoadingSelector);
  const onAnswerTextChange = useCallback(
    (answerId) => (newContent) =>
      dispatch(updateAnswerText(campaignId, questionId, answerId, newContent)),
    [dispatch, questionId, campaignId]
  );
  const onAnswerScoreChange = useCallback(
    (answerId) => (e) => {
      dispatch(
        updateAnswerScore(campaignId, questionId, answerId, e.target.value)
      );
    },
    [dispatch, questionId]
  );
  const onDeleteAnswer = useCallback(
    (answerId) => () =>
      dispatch(deleteAnswer(campaignId, questionId, answerId)),
    [dispatch, questionId, campaignId]
  );
  const onAddAnswerOption = useCallback(() => {
    dispatch(addQuestionOption(campaignId, questionId));
  }, [dispatch, campaignId, questionId]);
  const onReorderAnswers = useCallback(
    (newOrder) => dispatch(reorderAnswers(campaignId, questionId, newOrder)),
    [dispatch, campaignId, questionId]
  );
  const renderAnswerOption = useMemo(
    () =>
      renderAnswerOptionFactory(
        onAnswerTextChange,
        onAnswerScoreChange,
        question?.options?.length > 1 ? onDeleteAnswer : null,
        question?.with_scoring
      ),
    [onAnswerTextChange, onAnswerScoreChange, onDeleteAnswer, question]
  );

  return (
    <>
      <DragAndDropList
        elements={question?.options || []}
        onChange={onReorderAnswers}
        getElementId={(item) => item?.id}
        renderElement={renderAnswerOption}
        disabled={disabled || loading}
        style={{ paddingTop: svars.spaceMediumLarge }}
      />
      <TabButton
        data-testid="bo-campaign-add-answer-option"
        disabled={disabled || loading}
        icon="add"
        style={{
          display: 'flex',
          boxSizing: 'initial',
          margin: `0 auto`,
          marginTop: svars.spaceNormal,
          alignSelf: 'center',
        }}
        content={t({ id: 'add-answer-option' })}
        onClick={onAddAnswerOption}
      />
    </>
  );
}

MultichoiceQuestionEdition.propTypes = {
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  question: commonPropTypes.question.isRequired,
  disabled: PropTypes.bool,
};
MultichoiceQuestionEdition.defaultProps = { disabled: false };

const QuestionTextEditorsContainer = styled.div`
  flex-shrink: 1;
  min-height: calc(2 * ${svars.richTextEditorMinHeight});
  /* max-height: ${({ shrink }) => (shrink ? '60%' : '100%')}; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

function QuestionEditor({ questionId, loading }) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const question = useSelector(
    questionSelectorFactory(campaignId, questionId, true)
  );
  const onTitleChange = useCallback(
    (newContent) =>
      dispatch(updateQuestionTitle(campaignId, questionId, newContent)),
    [dispatch, questionId, campaignId]
  );
  const onDescriptionChange = useCallback(
    (newContent) =>
      dispatch(updateQuestionDescription(campaignId, questionId, newContent)),
    [dispatch, questionId, campaignId]
  );
  const onAddDisplayCondition = useCallback(
    (selectedQuestionId, selectedConditionIndex, values) => {
      dispatch(
        addQuestionDisplayCondition(
          campaignId,
          questionId,
          selectedQuestionId,
          selectedConditionIndex,
          values
        )
      );
    },
    [dispatch, campaignId, questionId]
  );
  const onDeleteDisplayCondition = useCallback(
    (conditionIndex) => {
      dispatch(
        deleteQuestionDisplayCondition(campaignId, questionId, conditionIndex)
      );
    },
    [dispatch, campaignId, questionId]
  );

  if (!question) return null;
  const isMultichoiceQuestion = isMultichoiceQuestionType(question?.type);
  return (
    <>
      <StyledSegment
        style={{
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 1,
        }}
      >
        <QuestionTextEditorsContainer
          shrink={isMultichoiceQuestion ? 'true' : null}
        >
          <RichTextEditor
            key={`title-${question?.id}`}
            prefix={question?.index}
            content={question?.title}
            placeholder={t({ id: 'title-input-placeholder' })}
            onChange={onTitleChange}
            // editable={!disabled}
            hasSubtitle
            withTextAlign
            withLink
            strong
          />
          <RichTextEditor
            key={`desc-${question?.id}`}
            content={question?.description}
            placeholder={t({ id: 'description-input-placeholder' })}
            onChange={onDescriptionChange}
            // editable={!disabled}
            isSubtitle
            withTextAlign
            withLink
          />
        </QuestionTextEditorsContainer>
        {(question?.type === NPS_QUESTION_ITEM.value && <NPSQuestionDummy />) ||
          (question?.type === DATE_QUESTION_ITEM.value && (
            <DateQuestionDummy />
          )) ||
          (question?.type === RATING_QUESTION_ITEM.value && (
            <RatingQuestionDummy
              nElements={question.n_elements}
              shape={question.shape}
            />
          )) ||
          (isMultichoiceQuestion && (
            <MultichoiceQuestionEdition
              campaignId={campaignId}
              questionId={questionId}
              question={question}
              disabled={loading}
            />
          )) ||
          (question?.type === 'TextSurveyQuestion' && null) ||
          null}
      </StyledSegment>
      <DisplayLogicEditorWithModal
        subHeaderLabel={t({ id: 'display-question-if' })}
        campaignId={campaignId}
        currentQuestionId={questionId}
        conditions={question?.conditions}
        addCondition={onAddDisplayCondition}
        deleteCondition={onDeleteDisplayCondition}
      />
    </>
  );
}

QuestionEditor.propTypes = {
  questionId: PropTypes.string,
  loading: PropTypes.bool,
};
QuestionEditor.defaultProps = {
  questionId: null,
  loading: false,
};

export default QuestionEditor;
