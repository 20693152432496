import { PureComponent } from 'react';

import { Trans } from '@lingui/macro';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';

import { Surface, Symbols } from 'recharts';

import HelpTooltip from 'components/ui/HelpTooltip';

import * as svars from 'assets/style/variables';

const ICON_SIZE = 32;

const EventKeys = [
  'children',
  'dangerouslySetInnerHTML',
  'onCopy',
  'onCopyCapture',
  'onCut',
  'onCutCapture',
  'onPaste',
  'onPasteCapture',
  'onCompositionEnd',
  'onCompositionEndCapture',
  'onCompositionStart',
  'onCompositionStartCapture',
  'onCompositionUpdate',
  'onCompositionUpdateCapture',
  'onFocus',
  'onFocusCapture',
  'onBlur',
  'onBlurCapture',
  'onChange',
  'onChangeCapture',
  'onBeforeInput',
  'onBeforeInputCapture',
  'onInput',
  'onInputCapture',
  'onReset',
  'onResetCapture',
  'onSubmit',
  'onSubmitCapture',
  'onInvalid',
  'onInvalidCapture',
  'onLoad',
  'onLoadCapture',
  'onError',
  'onErrorCapture',
  'onKeyDown',
  'onKeyDownCapture',
  'onKeyPress',
  'onKeyPressCapture',
  'onKeyUp',
  'onKeyUpCapture',
  'onAbort',
  'onAbortCapture',
  'onCanPlay',
  'onCanPlayCapture',
  'onCanPlayThrough',
  'onCanPlayThroughCapture',
  'onDurationChange',
  'onDurationChangeCapture',
  'onEmptied',
  'onEmptiedCapture',
  'onEncrypted',
  'onEncryptedCapture',
  'onEnded',
  'onEndedCapture',
  'onLoadedData',
  'onLoadedDataCapture',
  'onLoadedMetadata',
  'onLoadedMetadataCapture',
  'onLoadStart',
  'onLoadStartCapture',
  'onPause',
  'onPauseCapture',
  'onPlay',
  'onPlayCapture',
  'onPlaying',
  'onPlayingCapture',
  'onProgress',
  'onProgressCapture',
  'onRateChange',
  'onRateChangeCapture',
  'onSeeked',
  'onSeekedCapture',
  'onSeeking',
  'onSeekingCapture',
  'onStalled',
  'onStalledCapture',
  'onSuspend',
  'onSuspendCapture',
  'onTimeUpdate',
  'onTimeUpdateCapture',
  'onVolumeChange',
  'onVolumeChangeCapture',
  'onWaiting',
  'onWaitingCapture',
  'onAuxClick',
  'onAuxClickCapture',
  'onClick',
  'onClickCapture',
  'onContextMenu',
  'onContextMenuCapture',
  'onDoubleClick',
  'onDoubleClickCapture',
  'onDrag',
  'onDragCapture',
  'onDragEnd',
  'onDragEndCapture',
  'onDragEnter',
  'onDragEnterCapture',
  'onDragExit',
  'onDragExitCapture',
  'onDragLeave',
  'onDragLeaveCapture',
  'onDragOver',
  'onDragOverCapture',
  'onDragStart',
  'onDragStartCapture',
  'onDrop',
  'onDropCapture',
  'onMouseDown',
  'onMouseDownCapture',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseMoveCapture',
  'onMouseOut',
  'onMouseOutCapture',
  'onMouseOver',
  'onMouseOverCapture',
  'onMouseUp',
  'onMouseUpCapture',
  'onSelect',
  'onSelectCapture',
  'onTouchCancel',
  'onTouchCancelCapture',
  'onTouchEnd',
  'onTouchEndCapture',
  'onTouchMove',
  'onTouchMoveCapture',
  'onTouchStart',
  'onTouchStartCapture',
  'onPointerDown',
  'onPointerDownCapture',
  'onPointerMove',
  'onPointerMoveCapture',
  'onPointerUp',
  'onPointerUpCapture',
  'onPointerCancel',
  'onPointerCancelCapture',
  'onPointerEnter',
  'onPointerEnterCapture',
  'onPointerLeave',
  'onPointerLeaveCapture',
  'onPointerOver',
  'onPointerOverCapture',
  'onPointerOut',
  'onPointerOutCapture',
  'onGotPointerCapture',
  'onGotPointerCaptureCapture',
  'onLostPointerCapture',
  'onLostPointerCaptureCapture',
  'onScroll',
  'onScrollCapture',
  'onWheel',
  'onWheelCapture',
  'onAnimationStart',
  'onAnimationStartCapture',
  'onAnimationEnd',
  'onAnimationEndCapture',
  'onAnimationIteration',
  'onAnimationIterationCapture',
  'onTransitionEnd',
  'onTransitionEndCapture',
];

const getEventHandlerOfChild = (originalHandler, data, index) => (e) => {
  originalHandler(data, index, e);

  return null;
};

export const adaptEventsOfChild = (props, data, index) => {
  if (!isObject(props) || typeof props !== 'object') {
    return null;
  }

  let out = null;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const i in props) {
    const item = props[i];
    if (EventKeys.includes(i) && typeof item === 'function') {
      if (!out) out = {};
      out[i] = getEventHandlerOfChild(item, data, index);
    }
  }
  return out;
};

class CustomLegend extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static displayName = 'Legend';

  /**
   * Render the path of icon
   * @param {Object} data Data of each legend item
   * @return {String} Path element
   */
  renderIcon(data) {
    const { inactiveColor } = this.props;
    const halfSize = ICON_SIZE / 2;
    const sixthSize = ICON_SIZE / 6;
    const thirdSize = ICON_SIZE / 3;
    const color = data.inactive ? inactiveColor : data.color;

    if (data.type === 'plainline') {
      return (
        <line
          strokeWidth={4}
          fill="none"
          stroke={color}
          strokeDasharray={data.payload.strokeDasharray}
          x1={0}
          y1={halfSize}
          x2={ICON_SIZE}
          y2={halfSize}
          className="recharts-legend-icon"
        />
      );
    }
    if (data.type === 'line') {
      return (
        <path
          strokeWidth={4}
          fill="none"
          stroke={color}
          d={`M0,${halfSize}h${thirdSize}
            A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
            H${ICON_SIZE}M${2 * thirdSize},${halfSize}
            A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
          className="recharts-legend-icon"
        />
      );
    }
    if (data.type === 'rect') {
      return (
        <path
          stroke="none"
          fill={color}
          d={`M0,${ICON_SIZE / 8}h${ICON_SIZE}v${
            (ICON_SIZE * 3) / 4
          }h${-ICON_SIZE}z`}
          className="recharts-legend-icon"
        />
      );
    }

    return (
      <Symbols
        fill={color}
        cx={halfSize}
        cy={halfSize}
        size={ICON_SIZE}
        sizeType="diameter"
        type={data.type}
        // type={data.type as SymbolType}
      />
    );
  }

  renderLegendItem = (
    i,
    entry,
    formatter,
    itemStyle,
    iconSize,
    viewBox,
    svgStyle
  ) => {
    const finalFormatter = entry.formatter || formatter;
    const classNames = `recharts-legend-item legend-item-${i} ${
      entry.inactive ? 'inactive' : ''
    }`;

    if (entry.type === 'none') {
      return null;
    }

    return (
      <li
        className={classNames}
        style={itemStyle}
        key={`legend-item-${i}`} // eslint-disable-line react/no-array-index-key
        {...adaptEventsOfChild(this.props, entry, i)}
      >
        <Surface
          width={iconSize}
          height={iconSize}
          viewBox={viewBox}
          style={svgStyle}
        >
          {this.renderIcon(entry)}
        </Surface>
        <span
          className="recharts-legend-item-text"
          style={{ lineHeight: 1.05 }}
        >
          {finalFormatter ? finalFormatter(entry.value, entry, i) : entry.value}
        </span>
      </li>
    );
  };

  /**
   * Draw items of legend
   * @return {ReactElement} Items
   */
  renderItems() {
    const { payload, iconSize, layout, formatter, maxItems } = this.props;
    const viewBox = { x: 0, y: 0, width: ICON_SIZE, height: ICON_SIZE };
    const itemStyle = {
      display: layout === 'horizontal' ? 'inline-block' : 'block',
      marginRight: 2,
      lineHeight: 0.9,
      marginBottom: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // whiteSpace: 'pre',
      maxWidth: '100%',
    };
    const svgStyle = {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 4,
    };
    const nHiddenLegendItems = maxItems ? payload.length - maxItems + 1 : 0;
    const shouldHideLegendItems = nHiddenLegendItems > 1;
    const legendItems = (
      shouldHideLegendItems ? payload.slice(0, maxItems - 1) : payload
    ).map((entry, i) =>
      this.renderLegendItem(
        i,
        entry,
        formatter,
        itemStyle,
        iconSize,
        viewBox,
        svgStyle
      )
    );
    if (shouldHideLegendItems) {
      legendItems.push(
        <li
          className="recharts-legend-item legend-item-2"
          style={itemStyle}
          key="extra-legend-items"
        >
          <span data-html2canvas-ignore="true">
            <HelpTooltip
              triggerText={
                <>
                  + {nHiddenLegendItems} <Trans id="additional-elements" />
                </>
              }
              size="tiny"
              position="top right"
              mouseEnterDelay={200}
              help={
                <ul
                  className="recharts-default-legend"
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: svars.fontSizeSmall,
                  }}
                >
                  {payload
                    .slice(maxItems - 1)
                    .map((entry, i) =>
                      this.renderLegendItem(
                        maxItems - 1 + i,
                        entry,
                        formatter,
                        itemStyle,
                        iconSize,
                        viewBox,
                        svgStyle
                      )
                    )}
                </ul>
              }
            />
          </span>
        </li>
      );
    }
    return legendItems;
  }

  render() {
    const { payload } = this.props;
    if (!payload || !payload.length) {
      return null;
    }

    const finalStyle = {
      padding: 0,
      margin: 0,
      // textAlign: layout === 'horizontal' ? align : 'left',
      overflowY: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'left',
      whiteSpace: 'pre',
    };

    return (
      <ul className="recharts-default-legend" style={finalStyle}>
        {this.renderItems()}
      </ul>
    );
  }
}

const legendPropType = PropTypes.oneOf([
  'plainline',
  'line',
  'square',
  'rect',
  'circle',
  'cross',
  'diamond',
  'star',
  'triangle',
  'wye',
  'none',
]);

CustomLegend.propTypes = {
  // Maximum number of legend items to display
  maxItems: PropTypes.number,
  content: PropTypes.node,
  iconSize: PropTypes.number,
  iconType: legendPropType,
  layout: PropTypes.oneOf(['horizontal', 'vertical', 'centric', 'radial']),
  align: PropTypes.oneOf(['center', 'left', 'right']),
  verticalAlign: PropTypes.oneOf(['top', 'bottom', 'middle']),
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      id: PropTypes.string,
      type: legendPropType,
      color: PropTypes.string,
      payload: PropTypes.shape({
        strokeDasharray: PropTypes.string,
      }),
      formatter: PropTypes.func,
      inactive: PropTypes.bool,
    })
  ),
  inactiveColor: PropTypes.string,
  formatter: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
};

CustomLegend.defaultProps = {
  payload: undefined,
  maxItems: null,
  content: undefined,
  formatter: undefined,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  onClick: () => null,
  iconSize: 14,
  layout: 'horizontal',
  align: 'center',
  verticalAlign: 'middle',
  inactiveColor: '#ccc',
  iconType: undefined,
};

export default CustomLegend;
