import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';
import {
  accentColor,
  accentColorDisabled,
  colorPrimary,
  disabledOpacity,
  fontColorBase,
  fontSizeBase,
  fontWeightLight,
} from 'assets/style/variables';

export default styled.label`
  font-weight: ${fontWeightLight};
  color: ${fontColorBase};
`;

export const PrimaryLabel = styled.label`
  &&&&& {
    background-color: transparent;
    color: ${colorPrimary};
  }
`;

const TransparenLabelContainer = styled(Label)`
  &&& {
    background-color: transparent;
    opacity: ${(props) => (props.disabled ? disabledOpacity : 1)};
    color: ${(props) => (props.disabled ? accentColorDisabled : accentColor)};

    & * {
      color: ${(props) => (props.disabled ? accentColorDisabled : accentColor)};
    }
  }
`;

export function TransparentLabel({ label, disabled, help }) {
  return (
    <TransparenLabelContainer disabled={disabled}>
      <span style={{ fontSize: fontSizeBase, paddingRight: '0.7rem' }}>
        {label}
      </span>
      {help ? <HelpTooltip size="small" help={help} /> : null}
    </TransparenLabelContainer>
  );
}
TransparentLabel.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

TransparentLabel.defaultProps = {
  label: undefined,
  disabled: false,
  help: undefined,
};
export const WarningLabel = styled(Label)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${svars.fontColorBase} !important;
    font-weight: ${svars.fontWeightMedium};
    min-width: ${svars.smallButtonMinWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & .icon {
      margin: 0;
      padding-left: ${svars.spaceNormalLarge};
      font-size: ${svars.fontSizeMedium};
      line-height: 1.1;
    }
    &:hover {
      color: ${svars.colorDangerDisabled} !important;
      & .icon {
        color: ${svars.colorDangerDisabled};
      }
    }
    transition: ${svars.transitionBase};
    ${svars.hoverClickableCss}
  }
`;

const LabelContainer = styled.span`
  background: ${({ danger, accent, deactivated }) =>
    (danger && svars.colorDangerLightest) ||
    (accent && svars.accentColorMedium) ||
    (deactivated && svars.colorLightGrey) ||
    svars.colorYellowWarningLight};
  border: ${({ danger, accent, deactivated }) =>
    `1px solid ${
      (danger && svars.colorDangerLighter) ||
      (accent && svars.accentColorLight) ||
      (deactivated && svars.colorGreyMedium) ||
      svars.colorYellowWarning
    }`};
  padding: ${({ small }) =>
    small
      ? `${svars.spaceXSmall} ${svars.spaceNormal};`
      : `${svars.spaceSmall} ${svars.spaceNormalLarge}`};
  font-size: ${({ small }) =>
    small ? svars.fontSizeSmaller : svars.fontSizeMedium};
  font-weight: ${svars.fontWeightMedium};
  line-height: 1.1;
  color: ${svars.fontColorBase};
  border-radius: 50px;
`;

export function TagLabel({ text, children, small, ...props }) {
  return (
    <LabelContainer small={small ? '1' : null} {...props}>
      {children || <Trans id={text} />}
    </LabelContainer>
  );
}

TagLabel.propTypes = {
  text: commonPropTypes.i18nText,
  small: PropTypes.bool,
  children: PropTypes.node,
};

TagLabel.defaultProps = {
  text: null,
  children: null,
  small: false,
};

export function DefaultLabel(props) {
  return <TagLabel text={t({ id: 'default' })} accent="1" {...props} />;
}

export function NoConditionLabel(props) {
  return <TagLabel text={t({ id: 'not-used' })} danger="1" {...props} />;
}

export function ConditionedLabel(props) {
  return <TagLabel text={t({ id: 'conditioned' })} {...props} />;
}

export function DeactivatedLabel(props) {
  return (
    <TagLabel text={t({ id: 'deactivated' })} deactivated="1" {...props} />
  );
}

export function ActiveLabel(props) {
  return <TagLabel text={t({ id: 'active' })} accent="1" {...props} />;
}
