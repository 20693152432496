import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import * as styleVariables from 'assets/style/variables';

// eslint-disable-next-line import/prefer-default-export
export const NoMarginGrid = styled(Grid)`
  &&& {
    display: ${(props) => (props.hidden ? 'none' : 'inherit')};
    margin: 0 !important;
    width: 100% !important;
  }
`;

const makePaddedRow = (padding) => styled(Grid.Row)`
  &&& {
    padding: ${padding};
  }
`;

const makePaddedGrid = (padding) => styled(NoMarginGrid)`
  &&& {
    padding: ${padding};
  }
`;

export const GridRowNoPadding = makePaddedRow(0);
export const SmallSpacePaddedGrid = makePaddedGrid(styleVariables.spaceSmall);
export const NormalLargerSpacePaddedGrid = makePaddedGrid(
  styleVariables.spaceNormalLarge
);

export const SmallSpacePaddedRow = makePaddedRow(styleVariables.spaceSmall);
export const MediumSpacePaddedRow = makePaddedRow(styleVariables.spaceMedium);
export const LargeSpacePaddedRow = makePaddedRow(styleVariables.spaceLarge);

NoMarginGrid.Row = makePaddedRow(0);
