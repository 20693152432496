import React, { useCallback, useEffect, useMemo } from 'react';
import { useExpanded, useFlexLayout, useSortBy, useTable } from 'react-table';

import { msg } from '@lingui/macro';
import sum from 'lodash/sum';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import BeatingLoader from 'components/ui/BeatingLoader';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import emptyTableUrl from 'components/ui/svg/undraw_no_data_re_kwbl.svg';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const makeHeaderCell = (sortable) => {
  function HeaderCell({ getHeaderGroupProps, headers }) {
    const isSorted = (isSortedDesc) => (
      <Icon
        style={{
          margin: 0,
          color: svars.accentColor,
          fontSize: svars.fontSizeLarge,
        }}
        name={isSortedDesc ? 'caret down' : 'caret up'}
      />
    );
    return (
      // Applying the header row props
      <div {...getHeaderGroupProps()}>
        {
          // Looping over the headers in each row
          headers.map((column) => {
            const sortedIcon =
              column.isSorted && column.sortable !== false
                ? isSorted(column.isSortedDesc)
                : '';
            return column.resizable === false ? (
              <RowHeaderCellContainer
                {...column.getHeaderProps(
                  ...(sortable ? [column.getSortByToggleProps()] : [])
                )}
                sortable={sortable}
                isSorted={!!sortedIcon}
                centered={column.centered}
                title=""
              >
                {column.Header ? column.render('Header') : null}
                <div style={{ alignSelf: 'center' }}>{sortedIcon}</div>
              </RowHeaderCellContainer>
            ) : (
              <RowHeaderCellContainer
                isSorted={!!sortedIcon}
                {...column.getHeaderProps(
                  ...(sortable ? [column.getSortByToggleProps()] : [])
                )}
                centered={column.centered}
                title=""
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: column.centered ? 'center' : 'flex-start',
                  }}
                  className="rt-resizable-header-content"
                >
                  {column.Header ? column.render('Header') : null}
                  <div>{sortedIcon}</div>
                </div>
                <div
                  {...(column.getResizerProps ? column.getResizerProps() : {})}
                  className="rt-resizer"
                />
              </RowHeaderCellContainer>
            );
          })
        }
      </div>
    );
  }
  HeaderCell.propTypes = {
    headers: PropTypes.objectOf(
      PropTypes.shape({
        getHeaderProps: PropTypes.func,
        render: PropTypes.func,
        getResizerProps: PropTypes.func,
      })
    ).isRequired,
    getHeaderGroupProps: PropTypes.func.isRequired,
  };
  return HeaderCell;
};

const SortableHeaderCell = makeHeaderCell(true);
const BasicHeaderCell = makeHeaderCell(false);

function SubcomponentCell({ row: { getToggleRowExpandedProps } }) {
  return (
    // Applying the toggle expander props i.e onClick, style and title
    <RowCell
      {...getToggleRowExpandedProps()}
      title="Click here to see more information"
      className="rt-td rt-expandable p-0"
    >
      <div>•</div>
    </RowCell>
  );
}
SubcomponentCell.propTypes = {
  isExpanded: PropTypes.bool,
  row: PropTypes.shape({ getToggleRowExpandedProps: PropTypes.func }),
};

SubcomponentCell.defaultProps = { isExpanded: null, row: null };

export const columnPropTypes = PropTypes.shape({
  Header: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
  ]),
  id: PropTypes.string,
  resizable: PropTypes.bool,
  className: PropTypes.string,
  SubcomponentCell: PropTypes.func,
});

const makeCell = (getTdProps) => {
  function Cell({ getCellProps, row, render, column, ...otherProps }) {
    const extraCellProps = column.getCellProps
      ? column.getCellProps(row.original)
      : {};
    return (
      // Applying the cell props and rendering the cell content
      <RowCell
        {...getTdProps({ getCellProps, render, column, row, ...otherProps })}
        {...getCellProps()}
        {...extraCellProps}
        centered={column.centered}
      >
        {render('Cell', extraCellProps)}
      </RowCell>
    );
  }
  Cell.propTypes = {
    getCellProps: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    column: columnPropTypes.isRequired,
    row: PropTypes.shape().isRequired,
  };

  return Cell;
};

const RowGroup = styled.div`
  width: 100%;
`;
export const Row = styled.div`
  display: inline-flex !important;
  align-self: baseline;
  width: 100%;

  max-height: 180px;
  flex-grow: 0;
  border: 1px solid transparent;
  border-radius: ${svars.ctaBorderRadius};
  border-bottom-color: ${svars.borderColorLight};
  flex-grow: 0;
  padding: ${svars.spaceNormal} 0;
  transition: all 0.1s ease-out;
  ${({ selected }) => (selected ? svars.hoverClickableCss : '')}
  ${({ clickable, hoverable }) =>
    ((clickable || hoverable) &&
      css`
        cursor: ${clickable ? 'pointer' : 'default'};
        ${svars.hoverClickableCss}
        ${svars.activeClickableCss}
      `) ||
    ''}
`;

const TBody = styled.div`
  align-self: baseline;
  min-width: 100%;
  /* Make sure row appears above dimmer */
  z-index: 1;
`;
export const THead = styled.div`
  display: inline-flex;
  align-self: baseline;
  min-width: 100%;
  /* Sticky table header */
  position: sticky;
  top: 0;
  background: ${svars.colorLightestGrey};
  /* padding: 0 ${svars.spaceMediumLarge} 0 ${svars.spaceMedium}; */
  box-shadow: ${svars.primaryBoxShadow};
  z-index: 2;
`;

const TableContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  /* Setting overflow to hidden cause part of the table to be invisible in some settings */
  /* overflow: hidden; */
  border-radius: ${svars.borderRadius};
  border: solid 1px rgba(0, 0, 0, 0.05);
  background: white;
  box-shadow: ${svars.baseBoxShadow};
`;

const RowCell = styled.div`
  white-space: normal;
  word-break: break-word;
  border: none;
  display: grid;
  height: auto;
  justify-content: ${({ centered }) => (centered ? 'center' : 'normal')};
  align-content: center;
  margin: ${svars.spaceNormal};

  & span {
    margin: auto 0;
  }
`;
const RowHeaderCellContainer = styled.div`
  display: flex;

  ${({ centered }) => (centered ? 'justify-content: center;' : '')}
  & div {
    align-items: center;
  }

  & span {
    max-width: 100%;
    display: inline;
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* Adding text align centers the text even when text is multiline */
    ${({ centered }) => (centered ? 'text-align: center;' : '')}
  }
  margin: 0 ${svars.spaceNormal};
  align-self: center;
  border: none;
  box-shadow: none;
  ${({ isSorted }) => (isSorted ? `color: ${svars.accentColor};` : '')}
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  & .sort-asc {
    box-shadow: inset 0 2px 0 0 rgb(0 0 0 / 60%);
  }
  & .sort-desc {
    box-shadow: inset 0 2px 0 0 rgb(0 0 0 / 60%);
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  user-select: none;
  display: ${({ loading }) => (loading === 'true' ? 'block' : 'none')};
  transition: ${svars.transitionBase};
  width: 100%;
  height: 100%;
`;
LoadingOverlay.propTypes = { loading: PropTypes.string.isRequired };

function ReactTableUi({
  className,
  rowKey,
  data,
  columns,
  loading,
  getTdProps,
  selectedRow,
  onRowClick,
  SubComponent,
  defaultSorted,
  style,
  sortable,
  noHeader,
  noDataText,
  scrollOnSelect,
  hoverable,
}) {
  /* Defining default column width for every column */
  const defaultColumn = {
    minWidth: svars.defaultColumnMinWidth,
    maxWidth: svars.defaultColumnMaxWidth,
  };

  /* If the table is expandable then add the expander column */
  if (SubComponent) {
    columns.unshift({
      Header: '',
      id: 'expander',
      resizable: false,
      className: 'text-center',
      SubcomponentCell,
    });
  }
  const {
    getTableProps,
    setSortBy,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: data || [],
      defaultColumn,
      SubComponent,
      initialState: {
        sortBy: defaultSorted,
        hiddenColumns: columns
          .filter(({ show }) => show === false)
          .map(({ id, accessor }) => id || accessor),
      },
      disableSortRemove: true,
    },
    useFlexLayout,
    ...(sortable ? [useSortBy] : []),
    useExpanded
  );
  useEffect(() => {
    if (setSortBy) {
      setSortBy(defaultSorted);
    }
  }, [defaultSorted]);
  const tableMaxWidth = useMemo(
    () =>
      sum(
        columns.map(
          (column) => column.maxWidth || column.width || column.minWidth || 0
        )
      ) + 16,
    [columns]
  );
  const makeCellForColumn = makeCell(getTdProps);
  const onRowClickLoaded = useCallback(
    (row) => () => onRowClick && onRowClick(row.original),
    [onRowClick]
  );
  useEffect(() => {
    // If selected row is not empty and not visible, scroll to it
    if (selectedRow && rowKey) {
      const selectedRowElement = document.querySelector(
        `div[data-row-key="${selectedRow[rowKey]}"]`
      );
      if (selectedRowElement && scrollOnSelect) {
        selectedRowElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [selectedRow]);
  return (
    // Applying the table props
    <Container style={style} className={className} maxWidth={tableMaxWidth}>
      <TableContainer {...getTableProps}>
        {noHeader ? null : (
          <THead>
            {
              // Looping over the header rows
              headerGroups.map(sortable ? SortableHeaderCell : BasicHeaderCell)
            }
          </THead>
        )}
        {(!rows?.length && !loading && noDataText && (
          <EmptyDataPage
            i18nHeaderText={noDataText}
            illustrationUrl={emptyTableUrl}
            maxWidth="33%"
          />
        )) || (
          <TBody>
            {/* Applying the table body props */}
            <RowGroup {...getTableBodyProps()}>
              {
                // Looping over the table rows
                rows.map((row) => {
                  // Preparing row for rendering
                  prepareRow(row);

                  return (
                    <React.Fragment key={row.id}>
                      {/* Applying the row props and adding classname for alternate rows */}
                      <Row
                        data-row-key={row.original[rowKey]}
                        data-testid={`bo-row-testid-${row.id}`}
                        {...row.getRowProps()}
                        clickable={onRowClick ? 'true' : null}
                        hoverable={hoverable ? 'true' : null}
                        onClick={onRowClickLoaded(row)}
                        selected={
                          selectedRow &&
                          rowKey &&
                          selectedRow[rowKey] === row.original[rowKey]
                        }
                      >
                        {
                          // Looping over the row cells
                          row.cells.map(makeCellForColumn)
                        }
                      </Row>
                      {/* Checking row expansion  */}
                      {row.isExpanded ? SubComponent({ row }) : null}
                    </React.Fragment>
                  );
                })
              }
              <LoadingOverlay loading={`${loading}`}>
                <BeatingLoader />
              </LoadingOverlay>
            </RowGroup>
          </TBody>
        )}
      </TableContainer>
    </Container>
  );
}
ReactTableUi.propTypes = {
  rowKey: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
      ])
    )
  ),
  selectedRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  columns: PropTypes.arrayOf(columnPropTypes).isRequired,
  loading: PropTypes.bool,
  getTdProps: PropTypes.func,
  SubComponent: PropTypes.node,
  onRowClick: PropTypes.func,
  style: commonPropTypes.style,
  sortable: PropTypes.bool,
  className: PropTypes.string,
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool })
  ),
  // Whether the table header should be displayed
  noHeader: PropTypes.bool,
  // Displayed when data is empty
  noDataText: commonPropTypes.i18nText,
  // Whether to scroll to the selected row when selected
  scrollOnSelect: PropTypes.bool,
  // Whether rows should be highlighted on hover
  hoverable: PropTypes.bool,
};
ReactTableUi.defaultProps = {
  selectedRow: null,
  loading: false,
  getTdProps: () => {},
  SubComponent: null,
  onRowClick: null,
  style: {},
  data: [],
  sortable: true,
  defaultSorted: [],
  className: null,
  noHeader: false,
  rowKey: null,
  noDataText: msg({ id: 'no-data' }),
  scrollOnSelect: false,
  hoverable: false,
};

export default React.memo(ReactTableUi);
