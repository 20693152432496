import { createSelector } from '@reduxjs/toolkit';
import { actionTypes } from 'actions/view';
import { createLoadingSelector } from 'reducers/ui';
import { ontologiesSelector } from 'selectors/entities';

export const getFacetAggregatesConceptAggregates = (
  viewFacetAggregates,
  viewFacetId,
  conceptId
) => {
  const { conceptVisualizations } = viewFacetAggregates[viewFacetId];
  if (conceptVisualizations[conceptId]) {
    return conceptVisualizations[conceptId];
  }
  return {};
};

export const viewFacetAggregatesSelector = (state) =>
  state.view.viewFacetAggregates;

export const getConceptAggregatesSelector = createSelector(
  viewFacetAggregatesSelector,
  (viewFacetAggregates) => (viewFacetId, conceptId) =>
    getFacetAggregatesConceptAggregates(
      viewFacetAggregates,
      viewFacetId,
      conceptId
    )
);

export const viewFacetSelector = (state) => state.view.viewFacet;
export const viewFacetIdSelector = (state) => state.view.viewFacet?.id;

export const aggregatesSelector = createSelector(
  viewFacetIdSelector,
  viewFacetAggregatesSelector,
  (viewFacetId, viewFacetAggregates) =>
    viewFacetAggregates[viewFacetId]?.timeSeriesVisualizations
);

export const baseProductHierarchyGroupSelector = (state) =>
  state.entities.productHierarchyGroups?.[
    state.view.viewFacet?.base_product_hierarchy_group?.id
  ];
export const facetSourceGroupSelector = (state) =>
  state.entities.sourceGroups?.[state.view.viewFacet?.source_group?.id];

export const currentViewSelector = (state) => state.view.currentView;

export const ontologyTableDataSelector = (ontologyId) =>
  createSelector(
    ontologiesSelector(ontologyId),
    aggregatesSelector,
    (ontologies, aggregates) => {
      if (
        !aggregates?.conceptKpis ||
        !aggregates?.competitionConceptKpis ||
        !ontologies?.[ontologyId]
      )
        return null;
      const { competitionConceptKpis, conceptKpis } = aggregates || {};

      const data = [];
      Object.entries(ontologies[ontologyId]).forEach(([conceptId, concept]) => {
        const selectedConceptKpi = conceptKpis?.[ontologyId]?.[conceptId];
        if (selectedConceptKpi) {
          const competitionKpis =
            competitionConceptKpis.benchmark_kpis &&
            competitionConceptKpis.benchmark_kpis[ontologyId][conceptId]
              ? competitionConceptKpis.benchmark_kpis[ontologyId][conceptId][0]
              : {};

          data.push({
            conceptId: concept.id,
            name: concept.name,
            shareOfExtracts: selectedConceptKpi[0]?.share_of_extracts,
            competitionShareOfExtracts:
              competitionKpis?.competition_share_of_extracts,
            differentialSentiment:
              competitionKpis?.average_sentiment_differential,
            sentiment: selectedConceptKpi[0]?.average_sentiment,
          });
        }
      });
      return data;
    }
  );

export const fetchViewsLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_VIEWS_REQUEST,
]);
