import { Link as RouterLink } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import { HoverableIconButton } from 'components/ui/icon/HoverableIcon';

import * as svars from 'assets/style/variables';

function RowMenu({ items, disabled, upward }) {
  const finalGlobalDisabled =
    disabled || items.every(({ disabled: itemDisabled }) => itemDisabled);

  return (
    <Dropdown
      disabled={finalGlobalDisabled}
      direction="left"
      upward={upward}
      icon={
        <HoverableIconButton
          style={{
            fontSize: svars.fontSizeLarge,
            opacity: disabled ? 'initial' : null,
          }}
          name="ellipsis vertical"
          data-testid="bo-dropdown-menu-button"
          disabled={finalGlobalDisabled}
        />
      }
    >
      <Dropdown.Menu>
        {items.map(
          ({
            to,
            content,
            iconColor,
            icon,
            onClick,
            iconStyle,
            disabled: itemDisabled,
          }) => (
            <Dropdown.Item
              data-testid={`bo-dropdown-menu-buttons-${icon}`}
              key={`rmddmi-${content}`}
              as={RouterLink}
              to={to}
              onClick={onClick}
              content={capitalize(i18n._(content))}
              icon={<Icon color={iconColor} name={icon} style={iconStyle} />}
              disabled={itemDisabled}
            />
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

RowMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      content: PropTypes.string,
      iconColor: PropTypes.string,
      icon: PropTypes.string,
      iconStyle: PropTypes.shape,
      onClick: PropTypes.func,
    })
  ),

  disabled: PropTypes.bool,
  upward: PropTypes.bool,
};

RowMenu.defaultProps = {
  disabled: false,
  items: null,
  upward: false,
};

export default RowMenu;
