import { useSelector } from 'react-redux';

import { msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { viewFacetSourceItemsSelector } from 'selectors/entities';
import { viewFacetHierarchyGroupItemsSelector } from 'selectors/facet';

import CheckboxListFilter from 'components/ui/search/CheckboxListFilter';
import TextSearchFilter from 'components/ui/search/TextSearchFilter';

import { ViewFacetDateFilter } from './DatePeriodFilter';
import OntologyFilter from './OntologyFilter';
import ProductHierarchyFilter from './ProductHierarchyFilter';

const SENTIMENT_POLARITY_ITEMS = [
  {
    key: 'positives',
    i18nLabel: msg({ id: 'positive-sentiment' }),
    value: 'positives',
  },
  {
    key: 'negatives',
    i18nLabel: msg({ id: 'negative-sentiment' }),
    value: 'negatives',
  },
  // We don't propose meutral filter to avoid showing only neutral chunks
  // { key: 'neutrals', i18nLabel: msg({ id: "neutral-sentiment" }), value: 'neutrals' },
];

export function SourceFilter({
  sources,
  onFilterChange,
  onFilterReset,
  loading,
}) {
  const facetSourceItems = useSelector(viewFacetSourceItemsSelector);
  return (
    <CheckboxListFilter
      valueKey="sources"
      searchPlaceholder={t({ id: 'search-a-source' })}
      items={facetSourceItems}
      values={sources}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
      loading={loading}
    />
  );
}

SourceFilter.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
SourceFilter.defaultProps = { loading: false };

export function SentimentPolarityFilter({
  polarities,
  onFilterChange,
  onFilterReset,
}) {
  return (
    <CheckboxListFilter
      valueKey="polarities"
      items={SENTIMENT_POLARITY_ITEMS}
      values={polarities}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
    />
  );
}

SentimentPolarityFilter.propTypes = {
  polarities: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};

export function ProductHierarchyGroupFilter({
  productHierarchyGroups,
  onFilterChange,
  onFilterReset,
}) {
  const facetHierarchyGroupItems = useSelector(
    viewFacetHierarchyGroupItemsSelector
  );
  return (
    <CheckboxListFilter
      valueKey="productHierarchyGroups"
      searchPlaceholder={t({ id: 'search-product-service-groups' })}
      items={facetHierarchyGroupItems}
      values={productHierarchyGroups}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
    />
  );
}

ProductHierarchyGroupFilter.propTypes = {
  productHierarchyGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};

export const SEARCH_STATIC_FILTERS = [
  {
    title: msg({ id: 'keywords' }),
    key: 'keywords',
    keys: ['textSearchValues'],
    FilterComponent: TextSearchFilter,
  },
  {
    title: msg({ id: 'categories' }),
    key: 'categories',
    keys: ['ontologyConcepts', 'matchAllConcepts'],
    FilterComponent: OntologyFilter,
  },
  {
    title: msg({ id: 'products-services' }),
    key: 'products-services',
    keys: ['productHierarchies'],
    FilterComponent: ProductHierarchyFilter,
  },
  {
    title: msg({ id: 'period' }),
    key: 'period',
    keys: ['datePeriod', 'minDate', 'maxDate'],
    FilterComponent: ViewFacetDateFilter,
  },
  {
    title: msg({ id: 'sources' }),
    key: 'sources',
    keys: ['sources'],
    FilterComponent: SourceFilter,
  },
  {
    title: msg({ id: 'sentiment-polarity' }),
    key: 'sentiment-polarity',
    keys: ['polarities'],
    FilterComponent: SentimentPolarityFilter,
  },
  {
    title: msg({ id: 'product-services-group' }),
    key: 'product-services-group',
    keys: ['productHierarchyGroups'],
    FilterComponent: ProductHierarchyGroupFilter,
  },
];
