import { useMemo } from 'react';

import Distribution from 'components/customer/campaign/monitor/chart/Distribution';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

function AlternativeDistribution({
  chartId,
  height,
  width,
  data,
  meta: { cellKey, indicatorKey, minColor, maxColor, ...otherMeta },
  formatters,
}) {
  const distributionMeta = useMemo(
    () => ({
      cellKey,
      indicatorKey,
      minColor: minColor || svars.minVolumeColor,
      maxColor: maxColor || svars.volumeStrokeColor,
      ...otherMeta,
    }),
    [cellKey, indicatorKey, minColor, maxColor]
  );
  return (
    <Distribution
      chartId={chartId}
      height={height - 25}
      width={width}
      data={data}
      meta={distributionMeta}
      formatters={formatters}
    />
  );
}

AlternativeDistribution.propTypes = {
  ...commonPropTypes.dashboardChart,
  data: commonPropTypes.dashboardChart.data,
};
AlternativeDistribution.defaultProps = {
  data: [],
};
AlternativeDistribution.minHeight = svars.chartHeight;
AlternativeDistribution.minWidth = svars.chartMinWidth;

export default AlternativeDistribution;
