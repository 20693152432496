import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import Header from '../Header';

const StyledRadioButton = styled.button`
  &&&&&& {
    width: 100%;
    display: inline-flex;
    text-align: left;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 2.5 * svars.disabledOpacity : 1)};
    box-shadow: ${svars.baseBoxShadow};
    margin: ${svars.spaceSmall} 0;
    ${({ inline }) =>
      inline
        ? `
      margin-right: ${svars.spaceMediumLarge};

      &:last-child {
        margin-right: 0;
      }
    `
        : ''}
    padding: ${svars.spaceNormalLarge} ${svars.spaceMedium};
    border-radius: ${svars.ctaBorderRadius};
    border: 1px solid ${svars.colorLightGrey};
    background: ${svars.colorWhite};

    & input[type='radio'] {
      margin: auto 0;
    }

    & div {
      text-align: left;
    }

    ${svars.hoverClickableCss}
    ${svars.activeClickableCss}
  }
`;

function LargeRadioButtonGroup({
  items,
  onClick,
  value,
  inline,
  disabled,
}) {
  return (
    <div style={{ display: 'flex', flexDirection: inline ? 'row' : 'column' }}>
      {items.map(({ type, header, help, disabled: localDisabled }) => (
        <StyledRadioButton
          key={type}
          disabled={disabled || localDisabled}
          active={type === value}
          inline={inline ? 'true' : null}
          fluid
          onClick={() => onClick(type)}
          data-testid={type}
        >
          <input type="radio" checked={type === value} readOnly />
          <Header
            data-testid={header?.id}
            style={{
              margin: 0,
              marginLeft: svars.spaceNormalLarge,
              color: svars.fontColorBase,
            }}
          >
            <Trans render={capitalizedTranslation} id={header} />
            <Header.Subheader>
              <Trans id={help} />
            </Header.Subheader>
          </Header>
        </StyledRadioButton>
      ))}
    </div>
  );
}

LargeRadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // The value of the radio button
      type: PropTypes.string,
      header: commonPropTypes.i18nText,
      help: commonPropTypes.i18nText,
    })
  ).isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
};

LargeRadioButtonGroup.defaultProps = {
  value: null,
  inline: false,
  disabled: false,
};

export default LargeRadioButtonGroup;
