import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  analysisAuthorizedSelector,
  campaignAuthorizedSelector,
  isAdminSelector,
  isBwSelector,
} from 'selectors/user';

import { StyledDropdown } from 'components/ui/inputs/InTextDropdown';
import MenuBarsIcon from 'components/ui/svg/MenuBarsIcon';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const V3HomeTabs = (isAdmin, isBw, analysisAuthorized, campaignAuthorized) => {
  const tabs = [];

  if (analysisAuthorized || isBw) {
    tabs.push({
      route: '/facets',
      content: t({ id: 'analyses' }),
      icon: 'chart bar',
    });
    tabs.push({
      route: '/product-groups',
      content: t({ id: 'product-groups' }),
      icon: 'th large',
    });
  }
  if (campaignAuthorized || isAdmin) {
    tabs.push({
      route: '/campaign',
      content: t({ id: 'campaigns' }),
      icon: 'book',
    });
  }
  if (isBw) {
    tabs.push({
      route: '/admin',
      content: 'Admin',
      icon: 'doctor',
      color: 'red',
    });
  }
  return tabs;
};

const MenuButtonContainer = styled.span`
  display: flex;
  align-items: center;
  padding: ${svars.spaceNormalLarge};
  border-radius: 12px;

  & a {
    padding: 0 0 0 ${svars.spaceNormal} !important;
  }
`;

function AppsMenu({ children }) {
  const { i18n } = useLingui();
  const analysisAuthorized = useSelector(analysisAuthorizedSelector);
  const campaignAuthorized = useSelector(campaignAuthorizedSelector);
  const { pathname } = useLocation();
  const isAdmin = useSelector(isAdminSelector);
  const isBw = useSelector(isBwSelector);

  const tabs = useMemo(
    () => V3HomeTabs(isAdmin, isBw, analysisAuthorized, campaignAuthorized),
    [isAdmin, isBw, analysisAuthorized, campaignAuthorized, i18n?.locale]
  );
  return (
    <StyledDropdown
      key="drpdn-user-menu"
      icon={null}
      trigger={
        <MenuButtonContainer>
          <MenuBarsIcon />
          {children}
        </MenuButtonContainer>
      }
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        padding: 0,
        margin: 0,
      }}
    >
      <Dropdown.Menu>
        {tabs.map(({ route, content, icon, color }) => (
          <Dropdown.Item
            as={Link}
            to={route}
            key={route}
            selected={pathname?.includes(route)}
            content={<Trans render={capitalizedTranslation} id={content} />}
            icon={<Icon size="big" color={color || 'blue'} name={icon} />}
            style={{
              fontSize: svars.fontSizeLarge,
            }}
          />
        ))}
      </Dropdown.Menu>
    </StyledDropdown>
  );
}

AppsMenu.propTypes = { children: PropTypes.node };
AppsMenu.defaultProps = { children: null };

export default AppsMenu;
