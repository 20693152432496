import { campaignDraftLoadingActionTypes } from 'actions/campaign';
import { actionTypes as surveyActionTypes } from 'actions/survey';
import { createLoadingSelector } from 'reducers/ui';

// Used mostly to make sure draft automatic save does not happen when a request
// than can change the draft version is in progress
// eslint-disable-next-line import/prefer-default-export
export const campaignDraftLoadingSelector = createLoadingSelector([
  ...campaignDraftLoadingActionTypes,
  surveyActionTypes.ADD_QUESTION_REQUEST,
  surveyActionTypes.DUPLICATE_QUESTION_REQUEST,
  surveyActionTypes.ADD_OPTION_REQUEST,
]);
