import { useCallback } from 'react';

import { Trans, t } from '@lingui/macro';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DaySelect from 'components/ui/inputs/DaySelect';

import { useDate } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import { CompactMessage } from '../Text';

const CustomDateFilterContainer = styled.div`
  display: ${({ disabled }) => (disabled ? 'block' : 'inline-grid')};
  margin-top: ${svars.spaceNormal};
  margin-bottom: ${svars.spaceNormal};
  padding-right: ${svars.spaceMedium};
  height: ${({ disabled }) => (disabled ? 0 : 'auto')};
  overflow: hidden;
  transition: ${svars.transitionBase};
`;

const InputsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

function PeriodSelect({
  minDate: minDateProp,
  maxDate: maxDateProp,
  rangeMinDate: rangeMinDateProp,
  rangeMaxDate: rangeMaxDateProp,
  onFilterChange,
  onSetFilterActive,
  disabled,
}) {
  const onDateChange = useCallback(
    (key) => (date) => {
      onFilterChange({ [key]: date });
    },
    [onFilterChange]
  );

  // Cast as Date instances
  // NB : it is necessary to cast to and from Date as we cannot
  // store date instances in redux state
  const minDate = useDate(minDateProp);
  const maxDate = useDate(maxDateProp);
  const rangeMinDate = useDate(rangeMinDateProp);
  const rangeMaxDate = useDate(rangeMaxDateProp);

  const valueError = minDate && maxDate && isBefore(maxDate, minDate);
  return (
    <>
      <InputsContainer>
        <CustomDateFilterContainer disabled={disabled}>
          <Trans id="from" /> ...
          <DaySelect
            layout="row"
            disabled={disabled}
            error={valueError}
            fromDate={rangeMinDate}
            toDate={rangeMaxDate}
            onSelect={onDateChange('minDate')}
            selected={minDate}
            onTogglePopup={onSetFilterActive}
          />
        </CustomDateFilterContainer>

        <CustomDateFilterContainer disabled={disabled}>
          <Trans id="until" /> ...
          <DaySelect
            layout="row"
            disabled={disabled}
            error={valueError}
            fromDate={rangeMinDate}
            toDate={rangeMaxDate}
            onSelect={onDateChange('maxDate')}
            selected={maxDate}
            onTogglePopup={onSetFilterActive}
          />
        </CustomDateFilterContainer>
      </InputsContainer>
      {valueError && (
        <CompactMessage
          error
          header={t({ id: 'invalid-period' })}
          content={t({ id: 'facet-create.select-higher-minimum-date' })}
        />
      )}
    </>
  );
}

PeriodSelect.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  rangeMinDate: PropTypes.string,
  rangeMaxDate: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  onSetFilterActive: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

PeriodSelect.defaultProps = {
  minDate: null,
  maxDate: null,
  rangeMinDate: null,
  rangeMaxDate: null,
  disabled: false,
};

export default PeriodSelect;
