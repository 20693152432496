import { i18n } from '@lingui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

function RadioList({ options, selected, onChange, style }) {
  return (
    <span style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {options.map(({ value, label }) => (
        <Form.Field key={value} style={{ margin: 0 }}>
          <Radio
            style={{
              padding: svars.spaceNormalLarge,
              cursor: 'pointer',
            }}
            checked={selected === value}
            onClick={onChange(value)}
            label={capitalize(i18n._(label))}
            data-testid={label?.id}
          />
        </Form.Field>
      ))}
    </span>
  );
}

RadioList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: commonPropTypes.i18nText,
    })
  ),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  style: commonPropTypes.style,
};

RadioList.defaultProps = {
  options: [],
  selected: '',
  style: {},
};

export default RadioList;
