export const actionTypes = {
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  TOGGLE_LOADING_ENABLED: 'TOGGLE_LOADING_ENABLED',
  TOGGLE_SUPPORT_MODAL: 'TOGGLE_SUPPORT_MODAL',
  TOGGLE_KNOWLEDGE_BASE: 'TOGGLE_KNOWLEDGE_BASE',
};

export function startLoading() {
  return {
    type: actionTypes.START_LOADING,
  };
}

export function finishLoading() {
  return {
    type: actionTypes.FINISH_LOADING,
  };
}

export function toggleSupportModal() {
  return {
    type: actionTypes.TOGGLE_SUPPORT_MODAL,
  };
}

export function toggleKnowledgeBase(articleId, open = true) {
  return {
    type: actionTypes.TOGGLE_KNOWLEDGE_BASE,
    open,
    articleId,
  };
}
