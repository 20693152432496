import { api } from 'actions/utils';

import parseFacetAggregates from 'utils/facetAggregates';

export const actionTypes = {
  FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST:
    'FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST',
  FETCH_TIME_SERIES_VISUALIZATIONS_SUCCESS:
    'FETCH_TIME_SERIES_VISUALIZATIONS_SUCCESS',
  FETCH_TIME_SERIES_VISUALIZATIONS_FAILURE:
    'FETCH_TIME_SERIES_VISUALIZATIONS_FAILURE',

  // This action is sent when a view facet is updated, to clean up previously loaded aggregates
  // This view facet's time series aggregates are removed from state since they may be wrong
  // after view facet update
  RESET_VIEW_FACET_AGGREGATES: 'RESET_VIEW_FACET_AGGREGATES',

  FETCH_WORDCLOUD_V2_REQUEST: 'FETCH_WORDCLOUD_V2_REQUEST',
  FETCH_WORDCLOUD_V2_SUCCESS: 'FETCH_WORDCLOUD_V2_SUCCESS',
  FETCH_WORDCLOUD_V2_FAILURE: 'FETCH_WORDCLOUD_V2_FAILURE',

  FETCH_CONCEPT_AGGREGATES_REQUEST: 'FETCH_CONCEPT_AGGREGATES_REQUEST',
  FETCH_CONCEPT_AGGREGATES_SUCCESS: 'FETCH_CONCEPT_AGGREGATES_SUCCESS',
  FETCH_CONCEPT_AGGREGATES_FAILURE: 'FETCH_CONCEPT_AGGREGATES_FAILURE',

  FETCH_VIEWS_REQUEST: 'FETCH_VIEWS_REQUEST',
  FETCH_VIEWS_SUCCESS: 'FETCH_VIEWS_SUCCESS',
  FETCH_VIEWS_FAILURE: 'FETCH_VIEWS_FAILURE',

  FETCH_FACET_REQUEST: 'FETCH_FACET_REQUEST',
  FETCH_FACET_FAILURE: 'FETCH_FACET_FAILURE',
  FETCH_FACET_SUCCESS: 'FETCH_FACET_SUCCESS',

  FETCH_ONE_VIEW_FACET_REQUEST: 'FETCH_ONE_VIEW_FACET_REQUEST',
  FETCH_ONE_VIEW_FACET_FAILURE: 'FETCH_ONE_VIEW_FACET_FAILURE',
  FETCH_ONE_VIEW_FACET_SUCCESS: 'FETCH_ONE_VIEW_FACET_SUCCESS',
};

export const compileAggregatesKey = (aggregateType, conceptId) => {
  const addTag = (value) => (value == null ? '' : `-${value}`);
  return `${aggregateType}${addTag(conceptId)}`;
};

const isAggregatesLoaded = (
  draft,
  viewFacetId,
  aggregateType,
  conceptId = null
) =>
  draft.viewFacetAggregates[viewFacetId] &&
  draft.viewFacetAggregates[viewFacetId].loadedAggregateTypes.includes(
    compileAggregatesKey(aggregateType, conceptId)
  );

const fetchViews = () => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_VIEWS_REQUEST });
  let response;
  try {
    response = await api.get('views');
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_VIEWS_FAILURE });
  }
  return dispatch({
    type: actionTypes.FETCH_VIEWS_SUCCESS,
    views: response.data,
  });
};

export const fetchViewFacet =
  (id, initializeGroups = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.FETCH_ONE_VIEW_FACET_REQUEST,
      reset: initializeGroups,
    });
    let viewFacetsQuery;
    try {
      viewFacetsQuery = await api.get(`facets/${id}`);
    } catch (error) {
      return dispatch({
        type: actionTypes.FETCH_ONE_VIEW_FACET_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    return dispatch({
      type: actionTypes.FETCH_ONE_VIEW_FACET_SUCCESS,
      viewFacet: viewFacetsQuery.data,
      initializeGroups,
    });
  };

export const maybeFetchViews = () => async (dispatch, getState) => {
  if (getState().view.views.length === 0) {
    // Views have not been initialized properly (for instance because user was not logged in).
    return dispatch(fetchViews());
  }
  return undefined;
};

// V2 Aggregates related

export /**
 * Fetch visualizations aggregates based on a facet id and an aggregate type.
 *
 * @param {String} viewFacetId the id of the facet.
 * @param {String} aggregateType type of aggregate as defined in the api.
 */
const fetchTimeSeriesVisualizationsAggregates =
  (viewFacetId, aggregateType) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST });
    let aggregates;
    try {
      aggregates = await api.get(
        `facets/${viewFacetId}/aggregates/${aggregateType}`
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    const visualizations = parseFacetAggregates(aggregates.data, aggregateType);
    return dispatch({
      type: actionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_SUCCESS,
      visualizations,
      aggregateType,
      viewFacetId,
    });
  };

export /**
 * Check if aggregate was previously loaded, load it otherwise.
 *
 * @param {*} viewFacetId the id of the facet to get aggregate for.
 * @param {*} aggregateType the requested aggregate type.
 */
const maybeFetchTimeSeriesVisualizationsAggregates =
  (viewFacetId, aggregateType) => async (dispatch, getState) => {
    if (!isAggregatesLoaded(getState().view, viewFacetId, aggregateType)) {
      dispatch(
        fetchTimeSeriesVisualizationsAggregates(viewFacetId, aggregateType)
      );
    }
  };

const fetchConceptBasedAggregatesUrl = (
  viewFacetId,
  conceptId,
  aggregateType
) =>
  api.get(
    `facets/${viewFacetId}/concept_aggregates/${aggregateType}/${conceptId}`
  );

const fetchConceptWordcloud = (viewFacetId, conceptId) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_WORDCLOUD_V2_REQUEST });
  const aggregateType = 'wc';
  let aggregates;
  try {
    aggregates = await fetchConceptBasedAggregatesUrl(
      viewFacetId,
      conceptId,
      aggregateType
    );
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_WORDCLOUD_V2_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_WORDCLOUD_V2_SUCCESS,
    aggregateType,
    viewFacetId,
    conceptId,
    wordCloud: aggregates.data.data,
  });
};

export const maybeFetchConceptWordcloud =
  (viewFacetId, conceptId) => async (dispatch, getState) => {
    if (!isAggregatesLoaded(getState().view, viewFacetId, 'wc', conceptId)) {
      dispatch(fetchConceptWordcloud(viewFacetId, conceptId));
    }
  };

const fetchConceptBasedAggregates =
  (viewFacetId, conceptId, aggregateType) => async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_CONCEPT_AGGREGATES_REQUEST });
    let aggregatesQuery;
    try {
      aggregatesQuery = await fetchConceptBasedAggregatesUrl(
        viewFacetId,
        conceptId,
        aggregateType
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.FETCH_CONCEPT_AGGREGATES_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    const aggregates = parseFacetAggregates(
      aggregatesQuery.data,
      aggregateType
    );
    return dispatch({
      type: actionTypes.FETCH_CONCEPT_AGGREGATES_SUCCESS,
      aggregateType,
      viewFacetId,
      conceptId,
      aggregates,
    });
  };

export const maybeFetchConceptBasedAggregates =
  (viewFacetId, conceptId, aggregateType) => async (dispatch, getState) => {
    if (
      !isAggregatesLoaded(
        getState().view,
        viewFacetId,
        aggregateType,
        conceptId
      )
    ) {
      dispatch(
        fetchConceptBasedAggregates(viewFacetId, conceptId, aggregateType)
      );
    }
  };
