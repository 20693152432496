import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { msg } from '@lingui/macro';
import PropTypes from 'prop-types';

import { NPS_QUESTION_ITEM, QUESTION_TYPES_MAP } from 'selectors/survey';

import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { DualRange } from 'components/ui/inputs/Range';
import FiltersAccordion from 'components/ui/search/FiltersAccordion';

import * as svars from 'assets/style/variables';

const RANGE_FILTER_ITEMS = [
  { key: 'all', i18nLabel: msg({ id: 'all-answers' }), value: 'all' },
  {
    key: 'unanswered',
    i18nLabel: msg({ id: 'unanswered-responses-only' }),
    value: 'unanswered',
  },
  {
    key: 'custom',
    i18nLabel: msg({ id: 'select-specific-range...' }),
    value: 'custom',
  },
];

function RangeFilter({
  questionId,
  questionType,
  header,
  questionAnswerFiltersSelector,
  nElements,
  onFilterChange,
  isActive,
  onToggle,
}) {
  const { minValue, maxValue, empty } = useSelector(
    questionAnswerFiltersSelector({
      id: questionId,
    })
  );
  const rangeMinValue = questionType === NPS_QUESTION_ITEM.value ? 0 : 1;
  const rangeMaxValue = nElements - 1 + rangeMinValue;
  const onRangeModeUpdate = useCallback((_, { value }) => {
    if (value === 'all') {
      onFilterChange({ minValue: null, maxValue: null, empty: null });
    } else if (value === 'unanswered') {
      onFilterChange({ minValue: null, maxValue: null, empty: true });
    } else {
      onFilterChange({
        empty: false,
        minValue: rangeMinValue,
        maxValue: rangeMaxValue,
      });
    }
  }, []);
  const handleFromChange = useCallback(
    (value) => {
      onFilterChange({ minValue: value });
    },
    [onFilterChange]
  );

  const handleToChange = useCallback(
    (value) => {
      onFilterChange({ maxValue: value });
    },
    [onFilterChange]
  );
  const isCustom = minValue || maxValue;
  return (
    <FiltersAccordion.Filter
      title={header}
      icon={QUESTION_TYPES_MAP[questionType].icon}
      iconHelp={QUESTION_TYPES_MAP[questionType].text}
      iconColor={QUESTION_TYPES_MAP[questionType].iconColor}
      nFilterValues={[minValue, maxValue, empty].filter(Boolean).length}
      isActive={isActive}
      onToggle={onToggle}
    >
      <ButtonGroup
        as={SecondaryTabButton}
        items={RANGE_FILTER_ITEMS}
        onChange={onRangeModeUpdate}
        value={
          ((minValue != null || maxValue != null) && 'custom') ||
          (empty && 'unanswered') ||
          'all'
        }
        style={{ flexWrap: 'wrap', padding: svars.spaceNormalLarge }}
      />
      {isCustom ? (
        <DualRange
          fromValue={minValue}
          toValue={maxValue}
          fromMin={rangeMinValue}
          fromMax={rangeMaxValue}
          toMin={rangeMinValue}
          toMax={rangeMaxValue}
          onFromChange={handleFromChange}
          onToChange={handleToChange}
          style={{ margin: svars.spaceNormalLarge }}
        />
      ) : null}
    </FiltersAccordion.Filter>
  );
}

RangeFilter.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  questionAnswerFiltersSelector: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  nElements: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

RangeFilter.defaultProps = {
  isActive: false,
};

export default RangeFilter;
