import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { msg, t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Divider, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  deleteQuestion,
  duplicateQuestion,
  reorderQuestionsDragAndDrop,
  setQuestionType,
  toggleDisplayHomeScreenElement,
} from 'actions/survey';
import { campaignDraftLoadingSelector } from 'selectors/campaignLoading';
import {
  QUESTION_TYPES,
  displayHomeScreenSelector,
  makeQuestionLabel,
  newEndScreenIndexSelector,
  newQuestionSelector,
  surveyQuestionsSelector,
} from 'selectors/survey';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { AnalyticsAwareLink } from 'components/ui/Link';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import NavigationSectionHeader from 'components/ui/SectionHeader';
import LabelWithIcon from 'components/ui/SurveyQuestion';
import HelpcrunchButtonLink from 'components/ui/button/HelpcrunchButtonLink';
import DragAndDropList, {
  DraggableSegment,
} from 'components/ui/inputs/DragAndDropList';
import { ActiveLabel, DeactivatedLabel } from 'components/ui/inputs/Label';
import emptyPageUrl from 'components/ui/svg/undraw_preferences_2bda.svg';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import EndScreenList from './EndScreenList';
import EndScreenSettings from './EndScreenSettings';
import HomeScreenSettings from './HomeScreenSettings';
import { EndScreenEditor, HomeScreenEditor } from './MetaPageEditors';
import QuestionEditor from './QuestionEditor';
import QuestionSettings from './QuestionSettings';
import SelectQuestionTypeDropdown, {
  QuestionTypeChoiceList,
} from './SelectQuestionTypeDropdown';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${(props) => (props.width / 16) * 100}%;
  padding: ${svars.spaceMedium} 0;
  margin: 0 ${svars.spaceMedium};
`;

const LeftColumn = styled(Column)`
  background: ${svars.colorWhite};
  border-right: 1px solid ${svars.colorLightGrey};
  margin: 0;
  padding: 0;
`;

const RightColumn = styled(Column)`
  background: ${svars.colorWhite};
  border-left: 1px solid ${svars.colorLightGrey};
  margin: 0;
  padding: ${svars.spaceMedium} 0;
  overflow-y: auto;
`;

const ElementContainer = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
`;

const getQuestionItem = (type) =>
  QUESTION_TYPES.find((questionType) => questionType.value === type);

const questionDraggableElementFactory = (
  onDuplicate,
  onDelete,
  deleteIsDisabled,
  loading
) => {
  function QuestionDraggableElement({ id, index, type, title }) {
    const questionItem = getQuestionItem(type);
    return (
      <>
        <ElementContainer>
          <LabelWithIcon
            icon={questionItem?.icon}
            label={makeQuestionLabel(title, index)}
            iconColor={questionItem?.iconColor || svars.accentColorClear}
          />
        </ElementContainer>
        <RowMenu
          upward={index > 2}
          items={[
            {
              content: t({ id: 'duplicate' }),
              icon: 'copy outline',
              onClick: onDuplicate(id),
              disabled: loading,
            },
            {
              content: t({ id: 'delete' }),
              icon: 'delete',
              iconColor: 'red',
              disabled: loading || deleteIsDisabled,
              onClick: onDelete(id),
            },
          ]}
          disabled={false}
        />
      </>
    );
  }
  QuestionDraggableElement.propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };
  QuestionDraggableElement.defaultProps = {};
  return QuestionDraggableElement;
};

function MetaElementContainer({ label, icon, onClick, active }) {
  return (
    <DraggableSegment
      clickable="true"
      active={active ? 'true' : null}
      style={{
        margin: `0 ${svars.spaceNormal}`,
        width: `calc(100% - 2*${svars.spaceNormal})`,
      }}
      onClick={onClick}
    >
      <ElementContainer style={{ margin: svars.spaceMedium }}>
        <Icon
          size="big"
          style={{
            color: svars.accentColorClear,
            marginRight: svars.spaceNormal,
          }}
          name={icon}
        />
        <span
          style={{
            display: 'inline-flex',
            flexShrink: 0,
            marginRight: svars.spaceNormal,
          }}
        >
          <Trans id={label} />
        </span>
      </ElementContainer>
    </DraggableSegment>
  );
}

MetaElementContainer.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

const helpLinks = [
  {
    articleId: 33,
    content: msg({ id: 'how-to-create-engaging-survey-questions' }),
  },
  {
    articleId: 68,
    content: msg({ id: 'best-practices-for-survey-design' }),
  },
  {
    articleId: 66,
    content: msg({ id: 'understanding-different-question-types' }),
  },
];

function CustomizeQuestionsTab() {
  useLingui();
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [homeScreenEdition, setHomeScreenEdition] = useState(false);
  const [endScreenIndex, setEndScreenIndex] = useState(null);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] =
    useState(false);
  const newQuestion = useSelector(newQuestionSelector(campaignId));
  const newEndScreenIndex = useSelector(newEndScreenIndexSelector(campaignId));
  const campaignConfigurationIsLoading = useSelector(
    campaignDraftLoadingSelector
  );
  const displayHomeScreen = useSelector(
    displayHomeScreenSelector(campaignId, true)
  );
  const showEndScreen = endScreenIndex != null;

  const questions = useSelector(surveyQuestionsSelector(campaignId, true));
  useEffect(() => {
    if (questions?.length && !selectedQuestion) {
      setSelectedQuestion(questions[0]);
    }
  }, []);

  const onSelectQuestion = useCallback(
    (selected) => {
      setSelectedQuestion(selected);
      if (homeScreenEdition) {
        setHomeScreenEdition(false);
      }
      if (showEndScreen) {
        setEndScreenIndex(null);
      }
    },
    [
      homeScreenEdition,
      endScreenIndex,
      setSelectedQuestion,
      setHomeScreenEdition,
      setEndScreenIndex,
    ]
  );
  const onUpdateQuestions = useCallback((params) =>
    dispatch(reorderQuestionsDragAndDrop(campaignId, params))
  );
  const onDelete = useCallback(
    (questionId) => () => dispatch(deleteQuestion(campaignId, questionId)),
    []
  );
  const onDuplicate = useCallback(
    (questionId) => () => dispatch(duplicateQuestion(campaignId, questionId)),
    []
  );
  const onToggleQuestionCreateModal = useCallback(
    () => setIsQuestionCreateModalOpen(!isQuestionCreateModalOpen),
    [isQuestionCreateModalOpen]
  );
  const onCreateQuestion = useCallback(
    (questionId, questionType) => {
      dispatch(setQuestionType(campaignId, questionId, questionType)).then(
        () => {
          // Close home or end page edition
          if (homeScreenEdition) {
            setHomeScreenEdition(false);
          }
          if (showEndScreen) {
            setEndScreenIndex(null);
          }
        }
      );
    },
    [
      campaignId,
      dispatch,
      questions,
      setQuestionType,
      setSelectedQuestion,
      homeScreenEdition,
      endScreenIndex,
    ]
  );
  const onHomeScreenEdition = useCallback(() => {
    // Remove selected question
    if (selectedQuestion) {
      setSelectedQuestion(null);
    }
    if (showEndScreen) {
      setEndScreenIndex(null);
    }
    if (!homeScreenEdition) setHomeScreenEdition(true);
    if (!displayHomeScreen) {
      dispatch(toggleDisplayHomeScreenElement(campaignId, true));
    }
  }, [homeScreenEdition, endScreenIndex, selectedQuestion, displayHomeScreen]);

  const onSelectEndScreen = useCallback(
    (index) => {
      setSelectedQuestion(null);
      setHomeScreenEdition(false);
      setEndScreenIndex(index);
    },
    [setSelectedQuestion, setHomeScreenEdition, setEndScreenIndex]
  );
  const onlyOneItem = questions?.length === 1;
  const renderQuestionElement = useMemo(
    () =>
      questionDraggableElementFactory(
        onDuplicate,
        onDelete,
        onlyOneItem,
        campaignConfigurationIsLoading
      ),
    [
      onDuplicate,
      onDelete,
      questions,
      onlyOneItem,
      campaignConfigurationIsLoading,
    ]
  );
  useEffect(() => {
    if (newQuestion && selectedQuestion?.id !== newQuestion?.id) {
      onSelectQuestion(newQuestion);
    }
  }, [newQuestion]);
  useEffect(() => {
    if (newEndScreenIndex != null) {
      onSelectEndScreen(newEndScreenIndex);
    }
  }, [newEndScreenIndex]);
  if (!questions?.length) {
    if (campaignConfigurationIsLoading) {
      return null;
    }
    return (
      <EmptyDataPage
        layout="row"
        headerText={t({ id: 'empty-survey-case-title' })}
        illustrationUrl={emptyPageUrl}
        actionComponent={
          <>
            <QuestionTypeChoiceList
              disabled={campaignConfigurationIsLoading}
              onCreateQuestion={onCreateQuestion}
              headerText={t({ id: 'empty-survey-case-description' })}
              style={{ padding: `${svars.spaceNormalLarge} 0` }}
            />
            <Divider />
            <p style={{ marginTop: svars.spaceNormalLarge }}>
              <Trans id="need-help-check-out-these" />
            </p>
            <ul style={{ lineHeight: '1.6' }}>
              {helpLinks.map((link, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <HelpcrunchButtonLink
                    ButtonComponent={AnalyticsAwareLink}
                    base
                    articleId={link.articleId}
                    content={
                      <Trans
                        id={link.content}
                        render={capitalizedTranslation}
                      />
                    }
                  />
                </li>
              ))}
            </ul>
          </>
        }
        maxHeight="300px"
      />
    );
  }
  return (
    <Container>
      <LeftColumn width={4}>
        <NavigationSectionHeader
          onClick={onHomeScreenEdition}
          active={homeScreenEdition}
          title={t({ id: 'survey-landing-page' })}
          navigationSectionTestId="bo-campaign-landing-page-button"
          action={
            displayHomeScreen ? (
              <ActiveLabel small />
            ) : (
              <DeactivatedLabel small />
            )
          }
        />

        <Divider fitted />
        <NavigationSectionHeader
          title={t({ id: 'questions' })}
          action={
            <SelectQuestionTypeDropdown
              disabled={campaignConfigurationIsLoading}
              onCreateQuestion={onCreateQuestion}
              onToggle={onToggleQuestionCreateModal}
              modalIsOpen={isQuestionCreateModalOpen}
            />
          }
        />

        <DragAndDropList
          isItemDisabled={() => onlyOneItem}
          elements={questions}
          onChange={onUpdateQuestions}
          onRowClick={onSelectQuestion}
          selected={selectedQuestion}
          getElementId={(item) => item?.id}
          renderElement={renderQuestionElement}
          style={{ minHeight: '30vh' }}
        />
        <Divider fitted />

        <EndScreenList
          campaignId={campaignId}
          endScreenIndex={endScreenIndex}
          onSelectEndScreen={onSelectEndScreen}
          campaignConfigurationIsLoading={campaignConfigurationIsLoading}
        />
      </LeftColumn>
      <Column width={8}>
        <QuestionEditor
          key={`qe-${selectedQuestion?.id}`}
          questionId={selectedQuestion?.id}
          loading={campaignConfigurationIsLoading}
        />
        {homeScreenEdition ? <HomeScreenEditor /> : null}
        {showEndScreen ? (
          <EndScreenEditor
            key={`ese-${endScreenIndex}`}
            endScreenIndex={endScreenIndex}
          />
        ) : null}
      </Column>
      <RightColumn width={4}>
        {selectedQuestion ? (
          <QuestionSettings
            key={`qs-${selectedQuestion?.id}`}
            questionId={selectedQuestion?.id}
            withLogic={selectedQuestion?.index > 1}
          />
        ) : null}
        {homeScreenEdition ? <HomeScreenSettings /> : null}
        {showEndScreen ? (
          <EndScreenSettings
            key={`ess-${endScreenIndex}`}
            endScreenIndex={endScreenIndex}
          />
        ) : null}
      </RightColumn>
    </Container>
  );
}

export default CustomizeQuestionsTab;
