import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';
import styled from 'styled-components';

import { LightHeader } from 'components/ui/Header';
import { LightCheckbox } from 'components/ui/inputs/Checkbox';

import * as svars from 'assets/style/variables';

import HelpcrunchHelpTooltip from './navigation/HelpcrunchHelpTooltip';

const StyledAccordion = styled(Accordion)`
  &&& {
    height: 100%;
    display: flex;
    flex-direction: column;
    & div.title:first-letter {
      text-transform: uppercase;
    }
  }
`;

StyledAccordion.Content = Accordion.Content;

StyledAccordion.Title = styled(Accordion.Title)`
  &&&& {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: ${svars.spaceMedium};
    border-radius: ${svars.ctaBorderRadius};
    ${svars.hoverClickableCss}
    ${svars.activeClickableCss}
  }
`;

function AccordionToggleTitle({
  title,
  help,
  active,
  onToggle,
  articleId,
  accordionToggleTestId,
}) {
  return (
    <StyledAccordion.Title
      active={active}
      index={0}
      onClick={() => onToggle(!active)}
      style={{}}
      data-testid={accordionToggleTestId}
    >
      <LightHeader nomargin="true">
        {title}
        {help && (
          <HelpcrunchHelpTooltip
            articleId={articleId}
            helpText={help}
            position="top left"
          />
        )}
      </LightHeader>
      <LightCheckbox toggle checked={active} />
    </StyledAccordion.Title>
  );
}

AccordionToggleTitle.propTypes = {
  articleId: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  help: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  accordionToggleTestId: PropTypes.string,
};

AccordionToggleTitle.defaultProps = {
  articleId: null,
  help: null,
  active: false,
  accordionToggleTestId: undefined,
};

function AccordionItem({ content, active, articleId, ...titleProps }) {
  return (
    <>
      <AccordionToggleTitle
        {...titleProps}
        active={active}
        articleId={articleId}
      />
      <Accordion.Content active={active}>{content}</Accordion.Content>
    </>
  );
}

AccordionItem.propTypes = {
  articleId: PropTypes.number,
  ...AccordionToggleTitle.propTypes,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
AccordionItem.defaultProps = { ...AccordionToggleTitle.defaultProps };

export { AccordionItem, AccordionToggleTitle };
export default StyledAccordion;
