import { useCallback, useMemo, useState } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Trans, msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  maybeUpdateSearchFilters,
  resetAllFilters,
  resetSearchFilters,
  searchLoadingSelector,
  selectFilterPreset,
  updateSearchFilters,
} from 'actions/search';
import { facetEntitiesLoadingSelector } from 'selectors/entities';
import { tagSetFilteredItemsSelectorFactory } from 'selectors/facet';
import {
  facetPresetsSelector,
  nFiltersPositiveSelector,
  searchFilterValueSelector,
  searchTagFilterValuesSelector,
  selectedPresetSelector,
  shouldUpdateSearchSelector,
} from 'selectors/search';

import Link from 'components/ui/Link';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import MultichoiceFilterFactory from 'components/ui/panels/MultichoiceFilterFactory';
import SidePane from 'components/ui/panels/SidePane';
import FiltersAccordion from 'components/ui/search/FiltersAccordion';
import staticFieldFilterFactory from 'components/ui/search/staticFieldFilterFactory';

import commonPropTypes from 'utils/commonPropTypes';
import { useToggler } from 'utils/hooks';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import { useAnalysisTagFields } from '../useAnalysisFields';
import { SEARCH_STATIC_FILTERS } from './BasicFilters';
import FilterPresetDropdown from './FilterPresetDropdown';
import FilterPresetManagementModal from './FilterPresetManagementModal';

const HeaderTitleContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: ${svars.fontWeightMedium};
  padding-bottom: ${svars.spaceNormal};
`;

function ResetFilterLink({ content, onFilterReset, disabled }) {
  return (
    <div
      style={{
        textAlign: 'end',
        marginRight: svars.spaceMedium,
      }}
    >
      <Link base="true" onClick={onFilterReset} disabled={disabled}>
        <Trans id={content} />
      </Link>
    </div>
  );
}

ResetFilterLink.propTypes = {
  content: PropTypes.oneOfType([
    commonPropTypes.i18nText,
    PropTypes.string,
    PropTypes.node,
  ]),
  onFilterReset: PropTypes.func,
  disabled: PropTypes.bool,
};

ResetFilterLink.defaultProps = {
  content: msg({ id: 'reset' }),
  onFilterReset: () => {},
  disabled: false,
};

function SearchFiltersPaneActions({
  onResetAllFilters,
  onTogglePane,
  searchIsLoading,
}) {
  const nFiltersPositive = useSelector(nFiltersPositiveSelector);
  const shouldUpdateSearch = useSelector(shouldUpdateSearchSelector);
  return (
    <SidePane.Actions visible={nFiltersPositive || shouldUpdateSearch}>
      <AnalyticsAwareButton
        gaCategory="Campaign - search"
        gaAction="Reset filters"
        inputComponent={ButtonTransparentDanger}
        labelPosition="right"
        content={t({ id: 'reset-filters' })}
        onClick={onResetAllFilters}
        icon="refresh"
        disabled={!nFiltersPositive}
        style={{ marginRight: svars.spaceMediumLarge }}
        nowrap="true"
      />
      <AnalyticsAwareButton
        gaCategory="Campaign - search"
        gaAction="Apply filters"
        inputComponent={ButtonAccent}
        labelPosition="right"
        content={t({ id: 'apply-filters' })}
        onClick={onTogglePane}
        icon="filter"
        disabled={!shouldUpdateSearch || searchIsLoading}
        nowrap="true"
      />
    </SidePane.Actions>
  );
}
SearchFiltersPaneActions.propTypes = {
  onResetAllFilters: PropTypes.func.isRequired,
  onTogglePane: PropTypes.func.isRequired,
  searchIsLoading: PropTypes.bool,
};
SearchFiltersPaneActions.defaultProps = {
  searchIsLoading: false,
};

const PresetActionsContainer = styled.div`
  padding-top: ${svars.spaceSmall};
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
`;

const ActionInnerContainer = styled.span`
  display: flex;
  overflow-x: clip;
  flex-grow: 1;
`;

function SearchFiltersPane({
  visible,
  onToggle,
  onFilterPresetChange,
  onResetAllFilters,
  viewFacet,
  viewFacetId,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [presetModalIsOpen, setPresetModalIsOpen] = useState(
    !!(searchParams.get('update') && searchParams.get('preset'))
  );
  const [selectPresetDropdownIsOpen, setSelectPresetDropdownIsOpen] =
    useState(false);
  const [isPresetUpdate, setIsPresetUpdate] = useState(
    !!searchParams.get('update')
  );
  const [filterIsActive, setFilterIsActive] = useState(false); // hook 7
  const [isFilterActive, onFilterToggle] = useToggler();

  const facetEntitiesLoading = useSelector(facetEntitiesLoadingSelector);
  const searchIsLoading = useSelector(searchLoadingSelector);
  const selectedPreset = useSelector(selectedPresetSelector, shallowEqual);
  const filterPresets = useSelector(facetPresetsSelector);

  const tagFields = useAnalysisTagFields();

  const onMaybeUpdateSearch = useCallback(() => {
    dispatch(maybeUpdateSearchFilters({}, false));
  }, []);

  const togglePresetModal = useCallback(() => {
    if (!presetModalIsOpen) onMaybeUpdateSearch();
    setPresetModalIsOpen(!presetModalIsOpen);
  }, [presetModalIsOpen]);
  const onUpdateSearchFilters = useCallback((filtersUpdate) => {
    dispatch(updateSearchFilters(filtersUpdate, false, false, false));
  }, []);
  const onTogglePane = useCallback(() => {
    onToggle();
  }, [onToggle, selectPresetDropdownIsOpen]);

  const onFilterReset = useCallback(
    (filterKeys, resetValue, callback) => () =>
      dispatch(resetSearchFilters(filterKeys, resetValue, callback, false)),
    []
  );

  const onUpdatePresetModalOpen = useCallback(
    (selectPresetId) => {
      if (
        typeof selectPresetId === 'string' &&
        selectPresetId !== selectedPreset
      ) {
        onFilterPresetChange(null, { value: selectPresetId });
      }
      setIsPresetUpdate(true);
      togglePresetModal();
    },
    [setIsPresetUpdate, togglePresetModal, onFilterPresetChange]
  );
  const onPresetManagementModalClose = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (isPresetUpdate) setIsPresetUpdate(false);
      togglePresetModal();
    },
    [isPresetUpdate, togglePresetModal]
  );
  const onUpdateTagsFilters = useCallback(
    (key, tagSetId) =>
      ({ value: itemIds }) => {
        dispatch(
          updateSearchFilters({ [tagSetId]: itemIds }, false, false, true)
        );
      },
    []
  );
  const onResetTagFilters = useCallback(
    // eslint-disable-next-line no-unused-vars
    (filterKey = null, _isTag = false, feedbackFormValuesFieldName = null) =>
      () =>
      () =>
        onUpdateTagsFilters(feedbackFormValuesFieldName, filterKey)({ id: [] }),
    [onUpdateTagsFilters]
  );

  const toggleSelectPresetDropdown = useCallback(
    () => setSelectPresetDropdownIsOpen(!selectPresetDropdownIsOpen),
    [selectPresetDropdownIsOpen]
  );

  const selectedItem = selectedPreset
    ? filterPresets.find(({ id }) => id === selectedPreset)
    : {};
  const TagSetFieldFilter = useMemo(
    () =>
      MultichoiceFilterFactory(
        tagSetFilteredItemsSelectorFactory,
        searchTagFilterValuesSelector,
        onUpdateTagsFilters,
        onResetTagFilters,
        facetEntitiesLoading
      ),
    [
      searchTagFilterValuesSelector,
      onUpdateTagsFilters,
      onResetTagFilters,
      facetEntitiesLoading,
    ]
  );
  const StaticFieldFilter = useMemo(
    () =>
      staticFieldFilterFactory(
        searchFilterValueSelector,
        onUpdateSearchFilters,
        onFilterReset,
        onFilterToggle,
        facetEntitiesLoading,
        setFilterIsActive
      ),
    [
      onUpdateSearchFilters,
      onFilterReset,
      onFilterToggle,
      facetEntitiesLoading,
      setFilterIsActive,
    ]
  );
  return (
    <SidePane
      visible={visible}
      onToggle={onTogglePane}
      animation="push"
      direction="left"
      width="very wide"
      locked={selectPresetDropdownIsOpen || presetModalIsOpen || filterIsActive}
      dimmed
    >
      <SidePane.HeaderContainer
        style={{ height: 'auto', display: 'flex', flexDirection: 'column' }}
      >
        <HeaderTitleContainer>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: svars.spaceMedium }}>
              <Trans id="filters" render={capitalizedTranslation} />
            </span>
            <AnalyticsAwareButton
              gaCategory="Dashboard - search"
              gaAction="Create preset"
              gaLabel={`facet=${viewFacet?.id}`}
              inputComponent={SecondaryTabButton}
              style={{
                minHeight: 0,
                fontSize: svars.fontSizeBase,
              }}
              content={t({ id: 'new-preset' })}
              icon="add"
              labelPosition="right"
              onClick={togglePresetModal}
              disabled={searchIsLoading}
            />
          </span>
          <AnalyticsAwareHoverableIconButtonWithTooltip
            gaCategory="Dashboard - search"
            gaAction="close filters panel"
            name="angle double left"
            onClick={onTogglePane}
            help={t({ id: 'close-panel' })}
          />
        </HeaderTitleContainer>
        <PresetActionsContainer>
          <ActionInnerContainer>
            <FilterPresetDropdown
              viewFacetId={viewFacetId}
              selectedPreset={selectedPreset}
              filterPresets={filterPresets}
              onToggle={toggleSelectPresetDropdown}
              modalIsOpen={selectPresetDropdownIsOpen}
              togglePresetModal={togglePresetModal}
              onUpdatePresetModalOpen={onUpdatePresetModalOpen}
              onToggleFiltersPane={onTogglePane}
            />
            {(presetModalIsOpen && (!isPresetUpdate || selectedItem?.id) && (
              <FilterPresetManagementModal
                modalIsOpen
                onClose={onPresetManagementModalClose}
                updatePreset={isPresetUpdate ? selectedItem : {}}
              />
            )) ||
              null}
            <AnalyticsAwareButton
              inputComponent={SecondaryTabButton}
              gaCategory="Dashboard - search"
              gaAction="Update preset"
              gaLabel={`facet=${viewFacetId}`}
              icon="save"
              labelPosition="right"
              disabled={!selectedPreset}
              onClick={onUpdatePresetModalOpen}
              style={{ flexShrink: 0 }}
              content={t({ id: 'to-update-filter-preset' })}
            />
          </ActionInnerContainer>
        </PresetActionsContainer>
      </SidePane.HeaderContainer>
      <SidePane.Body>
        <FiltersAccordion>
          {SEARCH_STATIC_FILTERS.map(({ key, ...props }) => (
            <StaticFieldFilter
              key={key}
              itemId={key}
              valueId={key}
              isActive={isFilterActive(key)}
              {...props}
            />
          ))}
          {tagFields.map(({ id, tagSetId, ...props }) => (
            <TagSetFieldFilter
              {...props}
              itemId={tagSetId}
              valueId={id}
              icon="tags"
              iconHelp={t({ id: 'dynamic-field-help' })}
              isActive={isFilterActive(id)}
              onToggle={onFilterToggle(id)}
            />
          ))}
        </FiltersAccordion>
      </SidePane.Body>
      <SearchFiltersPaneActions
        onResetAllFilters={onResetAllFilters}
        onTogglePane={onTogglePane}
        searchIsLoading={searchIsLoading}
      />
    </SidePane>
  );
}
SearchFiltersPane.propTypes = {
  visible: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  viewFacetId: PropTypes.string.isRequired,
  onFilterPresetChange: PropTypes.func.isRequired,
  onResetAllFilters: PropTypes.func.isRequired,
  viewFacet: commonPropTypes.viewFacet,
};

SearchFiltersPane.defaultProps = {
  viewFacet: undefined,
};

export default connect(
  (state) => ({
    viewFacetId: state.view.viewFacet?.id,
  }),
  {
    onFilterPresetChange: selectFilterPreset,
    onResetAllFilters: resetAllFilters,
  }
)(SearchFiltersPane);
