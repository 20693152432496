import { useCallback, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import {
  AnalyticsAwareButton,
  ButtonSecondaryAccent,
} from 'components/ui/button';
import DragAndDropList from 'components/ui/inputs/DragAndDropList';
import DeleteModal from 'components/ui/modal/DeleteModal';

import * as svars from 'assets/style/variables';

import { DraggableElementFactory } from './DraggableElement';
import FormItemManagementModal, {
  formElementTypeMapping,
} from './FormItemManagementModal';

const renderHeader = (item) => (
  <>
    {item.label}: <em>{formElementTypeMapping[item.form_type]}</em>
  </>
);

function FormItemsManagementList({
  onAddElement,
  onUpdateElement,
  onRemoveElement,
  onRestoreElement,
  onReorder,
  addItemLabel,
  editItemLabel,
  restoreItemLabel,
  removeItemLabel,
  emptyListMessage,
  emptyListHeader,
  emptyDataIllustration,
  items,
  tagSetsOptions,
  mandatoryIsAllowed,
  mutableIsAllowed,
  userFormElementIsAllowed,
}) {
  useLingui();
  const [managementModalIsOpen, setManagementModalIsOpen] = useState(false);
  const [confirmDeleteElement, setConfirmDeleteElement] = useState(null);
  const [selectedFormElement, setSelectedFormElement] = useState(null);

  const activateEdition = useCallback(async (element) => {
    setSelectedFormElement(element);
    setManagementModalIsOpen(true);
  }, []);
  const endModalSession = useCallback(() => {
    setManagementModalIsOpen(false);
    if (selectedFormElement) setSelectedFormElement(null);
  }, [selectedFormElement]);
  const activateCreation = useCallback(
    () => setManagementModalIsOpen(true),
    []
  );
  const closeDeleteModal = useCallback(() => setConfirmDeleteElement(null), []);
  const confirmDelete = useCallback(
    (element) => setConfirmDeleteElement(element),
    []
  );
  const confirmDeleteElementLabel = confirmDeleteElement?.label;
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: svars.spaceNormal,
        }}
      >
        <AnalyticsAwareButton
          gaCategory="Campaign management"
          gaAction="Add member"
          icon="add"
          labelPosition="right"
          inputComponent={ButtonSecondaryAccent}
          onClick={activateCreation}
          style={{ marginLeft: svars.spaceMedium }}
          content={addItemLabel}
          data-testid="bo-add-member-button"
        />
      </div>
      <DragAndDropList
        elements={items}
        onChange={onReorder}
        renderElement={DraggableElementFactory(
          activateEdition,
          onRestoreElement,
          confirmDelete,
          editItemLabel,
          restoreItemLabel,
          removeItemLabel,
          renderHeader
        )}
        isItemDisabled={onReorder ? null : () => true}
      />
      {items.length === 0 && (
        <EmptyDataPage
          layout="row"
          i18nHeaderText={emptyListHeader}
          illustrationUrl={emptyDataIllustration}
          maxHeight="150px"
          actionComponent={
            <p>
              <Trans id={emptyListMessage} />
            </p>
          }
        />
      )}
      {managementModalIsOpen ? (
        <FormItemManagementModal
          addItemLabel={addItemLabel}
          editItemLabel={editItemLabel}
          formNames={items.map((element) => element.label)}
          create={!selectedFormElement}
          open={managementModalIsOpen}
          onClose={endModalSession}
          onAddElement={onAddElement}
          onUpdateElement={onUpdateElement}
          tagSetsOptions={tagSetsOptions}
          formElement={selectedFormElement}
          mandatoryIsAllowed={mandatoryIsAllowed}
          mutableIsAllowed={mutableIsAllowed}
          userFormElementIsAllowed={userFormElementIsAllowed}
        />
      ) : null}
      <DeleteModal
        open={!!confirmDeleteElement}
        onClose={closeDeleteModal}
        onDelete={() => onRemoveElement(confirmDeleteElement)}
        headerText={t({ id: 'delete-item-question' })}
        contentText={
          <Trans>
            item-deletion.confirm-modal-message-
            <b>{confirmDeleteElementLabel}</b>
          </Trans>
        }
        confirmWithInputValue={t({ id: 'to-delete-item' })}
      />
    </>
  );
}

FormItemsManagementList.propTypes = {
  onUpdateElement: PropTypes.func.isRequired,
  onAddElement: PropTypes.func.isRequired,
  onRemoveElement: PropTypes.func.isRequired,
  onRestoreElement: PropTypes.func,
  onReorder: PropTypes.func,
  addItemLabel: PropTypes.string.isRequired,
  editItemLabel: PropTypes.string.isRequired,
  restoreItemLabel: PropTypes.string.isRequired,
  removeItemLabel: PropTypes.string.isRequired,
  emptyListHeader: PropTypes.string.isRequired,
  emptyListMessage: PropTypes.string.isRequired,
  emptyDataIllustration: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tagSetsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // Whether the form element can be flagged as mandatory
  mandatoryIsAllowed: PropTypes.bool,
  // Whether the form element can be flagged as mutable
  mutableIsAllowed: PropTypes.bool,
  // Whether the form element can be a user assignation form element
  userFormElementIsAllowed: PropTypes.bool,
};

FormItemsManagementList.defaultProps = {
  onRestoreElement: null,
  onReorder: null,
  emptyDataIllustration: undefined,
  mandatoryIsAllowed: false,
  mutableIsAllowed: false,
  userFormElementIsAllowed: false,
};

export default FormItemsManagementList;
