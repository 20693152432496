import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import {
  addEndScreenDisplayCondition,
  deleteEndScreenDisplayCondition,
  updateEndScreenElement,
  updateHomeScreenElement,
} from 'actions/survey';
import {
  descriptionHomeScreenSelector,
  displayHomeScreenSelector,
  endScreenSelectorFactory,
  taglineHomeScreenSelector,
  titleHomeScreenSelector,
} from 'selectors/survey';

import StyledSegment from 'components/ui/Segment';
import RichTextEditor from 'components/ui/inputs/RichTextEditor';

import * as svars from 'assets/style/variables';

import DisplayLogicEditorWithModal from './logic/DisplayLogicEditorModal';

export function HomeScreenEditor() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const tagline = useSelector(taglineHomeScreenSelector(campaignId, true));
  const title = useSelector(titleHomeScreenSelector(campaignId, true));
  const displayHomeScreen = useSelector(
    displayHomeScreenSelector(campaignId, true)
  );
  const description = useSelector(
    descriptionHomeScreenSelector(campaignId, true)
  );
  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateHomeScreenElement(campaignId, key, value));
    },
    [dispatch, campaignId]
  );
  if (!displayHomeScreen) {
    return null;
  }
  return (
    <StyledSegment
      style={{
        padding: svars.spaceLarge,
        opacity: displayHomeScreen ? 1 : 0.9,
      }}
    >
      <Form.Field>
        <RichTextEditor
          content={tagline}
          onChange={onElementChange('tagline')}
          placeholder={t({ id: 'tagline-input-placeholder' })}
          basic
          hasSubtitle
          light
          centered
          singleLine
          editable={displayHomeScreen}
        />
      </Form.Field>
      <Form.Field>
        <RichTextEditor
          content={title}
          onChange={onElementChange('title')}
          placeholder={t({ id: 'home-screen-title-input-placeholder' })}
          basic
          isSubtitle
          heading
          centered
          singleLine
          editable={displayHomeScreen}
        />
      </Form.Field>
      <Form.Field>
        <RichTextEditor
          content={description}
          onChange={onElementChange('description')}
          placeholder={t({ id: 'description-input-placeholder' })}
          withTextAlign
          withLink
          editable={displayHomeScreen}
        />
      </Form.Field>
    </StyledSegment>
  );
}

export function EndScreenEditor({ endScreenIndex }) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const endScreen = useSelector(
    endScreenSelectorFactory(campaignId, endScreenIndex, true)
  );
  const {
    title,
    description,
    conditions,
    default: isDefaultScreen,
  } = endScreen || {};
  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateEndScreenElement(campaignId, endScreenIndex, key, value));
    },
    [dispatch, campaignId, endScreenIndex]
  );
  const onAddDisplayCondition = useCallback(
    (conditionQuestionId, conditionIndex, conditionValues) => {
      dispatch(
        addEndScreenDisplayCondition(
          campaignId,
          endScreenIndex,
          conditionQuestionId,
          conditionIndex,
          conditionValues
        )
      );
    },
    [dispatch, campaignId, endScreenIndex]
  );
  const onDeleteDisplayCondition = useCallback(
    (conditionIndex) => {
      dispatch(
        deleteEndScreenDisplayCondition(
          campaignId,
          endScreenIndex,
          conditionIndex
        )
      );
    },
    [dispatch, campaignId, endScreenIndex]
  );
  const [onTitleChange, onDescriptionChange] = useMemo(
    () => [onElementChange('title'), onElementChange('description')],
    [onElementChange]
  );
  if (!endScreen) {
    // This avoids rich text component to trigger an update when the end screen is empty
    // (not loading or resetting)
    return null;
  }
  return (
    <>
      <StyledSegment>
        <Form.Field>
          <RichTextEditor
            key={`title-${endScreenIndex}`}
            content={title}
            onChange={onTitleChange}
            placeholder={t({ id: 'end-screen-title-input-placeholder' })}
            isSubtitle
            heading
            centered
            singleLine
            basic
          />
        </Form.Field>
        <Form.Field>
          <RichTextEditor
            key={`description-${endScreenIndex}`}
            content={description}
            onChange={onDescriptionChange}
            placeholder={t({ id: 'description-input-placeholder' })}
            withTextAlign
            withLink
          />
        </Form.Field>
      </StyledSegment>
      <DisplayLogicEditorWithModal
        subHeaderLabel={t({ id: 'display-ending-if' })}
        campaignId={campaignId}
        currentQuestionId={null}
        conditions={isDefaultScreen ? [] : conditions}
        addCondition={onAddDisplayCondition}
        deleteCondition={onDeleteDisplayCondition}
        isDefaultScreen={isDefaultScreen}
        showEmptyCase
      />
    </>
  );
}

EndScreenEditor.propTypes = {
  endScreenIndex: PropTypes.number.isRequired,
};

EndScreenEditor.defaultProps = {};
