import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Trans, msg } from '@lingui/macro';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import Link from 'components/ui/Link';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch, capitalize, range } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: inherit;
  min-height: 0;
  ${({ bordered }) =>
    bordered
      ? `border: 1px solid ${svars.borderColorLight};
         padding: ${svars.spaceSmaller} 0 0 0;
         border-radius: ${svars.borderRadius};
         box-shadow: ${svars.baseBoxShadow};
         `
      : ''}
`;

const StripedListItem = styled(List.Item)`
  &&&&&& {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: ${(props) =>
      props.odd ? svars.colorLighterGrey : svars.colorWhite};
    padding: ${svars.spaceNormalLarge};

    &:hover {
      & div > label {
        color: ${svars.accentColor} !important;
      }
    }
    &::after {
      content: none;
    }
  }
`;

const CenteredMessage = styled.div`
  color: ${svars.fontColorLighter};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Get raw text label for search purposes.
 * This method is necessary as we receive both "text" labelled data and "label" labelled data.
 * We also allow "i18nLabel" labelled items, but it won't work with search as i18n texts are objects.
 *
 * @param {*} item
 */
const getRawLabel = (item) => item.label || item.text;

function BaseEmptyCheckboxList({ message, onReset, resetMessage }) {
  return (
    <div style={{ position: 'relative' }}>
      {range(9).map((key) => (
        <StripedListItem key={key} odd={key % 2}>
          {' '}
        </StripedListItem>
      ))}
      <CenteredMessage>
        {message ? (
          <Trans render={capitalizedTranslation} id={message} />
        ) : null}
        <Link onClick={onReset}>
          {resetMessage ? (
            <Trans render={capitalizedTranslation} id={resetMessage} />
          ) : null}
        </Link>
      </CenteredMessage>
    </div>
  );
}

BaseEmptyCheckboxList.propTypes = {
  message: commonPropTypes.i18nText.isRequired,
  onReset: PropTypes.func.isRequired,
  resetMessage: commonPropTypes.i18nText,
};

BaseEmptyCheckboxList.defaultProps = { resetMessage: null };

export const baseListItemProps = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  i18nLabel: commonPropTypes.i18nText,
  // We can use object value to map multiple fields to a given value
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  content: PropTypes.node,
  disabled: PropTypes.bool,
};

const EmptyCheckboxList = React.memo(BaseEmptyCheckboxList);

export const selectAllItems =
  (filteredItems, selectedItems, onSelectItems) => () => {
    const toAdd = filteredItems.filter(
      ({ value }) => !selectedItems.includes(value)
    );
    onSelectItems(toAdd);
  };

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${svars.spaceSmall} ${svars.spaceNormal};
`;

const SearchInputContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${svars.spaceSmaller} ${svars.spaceNormalLarge};
`;

export const CheckboxListFactory = (
  ItemListComponent,
  onSelectAllFilteredItems
) => {
  function CheckboxList({
    items,
    disabled,
    displaySelectedFirst,
    emptyListMessage,
    extraAction,
    headerStyle,
    isSelectedItem,
    loading,
    loadingDataMessage,
    nMaxSelectedItems,
    noDataMessage,
    onSelectItem,
    onSelectItems,
    onResetItems,
    onUnselectItem,
    onUnselectItems,
    placeholder,
    resetTextFilterMessage,
    searchable,
    selectedItems,
    showNSelected,
    style,
    bordered,
    getItemParent,
  }) {
    const [textFilterValue, setTextFilterValue] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const onTextSearch = debounce(
      (value) => {
        setFilteredItems(accentInsensitiveSearch(items, value, getRawLabel));
      },
      300,
      {
        leading: false,
      }
    );
    useEffect(() => {
      setFilteredItems(
        accentInsensitiveSearch(items, textFilterValue, getRawLabel)
      );
    }, [items]);

    const onTextFilterChange = useCallback(
      (e, { value }) => {
        setTextFilterValue(value);
        onTextSearch(value);
      },
      [textFilterValue, filteredItems, items]
    );

    const onResetTextFilter = useCallback(
      () => onTextFilterChange(null, { value: '' }),
      [onTextFilterChange]
    );

    let itemCounter = '-';
    const itemCounterStyles = {
      transition: svars.transitionBase,
      fontWeight: svars.fontWeightSemiBold,
      color: svars.colorPrimary,
    };
    const noInputData = !(items && items.length);
    const sortedItems = useMemo(() => {
      if (!selectedItems) {
        return filteredItems;
      }
      return displaySelectedFirst
        ? filteredItems.sort((a, b) => {
            if (isSelectedItem(a, selectedItems)) {
              return -1;
            }
            if (isSelectedItem(b, selectedItems)) {
              return 1;
            }
            return 0;
          })
        : filteredItems;
    }, [filteredItems, selectedItems]);

    if (selectedItems) {
      itemCounter = selectedItems.length;
      if (nMaxSelectedItems) {
        itemCounter = `${itemCounter} / ${nMaxSelectedItems}`;
        if (selectedItems.length >= nMaxSelectedItems) {
          itemCounterStyles.color = svars.colorDanger;
          itemCounterStyles.fontWeight = svars.fontWeightSemiBold;
        }
      }
    }
    const noMoreItems =
      nMaxSelectedItems &&
      selectedItems &&
      selectedItems.length >= nMaxSelectedItems;

    const onSelectAll = onSelectItems
      ? onSelectAllFilteredItems(
          filteredItems,
          selectedItems,
          onSelectItems,
          isSelectedItem,
          getItemParent
        )
      : null;
    const ResetButton =
      (selectedItems.length === 0 && onSelectAll) ||
      (selectedItems.length && onResetItems) ? (
        <HelpTooltip
          compact
          help={<Trans id="clear-selection" render={capitalizedTranslation} />}
          hoverable={false}
          disabled={!selectedItems.length}
          trigger={
            <span style={{ display: 'flex', alignSelf: 'center' }}>
              <Checkbox
                style={{ marginRight: svars.spaceNormalLarge }}
                indeterminate={selectedItems.length > 0}
                checked={false}
                disabled={
                  loading || (selectedItems.length === 0 && !onSelectAll)
                }
                onClick={
                  (disabled && null) ||
                  (selectedItems.length === 0 && onSelectAll) ||
                  onResetItems
                }
              />
            </span>
          }
        />
      ) : null;

    return (
      <Container style={style} bordered={bordered}>
        {(showNSelected || onSelectItems) && (
          <SummaryContainer style={headerStyle}>
            <span style={{ display: 'inline-flex' }}>
              {!searchable && ResetButton && (
                <span style={{ display: 'flex' }}>{ResetButton}</span>
              )}
              <span style={itemCounterStyles}>
                <Trans>{itemCounter} sélectionné(s)</Trans>
              </span>
            </span>
          </SummaryContainer>
        )}
        {searchable && (
          <SearchInputContainer>
            {ResetButton}
            <TextInput
              placeholder={capitalize(placeholder)}
              icon="search"
              style={{
                width: '100%',
                transition: 'all 0.5s ease-out',
              }}
              onChange={onTextFilterChange}
              value={textFilterValue}
              disabled={loading || disabled}
            />
          </SearchInputContainer>
        )}

        <div
          style={{
            height: 'inherit',
            overflowY: 'auto',
          }}
        >
          {sortedItems && sortedItems.length ? (
            <ItemListComponent
              items={sortedItems}
              selectedItems={selectedItems}
              onSelectItem={onSelectItem}
              onSelectItems={onSelectItems}
              onUnselectItem={onUnselectItem}
              onUnselectItems={onUnselectItems}
              extraAction={extraAction}
              disabled={disabled}
              noMoreItems={noMoreItems}
              isSelectedItem={isSelectedItem}
              getItemParent={getItemParent}
            />
          ) : (
            <EmptyCheckboxList
              message={
                (loading && loadingDataMessage) ||
                (noInputData && noDataMessage) ||
                emptyListMessage
              }
              resetMessage={!noInputData ? resetTextFilterMessage : null}
              onReset={onResetTextFilter}
            />
          )}
        </div>
      </Container>
    );
  }
  CheckboxList.propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...baseListItemProps,
      })
    ).isRequired,
    // Application (item, selectedItems) => <bool>
    // Item checkbox is checked if true
    isSelectedItem: PropTypes.func,
    selectedItems: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ])
    ).isRequired,
    onSelectItem: PropTypes.func.isRequired,
    // Define this method to allow multiple selection (select all)
    onSelectItems: PropTypes.func,
    onUnselectItem: PropTypes.func.isRequired,
    onUnselectItems: PropTypes.func,
    onResetItems: PropTypes.func,
    placeholder: PropTypes.oneOfType([
      commonPropTypes.i18nText,
      PropTypes.string,
    ]),
    searchable: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    bordered: PropTypes.bool,
    displaySelectedFirst: PropTypes.bool,
    style: commonPropTypes.style,
    showNSelected: PropTypes.bool,
    headerStyle: commonPropTypes.style,
    nMaxSelectedItems: PropTypes.number,
    emptyListMessage: commonPropTypes.i18nText,
    noDataMessage: commonPropTypes.i18nText,
    loadingDataMessage: commonPropTypes.i18nText,
    resetTextFilterMessage: commonPropTypes.i18nText,
    // Add an action at the end of each row - the function receives the row item as parameter
    extraAction: PropTypes.func,
    // Function to get the parent of an item - used only in hierarchical lists
    getItemParent: PropTypes.func,
  };

  CheckboxList.defaultProps = {
    showNSelected: false,
    headerStyle: {},
    disabled: false,
    loading: false,
    placeholder: '',
    searchable: false,
    bordered: false,
    style: {},
    displaySelectedFirst: false,
    onResetItems: null,
    onUnselectItems: null,
    onSelectItems: null,
    nMaxSelectedItems: null,
    emptyListMessage: msg({ id: 'no-result' }),
    loadingDataMessage: msg({ id: 'loading' }),
    noDataMessage: msg({ id: 'missing-data' }),
    resetTextFilterMessage: msg({ id: 'empty-filter' }),
    extraAction: null,
    isSelectedItem: (item, selectedItems) => selectedItems.includes(item.value),
    getItemParent: (item) => item.parent,
  };

  return CheckboxList;
};

export function CheckboxListItems({
  items,
  selectedItems,
  onSelectItem,
  onUnselectItem,
  extraAction,
  disabled,
  noMoreItems,
  isSelectedItem,
}) {
  return (
    <List selection verticalAlign="middle" style={{ height: '100%' }}>
      {items.map((item, position) => {
        const isItemSelected = isSelectedItem(item, selectedItems);
        const isDisabled =
          item.disabled || disabled || (!isItemSelected && noMoreItems);
        return (
          <StripedListItem
            key={`liis-${item.key}`}
            onClick={() =>
              isItemSelected ? onUnselectItem(item) : onSelectItem(item)
            }
            disabled={isDisabled}
            odd={position % 2}
          >
            <Checkbox
              style={{ display: 'flex', alignItems: 'center' }}
              label={
                <CheckboxLabel data-testid="bo-checkbox-label">
                  {(item.i18nLabel && (
                    <Trans
                      render={capitalizedTranslation}
                      id={item.i18nLabel}
                    />
                  )) ||
                    (item.content && item.content) ||
                    getRawLabel(item)}
                </CheckboxLabel>
              }
              checked={isItemSelected}
              disabled={isDisabled}
            />
            {extraAction ? extraAction({ item }) : null}
          </StripedListItem>
        );
      })}
    </List>
  );
}

CheckboxListItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      i18nLabel: commonPropTypes.i18nText,
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onUnselectItem: PropTypes.func.isRequired,
  extraAction: PropTypes.func,
  disabled: PropTypes.bool,
  noMoreItems: PropTypes.bool,
  isSelectedItem: PropTypes.func,
};

CheckboxListItems.defaultProps = {
  extraAction: null,
  disabled: false,
  noMoreItems: false,
  isSelectedItem: (item, selectedItems) => selectedItems.includes(item.value),
};
