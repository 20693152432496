import { i18n } from '@lingui/core';
import { msg } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';
import { useFormatter } from 'reducers/entityLabelFormatter';
import {
  entityLabelFormatterSelector,
  ontologyLoadingSelector,
} from 'selectors/entities';
import { viewFacetIdSelector, viewFacetSelector } from 'selectors/view';

import moment from 'moment';

import { TABLE_FILENAME_MAX_N_CHARS } from 'components/ui/button/export-as/ExportAsXls';

export const PERIODS_ITEMS = [
  { key: '1D', value: '1D', i18nLabel: msg({ id: '1-day' }) },
  { key: '1W', value: '1W', i18nLabel: msg({ id: '7-days' }) },
  { key: '1M', value: '1M', i18nLabel: msg({ id: '30-days' }) },
  { key: '3M', value: '3M', i18nLabel: msg({ id: '90-days' }) },
  { key: '1Y', value: '1Y', i18nLabel: msg({ id: '360-days' }) },
  { key: 'custom', value: 'custom', i18nLabel: msg({ id: 'custom-period' }) },
];

export const facetSearchSelector = (state) =>
  state.search.viewFacetSearch[state.view.viewFacet?.id] || {};
export const sortDateAscSelector = createSelector(
  facetSearchSelector,
  (facetSearch) => facetSearch?.sortDateAsc
);
export const paginationSelector = createSelector(
  facetSearchSelector,
  (facetSearch) => facetSearch?.searchResults?.pagination
);
export const facetPresetsSelector = createSelector(
  viewFacetIdSelector,
  (state) => state.search.viewFacetFilterPresets,
  (viewFacetId, viewFacetFilterPresets) =>
    viewFacetFilterPresets?.[viewFacetId] || []
);

export const selectedPresetSelector = createSelector(
  facetSearchSelector,
  (facetSearch) => facetSearch?.selectedPreset
);
export const useNameAvailableSelectorMethod = () =>
  useFormatter(
    facetPresetsSelector,
    (presets, [presetName, filterPresetId = null]) => {
      const lowerCasedPresetName = presetName.toLowerCase();
      return !presets.find(
        ({ id, name }) =>
          name.toLowerCase() === lowerCasedPresetName &&
          (!filterPresetId || (id && id !== filterPresetId))
      );
    }
  );
export const shouldUpdateSearchSelector = createSelector(
  facetSearchSelector,
  (facetSearch) => facetSearch?.shouldUpdateSearch
);

export const searchResultsSelector = (state) => {
  const viewFacetId = state.view.viewFacet?.id;
  return (
    (viewFacetId &&
      state.search.viewFacetSearch[viewFacetId] &&
      state.search.viewFacetSearch[viewFacetId].searchResults) ||
    {}
  );
};
export const searchResultsStatisticsSelector = createSelector(
  searchResultsSelector,
  (results) => results?.statistics
);

export const searchFiltersSelector = (state) =>
  state.search.viewFacetSearch?.[state.view.viewFacet?.id]?.filters || {};

export const presetIsEmptySelector = createSelector(
  searchFiltersSelector,
  (filters) =>
    !filters.textSearchValues.length &&
    !filters.sources.length &&
    !filters.productHierarchies.length &&
    !filters.productHierarchyGroups.length &&
    !filters.polarities.length &&
    !filters.ontologyConcepts.length &&
    !filters.datePeriod &&
    !Object.keys(filters.tags).length
);

export const searchFilterValueSelector = (key) =>
  createSelector(searchFiltersSelector, (filters) => filters?.[key]);
export const searchTagFilterValuesSelector = ({ tagSetId }) =>
  createSelector(searchFiltersSelector, (filters) => filters?.tags?.[tagSetId]);

export const textSearchValuesSelector = createSelector(
  searchFiltersSelector,
  (filters) => filters?.textSearchValues
);

const computeNFilterForKeys = (filterKey, filterValue) => {
  if (filterKey === 'polarities') return filterValue?.length || 0;
  if (moment.isMoment(filterValue)) return 1;
  if (typeof filterValue === 'object') return filterValue?.length;
  return filterValue ? 1 : 0;
};

export /**
 * Get the number of filters values from a list of keys
 *
 * @param {*} filtersValues
 */
const getNFiltersFromKeys =
  (filtersValues) =>
  (filterKeyOrKeys, isTag = false) => {
    if (Array.isArray(filterKeyOrKeys)) {
      return getNFiltersFromKeys(filtersValues)(filterKeyOrKeys[0], isTag);
    }
    const filterValue = isTag
      ? filtersValues.tags[filterKeyOrKeys]
      : filtersValues[filterKeyOrKeys];
    return computeNFilterForKeys(filterKeyOrKeys, filterValue);
  };

export const computeNFilters = (filters) =>
  Object.entries(filters)
    .map(([filterKey, filterValue]) =>
      computeNFilterForKeys(filterKey, filterValue)
    )
    .reduce((a, b) => a + b, 0);
const getFilterSummary = (values, formatter) => {
  let summary = values?.slice(0, 2).map(formatter).join(', ') || '-';
  if (values?.length > 3) {
    const nMore = values.length - 3;
    summary += `  ${i18n._(msg`+ ${nMore} more`)}`;
  }
  return summary;
};

export /**
 * Get the number of filters values from a list of keys
 *
 * @param {*} filtersValues
 */
const getSummaryFromKey =
  (filtersValues, entityLabelFormatter) =>
  (filterKeyOrKeys, isTag = false) => {
    let useSelectedKey = filterKeyOrKeys;
    if (Array.isArray(filterKeyOrKeys)) {
      useSelectedKey = filterKeyOrKeys?.[0];
      return getNFiltersFromKeys(filtersValues)(filterKeyOrKeys[0], isTag);
    }
    let summary = '';
    if (filterKeyOrKeys.includes('datePeriod')) {
      summary = PERIODS_ITEMS.find(
        ({ value }) => filtersValues.datePeriod === value
      )?.i18nLabel;
    } else if (
      filterKeyOrKeys === 'polarities' &&
      filtersValues.polarities?.length
    ) {
      summary = filtersValues.polarities
        .map((polarity) =>
          i18n._(
            polarity === 'positives'
              ? msg({ id: 'positives' })
              : msg({ id: 'negatives' })
          )
        )
        .join(', ');
    } else if (
      filterKeyOrKeys === 'textSearchValues' &&
      filtersValues.textSearchValues?.length
    ) {
      const keywords = filtersValues.textSearchValues.flat(1);
      summary = `${keywords.slice(0, 5).join(', ')}${
        keywords.length > 5 ? ' ...' : ''
      }`;
    } else if (
      filterKeyOrKeys === 'ontologyConcepts' &&
      filtersValues.ontologyConcepts.length
    ) {
      filtersValues.ontologyConcepts.forEach((conceptId, index) => {
        const value = entityLabelFormatter('concept', conceptId);
        summary += value;
        if (index < filtersValues.ontologyConcepts.length - 1) {
          summary += ', ';
        }
      });
    } else if (isTag) {
      const values = filtersValues.tags[useSelectedKey];
      summary = getFilterSummary(values, (item) =>
        entityLabelFormatter('tag', item)
      );
    } else {
      const values = filtersValues[useSelectedKey];
      summary = getFilterSummary(values, (item) =>
        entityLabelFormatter(useSelectedKey, item)
      );
    }
    return summary;
  };
export const getFilterSummarySelector = createSelector(
  searchFiltersSelector,
  entityLabelFormatterSelector,
  (filters, entityLabelFormatter) =>
    getSummaryFromKey(filters, entityLabelFormatter)
);
export const nFiltersPositiveSelector = createSelector(
  searchFiltersSelector,
  (filtersValues) =>
    (filtersValues &&
      Object.keys(filtersValues).some(
        (filterKey) =>
          filterKey !== 'tags' &&
          getNFiltersFromKeys(filtersValues)(filterKey) > 0
      )) ||
    (filtersValues.tags &&
      Object.values(filtersValues.tags).some((tagIds) => tagIds?.length > 0)) ||
    0
);

export const searchPageInitializingSelector = createSelector(
  searchResultsSelector,
  ontologyLoadingSelector,
  searchFiltersSelector,
  (searchResults, ontologyLoading, filtersValues) =>
    !searchResults.reviewChunks || ontologyLoading || !filtersValues
);

export const defaultExportFilenameSelector = createSelector(
  viewFacetSelector,
  searchFiltersSelector,
  entityLabelFormatterSelector,
  (
    viewFacet,
    { polarities, textSearchValues, ontologyConcepts },
    entityLabelFormatter
  ) => {
    const nameChunks = [viewFacet.name, i18n._(msg({ id: 'Extracts' }))];
    if ((polarities?.length || 0) === 1) {
      nameChunks.push(
        (polarities[0] === 'positives' && i18n._(msg({ id: 'positive' }))) ||
          (polarities[0] === 'negatives' && i18n._(msg({ id: 'negative' }))) ||
          i18n._(msg({ id: 'neutral' }))
      );
    }
    if (textSearchValues?.length) {
      let serializedTextSearchValues = textSearchValues
        .map((orTerms) =>
          orTerms.length === 1 ? orTerms[0] : `(${orTerms.join(' OR ')})`
        )
        .join(' & ');
      if (serializedTextSearchValues.length > 50) {
        serializedTextSearchValues = serializedTextSearchValues
          .slice(0, 55)
          .split(' ')
          .slice(0, -1)
          .join(' ');
      }
      nameChunks.push(serializedTextSearchValues);
    }

    if ((ontologyConcepts?.length || 0) > 0) {
      const nConcept = ontologyConcepts.length;
      nameChunks.push(
        nConcept > 1
          ? i18n._(msg`${nConcept} concepts`)
          : entityLabelFormatter('concept', ontologyConcepts[0])
      );
    }
    const finalName = nameChunks.join(' - ');

    return finalName.length <= TABLE_FILENAME_MAX_N_CHARS
      ? finalName
      : finalName.slice(0, TABLE_FILENAME_MAX_N_CHARS);
  }
);
export const nMaxChunksSelector = createSelector(
  searchResultsSelector,
  (searchResults) =>
    searchResults.reviewChunks ? searchResults.statistics.n_chunks : 0
);

export const focusedReviewChunkSelector = createSelector(
  facetSearchSelector,
  (facetSearch) => facetSearch?.focusReviewChunk
);

export const focusedReviewIdSelector = createSelector(
  focusedReviewChunkSelector,
  (focusReviewChunk) => focusReviewChunk?.review_id
);
export const focusedReviewChunkLocationSelector = createSelector(
  focusedReviewChunkSelector,
  (focusReviewChunk) => ({
    chunkIndex: focusReviewChunk?.chunk_index,
    textField: focusReviewChunk?.text_field,
  })
);

export const focusReviewSelector = createSelector(
  (state) => facetSearchSelector(state)?.reviews,
  focusedReviewIdSelector,
  (reviews, focusReviewId) => reviews?.[focusReviewId]
);
