import { useCallback, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Divider, List } from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { SmallHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import Segment from 'components/ui/Segment';
import emptyFiltersUrl from 'components/ui/svg/undraw_circles_y7s2.svg';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const N_LIST_ITEM_DISPLAYED = 4;
function ListField({ values, formatter }) {
  const [isToggled, setToggled] = useState(false);
  const doToggle = useCallback(() => !setToggled(!isToggled), [isToggled]);
  let toShow = values;
  let showLink = false;
  const nToToggle = values.length - N_LIST_ITEM_DISPLAYED;
  if (nToToggle > 0) {
    showLink = true;
    if (!isToggled) {
      toShow = values.slice(0, N_LIST_ITEM_DISPLAYED);
    }
  }
  return (
    <List>
      {toShow.map((value, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <List.Item key={`li-${i}`}>{formatter?.(value) || value}</List.Item>
      ))}
      {showLink ? (
        <List.Item key="link">
          <Link onClick={doToggle}>
            {isToggled ? <Trans id="show-less" /> : <Trans id="show-more" />}
            {` (${nToToggle})`}
          </Link>
        </List.Item>
      ) : null}
    </List>
  );
}
ListField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  formatter: PropTypes.func,
};
ListField.defaultProps = { values: [], formatter: null };

function OneFilterSummary({ label, padded, children }) {
  return (
    <div>
      <SmallHeader
        style={{
          margin: `${svars.spaceSmall} 0`,
          paddingRight: svars.spaceMedium,
        }}
      >
        {label}
      </SmallHeader>
      <div style={{ paddingBottom: padded ? svars.spaceMediumLarge : 0 }}>
        {children}
      </div>
    </div>
  );
}

OneFilterSummary.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
};

OneFilterSummary.defaultProps = { padded: true };

function OneRangeFilter({
  label,
  fromValue,
  toValue,
  empty,
  formatter,
  padded,
}) {
  const { i18n } = useLingui();
  if (fromValue == null && toValue == null && empty == null) {
    return null;
  }
  const emptyLabel = empty
    ? ` (${i18n._(
        t({
          id: 'show-unanswered-responses',
        })
      )})`
    : '';
  const value =
    fromValue != null || toValue != null
      ? [formatter?.(fromValue) || '...', ' → ', formatter?.(toValue) || '...']
      : '';
  return (
    <OneFilterSummary padded={padded} label={label}>
      {value}
      {emptyLabel}
    </OneFilterSummary>
  );
}

OneRangeFilter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  fromValue: PropTypes.string,
  toValue: PropTypes.string,
  formatter: PropTypes.func,
  empty: PropTypes.bool,
  padded: PropTypes.bool,
};

OneRangeFilter.defaultProps = {
  fromValue: null,
  toValue: null,
  formatter: null,
  empty: null,
  padded: true,
};
function OneFilter({ label, value, formatter, multi, padded }) {
  if (!multi || !value?.length) {
    if (value?.minDate != null || value?.maxDate != null) {
      return (
        <OneRangeFilter
          label={label}
          fromValue={value.minDate}
          toValue={value.maxDate}
          formatter={formatter}
          padded={padded}
        />
      );
    }
    if (
      value?.minValue != null ||
      value?.maxValue != null ||
      value?.empty != null
    ) {
      return (
        <OneRangeFilter
          label={label}
          fromValue={value.minValue}
          toValue={value.maxValue}
          empty={value.empty}
          formatter={formatter}
          padded={padded}
        />
      );
    }
    return null;
  }
  return (
    <OneFilterSummary label={label} padded={padded}>
      {(multi && Array.isArray(value) && (
        <ListField formatter={formatter} values={value} />
      )) ||
        (value && formatter?.(value)) ||
        value}
    </OneFilterSummary>
  );
}

OneFilter.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.node,
    PropTypes.number,
  ]),
  formatter: PropTypes.func,
  multi: PropTypes.bool,
  padded: PropTypes.bool,
};

OneFilter.defaultProps = {
  label: '',
  formatter: null,
  multi: true,
  value: null,
  padded: true,
};

const ContainerSegment = styled(Segment)`
  &&& {
    width: ${({ fluid }) =>
      fluid ? `calc(100% - 2*${svars.spaceMedium})` : '400px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${svars.spaceMedium};
    overflow: hidden;
  }
`;

function FiltersSummary({
  title,
  titleAction,
  isEmpty,
  footerElement,
  children,
  emptyDataActionElement,
  fluid,
  style,
}) {
  return (
    <ContainerSegment style={style} fluid={fluid ? 'true' : null}>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginTop: 0,
            marginBottom: svars.spaceNormalLarge,
          }}
        >
          <Trans id={title} />
          {titleAction}
        </div>
        {isEmpty ? (
          <EmptyDataPage
            style={{ padding: `${svars.spaceNormalLarge} ${svars.spaceSmall}` }}
            i18nHeaderText={t({ id: 'no-filter-set' })}
            actionComponent={emptyDataActionElement}
            illustrationUrl={emptyFiltersUrl}
            maxHeight="8rem"
          />
        ) : (
          <div style={{ paddingRight: svars.spaceSmall, overflowY: 'auto' }}>
            {children}
          </div>
        )}
      </span>
      {footerElement ? (
        <span style={{ padding: 0, margin: '0 auto' }}>
          <Divider />
          {footerElement}
        </span>
      ) : null}
    </ContainerSegment>
  );
}

FiltersSummary.propTypes = {
  title: commonPropTypes.i18nText.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  emptyDataActionElement: PropTypes.node,
  footerElement: PropTypes.node,
  // Whether the segment should take the full width
  fluid: PropTypes.bool,
  style: PropTypes.shape({}),
  titleAction: PropTypes.node,
};

FiltersSummary.defaultProps = {
  footerElement: null,
  emptyDataActionElement: null,
  fluid: false,
  style: {},
  titleAction: null,
};

FiltersSummary.OneFilter = OneFilter;

export default FiltersSummary;
