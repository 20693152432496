import { i18n } from '@lingui/core';
import { msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { createSelector } from '@reduxjs/toolkit';
import { EMPTY_CATEGORY_LABEL } from 'reducers/entityLabelFormatter';

import LabelWithIcon from 'components/ui/SurveyQuestion';
import { TagLabel } from 'components/ui/inputs/Label';

import commonPropTypes from 'utils/commonPropTypes';
import { removeHtmlTags } from 'utils/helpers';
import renderHtml from 'utils/html';

import * as svars from 'assets/style/variables';

import {
  campaignConfigurationDiffSelectorFactory,
  campaignCustomizationSelectorFactory,
  campaignSelector,
} from './campaign';

export const TEXT_SURVEY_QUESTION_ITEM = {
  key: 'TextSurveyQuestion',
  value: 'TextSurveyQuestion',
  text: msg({ id: 'text-survey-question' }),
  description: msg({ id: 'text-survey-question-description' }),
  icon: 'font',
  iconColor: '#71D387',
};

export const DATE_QUESTION_ITEM = {
  key: 'DateQuestion',
  value: 'DateQuestion',
  text: msg({ id: 'date-survey-question' }),
  description: msg({ id: 'date-survey-question-description' }),
  icon: 'calendar',
  iconColor: '#71D387',
};

export const MULTICHOICE_QUESTION_ITEM = {
  key: 'MultiChoiceSurveyQuestion',
  value: 'MultiChoiceSurveyQuestion',
  text: msg({ id: 'categorical-survey-question' }),
  description: msg({ id: 'categorical-survey-question-description' }),
  icon: 'list',
  iconColor: '#00BFFF',
};

export const NPS_QUESTION_ITEM = {
  key: 'NPS',
  value: 'NPSQuestion',
  text: msg({ id: 'NPS' }),
  description: msg({ id: 'NPS-description' }),
  icon: 'tachometer alternate',
  iconColor: '#FFA500',
};

export const RATING_QUESTION_ITEM = {
  key: 'rating',
  value: 'RatingQuestion',
  text: msg({ id: 'rating' }),
  description: msg({ id: 'rating-description' }),
  icon: 'star',
  iconColor: '#FFA500',
};

export const QUESTION_TYPES = [
  MULTICHOICE_QUESTION_ITEM,
  NPS_QUESTION_ITEM,
  RATING_QUESTION_ITEM,
  TEXT_SURVEY_QUESTION_ITEM,
  DATE_QUESTION_ITEM,
];
export const QUESTION_TYPES_MAP = {
  [TEXT_SURVEY_QUESTION_ITEM.value]: TEXT_SURVEY_QUESTION_ITEM,
  [MULTICHOICE_QUESTION_ITEM.value]: MULTICHOICE_QUESTION_ITEM,
  [NPS_QUESTION_ITEM.value]: NPS_QUESTION_ITEM,
  [RATING_QUESTION_ITEM.value]: RATING_QUESTION_ITEM,
  [DATE_QUESTION_ITEM.value]: DATE_QUESTION_ITEM,
};

const SCORED_QUESTION_TYPES = [
  NPS_QUESTION_ITEM.value,
  RATING_QUESTION_ITEM.value,
];
export const isScoredQuestionType = (type) =>
  SCORED_QUESTION_TYPES.includes(type);

export const AUTO_NAVIGATE_SPEED_OPTIONS = [
  {
    value: 1,
    label: msg({ id: 'immediate' }),
  },
  {
    value: 400,
    label: msg({ id: 'fast' }),
  },
  {
    value: 900,
    label: msg({ id: 'slow' }),
  },
];

const MULTICHOICE_TYPES = [
  MULTICHOICE_QUESTION_ITEM.value,
  NPS_QUESTION_ITEM.value,
  RATING_QUESTION_ITEM.value,
];

export const makeQuestionLabel = (title, index = null, suffix = null) => {
  const formattedText = `${index !== null ? `${index}. ` : ''}${
    title ? removeHtmlTags(title) : '-'
  }`;
  return suffix ? (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {formattedText}
      </span>
      <span>
        <TagLabel
          small
          warning="1"
          style={{ lineHeight: 1.6, marginLeft: svars.spaceNormalLarge }}
        >
          {suffix}
        </TagLabel>
      </span>
    </span>
  ) : (
    formattedText
  );
};

export function QuestionLabelWithIcon({
  question,
  questionNumber,
  alwaysWithIcon,
  withIcon = false,
}) {
  const label = makeQuestionLabel(question?.title, questionNumber);
  if (!alwaysWithIcon && !withIcon) {
    return label;
  }
  const icon = QUESTION_TYPES_MAP[question?.type]?.icon;
  const iconColor = QUESTION_TYPES_MAP[question?.type]?.iconColor;

  return <LabelWithIcon icon={icon} iconColor={iconColor} label={label} />;
}

QuestionLabelWithIcon.propTypes = {
  question: commonPropTypes.question,
  questionNumber: PropTypes.number,
  alwaysWithIcon: PropTypes.bool,
  withIcon: PropTypes.bool,
};

QuestionLabelWithIcon.defaultProps = {
  question: null,
  questionNumber: null,
  alwaysWithIcon: false,
  withIcon: false,
};

/**
 * Checks if the given question type is a multichoice type.
 *
 * @param {string} type - The type of the question to check.
 * @returns {boolean} - Returns true if the type is a multichoice type, otherwise false.
 */
export const isMultichoiceQuestionType = (type) =>
  MULTICHOICE_TYPES.includes(type);

export const getQuestionType = (questionType) =>
  QUESTION_TYPES.find(({ value }) => value === questionType);

const surveyCustomizationElementSelectorFactory =
  (key, elementKey = null, defaultValue = null) =>
  (campaignId, useDiff = false) =>
    createSelector(
      (useDiff ? campaignConfigurationDiffSelectorFactory : campaignSelector)(
        campaignId
      ),
      (configuration) => {
        const element = configuration?.[key] || defaultValue;
        if (elementKey != null) {
          return element?.[elementKey] || defaultValue;
        }
        return element;
      }
    );

export const surveyQuestionsSelector =
  surveyCustomizationElementSelectorFactory('questions', null, []);

export const surveyHasDateQuestionSelectorFactory = (
  campaignId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.some(({ type }) => type === DATE_QUESTION_ITEM.value) || false
  );

export const allVisibleQuestionsAreConditionedSelectorFactory = (
  campaignId,
  hiddenQuestionIds
) =>
  createSelector(surveyQuestionsSelector(campaignId), (questions) =>
    questions
      .filter((question) => !hiddenQuestionIds.includes(question.id))
      .every((question) => question.conditions?.length > 0)
  );

export const displayHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('display_home_screen', null, false);

export const endScreensSelectorFactory = (campaignId, useDiff = false) =>
  surveyCustomizationElementSelectorFactory(
    'end_screens',
    null,
    []
  )(campaignId, useDiff);

export const endScreenSelectorFactory = (campaignId, index, useDiff = false) =>
  surveyCustomizationElementSelectorFactory(
    'end_screens',
    index,
    null
  )(campaignId, useDiff);

export const taglineHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'tagline', '');
export const titleHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'title', '');

export const descriptionHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'description', '');

export const ctaTextHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'button_label', '');

export const endScreenElementSelectorFactory =
  (elementValue, defaultValue) =>
  (campaignId, index, useDiff = false) =>
    createSelector(
      endScreenSelectorFactory(campaignId, index, useDiff),
      (endScreen) => endScreen?.[elementValue] || defaultValue
    );
export const isDefaultEndScreenElementSelectorFactory =
  endScreenElementSelectorFactory('default', false);

export const endScreenIllustrationSelector = endScreenElementSelectorFactory(
  'illustration',
  null
);
export const endScreenLabelSelector = endScreenElementSelectorFactory(
  'label',
  ''
);

export const showCtaEndScreenSelector = endScreenElementSelectorFactory(
  'show_button',
  false
);
export const ctaLabelEndScreenSelector = endScreenElementSelectorFactory(
  'button_label',
  ''
);
export const ctaUrlEndScreenSelector = endScreenElementSelectorFactory(
  'button_url',
  ''
);

export const autoNavigateSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'auto_navigate',
  false
);
export const navigationSpeedSelector =
  surveyCustomizationElementSelectorFactory(
    'theme',
    'navigation_speed',
    AUTO_NAVIGATE_SPEED_OPTIONS[1].value
  );

export const primaryColorSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'primary',
  ''
);

export const mainLogoSelector = surveyCustomizationElementSelectorFactory(
  'logo_url',
  null,
  ''
);

export const previousLogoSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (configuration) => configuration.logo_url
  );

export const isRelaxedSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'relaxed',
  false
);

export const dpoEmailSelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'dpo_email',
  ''
);
export const privacyPolicySelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'organization_policy_url',
  ''
);

export const questionSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.find(
        ({ id: questionUniqueId }) => questionUniqueId === questionId
      ) || null
  );

/**
 * Create a selector for conditioned questions by a common question.
 *
 * @param {string} campaignId - The ID of the campaign.
 * @param {string} questionId - The ID of the question to filter by condition.
 * @param {boolean} [useDiff=false] - Flag to determine if differential data should be used.
 * @returns {Function} A selector function that filters questions based on conditions.
 */
export const conditionedQuestionSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.filter(({ conditions }) =>
        conditions?.some(
          ({ question: { id: conditionQuestionId } }) =>
            conditionQuestionId === questionId
        )
      ) || []
  );

export const questionAnswerItemsSelector =
  (campaignId, useDiff = false) =>
  (questionId) =>
    createSelector(
      questionSelectorFactory(campaignId, questionId, useDiff),
      (question) =>
        question?.options?.map(({ id: iterQuestionId, display_name }) => ({
          key: iterQuestionId,
          value: iterQuestionId,
          content: display_name?.trim() ? removeHtmlTags(display_name) : '-',
        })) || []
    );

export const conditionQuestionItemsSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false,
  // Do not return text questions
  categoricalOnly = false,
  filterIds = []
) =>
  createSelector(surveyQuestionsSelector(campaignId, useDiff), (questions) => {
    const currentQuestionIndex = questionId
      ? questions.findIndex(
          ({ id: questionUniqueId }) => questionUniqueId === questionId
        )
      : questions?.length || 0 + 1;
    const alreadyInConditions =
      questions[currentQuestionIndex]?.conditions?.map(
        ({ question }) => question
      ) || [];
    return (
      // Select only questions that are before the current question
      questions
        .slice(0, currentQuestionIndex)
        .filter(
          ({ id: questionUniqueId, type: questionType }) =>
            questionUniqueId &&
            !alreadyInConditions.includes(questionUniqueId) &&
            (!categoricalOnly ||
              !['TextSurveyQuestion'].includes(questionType)) &&
            !filterIds.includes(questionUniqueId)
        )
        .map(({ index, id, title }) => {
          const text = makeQuestionLabel(title, index);
          return {
            key: id,
            value: id,
            // Important to keep both text and content so both dropdown items and selected value display are aligned
            text,
            content: text,
          };
        }) || []
    );
  });

export const isFeedbackCampaignConfiguration = (campaign) =>
  !!campaign?.ontology || false;

export const isFeedbackCampaignSelector = (campaignId) =>
  createSelector(campaignSelector(campaignId), isFeedbackCampaignConfiguration);

// Survey campaigns
export const surveyQuestionsItemsSelectorFactory = (
  campaignId,
  multiChoiceQuestionOnly = false,
  useDiff = false,
  withConditionsIndication = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions
        ?.filter(
          (item) =>
            !multiChoiceQuestionOnly || isMultichoiceQuestionType(item.type)
        )
        .map(({ id, title, index, conditions, ...questionProps }) => ({
          // We use position as an alternative to id, since id does not exist at first when
          // question is created
          key: index,
          value: id,
          text: makeQuestionLabel(
            title,
            index,
            withConditionsIndication && conditions?.length
              ? `${i18n._(t({ id: 'conditioned' }))}`
              : ''
          ),
          tagSetId: questionProps.tag_set?.id,
          icon: QUESTION_TYPES_MAP[questionProps.type]?.icon,
          shape: questionProps.shape,
          color: QUESTION_TYPES_MAP[questionProps.type]?.iconColor,
          type: questionProps.type,
        })) || []
  );

/**
 * Creates a selector for formatting question labels.
 *
 * @param {string} campaignId - The ID of the campaign.
 * @param {boolean} [useDiff=false] - Flag to determine if differential data should be used.
 * @param {boolean} [alwaysWithIcon=false] - Flag to determine if the icon should always be included.
 * @returns {Function} A selector function that formats question labels.
 */
export const questionLabelFormatterSelectorFactory = (
  campaignId,
  useDiff = false,
  alwaysWithIcon = false,
  basicText = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    // campaignSelector(campaignId),
    (questions) =>
      function questionLabelFormatterFunc(questionId, withIcon = false) {
        const questionIndex = questions?.findIndex(
          ({ id }) => id === questionId
        );
        if (basicText) {
          return makeQuestionLabel(
            questions?.[questionIndex]?.title,
            questionIndex + 1
          );
        }
        return (
          <QuestionLabelWithIcon
            question={questions?.[questionIndex]}
            questionNumber={questionIndex + 1}
            alwaysWithIcon={alwaysWithIcon}
            withIcon={withIcon}
          />
        );
      }
  );

const answerLabelsSelector = (campaignId, useDiff = false) =>
  createSelector(
    (useDiff ? campaignConfigurationDiffSelectorFactory : campaignSelector)(
      campaignId
    ),
    (configuration) =>
      configuration.questions?.reduce((acc, question) => {
        question.options?.forEach((option) => {
          acc[option.id] = option;
        });
        return acc;
      }, {})
  );
export const questionAnswerLabelFormatterSelectorFactory = (
  campaignId,
  useDiff = false
) =>
  createSelector(
    answerLabelsSelector(campaignId, useDiff),
    (answerLabels) =>
      function answerLabelSelector(cellKey) {
        return (
          (typeof answerLabels[cellKey]?.display_name === 'string' &&
            renderHtml(answerLabels[cellKey]?.display_name)) ||
          cellKey?.map?.(
            (key) => removeHtmlTags(answerLabels[key]?.display_name) || '-'
          ) ||
          (cellKey === EMPTY_CATEGORY_LABEL &&
            i18n._(msg({ id: 'not-completed' }))) ||
          (cellKey != null && `${cellKey}`) ||
          '-'
        );
      }
  );

export const questionAnswerColorFormatterFactory = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (configuration) =>
      (values, questionId = null) => {
        const answerId = typeof cellKey === 'object' ? values[0] : values;
        if (questionId) {
          // Handle rating and NPS questions
          const question = configuration.questions?.find(
            ({ id }) => id === questionId
          );
          if (question.type === RATING_QUESTION_ITEM.value) {
            return svars.RATING_COLORS_YELLOW[answerId] || '';
          }
          if (question.type === NPS_QUESTION_ITEM.value) {
            return svars.NPS_COLORS[answerId] || '';
          }
        }
        const answer = configuration.questions
          ?.filter(({ type }) => type === MULTICHOICE_QUESTION_ITEM.value)
          ?.flatMap(({ options }) => options)
          .find(({ id }) => id === answerId);
        return answer?.color || '';
      }
  );
export const newQuestionSelector = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => customization?.newQuestion
  );

export const newEndScreenIndexSelector = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => customization?.newEndScreenIndex
  );

export const questionTitleMapSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaignConfiguration) =>
      campaignConfiguration?.questions?.reduce((acc, question) => {
        acc[question.id] = question.title;
        return acc;
      }, {}) || {}
  );
