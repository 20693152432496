import PropTypes from 'prop-types';
import { Icon, Loader } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import withClickAnalytics from 'utils/withAnalytics';

import * as svars from 'assets/style/variables';

export const hoverableIconStyle = css`
  ${({ large }) => (large ? `font-size: ${svars.fontSizeXLarge};` : '')}
  pointer-events: auto;
  opacity: ${({ disabled }) => (disabled ? 0.5 : svars.iconOpacityLighter)};
  color: ${({ disabled, transparent, warning, primary, active, inverted }) =>
    (disabled && svars.fontColorDisabled) ||
    (active && svars.accentColor) ||
    (inverted && svars.colorWhite) ||
    (transparent && svars.colorGrey) ||
    (primary && svars.colorPrimary) ||
    (warning && svars.colorDarkGrey) ||
    'inherit'};
  transition: ${svars.transitionBase};
  transform: ${({ active, disabled }) =>
    (active && !disabled && 'scale(1.1)') || null};

  &:hover {
    transform: ${({ disabled }) => (disabled ? 'null' : 'scale(1.1)')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    ${({ disabled, danger, accent, success }) =>
      (!disabled &&
        ((danger && `color: ${svars.colorDanger}`) ||
          (success && `color: ${svars.colorSuccess}`) ||
          (accent && `color: ${svars.accentColor}`))) ||
      ''};
  }
`;

export const HoverableIcon = styled(Icon)`
  &&&&&&& {
    ${hoverableIconStyle};
  }
`;
HoverableIcon.defaultProps = { size: 'large', accent: 'true' };
HoverableIcon.Group = styled(Icon.Group)`
  &&& {
    ${hoverableIconStyle};
    cursor: pointer;
  }
`;
export const HoverableIconButtonContainer = styled.button`
  &&&&&&& {
    cursor: ${(props) => (props.disabled ? 'forbidden' : 'pointer')};
    ${hoverableIconStyle};
    padding: ${({ size }) =>
      size === 'large'
        ? svars.spaceMedium
        : `${svars.spaceNormal} ${svars.spaceNormalLarge}`};
    border: none;
    background: ${({ active }) =>
      active ? svars.accentColorLighter : 'transparent'};
    border-radius: ${svars.borderRadius};

    &:focus,
    &:focus-visible {
      outline: none;
    }

    & i {
      margin-right: 0;
      pointer-events: none;
    }
  }
`;

export const LabelContainer = styled.span`
  font-size: ${svars.fontSizeLarge};
  margin-left: ${svars.spaceNormalLarge};
  text-transform: capitalize;
  vertical-align: middle;
`;

export function HoverableIconButton({
  name,
  size,
  style,
  loading,
  label,
  ...props
}) {
  return (
    <HoverableIconButtonContainer {...props} style={style}>
      {(loading && <Loader inline active size="small" />) ||
        (name && <Icon name={name} size={size} />)}
      {label ? <LabelContainer>{label}</LabelContainer> : null}
    </HoverableIconButtonContainer>
  );
}
HoverableIconButton.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  accent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.shape(),
  loading: PropTypes.bool,
};
HoverableIconButton.defaultProps = {
  size: null,
  accent: 'true',
  style: {},
  loading: false,
  label: null,
};

// Track onClick actions
export const AnalyticsAwareHoverableIconButton = withClickAnalytics(
  HoverableIconButton,
  'onClick'
);

export function HoverableIconButtonWithTooltip({
  help,
  style,
  active,
  inverted,
  tooltipInverted,
  position,
  mouseEnterDelay,
  mouseLeaveDelay,
  trigger,
  showTooltipOn,
  disabled,
  disabledHelp,
  ...hoverableIconProps
}) {
  const finalHelp = disabled ? disabledHelp || null : help;
  const finalTrigger = trigger || (
    <HoverableIconButton
      style={style}
      active={active ? 'true' : undefined}
      inverted={inverted ? 'true' : undefined}
      disabled={disabled}
      showBookIcon={false}
      {...hoverableIconProps}
    />
  );
  return (
    <HelpTooltip
      on={showTooltipOn}
      help={[finalHelp]}
      compact
      size="tiny"
      inverted={tooltipInverted}
      position={position}
      disabled={!help || (disabled && !disabledHelp)}
      trigger={
        disabled && disabledHelp ? <span>{finalTrigger}</span> : finalTrigger
      }
      baseColor
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
    />
  );
}

HoverableIconButtonWithTooltip.propTypes = {
  ...HoverableIcon.propTypes,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  showTooltipOn: PropTypes.oneOf(['hover', 'click']),
  tooltipInverted: PropTypes.bool,
};
HoverableIconButtonWithTooltip.defaultProps = {
  position: undefined,
  mouseEnterDelay: 800,
  mouseLeaveDelay: 200,
  help: null,
  showTooltipOn: 'hover',
  tooltipInverted: true,
};

export const AnalyticsAwareHoverableIconButtonWithTooltip = withClickAnalytics(
  HoverableIconButtonWithTooltip,
  'onClick'
);

export default HoverableIcon;
