import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { getCampaignConfiguration } from 'actions/campaign';
import {
  actionTypes as entitiesActionTypes,
  maybeFetchOntologies,
  maybeFetchUsers,
} from 'actions/entities';
import { loadingStateSelector } from 'reducers/ui';
import {
  campaignAclSelectorFactory,
  campaignConfigurationIsInitializedSelectorFactory,
} from 'selectors/campaign';
import { usersIsLoadingSelector } from 'selectors/entities';
import { maybeFetchCampaignPermissions } from 'store/campaign/campaignPermissionsSlice';
import { clearAllFilters } from 'store/monitor/monitorSearchSlice';

import BeatingLoader from 'components/ui/BeatingLoader';

import { useMemoizedFactorySelector } from 'utils/hooks';

function CampaignLoader() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const campaignConfigurationIsInitialized = useMemoizedFactorySelector(
    campaignConfigurationIsInitializedSelectorFactory,
    campaignId
  );

  const tagSetsAreLoading = useSelector(
    loadingStateSelector([entitiesActionTypes.FETCH_TAG_SETS_REQUEST])
  );
  const usersAreLoading = useSelector(usersIsLoadingSelector);
  const acl = useSelector(campaignAclSelectorFactory(campaignId));

  const ontologiesAreLoading = useSelector(
    loadingStateSelector([entitiesActionTypes.FETCH_ONTOLOGIES_REQUEST])
  );
  useEffect(() => {
    dispatch(getCampaignConfiguration(campaignId));
    dispatch(maybeFetchOntologies());
    dispatch(maybeFetchUsers());

    return () => {
      dispatch(clearAllFilters());
    };
  }, [campaignId]);

  useEffect(() => {
    // Fetch campaign permissions if the user has admin access to the campaign (thus to members page)
    if (acl?.a) {
      dispatch(maybeFetchCampaignPermissions({ campaignId }));
    }
  }, [acl]);

  const isLoading =
    !campaignConfigurationIsInitialized ||
    tagSetsAreLoading ||
    usersAreLoading ||
    ontologiesAreLoading;

  return isLoading ? <BeatingLoader /> : <Outlet />;
}

export default CampaignLoader;
