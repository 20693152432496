import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider, Form } from 'semantic-ui-react';

import {
  END_SCREEN_ILLUSTRATION_OPTIONS,
  updateEndScreenElement,
  updateEndScreenIllustrationOption,
} from 'actions/survey';
import {
  ctaLabelEndScreenSelector,
  ctaUrlEndScreenSelector,
  endScreenIllustrationSelector,
  endScreenLabelSelector,
  showCtaEndScreenSelector,
} from 'selectors/survey';

import Header, { LightHeader } from 'components/ui/Header';
import CustomizationInput from 'components/ui/inputs/CustomizationInput';
import RadioList from 'components/ui/inputs/RadioList';
import {
  DebouncedLimitedTextInput,
  LimitedTextInput,
  TextArea,
} from 'components/ui/inputs/TextInput';
import HelpcrunchHelpTooltip from 'components/ui/navigation/HelpcrunchHelpTooltip';

import { checkUrlAndAddScheme } from 'utils/dataValidation';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import TogglableSection from './TogglableSection';

function EndScreenSettings({ endScreenIndex }) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const showCta = useSelector(
    showCtaEndScreenSelector(campaignId, endScreenIndex, true)
  );
  const ctaLabel = useSelector(
    ctaLabelEndScreenSelector(campaignId, endScreenIndex, true)
  );
  const ctaUrl = useSelector(
    ctaUrlEndScreenSelector(campaignId, endScreenIndex, true)
  );
  const endScreenIllustration = useSelector(
    endScreenIllustrationSelector(campaignId, endScreenIndex, true)
  );
  const endScreenLabel = useSelector(
    endScreenLabelSelector(campaignId, endScreenIndex, true)
  );
  const onEndScreenLabelChange = useCallback(
    (event, { value }) => {
      dispatch(
        updateEndScreenElement(campaignId, endScreenIndex, 'label', value)
      );
    },
    [dispatch, campaignId, endScreenIndex]
  );

  const onElementChange = useCallback(
    (key) => (value) => {
      dispatch(updateEndScreenElement(campaignId, endScreenIndex, key, value));
    },
    [dispatch, campaignId, endScreenIndex]
  );
  const onToggleIllustration = useCallback(
    (event, { checked }) => {
      dispatch(
        updateEndScreenIllustrationOption(campaignId, endScreenIndex, checked)
      );
    },
    [dispatch, campaignId, endScreenIndex, endScreenIllustration]
  );
  const onIllustrationChange = useCallback(
    (value) => () => {
      dispatch(
        updateEndScreenIllustrationOption(campaignId, endScreenIndex, value)
      );
    },
    [dispatch, campaignId, endScreenIndex]
  );
  const onActivateEndScreenCta = useCallback(
    (event, { checked }) => {
      dispatch(
        updateEndScreenElement(
          campaignId,
          endScreenIndex,
          'show_button',
          checked
        )
      );
    },
    [dispatch, campaignId, endScreenIndex]
  );

  const onCtaUrlChange = useCallback(onElementChange('button_url'), [
    onElementChange,
  ]);

  return (
    <>
      <Form.Field
        style={{
          margin: `0 ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium}`,
        }}
      >
        <Header>
          <Trans render={capitalizedTranslation} id="ending-settings" />
        </Header>
      </Form.Field>
      <Divider fitted />
      <Form.Field style={{ margin: `0 ${svars.spaceMedium}` }}>
        <LightHeader style={{ paddingTop: svars.spaceMedium }}>
          <Trans id="end-screen-label" />
          <HelpcrunchHelpTooltip
            smallTooltip
            articleId={66}
            position="top left"
            helpText={t({ id: 'end-screen-label-help' })}
            style={{
              marginLeft: svars.spaceSmall,
              paddingTop: svars.spaceSmaller,
            }}
          />
        </LightHeader>
        <DebouncedLimitedTextInput
          maxCharacters={60}
          value={endScreenLabel}
          onChange={onEndScreenLabelChange}
          style={{ marginBottom: svars.spaceMediumLarge }}
        />
      </Form.Field>
      <Divider fitted />
      <Form.Field>
        <LightHeader>
          <TogglableSection
            checked={!!endScreenIllustration}
            onChange={onToggleIllustration}
            title={t({ id: 'add-an-illustration' })}
            subtitle={t({ id: 'add-an-illustration-subtitle' })}
            gaCategory="Campaign - customization"
            gaAction="Use ending illustration"
            gaLabel={`${campaignId}`}
            togglableSectionTestId="bo-campaign-add-illustration-checkbox"
          >
            <RadioList
              options={END_SCREEN_ILLUSTRATION_OPTIONS}
              selected={endScreenIllustration}
              onChange={onIllustrationChange}
              style={{ margin: `${svars.spaceNormal} 0` }}
            />
          </TogglableSection>
        </LightHeader>
      </Form.Field>
      <Divider fitted />
      <Form.Field>
        <TogglableSection
          checked={showCta}
          onChange={onActivateEndScreenCta}
          title={t({ id: 'add-a-cta' })}
          subtitle={t({ id: 'add-a-cta-subtitle' })}
          gaCategory="Campaign - customization"
          gaAction="Use ending cta"
          gaLabel={`${campaignId}`}
          togglableSectionTestId="bo-campaign-add-a-call-to-action-checkbox"
        >
          <Form.Field style={{ margin: 0 }}>
            <CustomizationInput
              label={t({ id: 'button-label' })}
              placeholder="Read more"
              value={ctaLabel}
              onChange={onElementChange('button_label')}
              InputComponent={LimitedTextInput}
              maxCharacters={40}
            />
            <CustomizationInput
              style={{ marginTop: svars.spaceNormal }}
              label={t({ id: 'button-link' })}
              placeholder="https://company.com/privacy"
              value={ctaUrl}
              onChange={onCtaUrlChange}
              validate={checkUrlAndAddScheme}
              errorMessage={t({ id: 'invalid-url' })}
              InputComponent={TextArea}
            />
          </Form.Field>
        </TogglableSection>
      </Form.Field>
    </>
  );
}

EndScreenSettings.propTypes = {
  endScreenIndex: PropTypes.number.isRequired,
};

export default EndScreenSettings;
