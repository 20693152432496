import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider } from 'semantic-ui-react';

import {
  removeFaqItem,
  reorderFaqItemsFromDragAndDrop,
  resetAllFaqItemsToDefault,
  restoreFaqItem,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import { updateConfidentialityElement } from 'actions/survey';
import { faqItemsSelectorFactory } from 'selectors/campaign';
import { dpoEmailSelector, privacyPolicySelector } from 'selectors/survey';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import Header from 'components/ui/Header';
import { DraggableElementFactory } from 'components/ui/ManagementList/DraggableElement';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import CustomizationInput from 'components/ui/inputs/CustomizationInput';
import DragAndDropList from 'components/ui/inputs/DragAndDropList';
import FeedbackAppFaqItemModal from 'components/ui/inputs/FeedbackAppFaqItemModal';

import { checkUrlAndAddScheme } from 'utils/dataValidation';
import { validateEmail } from 'utils/helpers';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import CustomizationTabSegment from './CustomizationTabSegment';

function CustomizeHelpPrivacyTab() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [editingActivated, setEditingActivated] = useState(false);
  const [selectedFaqItem, setSelectedFaqItem] = useState(null);

  const faqItems = useMemoizedFactorySelector(
    faqItemsSelectorFactory,
    campaignId
  );
  const dpoEmail = useSelector(dpoEmailSelector(campaignId, true));
  const privacyPolicy = useSelector(privacyPolicySelector(campaignId, true));

  const onResetAllFaqItemsToDefault = useCallback(() => {
    dispatch(resetAllFaqItemsToDefault(campaignId));
  }, [campaignId]);
  const onReorderFaqItemsFromDragAndDrop = useCallback(
    (reorderedItems) =>
      dispatch(reorderFaqItemsFromDragAndDrop(campaignId, reorderedItems)),
    []
  );
  const onEditFaqItem = useCallback(async (faqItem) => {
    await setSelectedFaqItem(faqItem);
    setEditingActivated(true);
  }, []);
  const onDeactivateEditing = useCallback(() => setEditingActivated(false), []);
  const onOpenCreateModal = useCallback(() => setCreateModalIsOpen(true), []);
  const onDeactivateCreation = useCallback(() => {
    setCreateModalIsOpen(false);
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
  }, [campaignId]);
  const onRestoreFaqItem = useCallback(
    (item) => dispatch(restoreFaqItem(campaignId, item.id)),
    []
  );
  const onRemoveFaqItem = useCallback(
    (item) => dispatch(removeFaqItem(campaignId, item.id)),
    []
  );

  const onSetConfidentialityElement = useCallback(
    (key) => (value) => {
      dispatch(updateConfidentialityElement(campaignId, key, value));
    },
    [dispatch, campaignId]
  );
  return (
    <>
      <CustomizationTabSegment fitted="true" style={{ flexShrink: 0 }}>
        <CustomizationInput
          label={t({ id: 'dpo-email' })}
          placeholder="dpo@company.com"
          value={dpoEmail}
          onChange={onSetConfidentialityElement('dpo_email')}
          validate={validateEmail}
          errorMessage={t({ id: 'invalid-email' })}
        />
        <Divider />
        <CustomizationInput
          label={t({ id: 'link-to-privacy-policy' })}
          placeholder="https://company.com/privacy"
          value={privacyPolicy}
          onChange={onSetConfidentialityElement('organization_policy_url')}
          validate={checkUrlAndAddScheme}
          errorMessage={t({ id: 'invalid-url' })}
        />
      </CustomizationTabSegment>
      <CustomizationTabSegment style={{ overflow: 'hidden' }}>
        <Header>
          <Trans id="faq-sections" />
        </Header>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SecondaryTabButton onClick={onOpenCreateModal}>
            <Trans id="add-faq-section" />
          </SecondaryTabButton>
          <SecondaryTabButton
            onClick={onResetAllFaqItemsToDefault}
            style={{ marginLeft: svars.spaceMedium }}
          >
            <Trans id="reset-faq-sections-to-default" />
          </SecondaryTabButton>
        </div>
        <Divider />
        {faqItems?.length ? (
          <DragAndDropList
            elements={faqItems}
            onChange={onReorderFaqItemsFromDragAndDrop}
            renderElement={DraggableElementFactory(
              onEditFaqItem,
              onRestoreFaqItem,
              onRemoveFaqItem,
              t({ id: 'edit-faq-section' }),
              t({ id: 'restore-faq-section' }),
              t({ id: 'remove-faq-section' })
            )}
          />
        ) : (
          <EmptyDataPage
            layout="row"
            headerText={t({ id: 'no-faq-sections' })}
            maxHeight="150px"
            actionComponent={
              <SecondaryTabButton
                onClick={onOpenCreateModal}
                content={t({ id: 'add-faq-section' })}
              />
            }
          />
        )}

        <FeedbackAppFaqItemModal
          campaignId={campaignId}
          create
          open={createModalIsOpen}
          onClose={onDeactivateCreation}
        />
        <FeedbackAppFaqItemModal
          campaignId={campaignId}
          faqItem={selectedFaqItem}
          open={editingActivated}
          onClose={onDeactivateEditing}
        />
      </CustomizationTabSegment>
    </>
  );
}

CustomizeHelpPrivacyTab.propTypes = {};

export default CustomizeHelpPrivacyTab;
