import { useLocation } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InvertedSecondaryTabButton } from 'components/ui/button/TabButton';
import { AnalyticsAwareHelpcrunchHelpTooltip } from 'components/ui/icon/HoverableHelpIcon';
import useHelpcrunch from 'components/ui/navigation/useHelpcrunch';

import * as svars from 'assets/style/variables';

const LinkContainer = styled.div`
  margin-top: ${svars.spaceNormalLarge};
`;

function HelpcrunchHelpTooltip({
  articleId,
  anchor,
  size,
  position,
  help,
  helpLink,
  triggerText,
  helpcrunchHelpTooltipTestId,
  helpcrunchButtonLinkTestId,
  smallTooltip,
  iconColor,
  ...props
}) {
  const [handleClick, active] = useHelpcrunch(articleId, anchor);
  const location = useLocation();

  const knowledgeBaseHelpLink =
    helpLink === true ? (
      <LinkContainer onClick={handleClick}>
        <InvertedSecondaryTabButton
          compact
          content={<Trans id="see-more-knowledge-base" />}
          icon="question circle outline"
          articleId={articleId}
          onClick={handleClick}
          gaCategory={articleId ? `Article ${articleId}` : 'Home'}
          gaAction={active ? 'Close FAQ' : 'Open FAQ'}
          gaLabel={`path=${location.pathname}`}
          active={active}
          data-testid={helpcrunchButtonLinkTestId}
          {...props}
        />
      </LinkContainer>
    ) : null;

  return window.HelpCrunch ? (
    <AnalyticsAwareHelpcrunchHelpTooltip
      articleId={articleId}
      size={size || 'small'}
      help={help}
      position={position}
      helpLink={knowledgeBaseHelpLink}
      triggerText={triggerText}
      name="question circle outline"
      gaCategory={articleId ? `Article ${articleId}` : 'Home'}
      gaAction={active ? 'Close FAQ' : 'Open FAQ'}
      gaLabel={`path=${location.pathname}`}
      active={active}
      data-testid={helpcrunchHelpTooltipTestId}
      smallTooltip={smallTooltip}
      iconColor={iconColor}
      {...props}
    />
  ) : null;
}

HelpcrunchHelpTooltip.propTypes = {
  position: PropTypes.string,
  size: PropTypes.string,
  articleId: PropTypes.number,
  anchor: PropTypes.string,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  helpLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  triggerText: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
  ]),
  helpcrunchHelpTooltipTestId: PropTypes.string,
  helpcrunchButtonLinkTestId: PropTypes.string,
  smallTooltip: PropTypes.bool,
  iconColor: PropTypes.string,
};

HelpcrunchHelpTooltip.defaultProps = {
  position: 'bottom center',
  size: null,
  articleId: null,
  anchor: null,
  helpLink: true,
  triggerText: null,
  helpcrunchHelpTooltipTestId: undefined,
  helpcrunchButtonLinkTestId: undefined,
  smallTooltip: false,
  iconColor: null,
};

export default HelpcrunchHelpTooltip;
