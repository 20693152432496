import { useLocation } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { SecondaryTabButton } from 'components/ui/button/TabButton';
import useHelpcrunch from 'components/ui/navigation/useHelpcrunch';

import withClickAnalytics from 'utils/withAnalytics';

export const AnalyticsAwareHelpcrunchButtonLink = withClickAnalytics(
  SecondaryTabButton,
  'onClick'
);

export function HelpcrunchButtonLinkBase({
  ButtonComponent,
  articleId,
  anchor,
  helpcrunchButtonLinkTestId,
  ...props
}) {
  const [handleClick, active] = useHelpcrunch(articleId, anchor);

  return (
    <ButtonComponent
      compact
      content={<Trans id="see-more-knowledge-base" />}
      icon="question circle outline"
      articleId={articleId}
      onClick={handleClick}
      gaCategory={articleId || 'Home'}
      gaAction={active ? 'Close FAQ' : 'Open FAQ'}
      active={active}
      data-testid={helpcrunchButtonLinkTestId}
      {...props}
    />
  );
}

HelpcrunchButtonLinkBase.propTypes = {
  ButtonComponent: PropTypes.elementType,
  articleId: PropTypes.number,
  anchor: PropTypes.string,
  helpcrunchButtonLinkTestId: PropTypes.string,
};

HelpcrunchButtonLinkBase.defaultProps = {
  ButtonComponent: SecondaryTabButton,
  articleId: null,
  anchor: null,
  helpcrunchButtonLinkTestId: undefined,
};

function HelpcrunchButtonLink({ ...props }) {
  const location = useLocation();

  return window.HelpCrunch ? (
    <HelpcrunchButtonLinkBase locationPath={location.pathname} {...props} />
  ) : null;
}

HelpcrunchButtonLink.propTypes = {
  articleId: PropTypes.number,
  anchor: PropTypes.string,
  helpcrunchButtonLinkTestId: PropTypes.string,
};

HelpcrunchButtonLink.defaultProps = {
  articleId: null,
  anchor: null,
  helpcrunchButtonLinkTestId: undefined,
};

export default HelpcrunchButtonLink;
