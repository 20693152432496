import { Component } from 'react';
import { connect } from 'react-redux';
import { createSearchParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans, msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import {
  maybeFetchConceptBasedAggregates,
  maybeFetchConceptWordcloud,
  actionTypes as viewActionTypes,
} from 'actions/view';
import { createLoadingSelector } from 'reducers/ui';
import {
  entityLabelFormatter as baseEntityLabelFormatter,
  tagLabelFormatterSelector,
} from 'selectors/entities';
import { getFacetAggregatesConceptAggregates } from 'selectors/view';

import moment from 'moment';

import ConceptTimeSeriesVisualizations from 'components/customer/visualization/ConceptTimeSeriesVisualizations';
import ScatterChartWithExportModal from 'components/customer/visualization/scatter-chart/ScatterChartWithExportModal';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import {
  MediumSpacePaddedRow,
  NoMarginGrid,
  NormalLargerSpacePaddedGrid,
} from 'components/ui/Grid';
import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import Segment from 'components/ui/Segment';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import Breadcrumb from 'components/ui/navigation/Breadcrumb';
import HelpcrunchHelpTooltip from 'components/ui/navigation/HelpcrunchHelpTooltip';
import emptyExplorationUrl from 'components/ui/svg/undraw_visual_data_b1wx.svg';
import HierarchicalTreemap from 'components/ui/visualization/HierarchicalTreemap';
import Statistics from 'components/ui/visualization/Statistics';

import { getInterpolatedColor } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import {
  floatFormatter,
  numberFormatter,
  onePrecisionFloatFormatter,
} from 'utils/formatter';
import { areEqualShallow, capitalize } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import DashboardKpi from '../dashboard/DashboardKpi';
import ConceptDrilldown from './ConceptDrilldown';
import {
  DISTRIBUTION_STATIC_DIMENSIONS,
  ExplorationBreakdownButtonWithModal,
} from './ExplorationBreakdownModal';
import ExplorationOntologyTable from './ExplorationOntologyTable';
import ExplorationSecondaryNavbar from './ExplorationSecondaryNavbar';

const EXPLORATION_CHART_HEIGHT = 300;
const EXPLORE_CHART_ID = 'explore-scatter';
const CONCEPT_FOCUS_EXPORT_ID = 'explore-concept-focus';

const EXPLORATION_MODE_ITEMS = [
  {
    key: 'treemap',
    i18nLabel: msg({ id: 'treemap-view' }),
    value: 'treemap',
  },
  { key: 'table', i18nLabel: msg({ id: 'table-view' }), value: 'table' },
  { key: 'graph', i18nLabel: msg({ id: 'graphical-view' }), value: 'graph' },
];

class V2DashboardExplore extends Component {
  constructor(props) {
    super(props);
    const ontologyId =
      props.entities?.concepts?.[props.locationQuery.concept]?.ontologyId ||
      (Object.keys(props.entities.ontologies) === 1 &&
        Object.keys(props.entities.ontologies)[0]) ||
      null;

    this.state = {
      ontologyId,
      selectedConceptItem: undefined,
      mappedData: undefined,
      displayLeafConceptsOnly: true,
      ...(ontologyId && props.timeSeriesVisualizations.conceptKpis
        ? this.makeData(ontologyId, props.locationQuery.concept)
        : {}),
      exploration: 'treemap',
      exportChartModalIsOpen: false,
      selectedGlobalDistribution: null,
      selectedConceptDistribution: null,
      // Concept drilldown state
      wordcloudPolarity: 'all',
      selectedWord: undefined,
    };
    this.onSelectConcept = this.onSelectConcept.bind(this);
    this.onSelectOntology = this.onSelectOntology.bind(this);
    this.onSelectGlobalDistribution =
      this.onSelectGlobalDistribution.bind(this);
    this.onSelectConceptDistribution =
      this.onSelectConceptDistribution.bind(this);
  }

  componentDidMount() {
    const {
      viewFacet,
      locationQuery,
      maybeFetchTimeSeriesVisualizationsAggregates,
    } = this.props;
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'general_dist');
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'product_kpi');
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'concept_kpi');
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'tag_kpi');
    // If parameters were given by previous link, scroll to top

    if (locationQuery.concept) {
      this.fetchConceptAggregates(viewFacet.id, locationQuery.concept);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { viewFacet, entities, timeSeriesVisualizations, locationQuery } =
      this.props;
    const { ontologyId } = this.state;

    if (
      Object.keys(entities.ontologies).length &&
      // Do not compute if no ontology is selected so exploration does not display
      ontologyId &&
      timeSeriesVisualizations?.conceptKpis &&
      (!areEqualShallow(prevProps.locationQuery, locationQuery) ||
        !prevState.mappedData ||
        prevProps.timeSeriesVisualizations?.conceptKpis !==
          timeSeriesVisualizations?.conceptKpis)
    ) {
      const conceptId = locationQuery.concept;
      // Possibly update ontologyId if conceptId is from another ontology
      let newOntologyId = entities?.concepts?.[conceptId]?.ontologyId;
      if (newOntologyId && newOntologyId !== ontologyId) {
        this.setState({
          ontologyId: newOntologyId,
        });
      } else {
        newOntologyId = ontologyId;
      }
      const newData = {
        ...this.makeData(newOntologyId, conceptId),
      };
      if (conceptId) {
        newData.selectedConceptItem = newData.customTreemapData.find(
          (item) => item.id === conceptId
        );
        this.fetchConceptAggregates(viewFacet.id, conceptId);
      }
      this.setState(newData);
    }
  }

  onSelectConcept(id, withUnselect = false) {
    const { viewFacet } = this.props;
    const { customTreemapData, selectedConceptItem } = this.state;
    if (id == null) {
      this.setState({
        selectedConceptItem: undefined,
      });
    }

    const item = customTreemapData?.find(
      (treemapItem) => treemapItem?.id === id
    );
    if (id == null || (item && item === selectedConceptItem && withUnselect)) {
      this.setState({
        selectedConceptItem: undefined,
      });
    } else {
      this.setState({ selectedConceptItem: item });
      this.fetchConceptAggregates(viewFacet.id, id);
    }
  }

  onSelectOntology(_, { value }) {
    this.setState({
      ontologyId: value,
      selectedConceptItem: undefined,
      ...this.makeData(value),
    });
  }

  getSearchRouteParameters = (texts, wordcloudPolarity) => {
    const { viewFacet } = this.props;
    const { selectedConceptItem } = this.state;

    const filters = {};
    if (texts.length) {
      filters.text = texts;
    }
    if (wordcloudPolarity === 'all') {
      filters.positive = 1;
      filters.negative = 1;
    } else if (wordcloudPolarity) {
      filters[wordcloudPolarity] = 1;
    }
    if (selectedConceptItem) {
      filters.concept = selectedConceptItem.id;
    }
    return `/facets/analyze/${viewFacet.id}/search?${createSearchParams({
      ...filters,
      phg: viewFacet.base_product_hierarchy_group.id,
    })}`;
  };

  toggleExportChartModal = () => {
    const { exportChartModalIsOpen } = this.state;
    this.setState({
      exportChartModalIsOpen: !exportChartModalIsOpen,
    });
  };

  fetchConceptAggregates = (viewFacetId, conceptId) => {
    const { onMaybeFetchConceptWordcloud, onMaybeFetchConceptBasedAggregates } =
      this.props;
    // Do not try to fetch if navigsting back to root (e.g. no concept)
    if (!conceptId) return;
    onMaybeFetchConceptWordcloud(viewFacetId, conceptId);
    onMaybeFetchConceptBasedAggregates(
      viewFacetId,
      conceptId,
      'concept_product_kpi'
    );
    onMaybeFetchConceptBasedAggregates(
      viewFacetId,
      conceptId,
      'concept_source_kpi'
    );
    onMaybeFetchConceptBasedAggregates(
      viewFacetId,
      conceptId,
      'concept_tag_kpi'
    );
    onMaybeFetchConceptBasedAggregates(viewFacetId, conceptId, 'concept_ts');
  };

  getEntityLabelFormatter = (entityName) => (item) => {
    const { entityLabelFormatter } = this.props;
    return entityLabelFormatter(entityName, item);
  };

  conceptLabelFormatter = (conceptId) => {
    const { entityLabelFormatter } = this.props;
    return entityLabelFormatter('concept', conceptId);
  };

  onSelectGlobalDistribution = (event, { value }) =>
    this.setState({ selectedGlobalDistribution: value });

  closeGlobalDistributionModal = () =>
    this.onSelectGlobalDistribution(null, { value: null });

  onSelectConceptDistribution = (event, { value }) =>
    this.setState({ selectedConceptDistribution: value });

  closeConceptDistributionModal = () =>
    this.onSelectConceptDistribution(null, { value: null });

  onScatterChartShapeClick = (event, target) => {
    const { exportChartModalIsOpen } = this.state;
    // If chart is being displayed in export modal do not use click related props
    if (!exportChartModalIsOpen) {
      return this.onSelectConcept(target.id, true);
    }
    return null;
  };

  onWordcloudPolarityChange = (_, { value }) =>
    this.setState({
      wordcloudPolarity: value,
      selectedWord: undefined,
    });

  onSelectedWordChange = (word) => {
    const { selectedWord } = this.state;
    this.setState({ selectedWord: selectedWord === word ? null : word });
  };

  makeData(ontologyId, selectedConceptId = null) {
    const {
      timeSeriesVisualizations: { conceptKpis },
      entities: { concepts },
    } = this.props;
    const parents = [];
    let selectedKpis = null;
    if (conceptKpis && selectedConceptId && !ontologyId) {
      selectedKpis = Object.entries(conceptKpis).flatMap(
        ([, ontologyKpis]) => ontologyKpis
      );
    } else if (
      conceptKpis &&
      concepts &&
      conceptKpis[ontologyId] &&
      Object.keys(concepts).length
    ) {
      selectedKpis = Object.entries(conceptKpis[ontologyId]);
    }

    const data = selectedKpis
      ? [
          ...selectedKpis.map(([conceptId, ontologyConceptKpis]) => {
            const concept = concepts[conceptId];
            const parentConceptId = concept.parent?.id || null;
            if (parentConceptId != null) {
              parents.push(parentConceptId);
            }
            return [
              concept.name,
              parentConceptId ? concepts[parentConceptId].name : 'Global',
              ontologyConceptKpis[0].n_chunks,
              ontologyConceptKpis[0].average_sentiment,
              ontologyConceptKpis[0].share_of_extracts,
              concept.level,
              conceptId,
              parentConceptId,
            ];
          }),
        ]
      : [];

    // Mapped data - used for table and graph exploration
    const mappedData = {};
    const customTreemapData = [];
    const customTreemapDataLeavesOnly = [];
    data.forEach((row) => {
      if (row[6] != null) {
        mappedData[row[6]] = row;
        const item = {
          id: row[6],
          n_chunks: row[2],
          share_of_extracts: row[4],
          label: row[0],
          average_sentiment: row[3],
          parent_id: row[7],
        };
        customTreemapData.push(item);
        if (!parents.includes(item.id)) {
          customTreemapDataLeavesOnly.push(item);
        }
      }
    });

    const dataState = {
      mappedData,
      customTreemapData,
      customTreemapDataLeavesOnly,
    };
    // If a concept is selected, select the related row
    if (selectedConceptId) {
      const selectedItem = customTreemapData.find(
        (item) => item.id === selectedConceptId
      );
      if (selectedItem) dataState.selectedConceptItem = selectedItem;
    }
    return dataState;
  }

  renderConceptTimeSeries() {
    const {
      viewFacet,
      timeSeriesVisualizations,
      entities,
      getConceptAggregates,
      conceptAggregatesIsLoading,
      tagLabelFormatter,
      entityLabelFormatter,
    } = this.props;
    const {
      selectedConceptItem,
      customTreemapData,
      selectedConceptDistribution,
      ontologyId,
    } = this.state;
    if (!selectedConceptItem) {
      return (
        <EmptyDataPage
          i18nHeaderText={msg({ id: 'dashboard-explore.explore-a-category' })}
          actionComponent={
            <Trans id="dashboard-explore.explore-a-category-description" />
          }
          illustrationUrl={emptyExplorationUrl}
        />
      );
    }
    const conceptId = selectedConceptItem.id;
    const conceptTimeSeriesVizualisations = getConceptAggregates(
      viewFacet.id,
      conceptId
    ).conceptTimeSeries;
    const concept = entities.concepts[conceptId];
    let nChunks = 0;
    let averageSentiment = 0;
    const selectedConceptKpis =
      timeSeriesVisualizations?.conceptKpis?.[ontologyId]?.[conceptId];
    if (selectedConceptKpis) {
      nChunks = selectedConceptKpis[0].n_chunks;
      averageSentiment = selectedConceptKpis[0].average_sentiment;
    }
    const conceptAggregates = getConceptAggregates(viewFacet.id, conceptId);
    let breakdownModalProps = {
      globalAverageSentiment:
        (selectedConceptDistribution &&
          timeSeriesVisualizations?.kpis?.kpis?.[0]?.average_sentiment) ||
        0,
    };
    const staticDistributionItem = DISTRIBUTION_STATIC_DIMENSIONS.find(
      ({ value }) => value === selectedConceptDistribution
    );
    if (staticDistributionItem) {
      breakdownModalProps = {
        categoryHeader: i18n._(staticDistributionItem.text),
        categoryDataKey: selectedConceptDistribution,
        modalIsOpen: !!selectedConceptDistribution,
        labelFormatter: this.getEntityLabelFormatter(
          staticDistributionItem.value
        ),
      };
      if (staticDistributionItem.value === 'product_hierarchy') {
        const conceptProductKpis = conceptAggregates.productKpis;
        breakdownModalProps.disabled = !(conceptProductKpis?.length || false);
        breakdownModalProps.data = conceptProductKpis;
      } else {
        const conceptSourceKpis = conceptAggregates.sourceKpis;
        breakdownModalProps.disabled = !(conceptSourceKpis?.length || false);
        breakdownModalProps.data = conceptSourceKpis;
      }
    } else if (selectedConceptDistribution) {
      const conceptTagKpis = conceptAggregates?.tagKpis?.kpis || {};
      breakdownModalProps = {
        categoryDataKey: 'tag',
        categoryHeader: selectedConceptDistribution
          ? entityLabelFormatter('tagSet', selectedConceptDistribution)
          : null,
        data: conceptTagKpis[selectedConceptDistribution] || null,
        disabled: !Object.keys(conceptTagKpis).length,
        modalIsOpen: selectedConceptDistribution,
        labelFormatter: tagLabelFormatter,
      };
    }
    const conceptName = concept.name;
    return (
      <NoMarginGrid id={`${CONCEPT_FOCUS_EXPORT_ID}-${concept.id}`}>
        <Grid.Row>
          <Grid.Column
            width={15}
            as={Header}
            data-testid="exploration-concept-name"
          >
            {concept.name}
          </Grid.Column>
          <Grid.Column width={1}>
            <ExportAsImage
              tooltipPosition="top left"
              disabled={conceptAggregatesIsLoading}
              exportId={`${CONCEPT_FOCUS_EXPORT_ID}-${concept.id}`}
              exportName={concept.name}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column
            width={8}
            textAlign="right"
            data-testid="exploration-sentiment-value"
          >
            <Statistics nChunks={nChunks} averageSentiment={averageSentiment} />
          </Grid.Column>
          <Grid.Column
            width={8}
            verticalAlign="bottom"
            textAlign="left"
            style={{ paddingLeft: svars.spaceLarge }}
            data-html2canvas-ignore="true"
          >
            <ExplorationBreakdownButtonWithModal
              title={t`dashboard-explore.concept-distribution-modal-title ${conceptName}`}
              onSelectConcept={this.onSelectConcept}
              onDistributionChange={this.onSelectConceptDistribution}
              currentDistribution={selectedConceptDistribution}
              modalIsOpen={!!selectedConceptDistribution}
              globalAverageSentiment={averageSentiment}
              onClose={this.closeConceptDistributionModal}
              path={Breadcrumb.getHierarchichalPath(
                selectedConceptItem?.id,
                customTreemapData
              )}
              withShareOfExtracts
              {...breakdownModalProps}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {(conceptTimeSeriesVizualisations ||
              conceptAggregatesIsLoading) && (
              <ConceptTimeSeriesVisualizations
                loading={conceptAggregatesIsLoading}
                data={conceptTimeSeriesVizualisations}
                id={`ts-concept-${viewFacet.id}-${conceptId}`}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </NoMarginGrid>
    );
  }

  renderExplorationChart() {
    const {
      customTreemapData,
      customTreemapDataLeavesOnly,
      displayLeafConceptsOnly,
      exportChartModalIsOpen,
      selectedConceptItem,
    } = this.state;

    return (
      <NoMarginGrid>
        <Grid.Row style={{ padding: 0 }} verticalAlign="bottom">
          <Grid.Column width={7}>
            <span
              style={{ fontSize: svars.fontSizeSmaller, fontStyle: 'italic' }}
            >
              <Trans id="dashboard-explore.click-on-dot-to-see-details" />.
            </span>
          </Grid.Column>
          <Grid.Column width={7} floated="right" textAlign="right">
            <HelpTooltip
              help={<Trans id="help-message.parent-category-description" />}
              size="tiny"
              position="top center"
              trigger={
                <AnalyticsAwareCheckbox
                  gaCategory="Customer"
                  gaAction="Analyze - toggle parents nodes"
                  gaLabel={`${displayLeafConceptsOnly}`}
                  clicked={displayLeafConceptsOnly ? 1 : 0}
                  onClick={() =>
                    this.setState({
                      displayLeafConceptsOnly: !displayLeafConceptsOnly,
                    })
                  }
                  style={{
                    borderRadius: 0,
                    textAlign: 'initial',
                    fontWeight: svars.fontWeightBase,
                    fontSize: svars.fontSizeSmaller,
                  }}
                  label={capitalize(
                    t({ id: 'display-also-parent-categories' })
                  )}
                  checked={!displayLeafConceptsOnly}
                  onChange={this.onTableViewChange}
                />
              }
            />
          </Grid.Column>
          <Grid.Column width={2} floated="right" textAlign="right">
            <ExportAsImage
              exportId={EXPLORE_CHART_ID}
              tooltipPosition="top left"
              onClick={this.toggleExportChartModal}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ScatterChartWithExportModal
              chartId={EXPLORE_CHART_ID}
              isAnimationActive={false}
              data={
                displayLeafConceptsOnly
                  ? customTreemapDataLeavesOnly
                  : customTreemapData
              }
              labelFormatter={this.conceptLabelFormatter}
              height={EXPLORATION_CHART_HEIGHT}
              categoryDataKey="id"
              colorFormatter={(item) =>
                getInterpolatedColor(item?.average_sentiment)
              }
              getRadius={svars.getPercentScatterRadius('share_of_extracts')}
              radiusTooltipFieldKey="share_of_extracts"
              onShapeClick={this.onScatterChartShapeClick}
              getOpacity={(item) =>
                ((exportChartModalIsOpen || !selectedConceptItem) && 1) ||
                item.id === selectedConceptItem.id
                  ? 1
                  : 0.3
              }
              modalIsOpen={exportChartModalIsOpen}
              onCloseModal={this.toggleExportChartModal}
              exportName={i18n._(
                EXPLORATION_MODE_ITEMS.find(({ key }) => key === 'graph')
                  .i18nLabel
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </NoMarginGrid>
    );
  }

  renderExplorationTreemap() {
    const { ontologyId, selectedConceptItem, customTreemapData } = this.state;

    return (
      <HierarchicalTreemap
        id={`hierarchical-treemap-${ontologyId}`}
        parentNodeId={selectedConceptItem ? selectedConceptItem.id : null}
        data={customTreemapData}
        onClick={this.onSelectConcept}
        height={EXPLORATION_CHART_HEIGHT}
        tooltipSentimentLabel={t({ id: 'sentiment' })}
        tooltipVolumeLabel={t({ id: 'volume' })}
        tooltipShareOfExtractsLabel={t({ id: 'share-of-extracts' })}
      />
    );
  }

  renderExplorationMode() {
    const { selectedConceptItem, exploration, customTreemapData, ontologyId } =
      this.state;
    let explorationMode;
    switch (exploration) {
      case 'treemap':
        explorationMode = this.renderExplorationTreemap();
        break;
      case 'table':
        explorationMode = (
          <ExplorationOntologyTable
            data={customTreemapData}
            selectedRow={selectedConceptItem}
            onRowClick={this.onSelectConcept}
            key={`treemap-table-${ontologyId}`}
            height={EXPLORATION_CHART_HEIGHT}
          />
        );
        break;
      case 'graph':
        explorationMode = this.renderExplorationChart();
        break;
      default:
        explorationMode = null;
    }
    return (
      <NoMarginGrid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <ButtonGroup
              as={SecondaryTabButton}
              items={EXPLORATION_MODE_ITEMS}
              onChange={(_, { value }) => this.setState({ exploration: value })}
              value={exploration}
              style={{ paddingBottom: svars.spaceNormal }}
              testid="explore-switch-view-button"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} style={{ padding: 0 }}>
          <Grid.Column>{explorationMode}</Grid.Column>
        </Grid.Row>
      </NoMarginGrid>
    );
  }

  renderEmptyExplorationCase() {
    const {
      entities: { ontologiesById },
    } = this.props;
    const { ontologyId } = this.state;

    return (
      <NormalLargerSpacePaddedGrid style={{ paddingTop: '10vh' }}>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header>
              <Trans id="dashboard-explore.select-an-ontology-to-start-exploring" />
              .
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <ButtonGroup
              items={Object.values(ontologiesById).map(
                ({ id, display_name }) => ({
                  key: id,
                  label: display_name,
                  value: id,
                })
              )}
              onChange={(_, { value }) => this.setState({ ontologyId: value })}
              value={ontologyId}
              centered
            />
          </Grid.Column>
        </Grid.Row>
      </NormalLargerSpacePaddedGrid>
    );
  }

  renderHeader() {
    const {
      viewFacet,
      tagLabelFormatter,
      timeSeriesVisualizations: {
        kpis,
        productHierarchyKpis,
        tagKpis,
        generalDistributions,
      } = {},
      entityLabelFormatter,
    } = this.props;
    const { selectedGlobalDistribution } = this.state;
    const updateDate = moment(viewFacet.aggregates_update_date);
    const distributionItem = DISTRIBUTION_STATIC_DIMENSIONS.find(
      ({ value }) => value === selectedGlobalDistribution
    );
    let breakdownModalProps = {};
    if (distributionItem) {
      breakdownModalProps = {
        data:
          selectedGlobalDistribution === 'source'
            ? generalDistributions?.source
            : productHierarchyKpis?.kpis,
        categoryDataKey: selectedGlobalDistribution,
        disabled: !productHierarchyKpis?.kpis?.length,
        labelFormatter: this.getEntityLabelFormatter(
          selectedGlobalDistribution
        ),
        categoryHeader: i18n._(distributionItem?.text),
      };
    } else if (selectedGlobalDistribution) {
      const tagSetName = entityLabelFormatter(
        'tagSet',
        selectedGlobalDistribution
      );
      breakdownModalProps = {
        data: tagKpis?.kpis?.[selectedGlobalDistribution],
        categoryDataKey: 'tag',
        disabled: !Object.keys(tagKpis?.kpis || {}).length,
        categoryHeader: tagSetName,
        labelFormatter: tagLabelFormatter,
      };
    }
    return (
      <MediumSpacePaddedRow
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          // minHeight: svars.dashboardHeaderMinHeight,
          borderBottom: `1px solid ${svars.colorLightGrey}`,
        }}
      >
        <Grid.Column width={6}>
          <Header style={{ fontSize: svars.fontSizeBig }}>
            <Header.Subheader style={{ paddingBottom: svars.spaceMedium }}>
              <Trans id="analysis-updated-on" />
              {' : '}
              {updateDate.format(svars.dateTimeFormat)}
            </Header.Subheader>
            <Header.Content>
              <Trans id="dashboard-explore.title" />
              <HelpcrunchHelpTooltip
                helpText={t({
                  id: 'explore-analysis-explanation-help-crunch-text',
                })}
                articleId={4}
                helpcrunchHelpTooltipTestId="bo-helpcrunch-article-4"
              />
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column verticalAlign="bottom" textAlign="left" width={6}>
          <div style={{ display: 'flex' }}>
            <DashboardKpi
              name={<Trans id="volume" render={capitalizedTranslation} />}
              value={kpis && kpis.kpis ? kpis.kpis[0].n_chunks : '-'}
              increase={
                kpis && kpis.increases && kpis.increases['1M']
                  ? kpis.increases['1M'][0].n_chunks
                  : '-'
              }
              formatter={numberFormatter}
              increaseFormatter={onePrecisionFloatFormatter}
              valueColor={svars.volumeColor}
              description={<Trans id="help-message.volume-description" />}
              wrapperStyle={{ marginRight: svars.spaceMediumLarge }}
              articleId={23}
              helpcrunchHelpTooltipTestId="bo-helpcrunch-article-23"
            />
            <DashboardKpi
              name={<Trans id="sentiment" render={capitalizedTranslation} />}
              value={kpis && kpis.kpis ? kpis.kpis[0].average_sentiment : '-'}
              increase={
                kpis && kpis.increases && kpis.increases['1M']
                  ? kpis.increases['1M'][0].average_sentiment
                  : '-'
              }
              formatter={floatFormatter}
              increaseFormatter={floatFormatter}
              description={
                <Trans id="help-message.average-sentiment-description" />
              }
              wrapperStyle={{ marginRight: svars.spaceMediumLarge }}
              absoluteIncrease
              articleId={7}
              helpcrunchHelpTooltipTestId="bo-helpcrunch-article-7"
            />
            <ExplorationBreakdownButtonWithModal
              title={t({ id: 'dashboard-explore.distribution-modal-title' })}
              globalAverageSentiment={kpis?.kpis?.[0]?.average_sentiment || 0}
              modalIsOpen={selectedGlobalDistribution}
              onClose={this.closeGlobalDistributionModal}
              onDistributionChange={this.onSelectGlobalDistribution}
              currentDistribution={selectedGlobalDistribution}
              {...breakdownModalProps}
            />
          </div>
        </Grid.Column>
      </MediumSpacePaddedRow>
    );
  }

  render() {
    const { entities, hidden, wordClouds, wordcloudsIsLoading } = this.props;
    const {
      mappedData,
      selectedConceptItem,
      customTreemapData,
      wordcloudPolarity,
      selectedWord,
      ontologyId,
    } = this.state;

    // Test - do not unmount rendered components when hidden
    // To avoid losing their states
    // Notably the selected tag could and selected ontology term
    // TODO : confirm it works or come back to umount for these components
    if (hidden || !entities.ontologies) return null;

    const singleAnalyzedOntology =
      Object.keys(entities.ontologies).length === 1;
    return (
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          display: hidden ? 'none' : 'block',
        }}
      >
        {this.renderHeader()}
        <NoMarginGrid>
          {ontologyId ? null : this.renderEmptyExplorationCase()}

          {mappedData ? (
            <>
              <ExplorationSecondaryNavbar
                ontologyId={ontologyId}
                selectedConceptItem={selectedConceptItem}
                customTreemapData={customTreemapData}
                singleAnalyzedOntology={singleAnalyzedOntology}
                onSelectOntology={this.onSelectOntology}
                onSelectConcept={this.onSelectConcept}
                getSearchRouteParameters={this.getSearchRouteParameters}
              />
              <MediumSpacePaddedRow
                style={{
                  paddingTop: svars.spaceMedium,
                  paddingBottom: 0,
                  // Required to avoid glitch between loading state and time series display
                  minHeight: '410px',
                }}
              >
                <Grid.Column width={9} stretched>
                  <Segment>
                    {ontologyId && this.renderExplorationMode()}
                  </Segment>
                </Grid.Column>
                <Grid.Column width={7} stretched>
                  <Segment>{this.renderConceptTimeSeries()}</Segment>
                </Grid.Column>
              </MediumSpacePaddedRow>
              <ConceptDrilldown
                wordcloudPolarity={wordcloudPolarity}
                selectedWord={selectedWord}
                onWordcloudPolarityChange={this.onWordcloudPolarityChange}
                onSelectedWordChange={this.onSelectedWordChange}
                wordClouds={wordClouds}
                wordcloudsIsLoading={wordcloudsIsLoading}
                selectedConceptItem={selectedConceptItem}
                getSearchRouteParameters={this.getSearchRouteParameters}
              />
            </>
          ) : null}
        </NoMarginGrid>
      </div>
    );
  }
}

V2DashboardExplore.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
  timeSeriesVisualizations: commonPropTypes.timeSeriesVisualizations.isRequired,
  wordClouds: commonPropTypes.wordClouds.isRequired,
  entities: commonPropTypes.entities.isRequired,
  locationQuery: PropTypes.shape({
    concept: PropTypes.string,
  }).isRequired,
  maybeFetchTimeSeriesVisualizationsAggregates: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  entityLabelFormatter: PropTypes.func.isRequired,
  tagLabelFormatter: PropTypes.func.isRequired,
  onMaybeFetchConceptWordcloud: PropTypes.func.isRequired,
  onMaybeFetchConceptBasedAggregates: PropTypes.func.isRequired,
  getConceptAggregates: PropTypes.func.isRequired,
  conceptAggregatesIsLoading: PropTypes.bool.isRequired,
  wordcloudsIsLoading: PropTypes.bool.isRequired,
};

V2DashboardExplore.defaultProps = {
  hidden: false,
};

const conceptAggregatesLoadingSelector = createLoadingSelector([
  viewActionTypes.FETCH_CONCEPT_AGGREGATES_REQUEST,
]);
const wordcloudLoadingSelector = createLoadingSelector([
  viewActionTypes.FETCH_WORDCLOUD_V2_REQUEST,
]);

export default connect(
  (state, { viewFacet }) => ({
    timeSeriesVisualizations:
      state.view.viewFacetAggregates[viewFacet?.id]?.timeSeriesVisualizations,
    wordClouds: state.view.viewFacetAggregates[viewFacet?.id]?.wordClouds,
    isLoading: state.ui.isLoading,
    getConceptAggregates: (viewFacetId, conceptId) =>
      getFacetAggregatesConceptAggregates(
        state.view.viewFacetAggregates,
        viewFacetId,
        conceptId
      ),
    conceptAggregatesIsLoading: conceptAggregatesLoadingSelector(state),
    wordcloudsIsLoading: wordcloudLoadingSelector(state),

    entities: state.entities,
    entityLabelFormatter: (entityType, id, meta) =>
      baseEntityLabelFormatter(state.entities, entityType, id, meta),
    tagLabelFormatter: tagLabelFormatterSelector(state),
  }),
  (dispatch) => ({
    onMaybeFetchConceptWordcloud: (viewFacetId, conceptId) =>
      dispatch(maybeFetchConceptWordcloud(viewFacetId, conceptId)),
    onMaybeFetchConceptBasedAggregates: (
      viewFacetId,
      conceptId,
      aggregateType
    ) =>
      dispatch(
        maybeFetchConceptBasedAggregates(viewFacetId, conceptId, aggregateType)
      ),
  })
)(V2DashboardExplore);
