import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import { hoverableIconStyle } from 'components/ui/icon/HoverableIcon';

import withClickAnalytics from 'utils/withAnalytics';

import * as svars from 'assets/style/variables';

const HoverableIcon = styled(Icon)`
  &&&& {
    ${hoverableIconStyle};
    height: ${svars.spaceMediumLarge};
    width: ${svars.spaceMedium};
    padding: ${svars.spaceSmall};
    margin-left: ${svars.spaceNormal};
    opacity: 1;
    font-size: ${({ smallTooltip }) =>
      smallTooltip ? svars.fontSizeSmall : 'initial'};
    color: ${({ iconColor }) => iconColor || svars.accentColor};
  }
`;

const StyledHelpTitle = styled.div`
  &&& {
    margin-bottom: ${svars.spaceSmaller};
    font-weight: ${svars.fontWeightBold};
    font-size: ${svars.fontSizeBase};

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const StyledHelpText = styled.div`
  &&& {
    font-size: ${svars.fontSizeBase};
  }
`;

const StyledLink = styled.div`
  font-weight: ${svars.fontWeightBold};
  color: ${svars.accentColorLightest};
  text-decoration: underline;
  cursor: pointer;
`;

const TriggerWrapper = styled.div`
  display: inline-block;
  margin-top: ${svars.spaceXSmall};
`;

function HoverableHelpIcon({ smallTooltip, iconColor, triggerText, ...props }) {
  return (
    <HoverableIcon
      smallTooltip={smallTooltip}
      iconColor={iconColor}
      triggerText={triggerText}
      {...props}
    />
  );
}

HoverableHelpIcon.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  smallTooltip: PropTypes.bool,
  iconColor: PropTypes.string,
  triggerText: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
  ]),
};

HoverableHelpIcon.defaultProps = {
  active: 'true',
  smallTooltip: false,
  iconColor: null,
  triggerText: null,
};

export function HelpcrunchHelpTooltip({
  helpTitle,
  helpText,
  helpLink,
  triggerText,
  active,
  inverted,
  tooltipInverted,
  position,
  trigger,
  disabled,
  disabledHelp,
  smallTooltip,
  iconColor,
  ...hoverableIconProps
}) {
  const resolvedHelpText = disabled ? disabledHelp || null : helpText;

  const combinedHelp = (
    <div>
      {helpTitle && (
        <StyledHelpTitle svars={svars}>{helpTitle}</StyledHelpTitle>
      )}
      <StyledHelpText>{resolvedHelpText}</StyledHelpText>
      <StyledLink>{helpLink}</StyledLink>
    </div>
  );

  const combinedTrigger = trigger || (
    <TriggerWrapper>
      {triggerText}
      <HoverableHelpIcon
        active={active ? 'true' : undefined}
        inverted={inverted ? 'true' : undefined}
        disabled={disabled}
        smallTooltip={smallTooltip}
        iconColor={iconColor}
        triggerText={triggerText}
        {...hoverableIconProps}
      />
    </TriggerWrapper>
  );

  return (
    <HelpTooltip
      help={[combinedHelp]}
      compact
      size="tiny"
      inverted={tooltipInverted}
      position={position}
      disabled={!resolvedHelpText || (disabled && !disabledHelp)}
      trigger={disabled && disabledHelp ? { combinedTrigger } : combinedTrigger}
      triggerText={triggerText}
      baseColor
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
    />
  );
}

HelpcrunchHelpTooltip.propTypes = {
  ...HoverableIcon.propTypes,
  helpTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helpLink: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  triggerText: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
  ]),
  position: PropTypes.string,
  tooltipInverted: PropTypes.bool,
  smallTooltip: PropTypes.bool,
  iconColor: PropTypes.string,
};

HelpcrunchHelpTooltip.defaultProps = {
  helpTitle: null,
  helpText: null,
  helpLink: null,
  triggerText: null,
  position: undefined,
  tooltipInverted: true,
  smallTooltip: false,
  iconColor: null,
};

export const AnalyticsAwareHelpcrunchHelpTooltip = withClickAnalytics(
  HelpcrunchHelpTooltip,
  'onClick'
);

export default HoverableIcon;
