import { createSelector } from '@reduxjs/toolkit';
import {
  addEmptyItem,
  productHierarchiesSelector,
  productHierarchyGroupsSelector,
  tagSetsSelector,
  tagsSelector,
} from 'reducers/entityLabelFormatter';
import { viewFacetSelector } from 'selectors/view';

const DEFAULT_LIST = [];

export const facetsSelector = (state) => state.facet.viewFacets;

export const viewFacetByIdSelector = (viewFacetId) =>
  createSelector(
    facetsSelector,
    (facets) => facets?.find((v) => v.id === viewFacetId) || null
  );

export const isComparativeViewFacet = (viewFacet) =>
  viewFacet.comparative_product_hierarchy_groups &&
  viewFacet.comparative_product_hierarchy_groups.length;

export const viewFacetHierarchyGroupItemsSelector = createSelector(
  viewFacetSelector,
  productHierarchyGroupsSelector,
  (viewFacet, productHierarchyGroups) => {
    const groups = [];
    [
      viewFacet.base_product_hierarchy_group,
      ...(viewFacet.comparative_product_hierarchy_groups || DEFAULT_LIST),
    ].forEach(({ id: hierarchyGroupId }) => {
      groups.push({
        key: hierarchyGroupId,
        value: hierarchyGroupId,
        label: productHierarchyGroups[hierarchyGroupId].name,
      });
    });
    return groups;
  }
);

const makeProductHierarchyItem = (item) => ({
  key: item.id,
  value: item.id,
  label: item.full_name,
  parent: item.parent,
});

export const viewFacetHierarchyItemsSelector = createSelector(
  viewFacetSelector,
  productHierarchiesSelector,
  productHierarchyGroupsSelector,
  (viewFacet, productHierarchies, productHierarchyGroups) => {
    const hierarchies = {};
    [
      viewFacet.base_product_hierarchy_group,
      ...(viewFacet.comparative_product_hierarchy_groups || DEFAULT_LIST),
    ].forEach(({ id: hierarchyGroupId }) => {
      productHierarchyGroups[hierarchyGroupId].items.forEach((groupItem) => {
        const item = productHierarchies[groupItem.id];
        hierarchies[item.id] = makeProductHierarchyItem(item);
        if (
          item.parent &&
          !hierarchies[item.parent] &&
          productHierarchies[item.parent]
        ) {
          hierarchies[item.parent] = makeProductHierarchyItem(
            productHierarchies[item.parent]
          );
          // Add brand
          if (hierarchies[item.parent].parent) {
            hierarchies[hierarchies[item.parent].parent] =
              makeProductHierarchyItem(
                productHierarchies[hierarchies[item.parent].parent]
              );
          }
        }
      });
    });
    return Object.values(hierarchies);
  }
);

export const facetTagSetsSelector = (state) =>
  state.view.viewFacet.analyzed_tag_sets;

export const analysisTagSetsItemsSelector = createSelector(
  facetTagSetsSelector,
  (state) => state.entities.tagSets,
  (analyzedTagSets, tagSets) => {
    const analyzedTagSetIds = analyzedTagSets.map(({ id }) => id);
    return Object.entries(tagSets)
      .filter(([tagSetId]) => analyzedTagSetIds.includes(tagSetId))
      .map(([tagSetId, { name }]) => ({
        key: tagSetId,
        value: tagSetId,
        text: name,
      }));
  }
);

export const formatItems = (tagSet) =>
  addEmptyItem(
    tagSet?.items.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    })) || DEFAULT_LIST
  );

export const tagSetItemsSelectorFactory = (tagSetId) =>
  createSelector((state) => state.entities?.tagSets?.[tagSetId], formatItems);

export const isFacetEmptySelector = (state) =>
  state.view.viewFacetAggregates[state.view.viewFacet?.id]?.isEmpty === true;

export const tagSetsItemsSelector = createSelector(tagSetsSelector, (tagSets) =>
  Object.keys(tagSets).map((id) => ({
    key: id,
    text: tagSets[id].name,
    value: id,
  }))
);

const selectFilteringTags = (state, viewFacet) =>
  viewFacet?.filtering_tags || [];

// Memoized selector with transformation
export const selectTransformedFilteringTags = createSelector(
  [tagsSelector, selectFilteringTags],
  (tagsState, filteringTags) => {
    const transformed = {};
    filteringTags.forEach((tag) => {
      const tagId = tag.id;
      const tagData = tagsState[tagId];
      if (tagData) {
        const tagSetId = tagData.tag_set.id;
        if (!transformed[tagSetId]) {
          transformed[tagSetId] = [];
        }
        transformed[tagSetId].push({ id: tagId });
      }
    });
    return transformed;
  }
);

export const tagSetFilteredItemsSelectorFactory = (itemId, valueId) =>
  createSelector(
    (state) => tagSetItemsSelectorFactory(itemId, valueId)(state),
    (state) => selectTransformedFilteringTags(state, viewFacetSelector(state)),
    (items, usedTags) => {
      if (!usedTags) return items;
      if (itemId in usedTags) {
        const idsFromUsedTags = Object.values(usedTags)
          .flat()
          .map((item) => item.id);
        return items.filter((item) => idsFromUsedTags.includes(item.key));
      }
      return items;
    }
  );
