import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import styled from 'styled-components';

import { resetSearchFilters } from 'actions/search';
import { getFilterSummarySelector } from 'selectors/search';

import HelpTooltip from 'components/ui/HelpTooltip';
import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryDangerTabButton } from 'components/ui/button/TabButton';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import { useAnalysisTagFields } from '../useAnalysisFields';
import { SEARCH_STATIC_FILTERS } from './BasicFilters';

const SecondaryDangerTabButtonWithLabel = styled(SecondaryDangerTabButton)`
  &&&&&&&&&&&&&&&&&&&&&&&& {
    :hover {
      i {
        color: inherit !important;
      }
    }
    max-width: 13rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${svars.spaceNormal};
    border-color: ${svars.fontColorLightest};
    div {
      margin-right: ${svars.spaceNormalLarge};
      background-color: ${svars.colorDangerLightest} !important;
      color: ${svars.fontColorBase} !important;
      border-top-right-radius: ${svars.ctaBorderRadius};
      border-bottom-right-radius: ${svars.ctaBorderRadius};
      border: ${({ disabled }) =>
          disabled ? 'transparent' : svars.fontColorLightest}
        1px solid !important;
      border-left: none !important;
      padding: ${svars.spaceNormal};
      cursor: pointer;
      margin: 0;
      i {
        margin: 0;
      }
      :hover {
        background-color: ${svars.colorDangerLighter} !important;
        i {
          color: ${svars.colorDanger} !important;
          background-color: ${svars.colorDangerLighter} !important;
          opacity: 1;
        }
      }
      ::before {
        display: none;
      }
    }
  }
`;

function DeleteFilterButton({ onClick, disabled, content, ...props }) {
  return (
    <HelpTooltip
      mouseEnterDelay={900}
      help={[content]}
      on="hover"
      compact
      size="tiny"
      position="top center"
      baseColor
      trigger={
        <span>
          <SecondaryDangerTabButtonWithLabel
            {...props}
            content={content}
            disabled={disabled}
            labelPosition="right"
            style={{ cursor: 'auto', marginRight: svars.spaceNormal }}
            label={
              <Label onClick={onClick} disabled={disabled}>
                <Icon name="delete" />
              </Label>
            }
          />
        </span>
      }
    />
  );
}
DeleteFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const Container = styled.span`
  overflow-x: auto;
  margin: auto 0;
  margin: 0 ${svars.spaceNormalLarge};
  padding: ${svars.spaceNormal} 0;
  white-space: pre;

  & button {
    cursor: auto !important;
  }
  ${svars.thinScrollbarCss}
`;

function FiltersDeleteGroup({ disabled, shouldCallOnChangeOnMount }) {
  const dispatch = useDispatch();
  const tagFields = useAnalysisTagFields();
  const getFilterSummary = useSelector(getFilterSummarySelector);
  const onFilterReset = useCallback(
    (_, { value: keys, isTag }) =>
      dispatch(resetSearchFilters(keys, null, null, true, isTag)),
    []
  );
  const items = [
    ...SEARCH_STATIC_FILTERS.map(({ title, keys }) => {
      const summary = getFilterSummary(keys[0], false);
      return {
        key: keys[0],
        keys,
        isTag: false,
        value: keys,
        labelPosition: 'right',
        label: summary
          ? `${capitalize(i18n._(title))} : ${i18n._(summary)}`
          : '',
        summary,
      };
    }),
    ...tagFields.map(({ id, label }) => {
      const summary = getFilterSummary(id, true);
      return {
        key: id,
        keys: [id],
        isTag: true,
        value: [id],
        label: `${label} : ${summary}`,
        summary,
      };
    }),
  ].filter(({ summary }) => summary && summary !== '-');
  return (
    <Container>
      <ButtonGroup
        as={DeleteFilterButton}
        items={items}
        disabled={disabled}
        onChange={onFilterReset}
        shouldCallOnChangeOnMount={shouldCallOnChangeOnMount}
        style={{
          display: 'inline-flex',
          margin: `0 ${svars.spaceMedium}`,
        }}
      />
    </Container>
  );
}

FiltersDeleteGroup.propTypes = {
  disabled: PropTypes.bool,
  shouldCallOnChangeOnMount: PropTypes.bool,
};
FiltersDeleteGroup.defaultProps = {
  disabled: false,
  shouldCallOnChangeOnMount: true,
};

export default FiltersDeleteGroup;
