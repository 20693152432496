import { useCallback, useEffect, useState } from 'react';

import { t } from '@lingui/macro';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Button, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { ErrorMessage } from 'components/ui/Message';
import { ButtonAccent, ButtonTransparentDanger } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

const isValidHexColor = (color) => {
  const hexRegex = /^#([0-9a-fA-F]{3}){1,2}$/;
  return hexRegex.test(color);
};

const getValidColor = (color, previousColor) =>
  isValidHexColor(color) ? color : previousColor;

const displayValidColor = (color, previousColor) => {
  const validColor = getValidColor(color, previousColor);
  return svars.getReadableColor(validColor) || previousColor;
};

const StyledPopup = styled(Popup)`
  background: none;
  border: none;
  box-shadow: none;

  &&& {
    padding: 0px;
    border: none;
  }

  &&&&::before {
    box-shadow: none;
    transition: ${svars.transitionBase};
    background-color: ${(props) => props.bgColor};
  }
`;

const CustomButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${svars.colorLighterGrey};
    transition: ${svars.transitionBase};

    &:hover {
      background-color: ${svars.colorLightGrey};
    }
  }
`;

const ColorPickerMainContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  background-color: ${svars.colorWhite};
  border-radius: ${svars.ctaBorderRadius};
`;

const LargeHexColorBox = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${svars.fontWeightBold};
  border-radius: ${svars.ctaBorderRadius} ${svars.ctaBorderRadius} 0 0;
  transition: ${svars.transitionBase};
  background-color: ${(props) =>
    isValidHexColor(props.color) ? props.color : props.previousColor};
  color: ${({ color, previousColor }) =>
    displayValidColor(color, previousColor)};
`;

const InputContainer = styled.div`
  width: 100%;
  padding: 0px 8px 8px 8px;
  border-radius: 0 0 4px 4px;
  background-color: ${svars.colorWhite};
`;

const ButtonActionsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButtonAccent = styled(ButtonAccent)`
  &&&& {
    width: 100%;
    margin: 0 ${svars.spaceNormalLarge};
    padding: ${svars.spaceNormal} 0px;
  }
`;

const StyledButtonTransparentDanger = styled(ButtonTransparentDanger)`
  &&&& {
    width: 100%;
    margin: ${svars.spaceNormalLarge};
    padding: ${svars.spaceNormal} 0px;
  }
`;

const InvalidColorContainer = styled.div`
  margin: ${(props) => (props.hexColorError ? svars.spaceNormal : '0')};
`;

const ColorPickerInput = styled(TextInput)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &&&& input {
    text-align: center;
    &:active,
    &:focus {
      border-color: ${(props) =>
        props.hexColorError ? svars.colorDanger : svars.accentColor};
    }
  }
`;

const ColorPaletteBox = styled.div`
  width: 28px;
  height: 28px;
  margin: ${svars.spaceSmall} 0;
  background-color: ${(props) => props.color};
  border-radius: ${svars.ctaBorderRadius};
  cursor: pointer;
`;

const ColorRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${svars.spaceNormal} 0px 0px 0px;
`;

const SmallHexColorBox = styled.div`
  min-width: 100px;
  padding: ${svars.spaceSmaller};
  background-color: ${(props) =>
    isValidHexColor(props.color) ? props.color : props.previousColor};
  color: ${({ color, previousColor }) =>
    displayValidColor(color, previousColor)};
  border-radius: ${svars.ctaBorderRadius};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function ColorGrid({ onColorSelect }) {
  return (
    <>
      <ColorRow>
        {svars.colorPickerPalette.slice(0, 4).map((color) => (
          <ColorPaletteBox
            key={color}
            color={color}
            onClick={() => onColorSelect(color)}
          />
        ))}
      </ColorRow>
      <ColorRow>
        {svars.colorPickerPalette.slice(4).map((color) => (
          <ColorPaletteBox
            key={color}
            color={color}
            onClick={() => onColorSelect(color)}
          />
        ))}
      </ColorRow>
    </>
  );
}

function ColorPreview({ color, previousColor }) {
  const validColor = getValidColor(color, previousColor);

  return (
    <SmallHexColorBox color={color} previousColor={previousColor}>
      <div>
        <span>{validColor}</span>
      </div>
    </SmallHexColorBox>
  );
}

export function CustomizedColorPicker({
  color,
  onChange,
  initialColor,
  previousColor,
  hexColorError,
  handlePopupClose,
  validateToClosePopup,
}) {
  const validColor = getValidColor(color, previousColor);

  const hasColorChanged = color !== initialColor;
  const invalidColorInput = !isValidHexColor(color);

  return (
    <Container>
      <LargeHexColorBox color={color} previousColor={previousColor}>
        {validColor}
      </LargeHexColorBox>
      <InputContainer>
        <ColorGrid onColorSelect={onChange} />
      </InputContainer>
      <InputContainer>
        <ColorPickerInput
          data-testid="bo-color-picker-popup-input"
          type="text"
          value={color}
          onChange={(e) => onChange(e.target.value)}
          hexColorError={hexColorError}
        />
      </InputContainer>
      <InvalidColorContainer>
        {hexColorError && (
          <ErrorMessage
            style={{
              textAlign: 'center',
              padding: `0 ${svars.spaceNormal} ${svars.spaceNormal}`,
            }}
            message={hexColorError}
            show={hexColorError}
          />
        )}
      </InvalidColorContainer>
      <ButtonActionsContainer>
        <StyledButtonAccent
          disabled={!hasColorChanged || invalidColorInput}
          onClick={validateToClosePopup}
          content={t({ id: 'validate-color' })}
          data-testid="bo-validate-color-button"
        />
      </ButtonActionsContainer>
      <ButtonActionsContainer>
        <StyledButtonTransparentDanger
          content={t({ id: 'close' })}
          onClick={handlePopupClose}
          data-testid="bo-close-color-picker-popup"
        />
      </ButtonActionsContainer>
    </Container>
  );
}

export function ColorPicker({ initialColor, onChange, colorPickerTestId }) {
  const [color, setColor] = useState(initialColor);
  const [displayedColor, setDisplayedColor] = useState(color);
  const [open, setOpen] = useState(false);
  const [previousColor, setPreviousColor] = useState(color);
  const [hexColorError, setHexColorError] = useState(null);

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
      setDisplayedColor(initialColor);
      setPreviousColor(initialColor);
    }
  }, [initialColor]);

  const validColor = getValidColor(color, previousColor);

  const debouncedSetError = useCallback(
    debounce((isValidColor) => {
      setHexColorError(isValidColor ? false : t({ id: 'invalid-color-code' }));
    }, 600),
    []
  );

  const handleInputChange = (value) => {
    const isValidColor = isValidHexColor(value);
    setColor(value);
    debouncedSetError(isValidColor);
    if (isValidColor) {
      setPreviousColor(value);
    }
  };

  const handleChange = (value) => {
    setHexColorError(false);
    handleInputChange(value);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  const validateToClosePopup = () => {
    const isValidColor = isValidHexColor(color);
    const hasColorChanged = color !== initialColor;
    if (hasColorChanged && isValidColor) {
      onChange(color);
    }
    setHexColorError(isValidColor && null);
    setColor(isValidColor ? color : previousColor);
    setOpen(false);
  };

  const handlePopupOpen = () => {
    setOpen(true);
    setHexColorError(false);
  };

  return (
    <ColorPickerMainContainer>
      <StyledPopup
        flowing
        bgColor={validColor}
        open={open}
        onClose={handlePopupClose}
        onOpen={handlePopupOpen}
        on="click"
        position="bottom right"
        trigger={
          <span>
            <CustomButton data-testid={colorPickerTestId}>
              <Icon
                style={{ marginRight: `${svars.spaceNormal}` }}
                name="edit"
              />
              <ColorPreview
                color={displayedColor}
                previousColor={previousColor}
              />
            </CustomButton>
          </span>
        }
        content={
          <CustomizedColorPicker
            color={color}
            handlePopupClose={handlePopupClose}
            onChange={handleChange}
            previousColor={previousColor}
            hexColorError={hexColorError}
            initialColor={initialColor}
            validateToClosePopup={validateToClosePopup}
          />
        }
      />
    </ColorPickerMainContainer>
  );
}

ColorPicker.propTypes = {
  initialColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  colorPickerTestId: PropTypes.string,
};

ColorPicker.defaultProps = {
  colorPickerTestId: undefined,
};

CustomizedColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  handlePopupClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  previousColor: PropTypes.string.isRequired,
  hexColorError: PropTypes.string,
  initialColor: PropTypes.string.isRequired,
  validateToClosePopup: PropTypes.func.isRequired,
};

CustomizedColorPicker.defaultProps = {
  hexColorError: null,
};

ColorGrid.propTypes = {
  onColorSelect: PropTypes.func.isRequired,
};

ColorPreview.propTypes = {
  color: PropTypes.string.isRequired,
  previousColor: PropTypes.string.isRequired,
};
