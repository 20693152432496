import PropTypes from 'prop-types';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import HelpcrunchHelpTooltip from '../navigation/HelpcrunchHelpTooltip';

const HeaderContainer = styled.span`
  display: inline-flex;
  font-weight: ${svars.fontWeightMedium};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  user-select: none;
  padding: ${svars.spaceNormal} 0;
`;

function BaseReactTableHeader({
  title,
  articleId,
  helpMessage,
  children,
  position,
  centered,
  style,
  ...props
}) {
  let titleComponent = (
    <HeaderContainer centered={centered} style={style}>
      {children || capitalize(title)}
    </HeaderContainer>
  );

  if (helpMessage) {
    titleComponent = (
      <HelpcrunchHelpTooltip
        style={{
          whiteSpace: 'pre',
          paddingTop: svars.spaceSmaller,
          margin: `${svars.spaceSmaller} ${svars.spaceSmall}`,
        }}
        smallTooltip
        articleId={articleId}
        iconColor={svars.colorSecondary}
        triggerText={titleComponent}
        helpText={helpMessage}
        position={position}
        mouseEnterDelay={550}
        {...props}
      />
    );
  }

  return titleComponent;
}

BaseReactTableHeader.propTypes = {
  articleId: PropTypes.number,
  helpMessage: PropTypes.oneOfType([PropTypes.node, commonPropTypes.i18nText]),
  title: PropTypes.oneOfType([PropTypes.node, commonPropTypes.i18nText]),
  children: PropTypes.node,
  position: PropTypes.string,
  centered: PropTypes.bool,
  style: commonPropTypes.style,
};

BaseReactTableHeader.defaultProps = {
  articleId: null,
  helpMessage: null,
  title: undefined,
  children: null,
  position: 'top center',
  centered: false,
  style: null,
};

export default BaseReactTableHeader;
