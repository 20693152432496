import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PERIODS_ITEMS } from 'selectors/search';
import { DATE_QUESTION_ITEM } from 'selectors/survey';
import { viewFacetSelector } from 'selectors/view';
import { questionAnswerFiltersSelector } from 'store/monitor/monitorSearchSlice';

import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import PeriodSelect from 'components/ui/inputs/PeriodSelect';
import FiltersAccordion from 'components/ui/search/FiltersAccordion';

import * as svars from 'assets/style/variables';

const PaddedSecondaryTabButton = styled(SecondaryTabButton)`
  margin-bottom: ${svars.spaceNormal} !important;
`;

const PaddedAccordionItem = styled.div`
  padding: ${svars.spaceNormal} ${svars.spaceMedium} ${svars.spaceMedium}
    ${svars.spaceMedium};
`;

export function ViewFacetDateFilter({
  datePeriod,
  minDate,
  maxDate,
  onFilterChange,
  onSetFilterActive,
}) {
  const viewFacet = useSelector(viewFacetSelector);
  const { min_date: rangeMinDate, max_date: rangeMaxDate } = viewFacet;
  const onPeriodChange = useCallback(
    (_, { value }) =>
      onFilterChange({ datePeriod: datePeriod === value ? null : value }),
    [datePeriod]
  );
  const customDateIsDisabled = datePeriod !== 'custom';
  return (
    <PaddedAccordionItem>
      <ButtonGroup
        as={PaddedSecondaryTabButton}
        items={PERIODS_ITEMS}
        onChange={onPeriodChange}
        value={datePeriod}
        style={{ flexWrap: 'wrap', paddingBottom: svars.spaceNormal }}
      />
      <PeriodSelect
        minDate={minDate}
        maxDate={maxDate}
        rangeMinDate={rangeMinDate}
        rangeMaxDate={rangeMaxDate}
        onFilterChange={onFilterChange}
        onSetFilterActive={onSetFilterActive}
        disabled={customDateIsDisabled}
      />
    </PaddedAccordionItem>
  );
}
ViewFacetDateFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  datePeriod: PropTypes.string.isRequired,
  onSetFilterActive: PropTypes.func.isRequired,
};
ViewFacetDateFilter.defaultProps = {
  minDate: null,
  maxDate: null,
};

export function SurveyDateQuestionFilter({
  questionId,
  header,
  isActive,
  onToggle,
  rangeMinDate,
  rangeMaxDate,
  onFilterChange,
  onSetFilterActive,
  customDateIsDisabled,
}) {
  const { minDate, maxDate } = useSelector(
    questionAnswerFiltersSelector({
      id: questionId,
    })
  );
  const nValues = [minDate, maxDate].filter(Boolean).length;
  return (
    <FiltersAccordion.Filter
      title={header}
      icon={DATE_QUESTION_ITEM.icon}
      iconHelp={DATE_QUESTION_ITEM.text}
      iconColor={DATE_QUESTION_ITEM.iconColor}
      nFilterValues={nValues}
      isActive={isActive}
      onToggle={onToggle}
    >
      <PaddedAccordionItem>
        <PeriodSelect
          minDate={minDate}
          maxDate={maxDate}
          rangeMinDate={rangeMinDate}
          rangeMaxDate={rangeMaxDate}
          onFilterChange={onFilterChange}
          onSetFilterActive={onSetFilterActive}
          disabled={customDateIsDisabled}
        />
      </PaddedAccordionItem>
    </FiltersAccordion.Filter>
  );
}

SurveyDateQuestionFilter.propTypes = {
  questionId: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSetFilterActive: PropTypes.func.isRequired,
  rangeMinDate: PropTypes.string,
  rangeMaxDate: PropTypes.string,
  customDateIsDisabled: PropTypes.bool,
};

SurveyDateQuestionFilter.defaultProps = {
  isActive: false,
  rangeMinDate: null,
  rangeMaxDate: null,
  customDateIsDisabled: false,
};
