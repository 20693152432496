import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  usersIsLoadingSelector,
  usersItemsSelectorFactory,
} from 'selectors/entities';
import {
  CAMPAIGN_PERMISSION_OPTIONS,
  addPermissionsToMembers,
  createPermissionLoadingSelector,
} from 'store/campaign/campaignPermissionsSlice';

import { ErrorMessage } from 'components/ui/Message';
import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { ButtonAccent, ButtonTransparentDanger } from 'components/ui/button';
import LargeRadioButtonGroup from 'components/ui/button/LargeRadioButtonGroup';
import CheckboxList from 'components/ui/inputs/checkbox-list/index';

import * as svars from 'assets/style/variables';

export const formElementTypeMapping = {
  InputFormElement: 'Input element',
  MultipleChoiceFormElement: 'Multichoice selector',
  UserFormElement: 'User selector',
};

const UserFormContainer = styled(Segment)`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 0;
`;

const sortMembers = (member1, member2) => {
  // Disabled are last
  if (member1.disabled && !member2.disabled) {
    return 1;
  }
  if (!member1.disabled && member2.disabled) {
    return -1;
  }
  return 0;
};

function CampaignMembersManagementModal({ open, onClose, members }) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const users = useSelector(usersItemsSelectorFactory());
  const [nonMemberUsers, allUsersAreMembers] = useMemo(
    () => [
      users
        .map((user) =>
          members.find((member) => member.user.id === user.value)
            ? {
                ...user,
                text: `${user.text} (${t({ id: 'already-a-member' })})`,
                disabled: true,
              }
            : user
        )
        .sort(sortMembers),
      members.length === users.length,
    ],
    [users, members]
  );

  const createPermissionLoading = useSelector(createPermissionLoadingSelector);
  // Add current members to the list of selected members so they display as selected
  const [currentMembers, setCurrentMembers] = useState(
    members.map(({ user }) => user.id)
  );
  const [permission, setPermission] = useState('reader');

  const onSelectItem = useCallback(
    (item) => {
      setCurrentMembers([...currentMembers, item.value]);
    },
    [currentMembers]
  );
  const onSelectItems = useCallback(
    (items) => {
      setCurrentMembers([
        ...currentMembers,
        ...items.map(({ value }) => value),
      ]);
    },
    [currentMembers]
  );
  const onUnselectItem = useCallback(
    ({ value }) => {
      setCurrentMembers(currentMembers.filter((member) => member !== value));
    },
    [currentMembers]
  );
  const onFilterReset = useCallback(() => {
    setCurrentMembers([]);
  }, []);
  const onSubmit = useCallback(() => {
    if (!createPermissionLoading) {
      dispatch(
        addPermissionsToMembers({
          campaignId,
          userIds: currentMembers.filter(
            (member) => !members.find(({ user }) => user.id === member)
          ),
          permissionType: permission,
        })
      ).then(() => {
        onClose();
      });
    }
  }, [currentMembers, permission, createPermissionLoading]);

  const usersIsLoading = useSelector(usersIsLoadingSelector);
  const isFormIncomplete = !currentMembers.length;
  return (
    <Modal closeIcon open={open} onClose={onClose} size="small">
      <Modal.Header content={t({ id: 'invite-new-members' })} />
      <Modal.Content
        style={{
          overflow: 'clip auto',
          padding: svars.spaceMedium,
        }}
      >
        <UserFormContainer>
          <Header>
            <Trans id="select-members-to-invite" />
            <ErrorMessage
              message={t({ id: 'campaign-members.no-user-left-to-invite' })}
              show={allUsersAreMembers}
            />
          </Header>
          <CheckboxList
            disabled={
              usersIsLoading || createPermissionLoading || allUsersAreMembers
            }
            bordered
            items={nonMemberUsers}
            style={{ maxHeight: svars.listMaxHeight }}
            selectedItems={currentMembers}
            loading={usersIsLoading}
            onSelectItem={onSelectItem}
            onSelectItems={onSelectItems}
            onUnselectItem={onUnselectItem}
            onResetItems={onFilterReset}
            placeholder={t({ id: 'search-users-for-annotation' })}
            noDataMessage={t({ id: 'no-user-found' })}
            searchable={(nonMemberUsers?.length || 0) > 8}
          />
        </UserFormContainer>

        <Segment style={{ display: 'flex', flexDirection: 'column' }}>
          <Header
            style={{
              marginBottom: svars.spaceNormal,
            }}
          >
            <Trans id="select-role" />
          </Header>
          <LargeRadioButtonGroup
            disabled={createPermissionLoading || nonMemberUsers?.length === 0}
            items={CAMPAIGN_PERMISSION_OPTIONS}
            value={permission}
            onClick={setPermission}
          />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger
          type="submit"
          onClick={onClose}
          content={t({ id: 'cancel' })}
          disabled={createPermissionLoading}
        />
        <ButtonAccent
          type="submit"
          disabled={isFormIncomplete}
          onClick={onSubmit}
          loading={createPermissionLoading}
        >
          <Trans id="invite" />
        </ButtonAccent>
      </Modal.Actions>
    </Modal>
  );
}

CampaignMembersManagementModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ).isRequired,
};

CampaignMembersManagementModal.defaultProps = {};

export default CampaignMembersManagementModal;
