import { useCallback } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { QUESTION_TYPES } from 'selectors/survey';

import { LightHeader } from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import { TabButton } from 'components/ui/button/TabButton';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const DropdownContainer = styled.div`
  max-height: 60vh;
  min-width: 28vw;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: ${svars.spaceNormal};
  padding-top: 0;
`;

const DropdownItemLabelContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
`;

const LightHeaderLabel = styled(LightHeader)`
  &&& {
    padding: 0;
    padding-right: ${svars.spaceMedium};
    margin: 0;
    display: flex;
    align-items: center;
    height: 25px;
  }
`;

const HoverableSegment = styled(Segment)`
  &&& {
    ${({ disabled }) =>
      !disabled
        ? `cursor: pointer; &:hover { background: ${svars.accentColorLightest}; }`
        : 'opacity: 0.5;'}
  }
`;

export function QuestionTypeChoiceList({
  onCreateQuestion,
  onToggle,
  headerText,
  disabled,
  style,
}) {
  const onQuestionTypeSelect = useCallback(
    (questionType) => () => {
      onCreateQuestion(null, questionType);
      onToggle?.();
    },
    [onCreateQuestion, onToggle]
  );

  return (
    <div style={style}>
      <LightHeader>
        <Trans id={headerText} /> :
      </LightHeader>
      <DropdownContainer>
        {QUESTION_TYPES.map(
          ({
            key,
            value,
            text,
            icon,
            iconColor,
            description,
            disabled: localDisabled,
          }) => (
            <HoverableSegment
              disabled={disabled || localDisabled ? '1' : null}
              key={`fpsi-${key}`}
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
              onClick={
                disabled || localDisabled ? null : onQuestionTypeSelect(value)
              }
              data-testid={`${key}Button`}
            >
              <DropdownItemLabelContainer>
                <Icon
                  name={icon}
                  size="large"
                  style={{
                    marginRight: svars.spaceMedium,
                    color: iconColor,
                  }}
                />
                <LightHeaderLabel
                  // inline="true"
                  style={{
                    // whiteSpace: 'pre',
                    // overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                  }}
                >
                  {/* <span
                    style={{
                      whiteSpace: 'pre',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  > */}
                  <Trans render={capitalizedTranslation} id={text} />
                  {description ? (
                    <LightHeaderLabel.Subheader>
                      <Trans id={description} />
                    </LightHeaderLabel.Subheader>
                  ) : null}
                  {/* </span> */}
                </LightHeaderLabel>
              </DropdownItemLabelContainer>
              <Icon name="add" />
            </HoverableSegment>
          )
        )}
      </DropdownContainer>
    </div>
  );
}

QuestionTypeChoiceList.propTypes = {
  onCreateQuestion: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  headerText: PropTypes.string.isRequired,
  style: commonPropTypes.style,
  disabled: PropTypes.bool,
};

QuestionTypeChoiceList.defaultProps = {
  disabled: false,
  onToggle: null,
  style: {},
};

function SelectQuestionTypeDropdown({
  onCreateQuestion,
  onToggle,
  modalIsOpen,
  disabled,
}) {
  return (
    <Popup
      open={modalIsOpen}
      flowing
      trigger={
        <span
          style={{
            float: 'right',
            overflowX: 'hidden',
          }}
        >
          <TabButton fitted="true" active={modalIsOpen} disabled={disabled}>
            <Icon
              data-testid="bo-campaign-add-questions-icon"
              name="add"
              style={{ margin: 0 }}
            />
          </TabButton>
        </span>
      }
      content={
        <QuestionTypeChoiceList
          style={{ padding: `${svars.spaceNormalLarge} ${svars.spaceNormal}` }}
          disabled={disabled}
          onCreateQuestion={onCreateQuestion}
          onToggle={onToggle}
          headerText={t({ id: 'select-a-question-type' })}
        />
      }
      on="click"
      onClose={onToggle}
      onOpen={onToggle}
      position="bottom right"
      style={{ zIndex: 900, padding: 0 }}
    />
  );
}

SelectQuestionTypeDropdown.propTypes = {
  onCreateQuestion: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

SelectQuestionTypeDropdown.defaultProps = { disabled: false };

export default SelectQuestionTypeDropdown;
