import { t } from '@lingui/macro';

import { createLoadingSelector } from 'reducers/ui';
import { nFiltersPositiveSelector } from 'selectors/search';

import { pushSuccessToaster } from 'components/ui/Toaster';

import { api, getQueryString } from './utils';

const DEFAULT_SEARCH_PAGE_SIZE = 20;

export const actionTypes = {
  FETCH_REVIEW_REQUEST: 'FETCH_REVIEW_REQUEST',
  FETCH_REVIEW_SUCCESS: 'FETCH_REVIEW_SUCCESS',
  FETCH_REVIEW_FAILURE: 'FETCH_REVIEW_FAILURE',

  // Review chunks search
  SEARCH_REVIEW_CHUNKS_REQUEST: 'SEARCH_REVIEW_CHUNKS_REQUEST',
  SEARCH_REVIEW_CHUNKS_FAILURE: 'SEARCH_REVIEW_CHUNKS_FAILURE',
  SEARCH_REVIEW_CHUNKS_SUCCESS: 'SEARCH_REVIEW_CHUNKS_SUCCESS',

  PAGINATE_REVIEW_CHUNKS_REQUEST: 'PAGINATE_REVIEW_CHUNKS_REQUEST',
  PAGINATE_REVIEW_CHUNKS_FAILURE: 'PAGINATE_REVIEW_CHUNKS_FAILURE',
  PAGINATE_REVIEW_CHUNKS_SUCCESS: 'PAGINATE_REVIEW_CHUNKS_SUCCESS',

  FOCUS_REVIEW_CHUNK: 'FOCUS_REVIEW_CHUNK',

  // Filter presets management
  FETCH_FILTER_PRESETS_REQUEST: 'FETCH_FILTER_PRESETS_REQUEST',
  FETCH_FILTER_PRESETS_FAILURE: 'FETCH_FILTER_PRESETS_FAILURE',
  FETCH_FILTER_PRESETS_SUCCESS: 'FETCH_FILTER_PRESETS_SUCCESS',

  SAVE_FILTER_PRESETS_REQUEST: 'SAVE_FILTER_PRESETS_REQUEST',
  SAVE_FILTER_PRESETS_FAILURE: 'SAVE_FILTER_PRESETS_FAILURE',
  SAVE_FILTER_PRESETS_SUCCESS: 'SAVE_FILTER_PRESETS_SUCCESS',

  DELETE_FILTER_PRESETS_REQUEST: 'DELETE_FILTER_PRESETS_REQUEST',
  DELETE_FILTER_PRESETS_FAILURE: 'DELETE_FILTER_PRESETS_FAILURE',
  DELETE_FILTER_PRESETS_SUCCESS: 'DELETE_FILTER_PRESETS_SUCCESS',

  SELECT_FILTER_PRESET: 'SELECT_FILTER_PRESET',

  UPDATE_SEARCH_FILTERS: 'UPDATE_SEARCH_FILTERS',
  SWITCH_DATE_SORT: 'SWITCH_DATE_SORT',
  INITIALIZE_FACET_SEARCH: 'INITIALIZE_FACET_SEARCH',
};

export const getInitialSearchFilters = () => ({
  filters: {
    sources: [],
    productHierarchies: [],
    productHierarchyGroups: [],
    textSearchValues: [],
    polarities: [],
    ontologyConcepts: [],
    matchAllConcepts: false,
    minDate: undefined,
    maxDate: undefined,
    datePeriod: '',
    tags: {},
  },
  sortDateAsc: false,
});

export const initializeFacetSearch = (viewFacetId) => ({
  viewFacetId,
  type: actionTypes.INITIALIZE_FACET_SEARCH,
});

const fetchReview = (reviewId) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_REVIEW_REQUEST });

  let response;
  const viewFacetId = getState().view.viewFacet?.id;
  try {
    response = await api.get(
      `facets/${viewFacetId}/reviews/${reviewId}?original_text=1`
    );
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_REVIEW_FAILURE });
  }
  return dispatch({
    type: actionTypes.FETCH_REVIEW_SUCCESS,
    reviewId,
    viewFacetId,
    review: response.data,
  });
};

/**
 * Compile a search filter payload that is compatible with both search and filter preset endpoints.
 *
 * @param {*} {
 *   viewFacetId,
 *   sources,
 *   productHierarchies,
 *   textSearchValues,
 *   polarities,
 *   matchAllConcepts,
 *   ontologyConcepts,
 *   datePeriod,
 *   minDate,
 *   maxDate,
 * }
 * @return {*} a formatted payload.
 */
export const getSearchFiltersPayload = (
  viewFacetId,
  {
    sources,
    productHierarchies,
    productHierarchyGroups,
    textSearchValues,
    polarities,
    matchAllConcepts,
    ontologyConcepts,
    datePeriod,
    minDate,
    maxDate,
    tags,
  } = {}
) => {
  const payload = {
    texts: textSearchValues,
    facet_id: viewFacetId,
    sources,
    tags,
    product_hierarchies: productHierarchies,
    hierarchy_groups: productHierarchyGroups,
  };
  if (polarities && polarities.length && polarities.length < 3) {
    payload.positives = false;
    payload.negatives = false;
    payload.neutrals = false;
    polarities.forEach((value) => {
      switch (value) {
        case 'positives':
          payload.positives = true;
          break;
        case 'negatives':
          payload.negatives = true;
          break;
        case 'neutrals':
          payload.neutrals = true;
          break;
        default:
          break;
      }
    });
  }
  if (ontologyConcepts?.length) {
    // ontologyConcepts is a list of string
    payload.concepts = ontologyConcepts.map((item) => item);

    if (matchAllConcepts) {
      payload.concepts_match_all = 'true';
    } else {
      payload.concepts_match_all = 'false';
    }
  }
  if (datePeriod) {
    payload.date_period = datePeriod;
    if (datePeriod === 'custom') {
      if (minDate) {
        payload.min_date = minDate;
      }
      if (maxDate) {
        payload.max_date = maxDate;
      }
    }
  }
  return payload;
};

export const getReviewChunks = async (
  viewFacetId,
  filters,
  {
    page = 1,
    sortDateAsc = false,
    pageSize = DEFAULT_SEARCH_PAGE_SIZE,
    withStatistics = true,
    withConcepts = false,
    withPolarity = false,
  }
) => {
  const payload = getSearchFiltersPayload(viewFacetId, filters);
  const queryString = getQueryString({
    page,
    page_size: pageSize,
    sort_date_asc: sortDateAsc ? '1' : '0',
    statistics: withStatistics ? '1' : '0',
    concepts: withConcepts ? '1' : '0',
    polarity: withPolarity ? '1' : '0',
  });
  const response = await api.post(`/chunks/search?${queryString}`, payload);
  return response;
};

const searchReviewChunks =
  (viewFacetId, filters, options = {}) =>
  async (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_REVIEW_CHUNKS_REQUEST,
    });
    let response;
    try {
      response = await getReviewChunks(viewFacetId, filters, options);
    } catch (error) {
      return dispatch({
        type: actionTypes.SEARCH_REVIEW_CHUNKS_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    return dispatch({
      type: actionTypes.SEARCH_REVIEW_CHUNKS_SUCCESS,
      viewFacetId,
      reviewChunks: response.data.data,
      statistics: response.data.statistics,
      pagination: {
        page: response.data.page,
        nPages: response.data.n_pages,
        pageSize: DEFAULT_SEARCH_PAGE_SIZE,
      },
    });
  };

export const paginateReviewChunks = () => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.PAGINATE_REVIEW_CHUNKS_REQUEST,
  });
  const viewFacetId = getState().view.viewFacet?.id;
  let response;
  const facetSearch = getState().search.viewFacetSearch[viewFacetId];
  try {
    response = await getReviewChunks(viewFacetId, facetSearch.filters, {
      page: (facetSearch.searchResults.pagination.page || 0) + 1,
      sortDateAsc: facetSearch.sortDateAsc,
      withStatistics: false,
    });
  } catch (error) {
    return dispatch({
      type: actionTypes.PAGINATE_REVIEW_CHUNKS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.PAGINATE_REVIEW_CHUNKS_SUCCESS,
    viewFacetId,
    reviewChunks: response.data.data,
    pagination: {
      page: response.data.page,
      nPages: response.data.n_pages,
    },
  });
};

export const focusReviewChunk = (reviewChunk) => (dispatch, getState) => {
  const viewFacetId = getState().view.viewFacet?.id;
  if (
    reviewChunk &&
    !getState().search.viewFacetSearch?.[viewFacetId]?.reviews[
      reviewChunk.review_id
    ]
  )
    dispatch(fetchReview(reviewChunk.review_id));
  dispatch({
    type: actionTypes.FOCUS_REVIEW_CHUNK,
    reviewChunk,
    viewFacetId,
  });
};

export const focusOnNextReviewChunk = (next) => (dispatch, getState) => {
  const viewFacetId = getState().view.viewFacet?.id;
  const reviewChunks =
    getState().search.viewFacetSearch?.[viewFacetId]?.searchResults
      ?.reviewChunks;
  const focusedChunkId =
    getState().search.viewFacetSearch[viewFacetId]?.focusReviewChunk?.id;

  const currentIndex = reviewChunks?.findIndex(
    ({ id }) => id === focusedChunkId
  );
  let nextIndex = currentIndex;
  if (next) {
    nextIndex = currentIndex < reviewChunks.length - 1 ? currentIndex + 1 : 0;
  } else {
    nextIndex = currentIndex > 0 ? currentIndex - 1 : reviewChunks.length - 1;
  }
  const nextChunk = reviewChunks[nextIndex];
  if (nextChunk) {
    dispatch(focusReviewChunk(nextChunk));
  }
};

/**
 * Query filter preset, optionally for a specific view facet.
 *
 * @param {*} [viewFacetId=null]
 */
export const fetchFilterPresets = (viewFacetId) => async (dispatch) => {
  dispatch({ type: actionTypes.FETCH_FILTER_PRESETS_REQUEST });
  let response;
  try {
    response = await api.get(
      `/chunks/search/preset${viewFacetId ? `?facet=${viewFacetId}` : ''}`
    );
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_FILTER_PRESETS_FAILURE });
    return Promise.reject();
  }
  dispatch({
    type: actionTypes.FETCH_FILTER_PRESETS_SUCCESS,
    viewFacetId,
    presets: response.data,
  });
  return Promise.resolve();
};

/**
 * Save a filter preset
 *
 * @param {*} filters
 */
export const saveFilterPresets =
  (
    presetId,
    presetNotificationId,
    presetName,
    filters,
    hasNotification,
    periodValue,
    hasCondition,
    condition,
    doSendMail,
    customEmail,
    doPurgeNotifications
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.SAVE_FILTER_PRESETS_REQUEST });
    let response;
    const viewFacetId = getState().view?.viewFacet?.id;
    const payload = {
      name: presetName,
      ...getSearchFiltersPayload(viewFacetId, filters),
    };

    // Manage notifications
    if (hasNotification) {
      payload.notification = {
        period: periodValue,
      };
      if (presetNotificationId) {
        payload.notification.id = presetNotificationId;
      }
      if (hasCondition) {
        payload.notification = {
          ...payload.notification,
          period: condition.period,
          condition_field: condition.condition_field,
          threshold: condition.threshold,
          is_threshold_relative: condition.is_threshold_relative,
        };
      } else {
        payload.notification.condition_field = null;
        payload.notification.threshold = null;
        payload.notification.is_threshold_relative = null;
      }

      if (doSendMail) {
        payload.notification.send_mail = true;
        if (customEmail) {
          payload.notification.custom_email = customEmail;
        } else {
          payload.notification.custom_email = null;
        }
      } else {
        payload.notification.send_mail = false;
        payload.notification.custom_email = null;
      }
    } else {
      payload.notification = null;
    }
    let isUpdate = false;
    try {
      let method = api.post;
      // When preset id is provided, run an update
      if (presetId) {
        isUpdate = true;
        payload.id = presetId;
        method = api.put;
      }
      response = await method(
        `/chunks/search/preset?do_delete_notifications=${doPurgeNotifications}`,
        payload
      );
    } catch (error) {
      return dispatch({ type: actionTypes.SAVE_FILTER_PRESETS_FAILURE });
    }
    dispatch({
      type: actionTypes.SAVE_FILTER_PRESETS_SUCCESS,
      preset: response.data,
      viewFacetId,
      isUpdate,
    });
    pushSuccessToaster(
      t({ id: 'filter-preset' }),
      isUpdate
        ? t({ id: 'preset-was-updated' })
        : t({ id: 'preset-was-created' })
    );

    return Promise.resolve(response.data.id);
  };

/**
 * Delete filter preset.
 *
 * @param {*} presetId
 */
export const deleteFilterPreset = (presetId) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_FILTER_PRESETS_REQUEST });
  let response;
  try {
    response = await api.delete(`/chunks/search/preset/${presetId}`);
  } catch (error) {
    return dispatch({ type: actionTypes.DELETE_FILTER_PRESETS_FAILURE });
  }
  pushSuccessToaster(
    t({ id: 'filter-preset' }),
    t({ id: 'preset-was-deleted' })
  );
  dispatch({
    type: actionTypes.DELETE_FILTER_PRESETS_SUCCESS,
    viewFacetId: getState().view.viewFacet.id,
    presetId,
  });
  return Promise.resolve(response.data.id);
};

export const updateSearchFilters =
  (filtersUpdate, doSearch = undefined, resetState = false, isTag = false) =>
  // eslint-disable-next-line consistent-return
  (dispatch, getState) => {
    const shouldUpdateSearch = resetState || Object.keys(filtersUpdate).length;
    const willUpdateSearch =
      resetState ||
      doSearch ||
      (doSearch !== false && Object.entries(filtersUpdate).length);
    const viewFacetId = getState().view?.viewFacet?.id;
    let newSearchState = getState().search.viewFacetSearch[viewFacetId].filters;

    if (resetState || Object.keys(filtersUpdate).length) {
      if (isTag) {
        const baseFilters = resetState
          ? getInitialSearchFilters().filters
          : getState().search.viewFacetSearch[viewFacetId].filters;
        newSearchState = {
          ...baseFilters,
          tags: {
            ...baseFilters.tags,
            ...filtersUpdate,
          },
        };
      } else {
        newSearchState = {
          ...(resetState
            ? getInitialSearchFilters().filters
            : getState().search.viewFacetSearch[viewFacetId].filters),
          ...filtersUpdate,
        };
      }
      dispatch({
        type: actionTypes.UPDATE_SEARCH_FILTERS,
        viewFacetId,
        resetState,
        filtersUpdate,
        newSearchState: isTag ? newSearchState.tags : newSearchState,
        isTag,
        shouldUpdateSearch: shouldUpdateSearch && !willUpdateSearch,
      });
    }
    if (willUpdateSearch) {
      return dispatch(searchReviewChunks(viewFacetId, newSearchState));
    }
  };

export const maybeUpdateSearchFilters =
  (searchFilters, forceSearch) => (dispatch, getState) => {
    const {
      view: { viewFacet },
      search: { viewFacetSearch },
    } = getState();
    const { shouldUpdateSearch } = viewFacetSearch[viewFacet?.id];
    return dispatch(
      updateSearchFilters(searchFilters, shouldUpdateSearch || forceSearch)
    );
  };

export const resetSearchFilters =
  (
    filterKeys,
    resetValue,
    callback = undefined,
    doSearch = true,
    isTag = false
  ) =>
  (dispatch, getState) => {
    const {
      view: { viewFacet },
      search: { viewFacetSearch },
    } = getState();
    const stateUpdate = {};
    if (isTag) {
      filterKeys.forEach((tagSetId) => {
        stateUpdate[tagSetId] = [];
      });
    } else {
      const facetSearch = viewFacetSearch[viewFacet?.id] || {};
      const initialFilters = getInitialSearchFilters().filters;
      filterKeys.forEach((key) => {
        const finalResetValue = resetValue || initialFilters[key];
        if (facetSearch.filters[key] !== finalResetValue) {
          stateUpdate[key] = finalResetValue;
        }
      });
    }
    if (Object.keys(stateUpdate)) {
      dispatch(updateSearchFilters(stateUpdate, doSearch, false, isTag));
      // Callback is called only if stateUpdate is non empty
      if (callback) callback(stateUpdate);
    }
  };

export const switchSearchDateSort = () => (dispatch, getState) => {
  const viewFacetId = getState().view?.viewFacet?.id;
  const facetSearch = getState().search.viewFacetSearch[viewFacetId];
  const sortDirection = !facetSearch.sortDateAsc;
  dispatch({
    type: actionTypes.SWITCH_DATE_SORT,
    sortDateAsc: sortDirection,
    viewFacetId,
  });
  return dispatch(
    searchReviewChunks(viewFacetId, facetSearch.filters, {
      sortDateAsc: sortDirection,
    })
  );
};

export const presetToSearchFilters = (preset) => {
  const filters = {
    polarities: [],
    matchAllConcepts: preset.concepts_match_all,
    textSearchValues: preset.texts,
  };
  if (!(preset.positives && preset.negatives && preset.neutrals)) {
    if (preset.positives) {
      filters.polarities.push('positives');
    }
    if (preset.negatives) {
      filters.polarities.push('negatives');
    }
    if (preset.neutrals) {
      filters.polarities.push('neutrals');
    }
  }
  if (preset.date_period) {
    filters.datePeriod = preset.date_period;
  }
  if (preset.min_date) {
    filters.minDate = preset.min_date;
  }
  if (preset.max_date) {
    filters.maxDate = preset.max_date;
  }
  if (preset.sources) {
    filters.sources = preset.sources.map(({ id }) => id);
  }
  if (preset.product_hierarchies) {
    filters.productHierarchies = preset.product_hierarchies.map(({ id }) => id);
  }
  if (preset.hierarchy_groups) {
    filters.productHierarchyGroups = preset.hierarchy_groups.map(
      ({ id }) => id
    );
  }
  if (preset.source_groups) {
    filters.sourceGroups = preset.source_groups.map(({ id }) => id);
  }

  if (preset.db_concepts) {
    filters.ontologyConcepts = preset.db_concepts.map(({ id }) => id);
  }
  if (preset.tags) {
    filters.tags = preset.tags;
  }

  return filters;
};

export const selectFilterPreset =
  (event, { value }) =>
  (dispatch, getState) => {
    if (value) {
      const preset = getState().search.viewFacetFilterPresets?.[
        getState().view?.viewFacet?.id
      ]?.find(({ id }) => id === value);
      if (preset) {
        dispatch(
          updateSearchFilters(presetToSearchFilters(preset), true, true)
        );
      }
    }
    dispatch({
      type: actionTypes.SELECT_FILTER_PRESET,
      value,
      viewFacetId: getState().view.viewFacet.id,
    });
  };

export const resetAllFilters = () => (dispatch, getState) => {
  dispatch(updateSearchFilters({}, true, true));
  return dispatch(selectFilterPreset(null, { value: null }));
};

export /**
 * On locationQuery change : this is triggered on landing on search page and based on url parameters
 *
 * @param {*} locationQuery
 * @param {boolean} [doSearch=false]
 */
const updateLocationQuery =
  (locationQuery, doSearch = false) =>
  (dispatch, getState) => {
    if (locationQuery.preset) {
      return dispatch(
        selectFilterPreset(null, { value: locationQuery.preset })
      );
    }
    let ontologyConcepts = [];
    if (locationQuery.concept) {
      ontologyConcepts = [locationQuery.concept];
    }
    const searchTexts =
      typeof locationQuery.text === 'string'
        ? [locationQuery.text]
        : locationQuery.text || [];
    const productHierarchies =
      typeof locationQuery.ph === 'string'
        ? [locationQuery.ph]
        : locationQuery.ph || [];
    const productHierarchyGroups =
      typeof locationQuery.phg === 'string'
        ? [locationQuery.phg]
        : locationQuery.phg || [];
    const searchFilters = {
      textSearchValues: searchTexts.map((word) => [word]),
      ontologyConcepts,
      productHierarchies,
      productHierarchyGroups,
    };
    searchFilters.polarities = [];
    if (locationQuery.positive) {
      searchFilters.polarities.push('positives');
    }
    if (locationQuery.negative) {
      searchFilters.polarities.push('negatives');
    }
    if (locationQuery.neutral) {
      searchFilters.polarities.push('neutrals');
    }
    if (locationQuery.tags) {
      searchFilters.tags = locationQuery.tags;
    }

    // Reset state if location query is non empty or if there are filters currently
    const resetState =
      Object.keys(locationQuery).length > 0 ||
      nFiltersPositiveSelector(getState());

    if (resetState) dispatch(selectFilterPreset(null, { value: null }));
    return dispatch(
      updateSearchFilters(
        searchFilters,
        doSearch || Object.keys(locationQuery).length > 0,
        resetState
      )
    );
  };

export const searchLoadingSelector = createLoadingSelector([
  actionTypes.FETCH_FILTER_PRESETS_REQUEST,
  actionTypes.SEARCH_REVIEW_CHUNKS_REQUEST,
  actionTypes.PAGINATE_REVIEW_CHUNKS_REQUEST,
]);
