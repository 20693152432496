import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  allVisibleQuestionsAreConditionedSelectorFactory,
  surveyQuestionsItemsSelectorFactory,
} from 'selectors/survey';
import {
  channelHiddenQuestionsSelector,
  setChannelHiddenQuestions,
} from 'store/monitor/channelsSlice';

import Accordion, { AccordionToggleTitle } from 'components/ui/Accordion';
import { ErrorMessage, WarningMessage } from 'components/ui/Message';
import { MediumPaddedSegment } from 'components/ui/Segment';
import CheckboxList from 'components/ui/inputs/checkbox-list/index';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

function ChannelQuestionFilter({ campaignId }) {
  const dispatch = useDispatch();
  const [configureFilteredQuestions, setConfigureFilteredQuestions] =
    useState(false);

  const questions = useSelector(
    surveyQuestionsItemsSelectorFactory(campaignId, false, false, true)
  );
  const onSetHiddenQuestionIds = useCallback(
    (questionIds) => dispatch(setChannelHiddenQuestions(questionIds)),
    []
  );
  const hiddenQuestionIds = useSelector(channelHiddenQuestionsSelector);
  const onSetConfigureFilteredQuestions = useCallback((value) => {
    if (!value && hiddenQuestionIds.length !== 0) {
      onSetHiddenQuestionIds([]);
    }
    setConfigureFilteredQuestions(value);
  }, []);
  const conditionedQuestionsWarning = useSelector(
    allVisibleQuestionsAreConditionedSelectorFactory(
      campaignId,
      hiddenQuestionIds
    )
  );
  const isQuestionNotHidden = useCallback(
    (item) => !hiddenQuestionIds.includes(item.value),
    [hiddenQuestionIds]
  );
  const onSelectItem = useCallback(
    ({ value }) =>
      onSetHiddenQuestionIds(hiddenQuestionIds.filter((id) => id !== value)),
    [hiddenQuestionIds]
  );
  const onUnselectItem = useCallback(
    ({ value }) => onSetHiddenQuestionIds([...hiddenQuestionIds, value]),
    [hiddenQuestionIds]
  );
  const isEmptySurvey = questions?.length === hiddenQuestionIds?.length;
  return (
    <MediumPaddedSegment
      style={{
        width: '100%',
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Accordion key="concept">
        <AccordionToggleTitle
          articleId={66}
          title={
            <Trans
              render={capitalizedTranslation}
              id="select-questions-to-display"
            />
          }
          help={t({ id: 'campaign-channel.select-questions-to-display-help' })}
          active={configureFilteredQuestions}
          onToggle={onSetConfigureFilteredQuestions}
          accordionToggleTestId="bo-create-channels-accordion-toggle"
        />
        <Accordion.Content
          active={configureFilteredQuestions}
          style={{
            padding: 0,
            display: configureFilteredQuestions ? 'flex' : null,
            flexDirection: 'column',
            background: isEmptySurvey ? svars.colorDangerLightest : null,
          }}
        >
          <CheckboxList
            style={{ maxHeight: svars.listMaxHeight, height: 'auto' }}
            items={questions}
            selectedItems={hiddenQuestionIds}
            onSelectItem={onSelectItem}
            onUnselectItem={onUnselectItem}
            isSelectedItem={isQuestionNotHidden}
            placeholder={t({ id: 'search-users-for-annotation' })}
            headerStyle={{
              padding: `${svars.spaceSmaller} ${svars.spaceNormal}`,
            }}
          />
          <ErrorMessage
            style={{ padding: svars.spaceNormalLarge }}
            message={t({ id: 'no-questions-to-display' })}
            show={isEmptySurvey}
          />
          <WarningMessage
            style={{ padding: svars.spaceNormalLarge }}
            message={t({ id: 'campaign-channel.all-questions-conditioned' })}
            show={!isEmptySurvey && conditionedQuestionsWarning}
          />
        </Accordion.Content>
      </Accordion>
    </MediumPaddedSegment>
  );
}

ChannelQuestionFilter.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

ChannelQuestionFilter.defaultProps = {};

export default ChannelQuestionFilter;
