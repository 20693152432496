import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Dimmer, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { SmallSpacePaddedGrid } from 'components/ui/Grid';
import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import { MediumPaddedSegment } from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonAccent,
  ButtonTransparentAccent,
} from 'components/ui/button';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import {
  TABLE_FILENAME_MAX_N_CHARS,
  exportAsXls,
} from 'components/ui/button/export-as/ExportAsXls';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import Range from 'components/ui/inputs/Range';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';
import ReactTableHeader from 'components/ui/table/ReactTableHeader';
import ReactTable, { columnPropTypes } from 'components/ui/table/ReactTableUi';
import { PlaceholderCell } from 'components/ui/table/cells/ReactTableCell';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const TEMPLATE_DATA = [{}, {}];

const ColumnCheckbox = styled(Checkbox)`
  &&& {
    height: 100%;
    width: 100%;
    display: flex;
    cursor: pointer;
  }
`;
function ColumnSelectRow({ checked, doCheck, label }) {
  return (
    <Grid.Column
      width={16}
      style={{ marginLeft: '5%', padding: svars.spaceNormal }}
    >
      <ColumnCheckbox
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        label={label}
        checked={checked}
        onClick={() => doCheck(!checked)}
      />
    </Grid.Column>
  );
}

const TableHeaderContainer = styled.label`
  width: calc(100% - 2 * ${svars.spaceNormal});
  font-size: ${svars.fontSizeMedium};
  margin: ${svars.spaceNormal};
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
`;

function SelectableTableHeader({
  selected,
  disabled,
  onToggle,
  i18nId,
  children,
}) {
  return (
    <ReactTableHeader style={{ margin: 0 }}>
      <Checkbox
        style={{
          width: '100%',
          cursor: 'pointer',
          padding: `${svars.spaceNormal} 0`,
        }}
        checked={selected}
        onClick={(event) => {
          event.stopPropagation();
          if (onToggle) onToggle(!selected);
        }}
        disabled={disabled}
        label={
          <TableHeaderContainer>
            {children || (i18nId && <Trans id={i18nId} />) || null}
          </TableHeaderContainer>
        }
      />
    </ReactTableHeader>
  );
}

SelectableTableHeader.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  i18nId: commonPropTypes.i18nText,
  children: PropTypes.node,
};
SelectableTableHeader.defaultProps = {
  selected: true,
  disabled: false,
  onToggle: null,
  children: null,
  i18nId: null,
};

ColumnSelectRow.propTypes = {
  checked: PropTypes.bool.isRequired,
  doCheck: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

const formatTogglableColumn =
  (selectedIds, toggleColumnSelection) =>
  ({
    id,
    accessor,
    formatter,
    label,
    width,
    minWidth,
    Header: columnHeader,
    label: columnLabel,
  }) => ({
    id,
    key: id,
    accessor,
    formatter,
    label: (label && capitalize(label)) || id,
    exportWidth: id === 'date' ? 30 : (minWidth || width) / 2,
    Header: (
      <SelectableTableHeader
        selected={selectedIds.includes(id)}
        onToggle={toggleColumnSelection(id)}
      >
        {columnLabel || columnHeader}
      </SelectableTableHeader>
    ),
    Cell: PlaceholderCell,

    minWidth: 130,
    maxWidth: 190,
  });

function SearchExportModal({
  onClose,
  modalIsOpen,
  columns,
  extraColumns,
  excludeIds,
  defaultFilename,
  nMaxChunks,
  nChunksLimit,
  getRawDataAction,
}) {
  const dispatch = useDispatch();

  const maxExportChunks = Math.min(nMaxChunks, nChunksLimit);
  const minNChunks = Math.min(maxExportChunks, 10);

  const [nChunks, setNChunks] = useState(minNChunks);
  const [selectedIds, setSelectedIds] = useState([
    ...(columns
      ?.filter(({ id }) => !excludeIds.includes(id))
      ?.map(({ id }) => id) || []),
    ...(extraColumns?.map(({ id }) => id) || []),
  ]);
  const [downloading, setIsDownloading] = useState(false);

  const [filename, setFilename] = useState(defaultFilename);

  const toggleColumnSelection = useCallback(
    (columnId) => () => {
      if (selectedIds.includes(columnId)) {
        setSelectedIds(selectedIds.filter((id) => id !== columnId));
      } else {
        setSelectedIds([...selectedIds, columnId]);
      }
    },
    [selectedIds]
  );
  const togglableColumns = useMemo(() => {
    const formatColumn = formatTogglableColumn(
      selectedIds,
      toggleColumnSelection
    );
    return [...columns, ...(extraColumns || [])]
      .filter(({ id }) => !excludeIds.includes(id))
      .map(formatColumn);
  }, [columns, selectedIds, toggleColumnSelection]);
  useEffect(() => {
    if (downloading) {
      dispatch(getRawDataAction({ nChunks }))
        .then((data) => {
          exportAsXls(
            data,
            togglableColumns.filter(({ key, tagSetId }) =>
              selectedIds.includes(key === 'tags' ? tagSetId : key)
            ),
            null,
            filename,
            capitalize(t({ id: 'data' })),
            onClose,
            t({ id: 'export-chunk-disclaimer' }),
            nChunks
          );
        })
        .then(() => {
          setIsDownloading(false);
        });
    }
  }, [downloading]);

  const nChunksLimitReached = nChunks >= nChunksLimit;
  const maxExportChunksIsMultipleOf10 = parseInt(maxExportChunks / 10, 10);
  const maxRangeValue =
    (maxExportChunksIsMultipleOf10 === maxExportChunks / 10 &&
      maxExportChunks) ||
    (minNChunks < 10 && minNChunks) ||
    maxExportChunksIsMultipleOf10 * 10 + 10;
  const rangeStep = maxExportChunks < 20 ? 1 : 10;
  return (
    <Modal icon="search" closeIcon onClose={onClose} open={modalIsOpen}>
      <Modal.Header>
        <Trans id="export-extracts" />
      </Modal.Header>
      <Modal.Content
        id="search-export-modal-content"
        style={{
          minHeight: '63vh',
          padding: `${svars.spaceNormal} ${svars.spaceMediumLarge}`,
        }}
      >
        <SmallSpacePaddedGrid style={{ paddingTop: 0 }}>
          <Grid.Row as={MediumPaddedSegment} verticalAlign="middle">
            <Grid.Column>
              <Header style={{ padding: `${svars.spaceNormal} 0` }}>
                <Trans id="filename" />
              </Header>
              <LimitedTextInput
                value={filename}
                onChange={(e, { value }) => setFilename(value)}
                maxCharacters={TABLE_FILENAME_MAX_N_CHARS}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            as={MediumPaddedSegment}
            style={{ marginBottom: svars.spaceMedium }}
          >
            <Grid.Column width={16}>
              <Header style={{ padding: `${svars.spaceMedium} 0` }}>
                <Trans id="columns-selection" />
              </Header>
            </Grid.Column>
            <Grid.Column
              width={16}
              style={{ paddingBottom: svars.spaceMedium }}
            >
              <ReactTable
                data={TEMPLATE_DATA}
                columns={togglableColumns}
                PaginationComponent={() => null}
                style={{
                  height: 'fit-content',
                  width: '100%',
                  borderRadius: svars.borderRadius,
                }}
                pageSize={3}
                resizable={false}
                sortable={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row as={MediumPaddedSegment}>
            <Grid.Column width={5}>
              <Header style={{ padding: `${svars.spaceMedium} 0` }}>
                <Trans id="number-of-extracts" />
              </Header>
            </Grid.Column>

            <Grid.Column width={7} floated="right" verticalAlign="middle">
              <Range
                min={minNChunks}
                max={maxRangeValue}
                onChange={({ target: { value } }) =>
                  setNChunks(value === maxRangeValue ? maxExportChunks : value)
                }
                step={rangeStep}
                type="range"
                value={nChunks}
                style={{
                  margin: `0 ${svars.spaceNormal}`,
                  width: '100%',
                }}
              />
            </Grid.Column>
            <Grid.Column
              width={3}
              textAlign="right"
              verticalAlign="middle"
              style={{
                fontSize: svars.fontSizeXLarge,
                color: nChunksLimitReached ? svars.colorWarning : 'inherit',
              }}
            >
              {nChunks}
            </Grid.Column>
            <Grid.Column
              width={1}
              textAlign="center"
              verticalAlign="middle"
              style={{ fontSize: svars.fontSizeXLarge }}
            >
              {nChunksLimitReached && (
                <HelpTooltip
                  help={<Trans id="export-limited-to-150-chunks" />}
                  size="tiny"
                  position="top center"
                  trigger={
                    <Icon
                      style={{ color: svars.colorWarning }}
                      name="warning circle"
                    />
                  }
                  mountNode={document.querySelector(
                    '#search-export-modal-content'
                  )}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </SmallSpacePaddedGrid>
        {downloading ? (
          <>
            <Loader />
            <Dimmer active={downloading} inverted />
          </>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <AnalyticsAwareButton
          gaCategory="Dashboard - search"
          gaAction="Export - cancel"
          gaLabel={`n=${nChunks}`}
          inputComponent={ButtonTransparentAccent}
          type="submit"
          onClick={onClose}
        >
          <Trans id="cancel" />
        </AnalyticsAwareButton>
        <AnalyticsAwareButton
          gaCategory="Dashboard - search"
          gaAction="Export"
          inputComponent={ButtonAccent}
          type="submit"
          loading={downloading}
          onClick={() => !downloading && setIsDownloading(true)}
        >
          <Trans id="export" />
        </AnalyticsAwareButton>
      </Modal.Actions>
    </Modal>
  );
}

SearchExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool,
  columns: PropTypes.arrayOf(columnPropTypes).isRequired,
  extraColumns: PropTypes.arrayOf(columnPropTypes),
  excludeIds: PropTypes.arrayOf(PropTypes.string),
  nMaxChunks: PropTypes.number,
  // Maximum number of authorized chunks to export
  nChunksLimit: PropTypes.number,
  defaultFilename: PropTypes.string.isRequired,
  // Redux action that optionnally query and return raw data asynchronously
  // Params : nChunks (number of chunks to export)
  getRawDataAction: PropTypes.func.isRequired,
};

SearchExportModal.defaultProps = {
  nChunksLimit: 150,
  modalIsOpen: false,
  excludeIds: [],
  nMaxChunks: null,
  extraColumns: [],
};

function SearchExportButtonWithModal({ disabled, style, ...props }) {
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const toggleSearchModal = useCallback(
    (open) => () => setExportModalIsOpen(open),
    []
  );
  return (
    <>
      <PrimaryTabButton
        onClick={toggleSearchModal(true)}
        icon={
          <Icon
            style={{ width: '2rem' }}
            size="large"
            name="file excel outline"
          />
        }
        labelPosition="right"
        content={<Trans id="export" />}
        disabled={disabled}
        style={style}
      />
      {exportModalIsOpen ? (
        <SearchExportModal
          {...props}
          modalIsOpen={exportModalIsOpen}
          onClose={toggleSearchModal(false)}
        />
      ) : null}
    </>
  );
}
SearchExportButtonWithModal.propTypes = {
  style: commonPropTypes.style,
  disabled: PropTypes.bool,
  ...omit(SearchExportModal.propTypes, ['onClose', 'modalIsOpen']),
};
SearchExportButtonWithModal.defaultProps = {
  disabled: false,
  ...SearchExportModal.defaultProps,
};

export default SearchExportButtonWithModal;
