import { t } from '@lingui/macro';

import DistributionVisualization from 'components/customer/visualization/DistributionVisualization';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const getValueCell = (Cell) =>
  // eslint-disable-next-line func-names
  function (value) {
    return <Cell value={value} />;
  };
function Distribution({
  chartId,
  height,
  width,
  data,
  testid,
  noDataMessage,
  meta: { cellKey, Cell, indicatorKey, minColor, maxColor, doSortSlices },
  formatters: { colorFormatter, labelFormatter },
}) {
  const layout =
    (height && width && height / width < 0.7 && 'horizontal') || 'vertical';
  const tooltipHeaderField = {
    key: cellKey,
    isEntity: false,
    formatter: labelFormatter,
    content: Cell ? getValueCell(Cell) : undefined,
  };
  return (
    <DistributionVisualization
      layout={layout}
      height={height}
      data={data}
      loading={false}
      chartId={chartId}
      data-testid={testid}
      cellKey={cellKey}
      indicatorKey={indicatorKey}
      legendFormatter={labelFormatter}
      tooltipHeaderField={tooltipHeaderField}
      othersCellLabel={(nSource) => t`+ ${nSource} others`}
      minColor={minColor}
      maxColor={maxColor}
      colorFormatter={colorFormatter}
      noDataMessage={noDataMessage}
      doSortSlices={doSortSlices}
    />
  );
}

Distribution.propTypes = commonPropTypes.dashboardChart;
Distribution.defaultProps = {
  data: [],
  meta: {},
  formatters: {},
};
Distribution.minHeight = svars.chartHeight;
Distribution.minWidth = svars.chartMinWidth;

export default Distribution;
