import { Trans, msg, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import Header from 'components/ui/Header';
import HelpTooltip from 'components/ui/HelpTooltip';
import Segment from 'components/ui/Segment';

import { getInterpolatedColor } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import { floatFormatter, percentFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

const N_CHUNKS_RANGES = [
  {
    range: [-0.01, 0.01],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-steady' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-steady',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-steady',
    }),
  },

  {
    range: [0.01, 0.05],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-slight-grow' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-slight-grow',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-slight-grow',
    }),
  },
  {
    range: [0.05, 0.2],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-grow' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-grow',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-grow',
    }),
  },
  {
    range: [0.2, null],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-strong-grow' }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-strong-grow',
    }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-strong-grow',
    }),
  },
  {
    range: [-0.05, -0.01],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-slight-decrease' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-slight-decrease',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-slight-decrease',
    }),
  },
  {
    range: [-0.2, -0.05],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-decrease' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-decrease',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-decrease',
    }),
  },
  {
    range: [null, -0.2],
    nChunksHeaderText: msg({ id: 'dashboard-card.volume-strong-decrease' }),
    positiveNChunksHeaderText: msg({
      id: 'dashboard-card.positive-points-volume-strong-decrease',
    }),
    negativeNChunksHeaderText: msg({
      id: 'dashboard-card.negative-points-volume-strong-decrease',
    }),
  },
];
const AVERAGE_SENTIMENT_RANGES = [
  {
    range: [-0.01, 0.01],
    headerText: msg({ id: 'dashboard-card.sentiment-steady' }),
  },
  {
    range: [0.01, 0.05],
    headerText: msg({ id: 'dashboard-card.sentiment-slight-grow' }),
  },
  {
    range: [0.05, 0.3],
    headerText: msg({ id: 'dashboard-card.sentiment-grow' }),
  },
  {
    range: [0.3, null],
    headerText: msg({ id: 'dashboard-card.sentiment-strong-grow' }),
  },
  {
    range: [-0.05, -0.01],
    headerText: msg({ id: 'dashboard-card.sentiment-slight-decrease' }),
  },
  {
    range: [-0.3, -0.05],
    word: 'baisse',
    headerText: msg({ id: 'dashboard-card.sentiment-decrease' }),
  },
  {
    range: [null, -0.3],
    headerText: msg({ id: 'dashboard-card.sentiment-strong-decrease' }),
  },
];

const selectRange = (value, ranges) =>
  ranges.find(
    ({ range: [minValue, maxValue] }) =>
      (minValue == null || value >= minValue) &&
      (maxValue == null || value <= maxValue)
  );

const getVolumeIconInfo = (
  isCardPositive,
  isCardIncreasing,
  iconColor = null
) => ({
  iconName: 'signal',
  iconColor:
    iconColor ||
    (isCardPositive && svars.absoluteMaxColor) ||
    svars.absoluteMinColor,
  iconStyle: isCardIncreasing ? null : { transform: 'scale(-1, 1)' },
});

export const selectDashboardCards = (kpis) => {
  if (!(kpis && kpis.increases) || !kpis?.increases?.['3M']?.[0]?.n_chunks) {
    return [
      {
        key: 'emptyCard-1',
        headerText: t({ id: 'not-enough-data' }),
        extraText: t({ id: 'cardItem-not-enough-data-for-quarterly-trend' }),
      },
    ];
  }
  const { n_chunks, n_positive, n_negative, average_sentiment } =
    kpis.increases['3M'][0];
  const cards = [];
  let isCardPositive;
  const getPercentage = (value) =>
    `${typeof value === 'number' && value > 0 ? '+' : ''}${percentFormatter(
      value * 100
    )}`;

  // Volume card
  isCardPositive = n_chunks > 0;
  const nChunksRange = selectRange(n_chunks, N_CHUNKS_RANGES);
  const growthRate = getPercentage(n_chunks);
  cards.push({
    key: 'cardItem-volume',
    headerText: nChunksRange.nChunksHeaderText,
    extraText: t`${growthRate} sur les 3 derniers mois`,
    ...getVolumeIconInfo(isCardPositive, isCardPositive, svars.volumeColor),
  });

  // Sentiment card
  isCardPositive = average_sentiment > 0;
  const sentimentRange = selectRange(
    average_sentiment,
    AVERAGE_SENTIMENT_RANGES
  );
  const variationRate = floatFormatter(average_sentiment);
  cards.push({
    key: 'cardItem-sentiment',
    iconName: 'bolt',
    iconColor: getInterpolatedColor(average_sentiment),
    headerText: sentimentRange.headerText,
    extraText: msg`Le sentiment a varié de ${variationRate} sur les 3 derniers mois`,
  });

  // Positive/negative volume card
  const positivePolarityIsSelected =
    Math.abs(n_positive) > Math.abs(n_negative);

  const polarityWord = positivePolarityIsSelected
    ? t({ id: 'positive-points' })
    : t({ id: 'negative-points' });
  const polarityIndicator = positivePolarityIsSelected
    ? n_positive
    : n_negative;
  // Card is positive if:
  // - selected polarity is positive and number of positives is increasing;
  // - selected polarity is negative and number of negatives is decreasing;
  isCardPositive =
    (positivePolarityIsSelected && polarityIndicator > 0) ||
    (!positivePolarityIsSelected && polarityIndicator < 0);
  const polarizedNChunksRange = selectRange(polarityIndicator, N_CHUNKS_RANGES);
  const polarityValue = getPercentage(polarityIndicator);
  cards.push({
    key: 'cardItem-polarity-volume',
    headerText: positivePolarityIsSelected
      ? polarizedNChunksRange.positiveNChunksHeaderText
      : polarizedNChunksRange.negativeNChunksHeaderText,
    extraText: t`${polarityWord} : ${polarityValue} sur les 3 derniers mois`,
    ...getVolumeIconInfo(positivePolarityIsSelected, polarityIndicator > 0),
  });
  return cards;
};

function DashboardCard({
  headerText,
  extraText,
  iconName,
  iconColor,
  iconStyle,
}) {
  return (
    <Segment
      textAlign="center"
      centered={1}
      style={{
        minHeight: svars.cardMinHeight,
        maxWidth: svars.cardMaxWidth,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: `${svars.spaceMediumLarge} ${svars.spaceNormal}`,
        paddingTop: '20%',
      }}
    >
      <Header
        icon
        style={{
          fontSize: svars.fontSizeBase,
          paddingBottom: svars.spaceSmall,
        }}
      >
        <Icon
          name={iconName}
          style={{
            fontSize: svars.largeIconFontSize,
            color: iconColor,
            ...iconStyle,
          }}
        />
        <Trans id={headerText} />
      </Header>
      <Segment.Inline
        style={{
          fontSize: svars.fontSizeSmaller,
          lineHeight: svars.lineHeightSmall,
          minHeight: '3rem',
        }}
      >
        <Trans id={extraText} />
        {extraText && (
          <HelpTooltip
            style={{ lineHeight: '1' }}
            position="bottom right"
            mouseEnterDelay={50}
            mouseLeaveDelay={50}
            help={t({ id: 'dashboard-kpi.90-days-variation-description' })}
          />
        )}
      </Segment.Inline>
    </Segment>
  );
}

DashboardCard.propTypes = {
  iconName: PropTypes.string,
  headerText: commonPropTypes.i18nText,
  extraText: commonPropTypes.i18nText,
  iconColor: PropTypes.string,
  iconStyle: commonPropTypes.style,
};
DashboardCard.defaultProps = {
  iconName: 'signal',
  iconColor: svars.colorSecondary,
  headerText: null,
  extraText: null,
  iconStyle: null,
};

export default DashboardCard;
