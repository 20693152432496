import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RowActions from 'components/ui/ManagementList/RowActions';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { FlexCell } from 'components/ui/ManagementList/header';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

export const FlexRow = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ManagementListRowFactory = (
  onDelete,
  rowActions,
  allowDelete = true,
  onRename = null,
  onCopy = null,
  onArchive = null,
  isCopyDisabled = null,
  onSetDefault = null
) => {
  function ManagementListRow({ fields, item, index }) {
    const adminActionDisabled =
      (typeof allowDelete === 'function' && !allowDelete(item)) || !allowDelete;
    const menuItems = [
      ...(onRename
        ? [
            {
              content: t({ id: 'rename' }),
              icon: 'edit outline',
              onClick: () => onRename(item),
            },
          ]
        : []),
      ...(onCopy
        ? [
            {
              content: t({ id: 'copy' }),
              icon: 'copy',
              onClick: () => onCopy(item),
              disabled: isCopyDisabled?.(item),
            },
          ]
        : []),
      ...(onArchive
        ? [
            {
              content: item.archived
                ? t({ id: 'unarchive' })
                : t({ id: 'archive' }),
              icon: 'archive',
              iconStyle: { color: svars.colorWarning },
              onClick: () => onArchive(item),
              disabled: adminActionDisabled,
            },
          ]
        : []),
      ...(onSetDefault
        ? [
            {
              content: t({ id: 'set-default' }),
              icon: 'star',
              onClick: () => onSetDefault(item),
            },
          ]
        : []),
      ...(onDelete
        ? [
            {
              content: t({ id: 'delete' }),
              icon: 'delete',
              iconStyle: { color: svars.colorDanger },
              onClick: () => onDelete(item),
              disabled: adminActionDisabled,
            },
          ]
        : []),
    ];
    const finalRowActions =
      typeof rowActions === 'function' ? rowActions(item) : rowActions;
    const finalMenuItems = item?.menuItems || menuItems;
    return (
      <FlexRow>
        {fields.map(
          ({
            id,
            Cell,
            accessor,
            formatter,
            colorFormatter,
            header,
            getCellProps,
            width,
            centered,
          }) => {
            const value = accessor(item);
            const color = colorFormatter ? colorFormatter(item) : null;
            return (
              <FlexCell
                width={width}
                verticalAlign="middle"
                key={`${header}-${id}`}
                centered={centered}
              >
                {Cell ? (
                  <Cell
                    value={value}
                    color={color}
                    formatter={formatter}
                    {...getCellProps?.(item)}
                  />
                ) : (
                  value
                )}
              </FlexCell>
            );
          }
        )}
        <RowActions actions={finalRowActions} item={item} />
        {finalMenuItems.length ? (
          <RowMenu upward={index > 0} items={finalMenuItems} />
        ) : null}
      </FlexRow>
    );
  }
  ManagementListRow.propTypes = {
    index: PropTypes.number,
    item: PropTypes.shape({
      name: PropTypes.string,
      app_route: PropTypes.string,
      id: PropTypes.string,
      create_date: PropTypes.string,
      archived: PropTypes.bool,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          icon: PropTypes.string,
          onClick: PropTypes.func,
          disabled: PropTypes.bool,
          iconStyle: commonPropTypes.style,
        })
      ),
    }).isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  ManagementListRow.defaultProps = {
    index: null,
  };
  return ManagementListRow;
};

export default ManagementListRowFactory;
