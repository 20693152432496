import { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { EMPTY_CATEGORY_LABEL } from 'reducers/entityLabelFormatter';

import { Cell, Pie, ResponsiveContainer, Tooltip } from 'recharts';

import { getEmptySvgPattern } from 'components/customer/visualization/SvgSentimentGradient';
import CustomLegend from 'components/ui/visualization/CustomLegend';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import interactiveLegend from 'components/ui/visualization/InteractiveLegend';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';
import RechartsTooltip, {
  tooltipFieldPropTypes,
} from 'components/ui/visualization/RechartsTooltip';
import { PieChart } from 'components/ui/visualization/StyledChart';

import { getGradientNColors } from 'utils/colors';
import commonPropTypes from 'utils/commonPropTypes';
import { onePrecisionFloatFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

const getGradientColorFormatter = (nColors, minColor, maxColor) => {
  const colors = getGradientNColors(minColor, maxColor, Math.max(3, nColors));
  return (name, index) =>
    name === EMPTY_CATEGORY_LABEL
      ? svars.emptyCategoryGradientUrl
      : colors[index];
};

const MAX_LEGEND_ITEMS_HORIZONTAL = 12;
const MAX_LEGEND_ITEMS_VERTICAL = 5;
const N_MAX_CELLS = 15;

const sortSlicesDefault = (indicatorKey) => (pred1, pred2) =>
  -(pred1[indicatorKey] - pred2[indicatorKey]);

function DistributionVisualization({
  data,
  indicatorKey,
  legendFormatter,
  tooltipExtraFieldsKeys,
  cellKey,
  colorFormatter,
  othersCellLabel,
  minColor,
  maxColor,
  height,
  width,
  loading,
  chartId,
  tooltipExtraFields,
  tooltipHeaderField,
  layout,
  noDataMessage,
  doSortSlices,
}) {
  const [legendState, setLegendState] = useState(
    interactiveLegend.getInitialState()
  );
  const onLegendStateChange = useCallback(
    (newState) => setLegendState(newState),
    [setLegendState]
  );
  const sortedData = useMemo(() => {
    if (!data) return data;
    let newSorted = [...data];
    if (doSortSlices) {
      const sortMethod =
        typeof doSortSlices === 'function'
          ? doSortSlices
          : sortSlicesDefault(indicatorKey);
      newSorted = newSorted.sort(sortMethod);
    }
    let extraCell = null;
    if (newSorted.length > N_MAX_CELLS) {
      const extraCellIndicators = newSorted
        .slice(N_MAX_CELLS - 1, data.length)
        .reduce(
          (acc, item) => [
            acc[0] + item[indicatorKey],
            acc[1] + item[indicatorKey] * item.average_sentiment,
          ],
          [0, 0]
        );
      extraCell = {
        [indicatorKey]: extraCellIndicators[0],
        average_sentiment: extraCellIndicators[1] / extraCellIndicators[0],
        [cellKey]:
          typeof othersCellLabel === 'string'
            ? othersCellLabel
            : othersCellLabel(data.length - N_MAX_CELLS + 1),
      };
      newSorted = [...newSorted.slice(0, N_MAX_CELLS - 1), extraCell];
    }
    return newSorted;
  }, [
    data,
    cellKey,
    legendFormatter,
    indicatorKey,
    othersCellLabel,
    legendState,
  ]);

  if (!data) return <div />;
  if (!(data && data.length)) {
    return <EmptyDataVisualization message={noDataMessage} />;
  }
  const indicatorCumulated = data.reduce(
    (accumulator, item) => accumulator + item[indicatorKey],
    0
  );
  const getColor = (value, be) =>
    colorFormatter?.(value, cellKey) ||
    getGradientColorFormatter(sortedData.length, minColor, maxColor)(value, be);
  const maxLegendItems =
    layout === 'vertical'
      ? MAX_LEGEND_ITEMS_VERTICAL
      : MAX_LEGEND_ITEMS_HORIZONTAL;
  return loading ? (
    <LoadingDataVisualization height={height} width={width} />
  ) : (
    <ResponsiveContainer height={height} id={chartId}>
      <PieChart margins={{ right: 150 }}>
        {getEmptySvgPattern()}
        <Pie
          data={sortedData}
          labelLine={false}
          dataKey={indicatorKey}
          animationBegin={0}
          animationDuration={800}
          innerRadius="30%"
          paddingAngle={1}
          minAngle={2.5}
        >
          {sortedData.map((entry, chartIndex) => {
            const percent = (entry[indicatorKey] / indicatorCumulated) * 100;
            const opacity = interactiveLegend.getOpacity(
              legendState,
              chartIndex,
              0.03,
              1
            );
            const cellValue = `(${
              percent < 0.1 ? '<0.1' : onePrecisionFloatFormatter(percent)
            }%) `;
            const color = getColor(entry?.[cellKey], chartIndex);
            return (
              <Cell
                name={
                  <>
                    <span style={{ display: 'inline-flex', minWidth: '47px' }}>
                      {cellValue}
                    </span>
                    {legendFormatter(entry[cellKey]) || entry[cellKey]}
                  </>
                }
                legendType="none"
                key={cellKey}
                fill={color}
                strokeOpacity={opacity}
                fillOpacity={opacity}
              />
            );
          })}
        </Pie>

        <Tooltip
          content={
            <RechartsTooltip
              fieldKeys={[...tooltipExtraFieldsKeys, indicatorKey]}
              extraFields={tooltipExtraFields}
              headerField={tooltipHeaderField}
            />
          }
        />
        {legendFormatter &&
          interactiveLegend({
            layout,
            align: layout === 'vertical' ? 'center' : 'right',
            verticalAlign: layout === 'vertical' ? 'bottom' : 'middle',
            legendState,
            onStateChange: onLegendStateChange,
            useChartIndex: true,
            wrapperStyle: {
              width: 'auto',
              maxWidth: layout === 'vertical' ? '100%' : '65%',
              maxHeight:
                data.length >= maxLegendItems && layout === 'vertical'
                  ? '45%'
                  : '95%',
              margin: 'auto',
              overflowY: 'auto',
            },
            content: <CustomLegend maxItems={maxLegendItems} />,
          })}
      </PieChart>
    </ResponsiveContainer>
  );
}

DistributionVisualization.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  // A method to extract the key of each 'cell' of the distribution (i.e. each slice of the pie)
  cellKey: PropTypes.string,
  // The indicator to plot distribution for (usually volume (`n_chunks`))
  indicatorKey: PropTypes.string.isRequired,
  // The label to display in legend for each slice
  legendFormatter: PropTypes.func,
  tooltipExtraFieldsKeys: PropTypes.arrayOf(PropTypes.string),
  tooltipExtraFields: PropTypes.arrayOf(tooltipFieldPropTypes),
  tooltipHeaderField: tooltipFieldPropTypes,
  // Colors
  colorFormatter: PropTypes.func,
  minColor: PropTypes.string,
  maxColor: PropTypes.string,

  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  othersCellLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  chartId: PropTypes.string.isRequired,
  // Whether to display pie and legend as column or row
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  // Message to display when there is no data
  noDataMessage: commonPropTypes.i18nText,
  // Whether to sort slices by indicator value
  doSortSlices: PropTypes.bool,
};
DistributionVisualization.defaultProps = {
  data: undefined,
  legendFormatter: undefined,
  tooltipExtraFieldsKeys: [],
  tooltipExtraFields: [],
  tooltipHeaderField: null,
  colorFormatter: null,
  loading: false,
  othersCellLabel: undefined,
  height: '100%',
  width: '100%',
  layout: 'vertical',
  minColor: svars.minVolumeColor,
  maxColor: svars.volumeStrokeColor,
  cellKey: null,
  noDataMessage: undefined,
  doSortSlices: true,
};

export default DistributionVisualization;
