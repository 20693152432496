import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { Grid, Icon, List, ListItem, Rating } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  useProductHierarchyGroupLabelFormatter,
  useSourceGroupLabelFormatter,
  useTagColorFormatter,
  useTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { selectTransformedFilteringTags } from 'selectors/facet';

import moment from 'moment';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { GridRowNoPadding } from 'components/ui/Grid';
import HelpTooltip from 'components/ui/HelpTooltip';
import Link, { RouterLinkV2 } from 'components/ui/Link';
import RowMenu from 'components/ui/ManagementList/RowMenu';
import { LightText } from 'components/ui/Text';
import {
  AnalyticsAwareButton,
  ButtonSecondaryAccent,
} from 'components/ui/button';
import FlagItem from 'components/ui/cells/FlagItem';
import { PrimaryLabel } from 'components/ui/inputs/Label';
import ListChips from 'components/ui/visualization/ListChips';

import commonPropTypes from 'utils/commonPropTypes';
import { formatPeriod } from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const FacetDetails = styled.div`
  display: ${(props) => (props.active ? 'inherit' : 'none')};
  padding: ${svars.spaceLarge};
`;

const FavoriteIcon = styled(Rating)`
  &&& {
    i {
      outline: none;
    }
  }
`;

const GridBlock = styled(Grid)`
  display: block;
`;

function ViewFacetDetails({ viewFacet }) {
  const productHierarchyGroupLabelFormatter =
    useProductHierarchyGroupLabelFormatter();
  const sourceGroupLabelFormatter = useSourceGroupLabelFormatter();
  const tagLabelFormatter = useTagLabelFormatter();
  const tagLabelColorFormatter = useTagColorFormatter();
  const transformedFilteringTags = useSelector((state) =>
    selectTransformedFilteringTags(state, viewFacet)
  );

  return (
    <GridBlock>
      <GridRowNoPadding>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Trans
              render={capitalizedTranslation}
              id="base-product-services-group"
            />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {productHierarchyGroupLabelFormatter(
            viewFacet.base_product_hierarchy_group?.id
          )}
        </Grid.Column>
      </GridRowNoPadding>
      {viewFacet.comparative_product_hierarchy_groups &&
      viewFacet.comparative_product_hierarchy_groups.length ? (
        <GridRowNoPadding>
          <Grid.Column width={7} textAlign="right">
            <PrimaryLabel>
              <Trans
                render={capitalizedTranslation}
                id="comparative-product-services-groups"
              />
            </PrimaryLabel>
          </Grid.Column>
          <Grid.Column width={9}>
            {viewFacet.comparative_product_hierarchy_groups
              ? viewFacet.comparative_product_hierarchy_groups
                  .map(({ id }) => productHierarchyGroupLabelFormatter(id))
                  .join(', ')
              : '-'}
          </Grid.Column>
        </GridRowNoPadding>
      ) : null}
      <GridRowNoPadding>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Icon name="newspaper outline" />
            <Trans render={capitalizedTranslation} id="source(s)" />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {sourceGroupLabelFormatter(viewFacet?.source_group?.id)}
        </Grid.Column>
      </GridRowNoPadding>
      <GridRowNoPadding>
        <Grid.Column width={7} textAlign="right">
          <PrimaryLabel>
            <Icon name="calendar alternate" />
            <Trans render={capitalizedTranslation} id="period" />
          </PrimaryLabel>
        </Grid.Column>
        <Grid.Column width={9}>
          {(viewFacet && !viewFacet.period_type && (
            <Trans render={capitalizedTranslation} id="all-time" />
          )) ||
            formatPeriod(viewFacet) ||
            null}
        </Grid.Column>
      </GridRowNoPadding>
      {viewFacet?.restitution_language ? (
        <Grid.Row style={{ padding: '0' }}>
          <Grid.Column width={7} textAlign="right">
            <PrimaryLabel>
              {/* <Icon name="newspaper outline" /> */}
              <Trans
                render={capitalizedTranslation}
                id="restitution-language"
              />
            </PrimaryLabel>
          </Grid.Column>
          <Grid.Column width={9}>
            <FlagItem language={viewFacet?.restitution_language} />
          </Grid.Column>
        </Grid.Row>
      ) : null}
      {!isEmpty(transformedFilteringTags) ? (
        <GridRowNoPadding>
          <Grid.Column width={7} textAlign="right">
            <PrimaryLabel>
              <Icon name="tags" />
              <Trans render={capitalizedTranslation} id="tag-list" />
            </PrimaryLabel>
          </Grid.Column>
          <Grid.Column width={9} style={{ display: 'flex', maxWidth: '100%' }}>
            <List>
              {Object.values(transformedFilteringTags).map((tags_per_tagset) =>
                tags_per_tagset && tags_per_tagset.length > 0 ? (
                  <ListItem key={tags_per_tagset[0].id}>
                    <ListChips
                      size="tiny"
                      items={tags_per_tagset}
                      field="id"
                      formatter={tagLabelFormatter}
                      colorFormatter={tagLabelColorFormatter}
                    />
                  </ListItem>
                ) : null
              )}
            </List>
          </Grid.Column>
        </GridRowNoPadding>
      ) : null}
    </GridBlock>
  );
}
ViewFacetDetails.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
};

function NoFacetPage() {
  return (
    <EmptyDataPage
      headerText={<Trans id="no-analysis-found" />}
      actionComponent={
        <div>
          <Trans>
            Commencez par{' '}
            <RouterLinkV2 base="true" to="/facets/create">
              créer une analyse
            </RouterLinkV2>
            , ou naviguez vers{' '}
            <RouterLinkV2 base="true" to="/facets/product-groups">
              vos groupes de produit(s) / service(s)
            </RouterLinkV2>
            .
          </Trans>
        </div>
      }
    />
  );
}

NoFacetPage.propTypes = {};

export const viewFacetRowFactory = (
  showDetailsViewFacetIds,
  onViewFacetDelete,
  onSetViewFacetAsFavorite,
  onShowViewFacetDetails
) => {
  function ViewFacetRow({ item }) {
    const viewFacet = item;
    const lastUpdateDate = viewFacet.aggregates_update_date
      ? viewFacet.aggregates_update_date &&
        moment(viewFacet.aggregates_update_date)
      : null;
    const viewFacetIsReady = viewFacet.status === 'ready';
    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7} verticalAlign="middle">
              {viewFacet.name}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: svars.fontColorLightest,
                  marginRight: svars.spaceMedium,
                }}
              >
                <span>
                  <Trans id="created-on@fem." />
                  {` ${moment(viewFacet.create_date).format(svars.dateFormat)}`}
                </span>
                <span>
                  (
                  <Link base onClick={onShowViewFacetDetails(viewFacet.id)}>
                    <Trans
                      id={
                        showDetailsViewFacetIds.includes(viewFacet.id)
                          ? t({ id: 'less-details' })
                          : t({ id: 'more-details' })
                      }
                    />
                  </Link>
                  )
                </span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <span style={{ display: 'inline-block', textAlign: 'start' }}>
                {lastUpdateDate
                  ? lastUpdateDate.format(svars.dateTimeDateFormat)
                  : '-'}
                <br />
                <span style={{ color: svars.fontColorLightest }}>
                  {lastUpdateDate
                    ? lastUpdateDate.format(svars.dateTimeTimeFormat)
                    : '-'}
                </span>
              </span>
            </Grid.Column>
            <Grid.Column width={1} textAlign="center" verticalAlign="middle">
              <HelpTooltip
                help={[
                  viewFacet.favorite
                    ? t({ id: 'view-facet-list.remove-from-favorites' })
                    : t({ id: 'view-facet-list.add-to-favorites' }),
                ]}
                compact
                position="top center"
                trigger={
                  <span>
                    <FavoriteIcon
                      size="huge"
                      icon="star"
                      rating={viewFacet.favorite ? 1 : 0}
                      onRate={onSetViewFacetAsFavorite(
                        viewFacet.id,
                        !viewFacet.favorite
                      )}
                      maxRating={1}
                      style={{ margin: `0 ${svars.spaceNormal}` }}
                    />
                  </span>
                }
              />
            </Grid.Column>
            <Grid.Column
              width={4}
              floated="right"
              textAlign="right"
              verticalAlign="middle"
            >
              {viewFacetIsReady ? (
                <RouterLink to={`/facets/analyze/${viewFacet.id}/overview`}>
                  <AnalyticsAwareButton
                    data-testid="bo-visualize-analysis-button"
                    id="visualize"
                    inputComponent={ButtonSecondaryAccent}
                    gaCategory="View facet list"
                    gaAction="Go to view facet analysis"
                    gaLabel={viewFacet.id}
                    style={{ marginRight: svars.spaceMedium }}
                  >
                    <Trans id="visualize" />
                  </AnalyticsAwareButton>
                </RouterLink>
              ) : (
                <LightText
                  data-testid="bo-visualize-waiting-button"
                  style={{ marginRight: svars.spaceMedium }}
                >
                  <Trans
                    id={
                      viewFacet.status === 'computing'
                        ? t({ id: 'analysis-running' })
                        : t({ id: 'analysis-waiting' })
                    }
                  />
                </LightText>
              )}
              <RowMenu
                disabled={!viewFacetIsReady}
                items={[
                  {
                    to: `/facets/update/${viewFacet.id}`,
                    dataTestid: 'bo-dropdown-menu-edit-button',
                    content: t({ id: 'modify' }),
                    icon: 'setting',
                  },
                  {
                    to: `/facets/copy/${viewFacet.id}`,
                    content: t({ id: 'copy' }),
                    icon: 'clone outline',
                  },
                  {
                    content: t({ id: 'delete' }),
                    icon: 'delete',
                    iconColor: 'red',
                    onClick: onViewFacetDelete(viewFacet),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FacetDetails active={showDetailsViewFacetIds.includes(viewFacet.id)}>
          <ViewFacetDetails viewFacet={viewFacet} />
        </FacetDetails>
      </>
    );
  }
  ViewFacetRow.propTypes = {
    item: commonPropTypes.viewFacet.isRequired,
  };
  return ViewFacetRow;
};
export default viewFacetRowFactory;
