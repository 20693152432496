import { useCallback, useEffect, useState } from 'react';

import { Trans } from '@lingui/macro';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LightHeader } from 'components/ui/Header';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import { ErrorMessage } from '../Message';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: ${svars.spaceNormal};
`;

function CustomizationInput({
  label,
  validate,
  onChange,
  value,
  errorMessage,
  placeholder,
  style,
  InputComponent,
  ...inputProps
}) {
  const [inputValue, setInputValue] = useState(value);
  const [isInvalid, setIsInvalid] = useState(false);
  const debouncedValueChange = useCallback(
    debounce((newValue) => {
      if (newValue === '') {
        setIsInvalid(false);
        return;
      }
      let validUrl;
      if (validate) {
        validUrl = validate(newValue);
        if (validUrl) {
          setIsInvalid(false);
        } else {
          setIsInvalid(validUrl === false ? true : validUrl);
          return;
        }
      } else {
        validUrl = newValue;
      }
      const finalUrl = validUrl === true ? newValue : validUrl;
      if (finalUrl !== value && finalUrl !== '') {
        onChange(finalUrl);
      }
      if (finalUrl !== newValue) {
        setInputValue(finalUrl);
      }
    }, 500),
    []
  );

  const onInputChange = useCallback(
    (e, { value: newValue }) => {
      setInputValue(newValue);
      debouncedValueChange(newValue);
    },
    [inputValue, debouncedValueChange]
  );

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <Container style={style}>
      {label ? (
        <LabelContainer>
          <LightHeader
            content={<Trans id={label} />}
            style={{
              margin: `${svars.spaceNormal} ${svars.spaceMediumLarge} 0 0`,
              marginRight: svars.spaceMediumLarge,
            }}
          />
        </LabelContainer>
      ) : null}
      <InputComponent
        style={{ width: '100%' }}
        value={inputValue}
        onChange={onInputChange}
        error={isInvalid}
        placeholder={placeholder}
        {...inputProps}
      />
      {errorMessage && <ErrorMessage message={errorMessage} show={isInvalid} />}
    </Container>
  );
}

CustomizationInput.propTypes = {
  label: commonPropTypes.i18nText,
  // Function to validate the input value
  // - Return true if the value is valid
  // - Return false if the value is invalid
  // - Return a string if the value is valid but needs to be modified
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  InputComponent: PropTypes.elementType,
};

CustomizationInput.defaultProps = {
  label: null,
  validate: null,
  errorMessage: null,
  placeholder: null,
  style: {},
  InputComponent: TextInput,
};

export default CustomizationInput;
