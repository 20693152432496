import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { maybeGetCampaigns } from 'actions/campaign';
import { maybeFetchTagSets } from 'actions/entities';
import { maybeFetchViewFacets } from 'actions/facet';

const listLoaderFactory = (fetchAction) =>
  function LoaderComponent() {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchAction());
      dispatch(maybeFetchTagSets());
    }, []);
    return null;
  };

export const CampaignListLoader = listLoaderFactory(maybeGetCampaigns);
export const FacetListLoader = listLoaderFactory(maybeFetchViewFacets);

export default listLoaderFactory;
