import { useCallback } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { NPS_QUESTION_ITEM } from 'selectors/survey';

import { SmallHeader } from 'components/ui/Header';
import { StyledPopup } from 'components/ui/HelpTooltip';
import { SelectBox } from 'components/ui/inputs/Dropdown';
import PeriodSelect from 'components/ui/inputs/PeriodSelect';
import { DualRange } from 'components/ui/inputs/Range';
import { NFiltersIcon } from 'components/ui/search/FiltersAccordion';
import FiltersSummary from 'components/ui/search/FiltersSummary';

import commonPropTypes from 'utils/commonPropTypes';
import { dayFormatter, numberFormatter } from 'utils/formatter';

import * as svars from 'assets/style/variables';

export function MultiChoiceCondition({
  questionOptionItems,
  values,
  setConditionValues,
}) {
  const handleChange = (e, { value }) => {
    setConditionValues(value);
  };
  return (
    <SelectBox
      placeholder={t({ id: 'select-option(s)' })}
      style={{
        margin: `${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceMedium}`,
        minWidth: '200px',
        flexGrow: 1,
      }}
      fluid
      multiple
      value={values}
      onChange={handleChange}
      options={questionOptionItems}
      noResultMessage={t({ id: 'no-more-options-to-select' })}
      selectBoxTestId="bo-combobox-for-select-conditional-option"
    />
  );
}

MultiChoiceCondition.propTypes = {
  questionOptionItems: PropTypes.arrayOf(commonPropTypes.items).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  setConditionValues: PropTypes.func.isRequired,
};

export function DateCondition({ values, question, setConditionValues }) {
  const handleFilterChange = useCallback(
    (newValues) => {
      setConditionValues({
        ...values,
        ...(newValues.minDate ? { min_date: newValues.minDate } : {}),
        ...(newValues.maxDate ? { max_date: newValues.maxDate } : {}),
      });
    },
    [values, setConditionValues]
  );
  return (
    <PeriodSelect
      minDate={values.min_date}
      maxDate={values.max_date}
      rangeMinDate={question.min_date}
      rangeMaxDate={question.max_date}
      onFilterChange={handleFilterChange}
      onSetFilterActive={() => {}}
      disabled={false}
    />
  );
}

DateCondition.propTypes = {
  values: PropTypes.shape({
    min_date: PropTypes.string,
    max_date: PropTypes.string,
  }).isRequired,
  question: commonPropTypes.question.isRequired,
  setConditionValues: PropTypes.func.isRequired,
};

export function ScoredCondition({ question, values, setConditionValues }) {
  const handleFromChange = (value) => {
    setConditionValues({ ...values, min_value: value });
  };

  const handleToChange = (value) => {
    setConditionValues({ ...values, max_value: value });
  };
  const minValue = question.type === NPS_QUESTION_ITEM.value ? 0 : 1;
  return (
    <DualRange
      fromValue={values.min_value}
      toValue={values.max_value}
      onFromChange={handleFromChange}
      onToChange={handleToChange}
      fromMin={minValue}
      fromMax={question.n_elements ? question.n_elements - 1 + minValue : 10}
      toMin={minValue}
      toMax={question.n_elements ? question.n_elements - 1 + minValue : 10}
    />
  );
}

ScoredCondition.propTypes = {
  question: commonPropTypes.question.isRequired,
  values: PropTypes.shape({
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  }).isRequired,
  setConditionValues: PropTypes.func.isRequired,
};

const mapSummaryProps = ({
  min_date,
  max_date,
  min_value,
  max_value,
  empty,
  type,
}) => {
  const toReturn = {};
  if (min_date) {
    toReturn.minDate = min_date;
  }
  if (max_date) {
    toReturn.maxDate = max_date;
  }
  if (min_value) {
    toReturn.minValue = min_value;
  }
  if (max_value) {
    toReturn.maxValue = max_value;
  }
  if (empty) {
    toReturn.empty = empty;
  }
  if (type) {
    toReturn.type = type;
  }
  return toReturn;
};
export function ConditionValueSummary({
  type,
  values,
  labelFormatter,
  ...others
}) {
  let formatter;
  let finalValues = mapSummaryProps(others);
  if (type === 'DateDisplayCondition') {
    formatter = dayFormatter;
  } else if (type === 'MultiChoiceDisplayCondition') {
    formatter = (it) => labelFormatter(it.id);
    finalValues = values;
  } else {
    formatter = (number) => numberFormatter(number, '...');
  }

  return (
    <StyledPopup
      compact
      inverted
      trigger={
        <span>
          <NFiltersIcon
            content={
              values?.length || (
                <Icon
                  name="info circle"
                  style={{ color: svars.colorPrimary,fontSize: svars.fontSizeLarger }}
                />
              )
            }
          />
        </span>
      }
      content={
        <>
          <SmallHeader style={{ marginBottom: svars.spaceSmaller }}>
            <Trans id="selected-values" /> :
          </SmallHeader>
          <FiltersSummary.OneFilter
            value={finalValues}
            formatter={formatter}
            padded={false}
            multi
          />
        </>
      }
    />
  );
}

ConditionValueSummary.propTypes = {
  question: commonPropTypes.question.isRequired,
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multi: PropTypes.bool,
  type: PropTypes.string.isRequired,
  labelFormatter: PropTypes.func,
};

ConditionValueSummary.defaultProps = {
  values: null,
  multi: null,
  labelFormatter: null,
};
