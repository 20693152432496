import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

const withPolling =
  (pollingAction, duration = 2000, leading = true) =>
  (BaseComponent) => {
    function WrapperComponent(props) {
      const dispatch = useDispatch();
      useEffect(() => {
        const dataPolling = setInterval(() => {
          dispatch(pollingAction());
        }, duration);

        if (leading) {
          dispatch(pollingAction());
        }

        return () => {
          clearInterval(dataPolling);
        };
      }, [duration, leading]);

      return <BaseComponent {...props} />;
    }

    WrapperComponent.propTypes = { pollingAction: PropTypes.func.isRequired };
    return WrapperComponent;
  };

export default withPolling;
