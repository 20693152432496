import { api } from 'actions/utils';

import { actionTypes as viewActionTypes } from './view';

export const actionTypes = {
  CREATE_VIEW_FACET_REQUEST: 'CREATE_VIEW_FACET_REQUEST',
  CREATE_VIEW_FACET_SUCCESS: 'CREATE_VIEW_FACET_SUCCESS',
  CREATE_VIEW_FACET_FAILURE: 'CREATE_VIEW_FACET_FAILURE',

  UPDATE_VIEW_FACET_REQUEST: 'UPDATE_VIEW_FACET_REQUEST',
  UPDATE_VIEW_FACET_SUCCESS: 'UPDATE_VIEW_FACET_SUCCESS',
  UPDATE_VIEW_FACET_FAILURE: 'UPDATE_VIEW_FACET_FAILURE',

  DELETE_VIEW_FACET_REQUEST: 'DELETE_VIEW_FACET_REQUEST',
  DELETE_VIEW_FACET_SUCCESS: 'DELETE_VIEW_FACET_SUCCESS',
  DELETE_VIEW_FACET_FAILURE: 'DELETE_VIEW_FACET_FAILURE',

  FETCH_VIEW_FACETS_REQUEST: 'FETCH_VIEW_FACETS_REQUEST',
  FETCH_VIEW_FACETS_SUCCESS: 'FETCH_VIEW_FACETS_SUCCESS',
  FETCH_VIEW_FACETS_FAILURE: 'FETCH_VIEW_FACETS_FAILURE',

  SET_VIEW_FACET_AS_FAVORITE_REQUEST: 'SET_VIEW_FACET_AS_FAVORITE_REQUEST',
  SET_VIEW_FACET_AS_FAVORITE_FAILURE: 'SET_VIEW_FACET_AS_FAVORITE_FAILURE',
  SET_VIEW_FACET_AS_FAVORITE_SUCCESS: 'SET_VIEW_FACET_AS_FAVORITE_SUCCESS',
};

const makeViewFacetApiPayload = ({
  viewFacetId = null,
  name,
  sourceGroupId,
  baseProductHierarchyGroupId,
  comparativeProductHierarchyGroupIds,
  periodType,
  period,
  minDate,
  maxDate,
  restitutionLanguage,
  selectedTagSetItems,
}) => {
  const payload = {
    name,
    source_group: { id: sourceGroupId },
    period_type: periodType,
    // /!\ the restituation MUST be null and not undefined as undefined would not appear in the payload
    // thus won't allow updating from a value to null
    restitution_language: restitutionLanguage || null,
    period,
    min_date:
      periodType === 'custom' && minDate != null ? `${minDate}T00:00` : null,
    max_date:
      periodType === 'custom' && maxDate != null ? `${maxDate}T00:00` : null,
    base_product_hierarchy_group: { id: baseProductHierarchyGroupId },
    comparative_product_hierarchy_groups:
      comparativeProductHierarchyGroupIds &&
      comparativeProductHierarchyGroupIds.length
        ? comparativeProductHierarchyGroupIds.map((id) => ({ id }))
        : null,
    analyzed_tag_sets: Object.keys(selectedTagSetItems).map((id) => ({ id })),
    filtering_tags: Object.values(selectedTagSetItems).flatMap((tagIds) =>
      tagIds.map((id) => ({ id }))
    ),
  };
  if (viewFacetId) payload.id = viewFacetId;
  return payload;
};

export const fetchViewFacets = () => async (dispatch, getState) => {
  let viewFacetsQuery;
  dispatch({ type: actionTypes.FETCH_VIEW_FACETS_REQUEST });
  try {
    viewFacetsQuery = await api.get('facets');
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_VIEW_FACETS_FAILURE });
  }
  return dispatch({
    type: actionTypes.FETCH_VIEW_FACETS_SUCCESS,
    viewFacets: viewFacetsQuery.data,
  });
};

export const maybeFetchViewFacets = () => async (dispatch, getState) => {
  if (!getState().facet.loaded.includes('viewFacets')) {
    dispatch(fetchViewFacets());
  }
};

export const createViewFacet = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_VIEW_FACET_REQUEST });
  const apiPayload = makeViewFacetApiPayload(payload);

  let viewFacetRequest;
  try {
    viewFacetRequest = await api.post('facets', apiPayload);
  } catch (error) {
    return dispatch({ type: actionTypes.CREATE_VIEW_FACET_FAILURE });
  }
  return dispatch({
    type: actionTypes.CREATE_VIEW_FACET_SUCCESS,
    viewFacet: viewFacetRequest.data,
    filters: payload,
  });
};

export const updateViewFacet = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_VIEW_FACET_REQUEST });
  const apiPayload = makeViewFacetApiPayload(payload);
  let viewFacetRequest;
  try {
    viewFacetRequest = await api.put('facets', apiPayload);
  } catch (error) {
    return dispatch({ type: actionTypes.CREATE_VIEW_FACET_FAILURE });
  }
  dispatch({
    type: viewActionTypes.RESET_VIEW_FACET_AGGREGATES,
    viewFacetId: viewFacetRequest.data.id,
  });
  return dispatch({
    type: actionTypes.UPDATE_VIEW_FACET_SUCCESS,
    viewFacet: viewFacetRequest.data,
    filters: payload,
  });
};

export const deleteViewFacet = (viewFacetId) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_VIEW_FACET_REQUEST });
  try {
    await api.delete(`facets/${viewFacetId}`);
  } catch (error) {
    return dispatch({
      type: actionTypes.DELETE_VIEW_FACET_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.DELETE_VIEW_FACET_SUCCESS,
    viewFacetId,
  });
};

export const setViewFacetAsFavorite =
  (viewFacetId, favorite) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_VIEW_FACET_AS_FAVORITE_REQUEST });
    try {
      await api.post('facets-favorite', {
        id: viewFacetId,
        value: favorite,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.SET_VIEW_FACET_AS_FAVORITE_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    return dispatch({
      type: actionTypes.SET_VIEW_FACET_AS_FAVORITE_SUCCESS,
      viewFacetId,
      value: favorite,
    });
  };
