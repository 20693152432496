import { useSelector } from 'react-redux';

import { msg, t } from '@lingui/macro';

import {
  useChannelLabelFormatter,
  useUserOrTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { formElementLabelFormatterSelectorFactory } from 'selectors/campaign';
import { tagColorFormatterSelector } from 'selectors/entities';
import {
  questionAnswerColorFormatterFactory,
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
} from 'selectors/survey';
import { hasMoreThanOneScoredQuestionSelector } from 'store/monitor/monitorSlice';

import {
  EmptyPlaceholderValueCell,
  FormattedCell,
  NumberCell,
} from 'components/ui/table/cells/ReactTableCell';

import {
  incrementFormatterFactory,
  onePrecisionFloatFormatter,
} from 'utils/formatter';

import * as svars from 'assets/style/variables';

import {
  enrichFieldProps,
  useSurveyQuestionsAlternativeDistributionOptions,
} from '../useCampaignFields';
import Kpi, { kpiAccessorFactory } from './chart/Kpi';
import QuestionScoresTable from './chart/QuestionScoresTable';
import {
  channelDistributionChartConfigFactory,
  distributionByChartConfigFactory,
  tagFormsChartConfigFactory,
  timeSeriesChartConfigFactory,
  volumeChartConfig,
} from './chart/commonChartConfiguration';

export const surveyQuestionsChartConfigFactory = (
  questionAnswerLabelFormatter,
  questionLabelFormatter,
  questionAnswerColorFormatter
) =>
  distributionByChartConfigFactory(
    (data) => data.questions,
    (item) => item?.value,
    'question-distribution-select',
    t({ id: 'question-distribution.no-data-message' }),
    questionAnswerLabelFormatter,
    questionAnswerColorFormatter,
    useSurveyQuestionsAlternativeDistributionOptions,
    questionLabelFormatter,
    svars.competitionEntityColors[3],
    svars.competitionEntityColors[1],
    t({ id: 'answer' }),
    250,
    ''
  );

const getSurveyCampaignMonitoringDashboardConfig = (campaignId) => {
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const userOrTagLabelFormatter = useUserOrTagLabelFormatter();
  const formElementLabelFormatter = useSelector(
    formElementLabelFormatterSelectorFactory(campaignId)
  );
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, false, true)
  );
  const textQuestionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, false, false, true)
  );
  const questionAnswerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const questionAnswerColorFormatter = useSelector(
    questionAnswerColorFormatterFactory(campaignId)
  );

  const hasScore = useSelector(hasMoreThanOneScoredQuestionSelector);

  return [
    {
      row: 0,
      height: 3,
      children: [
        {
          row: 0,
          col: 0,
          width: 3,
          children: [
            {
              row: 0,
              col: '0-0',
              height: 1.5,
              ...volumeChartConfig,
            },
            {
              row: 0,
              col: '0-1',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t({ id: 'score' }),
              titleHelperHeader: t({ id: 'score-helper-header' }),
              titleHelper: t({ id: 'score-helper' }),
              accessor: kpiAccessorFactory('score'),
              meta: {
                color: svars.scoreColor,
                formatter: onePrecisionFloatFormatter,
                increaseFormatter: incrementFormatterFactory(
                  onePrecisionFloatFormatter
                ),
                emptyDataMessage: hasScore
                  ? msg({ id: 'campaign-monitoring.no-scored-answer' })
                  : msg({ id: 'campaign-monitoring.no-scored-configured' }),
              },
            },
          ],
        },
        {
          width: 7,
          row: 0,
          col: 1,
          ...timeSeriesChartConfigFactory({
            id: 'score',
            label: t({ id: 'score' }),
            accessor: 'score',
            Cell: EmptyPlaceholderValueCell,
            centered: true,
          }),
        },
        {
          row: 0,
          col: 2,
          width: 5,
          ...channelDistributionChartConfigFactory(channelLabelFormatter),
        },
      ],
    },
    {
      row: 1,
      height: 3,
      children: [
        ...(hasScore
          ? [
              {
                row: 1,
                col: 0,
                height: 3,
                width: 8,
                title: t({ id: 'scores-per-question-title' }),
                component: QuestionScoresTable,
                accessor: (data) => data.meanScorePerQuestion,
                meta: {
                  marged: false,
                  columns: [
                    {
                      accessor: 'id',
                      id: 'id',
                      Cell: FormattedCell,
                      Header: null,
                      formatter: questionLabelFormatter,
                      minWidth: 70,
                      sortable: false,
                    },
                    enrichFieldProps(
                      {
                        centered: true,
                        accessor: 'averageScore',
                        id: 'averageScore',
                        Cell: NumberCell,
                        helpMessage: t({ id: 'average-score-header-help' }),
                        minWidth: 25,
                        width: 1,
                        maxWidth: 60,
                        sortable: true,
                      },
                      true
                    ),
                  ],
                  defaultSorted: [{ id: 'id', desc: false }],
                },
                makeTableData: ({ data, meta }) => ({
                  data,
                  columns: meta.columns.map((column) => ({
                    ...column,
                    formatter:
                      column.accessor === 'id'
                        ? textQuestionLabelFormatter
                        : null,
                    Header:
                      column.accessor === 'id'
                        ? t({ id: 'question' })
                        : t({ id: 'average-score' }),
                  })),
                }),
                overrideToggleDataModal: true,
                overrideExportAsImage: true,
              },
            ]
          : []),
        {
          row: 1,
          col: 1,
          width: 8,
          ...surveyQuestionsChartConfigFactory(
            questionAnswerLabelFormatter,
            textQuestionLabelFormatter,
            questionAnswerColorFormatter
          ),
        },
        {
          row: 1,
          col: 2,
          width: 8,
          ...tagFormsChartConfigFactory(
            userOrTagLabelFormatter,
            formElementLabelFormatter,
            tagColorFormatter
          ),
        },
      ],
    },
  ];
};
export default getSurveyCampaignMonitoringDashboardConfig;
