import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import { updateQuestionSetting } from 'actions/survey';

import InlineFormField from 'components/ui/form/InlineFormField';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

import { HeartIcon, StarIcon } from './QuestionDummies';
import ScoredQuestionSettings from './ScoredQuestionSettings';

const MIN_N_ELEMENTS = 4;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${svars.spaceMedium};
`;

const numberOfElementsOptions = Array.from({ length: 8 }, (_, i) => ({
  key: i + MIN_N_ELEMENTS,
  text: i + MIN_N_ELEMENTS,
  value: i + MIN_N_ELEMENTS,
}));

const shapeOptions = [
  {
    key: 'star',
    value: 'star',
    // text: 'Star',
    text: <StarIcon size={22} color={svars.colorYellowWarning} />,
  },
  {
    key: 'heart',
    value: 'heart',
    // text: 'Heart',
    text: <HeartIcon size={20} color={svars.colorDanger} />,
  },
];

function RatingQuestionSettings({ question, campaignId, questionId }) {
  const dispatch = useDispatch();

  const createCallback = useCallback(
    (setting) =>
      (e, { value }) =>
        dispatch(updateQuestionSetting(campaignId, questionId, setting, value)),
    [dispatch, campaignId, questionId]
  );

  const onNumberOfElementsChange = createCallback('n_elements');
  const onShapeChange = createCallback('shape');
  return (
    <>
      <Container>
        <InlineFormField
          labelId={t({ id: 'shape' })}
          input={
            <StyledDropdown
              data-testid="bo-shape-styled-dropdown"
              fluid={null}
              compact="true"
              placeholder={t({ id: 'select-shape' })}
              selection
              options={shapeOptions}
              value={question.shape}
              onChange={onShapeChange}
            />
          }
        />
        <InlineFormField
          noMargin
          labelId={t({ id: 'number-of-options' })}
          input={
            <StyledDropdown
              data-testid={`bo-number-of-options-${question.n_elements}`}
              search
              fluid={null}
              compact="true"
              placeholder={t({ id: 'select-number-of-elements' })}
              selection
              style={{ width: svars.inputWidthXSmall }}
              options={numberOfElementsOptions}
              value={question.n_elements}
              onChange={onNumberOfElementsChange}
            />
          }
        />
      </Container>
      <Divider fitted />

      <ScoredQuestionSettings
        question={question}
        campaignId={campaignId}
        questionId={questionId}
      />
    </>
  );
}

RatingQuestionSettings.propTypes = {
  question: commonPropTypes.question,
  campaignId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
};
RatingQuestionSettings.defaultProps = {
  question: {},
};

export default RatingQuestionSettings;
